import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/main.css";
import LogSnag from '@logsnag/vue';
import * as Sentry from "@sentry/vue";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://c2f5ce151750a2be5b3bb6a90f4284f3@o4508438649372672.ingest.us.sentry.io/4508438665297920",
  integrations: [
],
});

app.use(LogSnag, {
  token: import.meta.env.VITE_LOGSNAG_TOKEN,
  project: import.meta.env.VITE_LOGSNAG_PROJECT
});

app.use(router);

app.mount("#app");