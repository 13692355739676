<script>
import { StudioStore } from '../stores/StudioStore'

export default {
  props: ['pos', 'item'],
  data() {
    return {
      itemHeight: 32.8
    }
  },
  mounted() {

  },
  computed: {
    position() {
      return `transform: translate3d(${this.pos.x}px, ${this.pos.y - 58}px, 0)`
    },
    options() {
      return this.getItemActions();
    }
  },
  methods: {
    getItemActions() {
      const item = this.item;
      const index = item.getIndex();
      const baseActions = [
        {
          label: 'Show/Hide',
          action: () => item.toggleVisibility()
        },
        {
          label: 'Lock/Unlock',
          action: () => item.toggleProp('locked')
        },
        {
          label: 'Duplicate',
          action: () => StudioStore.duplicateItem(item)
        },
        {divider: true},
        {
          label: 'Move up',
          action:  () => item.moveToPosition(index + 1, index)
        },
        {
          label: 'Move back',
          action:  () => item.moveToPosition(index - 1, index)
        },
        {divider: true},
        {
          label: 'Delete',
          action:  () => this.$emit('delete-item', item)
        }
      ]

      if(item.isBackground) {
        return [
          {
            label: 'Play/Pause',
            action: () => item.toggleProp('animating')
          },
          {divider: true},
          {
            label: 'Show/Hide',
            action: () => item.toggleVisibility()
          },
          {
            label: 'Lock/Unlock',
            action: () => item.toggleProp('locked')
          },
        ]
      }

      if (item.layerType === 'effect') {
        baseActions.unshift({divider: true});
        baseActions.unshift({
          label: 'Change effect',
          action: () => {
            StudioStore.state.swapEffectItem = item;
            StudioStore.state.browsingEffects = true;
          }
        })
        if('animating' in item) {
          baseActions.unshift({
            label: 'Play/Pause',
            action: () => item.toggleProp('animating')
          })
        }
        if (item.type === 'custom') {
          baseActions.unshift({
            label: 'Edit code',
            action: () => StudioStore.state.customCodeItemId = item.local.id
          })
        }
        return baseActions;
      } else if (item.isElement) {
        return [
          {
            label: 'Add effect',
            action: () => this.$emit('add-effect', item)
          },
          {
            label: item.mask ? 'Remove Mask' : 'Use as Mask',
            action: () => item.toggleMask()
          },
          {divider: true},
          ...baseActions,
        ];
      }
    }
  }
}
</script>

<template v-if="item">
  <div class="flyout-options dropdown-options__short" ref="dropdown_options" :style="position">
    <template
      v-for="(item, index) in options"
      :key="index"
    >
      <div v-if="item.divider" class="divider"></div>
      <a v-else href="javascript:void(0)"
        class="dropdown-option"
        @click="item.action"
      >{{ item.label }}</a>
    </template>
  </div>
</template>

<style lang="scss">

.flyout-options {
  display: flex;
  position: absolute;
  min-width: 15rem;
  padding: 0.5rem;
  flex-direction: column;
  background-color: var(--menu-bg-color);
  color: var(--white);
  border-radius: 0.4rem;
  box-shadow: 0 0.5rem 1.5rem rgba(#000, 0.15);
  z-index: 99999;
  overflow: hidden;

  &.dropdown-options__short {
    overflow-y: auto;
    max-height: 60rem;
  }
}

.divider {
  height: 1px;
  background-color: var(--accent-color);
  margin: 1rem 0;
}

.dropdown-value {
  position: relative;
  display: block;
  padding: 0.7rem 2rem 0.7rem 1rem;
  border-radius: 0.4rem;
  background-color: var(--accent-color);
  width: 100%;
  height: 100%;
  line-height: 1.33333333;
}

.dropdown-icon {
  position: absolute;
  right: 0.5rem;
  top: 0.85rem;
  pointer-events: none;
}

.dropdown-option {
  display: flex;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  margin: 0 0.1rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.dropdown-option__large {
    padding: 0.8rem 1.2rem;
  }

  &:hover {
    background-color: var(--menu-hover-color);
  }
}

</style>