<script>
  import Icon from './Icon.vue';
  import AssetItem from './AssetItem.vue';
  import { StudioStore } from '../stores/StudioStore';
  import { DesignsStore } from '../stores/DesignsStore';
  import { UserStore } from '../stores/UserStore';
  import { compressThumbnail } from '../scripts/Helpers.js';
  import { CreateCommand } from '../scripts/Commands.js';
  import { Img, Effect } from '../scripts/LayerTypes.js';

  export default {
  components: {
    Icon,
    AssetItem
  },
  props: ['currentSize'],
  data() {
    return {
      state: StudioStore.state,
      initialItem: null
    }
  },
  mounted() {
    document.addEventListener("mousedown", this.handleClickOutside);
    if(!this.state.images.length) {
      DesignsStore.getImages(UserStore.id);
    }
    if(this.selectedItem?.type === 'sdf_shape') {
      this.state.imagesBrowser.createMsdf = true;
    }
    this.initialItem = this.selectedItem;
  },
  watch: {
    selectedItem(newVal) {
      this.initialItem = newVal;
    }
  },
  beforeUnmount() {
    this.state.imagesBrowser.replaceId = '';
    this.state.imagesBrowser.createMsdf = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  },
  methods: {
    handleChooseImage(image) {
      if(this.settingEffectTexture) {
        this.selectTexture(image);
      } else if(this.state.imagesBrowser.replaceId) {
        this.replaceImage(image);
      } else if(this.state.imagesBrowser.createMsdf) {
        const effect = new Effect({
          type: 'sdf_shape',
          shape: '20',
          texture: {
            svgSrc: image.src,
            thumb: image.thumb,
            name: image.name
          }
        });
        this.generateMsdf(image, effect, () => {
          const command = new CreateCommand(effect);
          StudioStore.performAction(command);
        });

      } else {
        this.selectImage(image);
      }
    },
    selectImage(image) {
      compressThumbnail(image.thumb, 260, (result) => {
        const command = new CreateCommand(new Img({
          src: image.src,
          thumb: result,
        }));
        StudioStore.performAction(command);
        StudioStore.state.imagesBrowser.open = false;
      });
    },
    selectTexture(image) {
      if(this.state.imagesBrowser.createMsdf) {
        this.generateMsdf(image, this.item);
      } else {
        this.addCustomTexture(image);
      }
    },
    generateMsdf(image, item, callback) {
      this.state.imagesBrowser.open = false;
        let precision = +item.precision;
        let params = {
          scale: 1,
          range: 32,
          svgSize: 128,
          msdfSize: 128,
        }
        if(precision === 0) {
          params = {
            scale: 1,
            range: 16,
            svgSize: 64,
            msdfSize: 64,
          }
        } else if(precision === 2) {
          params = {
            scale: 1,
            range: 64,
            svgSize: 256,
            msdfSize: 256,
          }
        }
        DesignsStore.createMsdf(
          image.src, params
        ).then(url => {
          item.texture = {
            src: url,
            thumb: image.thumb,
            name: image.name,
            svgSrc: image.src,
            sampler: 'uCustomTexture',
          };
          if(callback) {
            callback();
          } else {
            item.getPlane().loadImage(
              url,
              {
                sampler: 'uCustomTexture',
                premultipliedAlpha: false,
                generateMipMap: false,
              },
              tex => {
                StudioStore.renderFrame();
                this.$emit('update');
              }
            );
          }
        });
    },
    addCustomTexture(image) {
      this.item.texture = {
        src: image.src,
        thumb: image.thumb,
        name: image.name,
        sampler: 'uCustomTexture'
      }
      this.item.getPlanes().forEach(plane => {
        plane.loadImage(image.src, {
          sampler: 'uCustomTexture'
        }, tex => {
          this.$emit('update');
          StudioStore.renderFrame();
          StudioStore.state.imagesBrowser.open = false;
        });
      });
    },
    replaceImage(image) {
      if(this.item) {
        this.item.src = image.src;
        compressThumbnail(image.thumb, 260, (result) => {
          this.item.thumb = result;
          this.item.loadImage();
          this.state.imagesBrowser.replaceId = '';
          this.state.imagesBrowser.open = false;
        });
      }

    },
    uploadImage(e) {
      this.state.imageUploading = true;
      this.state.imageUploadProgress = 0;
      DesignsStore.uploadImage(e);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.state.imagesBrowser.open = false;
      }
    },
  },
  computed: {
    selectedItem() { return this.state.history.find(n => n.id === this.state.imagesBrowser.replaceId) || StudioStore.getSelectedItem()},
    settingEffectTexture() { return this.selectedItem && this.selectedItem.layerType === 'effect'},
    item() { return this.selectedItem || this.initialItem }
  },
}
</script>

<template>
  <div class="modal modal__image-library modal__pop-in" :class="{'modal__right': settingEffectTexture}">
    <div class="images-container">
      <div v-if="state.imagesBrowser.createMsdf" class="block w-100">
          <p class="dialog dialog__primary flex mt-0"><Icon icon="info" class="mr-1 font-tertiary-color" /> Make sure SVG is cropped tightly, merged, and flattened.</p>
      </div>
      <div class="image-library-new">
        <button class="button button__icon flex direction-column" style="width: 100%; height: 100%">
          <Icon size="16" icon="upload" />
          <input class="upload-image" type="file" accept=".png,.jpg,.jpeg,.webp,.svg" @change="uploadImage" name="imageLoader"/>
          Upload
        </button>
      </div>
      <div v-if="state.imageUploading" class="image-library-new">
        <svg class="saving-spinner" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
          <path d="M16 8.5A7.5 7.5 0 1 1 8.5 1" stroke="currentColor"/>
        </svg>
        <span style="margin-top: 0.5rem">{{ state.imageUploadProgress }}%</span>
      </div>
      <AssetItem 
        v-for="(image, index) in state.images"
        :image="image"
        :index="index"
        :disabled="state.imagesBrowser.createMsdf && !image.name.includes('.svg')"
        :key="'image_' + index"
        @choose="handleChooseImage(image)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

.modal__image-library {
  width: 58rem;
  height: 65rem;
  top: 5.9rem;
  right: unset;
  left: 28.5rem;
  border-radius: 0.8rem;
  z-index: 99999;
  overflow: hidden;
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2rem;
    width: 100%;
    background: linear-gradient(to top, var(--bg-color), var(--bg-color-no-alpha))
  }

  &.modal__right {
    left: unset;
    right: 29rem;
  }
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding-bottom: 3rem;
  margin-left: -0.5rem;
  margin-bottom: -0.5rem;
  width: calc(100% + 1rem);
  overflow-y: auto;
  &>* {
    margin: 0.5rem;
  }
}

.image-library-new {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 13rem;
  height: 13rem;
  border: 1px dashed var(--accent-color);
  color: var(--font-secondary-color);
  border-radius: 0.4rem;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    background-color: var(--accent-color);
    color: var(--font-color);
  }
  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.image-library-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 12rem;
  height: 12rem;
  box-shadow: inset 0 0 0 0.1rem var(--accent-color);
  color: var(--font-secondary-color);
  border-radius: 0.4rem;
}

</style>