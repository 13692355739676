import {
  computeFragColor,
  vertexShader,
  UNIVERSAL_UNIFORMS,
  FLOATING_POINT,
  EASE,
  mixRadiusProperties,
  PERLIN_NOISE,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const flowFieldShader = `#version 300 es
  precision mediump float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;

  uniform sampler2D uTexture;
  uniform float uAmount;
  uniform float uScale;
  uniform float uPhase;
  uniform float uMix;
  uniform float uAngle;
  uniform float uTime;
  uniform int uEasing;
  uniform float uMixRadius;
  uniform int uMixRadiusInvert;
  uniform vec2 uPos;
  ${UNIVERSAL_UNIFORMS}
  ${EASE}
  ${PERLIN_NOISE}

  const float MAX_ITERATIONS = 16.;
  
  vec2 flow (in vec2 st) {
    float aspectRatio = uResolution.x/uResolution.y;

    vec2 mPos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    vec2 pos = uMixRadius == 1.00 ? mPos : uPos;
    float dist = ease(uEasing, max(0.,1.-distance(st * vec2(aspectRatio, 1), mPos * vec2(aspectRatio, 1)) * 4. * (1. - uMixRadius)));

    // #ifelseopen
    if (uMixRadiusInvert == 1) {
        dist = max(0., (0.5 - dist));
    }
    // #ifelseclose

    float sprd = (uScale + 0.01) / ((aspectRatio + 1.) / 2.);
    float amt = uAmount * 0.01 * dist;
    
    if(amt <= 0.) {
      return st;
    }

    for (float i = 0.; i < MAX_ITERATIONS; i++) {
        vec2 scaled = (st-0.5) * vec2(aspectRatio, 1) + (1. - pos);
        float perlin = perlin_noise(vec3((scaled-0.5) * (5. * sprd), uPhase*5. + uTime/60.))-0.5;
        float ang = (perlin * (360. * (uAngle * 6.))) * 3.1415926 / 180.;
        st += vec2(cos(ang), sin(ang)) * amt;
        st = clamp(st, 0., 1.);
    }

    return st;
}

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;
  vec4 color =  texture(uTexture, mix(uv, flow(uv), uMix));
  ${computeFragColor('color')}
}
`;

const flowFieldParams = {
  fragmentShader: flowFieldShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    spread: {
      name: 'uScale',
      type: '1f',
      value: 0.25,
    },
    deviation: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    complexity: {
      name: 'uAngle',
      type: '1f',
      value: 0.25,
    },
    phase: {
      name: 'uPhase',
      type: '1f',
      value: 0,
    },
    blend: {
      name: 'uMix',
      type: '1f',
      value: 0.5,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const FLOW = {
  id: 'flowField',
  label: 'Flow field',
  params: flowFieldParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 0.25,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    deviation: {
      label: 'Amount',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    spread: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    blend: {
      label: 'Mix',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    complexity: {
      label: 'Angle',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    phase: {
      label: 'Phase',
      value: 0,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...mixRadiusProperties,
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
