import { Vec2 } from 'curtainsjs';
import {
  vertexShader,
  UNIVERSAL_UNIFORMS,
  FLOATING_POINT,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';

const fragmentShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform vec2 uPos;
uniform float uScale;
uniform float uTime;
uniform float uAngle;
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;

const float PI = 3.1415926;

vec2 polar(vec2 uv, vec2 pos) {
  uv -= pos;
  float angle = atan(uv.y, uv.x);
  float radius = length(uv);

  float xCoord = mod((angle + uAngle * 2.0 * PI) + (uTime * 0.05) + PI, 2.0 * PI) / (2.0 * PI);

  float yCoord = radius * uScale;

  return (vec2(yCoord, fract(xCoord)));
}

void main() {
  vec2 uv = vTextureCoord;
  vec2 aspectRatio = vec2(uResolution.x/uResolution.y, 1);
  vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
  uv = polar(uv * aspectRatio, pos * aspectRatio);

  vec4 color = texture(uTexture, uv);

  fragColor = color;
}`;

export const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depth: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },

  uniforms: {
    resolution: {
      name: 'uResolution',
      type: '2f',
      value: new Vec2(1080),
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 1,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const POLAR = {
  id: 'polar',
  label: 'Polar',
  params: params,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Scale of polar warp',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
