import { SIMPLEX_NOISE, SIMPLEX_2S_NOISE } from './ShaderHelpers.js';

const STANDARD_HEADER = `precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uCustomTexture;
uniform vec2 uResolution;
uniform vec2 uMousePos;
uniform float uMouseClick;

uniform vec3 uColor1;
uniform vec3 uColor2;
uniform vec2 uPos;
uniform float uAmount;
uniform float uScale;
uniform float uFrequency;
uniform float uAngle;
uniform float uAmplitude;
uniform int uVariant;
uniform float uTime;

out vec4 fragColor;`

const BLANK = `#version 300 es
${STANDARD_HEADER}

void main() {
  vec2 uv = vTextureCoord;

  vec4 color = texture(uTexture, uv);

  fragColor = color;
}`;

const BLANK_WITH_COMMENTS = `#version 300 es
precision mediump float;

// Welcome to the Unicorn Studio code editor!
// -----------
// This is where you'll be able to write your own code to create unique and 
// custom visual effects for your digital projects using WebGL2 shader code.
// We'll cover some basic concepts in this tutorial to get you started!
// For a comprehensive guide, you might find this online book helpful: https://thebookofshaders.com/

// Define the texture coordinates for our shader.
in vec2 vTextureCoord;

// 1. Uniforms
// Uniforms are variables that are consistent across every vertex in a draw call.
// Think of them as global settings that your main application sets up, and both the vertex and fragment shaders can read them.

// uTexture: This is the texture that represents our scene.
uniform sampler2D uTexture;
// uCustomTexture: This is an optional texture you can add in the control panel.
uniform sampler2D uCustomTexture;

// uResolution: This represents the width and height of our screen in pixels.
uniform vec2 uResolution;

// uMousePos and uMouseClick: These track the current position of the mouse (x, y coordinates) and its click state respectively.
// We can use them to add interactivity to our shader.
uniform vec2 uMousePos;
uniform float uMouseClick;

// These uniforms are parameters we can adjust using our detail panel to the right.
uniform vec3 uColor1;    // Represents a RGB color
uniform vec3 uColor2;    // Represents another RGB color
uniform vec2 uPos;       // Represents a position in 2D space
uniform float uAmount;   // A general purpose variable that could be used for controlling intensity, or amount of something
uniform float uScale;    // Useful for scaling or resizing elements
uniform float uFrequency; // Useful for controlling speed or rate of changes
uniform float uAngle;    // Represents an angle
uniform float uAmplitude; // Controls the maximum displacement or variation of a variable
uniform int uVariant;    // A switch that can be used to choose between different modes or variations of your shader

// uTime: This represents the current time. It's useful for creating animations or effects that change over time.
uniform float uTime;

// This is our output color. After processing, we'll set this variable to the color we want our current pixel to be.
out vec4 fragColor;

// 2. Main function
// This is where we write the code that executes for each vertex

void main() {
  // uv: These are the coordinates of the current pixel within the texture, ranging from 0 (left or top edge) to 1 (right or bottom edge).
  vec2 uv = vTextureCoord;

  // We can move, skew, scale, distort, etc. by maniplulating the uv coords
  // Uncomment the line below to add a wave effect
  // uv.x += sin(uv.y * 10. * uScale) * uAmount;

  // Uncomment the line below to make the texture move with the mouse.
  // The texture's center will follow the mouse position.
  // uv -= uMousePos - 0.5;

  // Get the color from our texture at the location specified by our uv coordinates.
  vec4 color = texture(uTexture, uv);

  // We can adjust the red, green, blue, and alpha channels of this color.
  // Here's how you could add a red vignette effect:
  // color.r += smoothstep(0., uScale, distance(uv, uPos)) * uAmount;

  // Finally, we set the output color of our pixel. 
  // This is the color that you'll actually see in the final rendering!
  fragColor = color;
}`;

const RIPPLE = `#version 300 es
${STANDARD_HEADER}

// Easing function for smooth wave transitions
float easeInOut(float t) {
  return t < 0.5 ? 2.0 * t * t : -1.0 + (4.0 - 2.0 * t) * t;
}

// Ripple effect function
vec2 ripple(vec2 uv, vec2 pos, float amplitude, float frequency, float time) {
  float distance = length(uv - pos);
  float wave = sin(distance * frequency + time) * amplitude;
  return uv + normalize(uv - pos) * wave;
}

void main() {
  vec2 uv = vTextureCoord;

  // Calculate the ripple effect
  float rippleAmplitude = easeInOut(uAmplitude * 0.25);
  float rippleFrequency = easeInOut(uScale * 10.);
  vec2 rippled = ripple(uv, uPos, rippleAmplitude, rippleFrequency, uTime/20.);

  // Sample the texture with the ripple effect applied
  vec4 color = texture(uTexture, rippled);

  // Set the output color
  fragColor = color;
}
`;

const FBM = `#version 300 es
${STANDARD_HEADER}

// Hash function used for generating pseudorandom values
vec2 hash(vec2 p) {
    p = vec2(dot(p, vec2(127.1, 311.7)), dot(p, vec2(269.5, 183.3)));
    return -1.0 + 2.0 * fract(sin(p) * 43758.5453123);
}

// 2D Perlin noise function
float noise(vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    // Four corners in 2D of a tile
    float a = dot(hash(i), f - vec2(0.0, 0.0));
    float b = dot(hash(i + vec2(1.0, 0.0)), f - vec2(1.0, 0.0));
    float c = dot(hash(i + vec2(0.0, 1.0)), f - vec2(0.0, 1.0));
    float d = dot(hash(i + vec2(1.0, 1.0)), f - vec2(1.0, 1.0));

    // Smooth interpolation
    vec2 u = f * f * (3.0 - 2.0 * f);

    return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
}

// FBM function
float fbm(vec2 st) {
    float value = 0.0;
    float amplitude = 0.5;
    
    for (int i = 0; i < 5; i++) {
        value += amplitude * noise(st);
        st *= 2.0;
        amplitude *= uAmplitude;
    }

    return value;
}

void main() {
    vec2 uv = vTextureCoord;
    uv -= uPos;
    uv *= uScale * 5.; // Scale the UV coordinates

    // Get base color from texture
    vec4 color = texture(uTexture, uv);

    // Apply FBM pattern
    float pattern = fbm(uv + vec2(0., uTime * 0.1)); // Adding time for animation

    // Combine the color with the FBM pattern
    color.rgb = vec3(pattern + 0.5);

    fragColor = color;
}
`

const LIQUIFY = `#version 300 es
${STANDARD_HEADER}

vec2 liquify(vec2 st) {
  float xPos = 1. - uPos.y * 10.;
  float yPos = 1. - uPos.x * 10.;
  float amplitude = uAmplitude/5.;
  for(float i = 1.0; i < 4.; i++){
    st.x += (amplitude / i) *
      (cos(i * (5. * (uScale + 0.1)) * st.y + uTime*0.005 + xPos) +
      cos(i * (7. * (uScale + 0.1)) * st.y + uTime*0.01 + xPos) +
      cos(i * (9. * (uScale + 0.1)) * st.y * 2. + uTime*0.0125 + xPos)/3.);

    st.y += (amplitude / i) *
      (sin(i * (5. * (uScale + 0.1)) * st.x + uTime*0.005 + yPos) +
      sin(i * (7. * (uScale + 0.1)) * st.x + uTime*0.01 + yPos) +
      sin(i * (9. * (uScale + 0.1)) * st.x * 2. + uTime*0.0125 + yPos)/3.);
  }
  return st;
}

void main() {
  vec2 uv = vTextureCoord;

  // Sample the texture with the liquify effect applied
  vec4 color = texture(uTexture, mix(uv, liquify(uv), uAmount));

  // Set the output color
  fragColor = color;
}
`

const SIMPLEX = `#version 300 es
${STANDARD_HEADER}

${SIMPLEX_NOISE}

void main() {
  vec2 uv = vTextureCoord;

  // Set position and scale the coords
  vec2 scaled = (uv - uPos) * 7. * uScale;

  // Create the noise coords, use time for z to animate
  vec2 noiseCoords = vec2(
    snoise(vec3(scaled, uTime * 0.01)),
    snoise(vec3(scaled, uTime * 0.01 + 1.))
  );
  
  // Add noise coords to uv
  vec4 color = texture(uTexture, uv + noiseCoords * uAmount);

  // Map to clip space [0-1] and output noise to rgb value
  color.rgb = vec3(noiseCoords.x * 0.5 + 0.5);

  fragColor = color;
}
`

const SIMPLEX_2S = `#version 300 es
${STANDARD_HEADER}

${SIMPLEX_2S_NOISE}

void main() {
  vec2 uv = vTextureCoord;

  // Set position and scale the coords
  vec2 scaled = (uv - uPos) * 7. * uScale;

  vec3 noise = bccNoiseDerivatives_XYBeforeZ(vec3(scaled, uTime * 0.01)).rgb/7. + 0.5;
  vec4 color = vec4(noise, 1);
  fragColor = color;
}
`

const VORONOI = `#version 300 es
${STANDARD_HEADER}

const float PI = 3.14159265359;
mat2 rot(float a) {
  return mat2(cos(a), -sin(a), sin(a), cos(a));
}

// Hash function to generate pseudorandom values
vec2 hash(vec2 p) {
    p = vec2(dot(p, vec2(127.1, 311.7)), dot(p, vec2(269.5, 183.3)));
    return -1.0 + 2.0 * fract(sin(p) * 43758.5453123);
}

// Function to calculate Voronoi pattern
vec3 voronoi(vec2 st) {
    vec2 i_st = floor(st);
    vec2 f_st = fract(st);

    float m_dist = 1.0;  // Minimum distance
    vec2 m_point;        // Coordinates of the closest seed point

    for (int y = -1; y <= 1; y++) {
        for (int x = -1; x <= 1; x++) {
            // Neighbor place in the grid
            vec2 neighbor = vec2(float(x), float(y));

            // Random position from current + neighbor place in the grid
            vec2 point = hash(i_st + neighbor);

            // Animate the point
            point = 0.5 + 0.5 * sin(uTime * 0.1 + 6.2831 * point);

            // Vector between the pixel and the point
            vec2 diff = neighbor + point - f_st;

            // Distance to the point
            float dist = length(diff);

            // Keep the closer distance
            if (dist < m_dist) {
                m_dist = dist;
                m_point = point;
            }
        }
    }

    return vec3(m_dist, m_point.x, m_point.y);
}

void main() {
    vec2 uv = vTextureCoord;

    // Get base color from texture
    vec4 color = texture(uTexture, uv);

    uv -= uPos; // Offset by position
    uv = uv * rot(uAngle * 2. * PI); // Apply rotation
    uv *= 30. * uScale; // Apply scale

    // Calculate Voronoi pattern
    vec3 voro = voronoi(uv);

    // Random dots based on distance; modify as needed
    color.rgb = mix(color.rgb, uColor1, 1.0 - step(0.1, voro.x));
    
    // Raw voronoi pattern
    color.rgb = vec3(voro.x);

    fragColor = color;
}`

const GRAIN = `#version 300 es
${STANDARD_HEADER}

float random(vec2 seed) {
  seed.x *= uResolution.x/uResolution.y;
  return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

void main() {
  vec2 uv = vTextureCoord;
  
  // Sample the texture
  vec4 color = texture(uTexture, uv);

  // Get a random float for each channel
  vec3 grain = vec3(
    random(uv),
    random(uv + 1.),
    random(uv + 2.)
  );

  // rgb grain
  grain = (grain - 0.5) * uAmount;

  // Uncomment to see it in monotone
  // grain = vec3(grain.x) * uAmount;

  // Add it to rgb channels
  color.rgb += grain;

  fragColor = color;
}
`

const SUPER_SHADER = `#version 300 es
${STANDARD_HEADER}

const float PI = 3.14159265359;

// Rotate matrix for a uv coord
mat2 rot(float a) {
  return mat2(cos(a), -sin(a), sin(a), cos(a));
}

// Pseudo-random number generator
float random(vec2 seed) {
  return fract(sin(dot(seed, vec2(12.9898, 78.233))) * 43758.5453);
}

// Hash function used for generating pseudorandom values
vec2 hash(vec2 p) {
    p = vec2(dot(p, vec2(127.1, 311.7)), dot(p, vec2(269.5, 183.3)));
    return -1.0 + 2.0 * fract(sin(p) * 43758.5453123);
}

// Pixelate function

vec2 pixelateCoord(vec2 uv) {
  float aspectRatio = uResolution.x/uResolution.y;
  // Compute a modulated coordinate to create pixelation effect
  vec2 modulate = mod(vec2(uv.x * aspectRatio, uv.y) - uPos, (uScale + 0.01)  * 0.1);
  
  // Create a new pixelated coordinate by subtracting modulated value and adding half of scaled value
  return vec2(
    uv.x - modulate.x / aspectRatio + (0.08333 * uScale) * 0.5,
    uv.y - modulate.y + (0.08333 * uScale) * 0.5
  );
}

// Voronoi pattern function
vec3 voronoi(vec2 st) {
    vec2 i_st = floor(st);
    vec2 f_st = fract(st);

    float m_dist = 1.0;  // Minimum distance
    vec2 m_point;        // Coordinates of the closest seed point

    for (int y = -1; y <= 1; y++) {
        for (int x = -1; x <= 1; x++) {
            // Neighbor place in the grid
            vec2 neighbor = vec2(float(x), float(y));

            // Random position from current + neighbor place in the grid
            vec2 point = hash(i_st + neighbor);

            // Animate the point
            point = 0.5 + 0.5 * sin(uTime * 0.1 + 6.2831 * point);

            // Vector between the pixel and the point
            vec2 diff = neighbor + point - f_st;

            // Distance to the point
            float dist = length(diff);

            // Keep the closer distance
            if (dist < m_dist) {
                m_dist = dist;
                m_point = point;
            }
        }
    }

    return vec3(m_dist, m_point.x, m_point.y);
}

// 3D Simplex noise function
${SIMPLEX_NOISE}

// 2D Perlin noise function
float noise(vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    // Four corners in 2D of a tile
    float a = dot(hash(i), f - vec2(0.0, 0.0));
    float b = dot(hash(i + vec2(1.0, 0.0)), f - vec2(1.0, 0.0));
    float c = dot(hash(i + vec2(0.0, 1.0)), f - vec2(0.0, 1.0));
    float d = dot(hash(i + vec2(1.0, 1.0)), f - vec2(1.0, 1.0));

    // Smooth interpolation
    vec2 u = f * f * (3.0 - 2.0 * f);

    return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
}

// FBM function
float fbm(vec2 st) {
    float value = 0.0;
    float amplitude = 0.5;
    
    for (int i = 0; i < 5; i++) {
        value += amplitude * noise(st);
        st *= 2.0;
        amplitude *= uAmplitude;
    }

    return value;
}

void main() {
  vec2 uv = vTextureCoord;

  uv -= uPos; // Offset by position
  uv = uv * rot(uAngle * 2. * PI); // Apply rotation
  uv *= uScale * 2.; // Apply scale;
  uv += 0.5; // Re-center
  
  // Sample the texture
  vec4 color = texture(uTexture, uv);

  fragColor = color;
}
`

const PIXELATE = `#version 300 es
${STANDARD_HEADER}

void main() {
  vec2 uv = vTextureCoord;

  // Compute aspect ratio
  float aspectRatio = uResolution.x/uResolution.y;

  // Compute a modulated coordinate to create pixelation effect
  vec2 modulate = mod(vec2(uv.x * aspectRatio, uv.y) - uPos, (uScale + 0.01) / 12.);
  
  // Create a new pixelated coordinate by subtracting modulated value and adding half of scaled value
  vec2 pixelatedCoord = vec2(
    uv.x - modulate.x / aspectRatio + (0.08333 * uScale)/2.,
    uv.y - modulate.y + (0.08333 * uScale)/2.
  );

  // Fetch the color from the original texture using pixelated coordinates
  vec4 color = texture(uTexture, pixelatedCoord);

  // Output the final pixel color
  fragColor = color;
}
`

const LENS = `#version 300 es
${STANDARD_HEADER}

vec2 lensDistort(
  float u,
  float v,
  float uCenter,
  float vCenter,
  float lensRadius,
  float tau)
{ 
  u -= uCenter;
  v -= vCenter;

  float s = sqrt(u * u + v * v);
  if (s > lensRadius)
      return vec2(u + uCenter, v + vCenter);

  float z = sqrt(lensRadius * lensRadius - s * s);

  float uAlpha = (1.0 - (1.0 / tau)) * asin(u / lensRadius);
  float vAlpha = (1.0 - (1.0 / tau)) * asin(v / lensRadius);

  u = uCenter + z * sin(uAlpha);
  v = vCenter + z * sin(vAlpha);

  return vec2(u, v);
}

void main() {
  vec2 uv = vTextureCoord;

  vec4 color = texture(uTexture, uv);

  float aspectRatio = uResolution.x/uResolution.y;

  // Correct for aspect ratio
  uv.x = uv.x * aspectRatio - (-0.5 + 0.5 * aspectRatio);

  // Apply the lens distortion
  vec2 sphereCoords = lensDistort(
    uv.x,
    uv.y,
    uPos.x,
    uPos.y,
    uScale/2.,
    1. + uAmplitude * 9.
  );

  vec2 scaledCoords = (sphereCoords - 0.5) + 0.5;

  color = texture(uTexture, clamp(scaledCoords, 0.0, 1.0));

  fragColor = color;
}
`

const NORMAL_MAP = `#version 300 es
${STANDARD_HEADER}

vec2 random(vec2 uv) {
  uv = fract(sin(vec2(dot(uv, vec2(12.9898, 78.233)), 
                      dot(uv, vec2(63.7264, 99.3587)))) * 43758.5453);
  return -1.0 + 2.0 * uv;
}

vec4 photoshop_desaturate(vec3 color)
{
  float bw = (min(color.r, min(color.g, color.b)) + max(color.r, max(color.g, color.b))) * 0.5;
  return vec4(bw, bw, bw, 1.0);
}

float getHeight(vec2 uv) {
  vec4 col = texture(uTexture, uv);
  col = photoshop_desaturate(col.rgb);
  return col.r;
}

vec4 computeMap(vec2 uv, float offset) {
  float rotation = (-(uAngle + offset) * 360.) * 3.1415926 / 180.;
  vec2 ste = 1./uResolution;
  float height = getHeight(uv);
  vec2 dxy = height - vec2(getHeight(uv + vec2(ste.x*cos(rotation), 0.)),
                          getHeight(uv + vec2(0., ste.y*sin(rotation))));
  // use the abs function to ensure the value isn't less than 0
  vec3 color = vec3(abs(dxy * uAmount)/ste,1.0);
  // normalize color vector
  color = normalize(color);
  // adjust brightness 
  return vec4(color, height);
}


void main() {
  vec2 uv = vTextureCoord;
  vec4 heightMap = computeMap(uv, uScale);
  heightMap.rgb *= 2.;
  
  // vec4 color = texture(uTexture, uv);
  // color.rgb += heightMap.r;
  // color.rgb -= heightMap.g;
  
  fragColor = heightMap;
}
`;




export const CUSTOM_SHADERS = {
  blank: BLANK,
  blankWithComments: BLANK_WITH_COMMENTS,
  ripple: RIPPLE,
  simplex: SIMPLEX,
  simplex2s: SIMPLEX_2S,
  fbm: FBM,
  liquify: LIQUIFY,
  grain: GRAIN,
  lens: LENS,
  pixelate: PIXELATE,
  superShader: SUPER_SHADER,
  voronoi: VORONOI,
  heightMap: NORMAL_MAP
}