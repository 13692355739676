import {
  computeFragColor,
  FLOATING_POINT,
  EASE,
  mixRadiusProperties,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const vertexShader = `#version 300 es
  precision mediump float;
  in vec3 aVertexPosition;
  in vec2 aTextureCoord;
  uniform mat4 uMVMatrix;
  uniform mat4 uPMatrix;
  uniform mat4 uTextureMatrix;

  out vec3 vVertexPosition;
  out vec2 vTextureCoord;

  uniform float uFrequency;
  uniform float uAmplitude;
  uniform float uRotation;
  uniform float uTime;
  uniform vec2 uPos;
  ${UNIVERSAL_UNIFORMS}

  //https://gist.github.com/ayamflow/c06bc0c8a64f985dd431bd0ac5b557cd
  vec2 rotateUV(vec2 uv, float rotation)
  {
      float mid = 0.5;
      return vec2(
          cos(rotation) * (uv.x - mid) + sin(rotation) * (uv.y - mid) + mid,
          cos(rotation) * (uv.y - mid) - sin(rotation) * (uv.x - mid) + mid
      );
  }

  const float PI = 3.141592;

  void main() {	
    vec3 vertexPosition = aVertexPosition;
    vec2 waveCoord = vec2(vertexPosition.xy);
    float angle = (uRotation * 360.) * 3.1415926 / 180.;
    float thirdPI = PI * 0.3333;
    float time = uTime*0.25;
    float frequency = 20.0 * uFrequency;
    float dist = 1.-distance(uPos, aTextureCoord);
    dist = max(0., dist);
    float amp = uAmplitude * 0.2;

    float waveX = sin((waveCoord.y + uPos.y) * frequency + (time * thirdPI)) * amp;
    float waveY = sin((waveCoord.x - uPos.x) * frequency + (time * thirdPI)) * amp;
    waveCoord.xy += vec2(mix(waveX, 0., uRotation), mix(0., waveY, uRotation));

    if(vertexPosition.x == 1.) {
        waveCoord.x = 1.;
    }
    if(vertexPosition.x == -1.) {
        waveCoord.x = -1.;
    }
    if(vertexPosition.y == 1.) {
        waveCoord.y = 1.;
    }
    if(vertexPosition.y == -1.) {
        waveCoord.y = -1.;
    }

    gl_Position = uPMatrix * uMVMatrix * vec4(vec3(aVertexPosition), 1.0);

    vTextureCoord = (uTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;
    vertexPosition.xy = waveCoord;
    vVertexPosition = vertexPosition;
  }
`;

const sinShader = `#version 300 es
  precision mediump float;

  in vec2 vTextureCoord;
  in vec3 vVertexPosition;
  
  uniform sampler2D uTexture;
  uniform float uMixRadius;
  uniform int uMixRadiusInvert;
  uniform int uEasing;
  uniform vec2 uPos;
  ${UNIVERSAL_UNIFORMS}
  ${EASE}
  
  out vec4 fragColor;

  void main() {	
    vec2 uv = vTextureCoord;
    vec2 wave = vVertexPosition.xy * 0.5 + 0.5;
    float aspectRatio = uResolution.x/uResolution.y;

    vec2 mPos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    vec2 pos = uMixRadius == 1.00 ? mPos : uPos;
    float dist = ease(uEasing, max(0.,1.-distance(uv * vec2(aspectRatio, 1), mPos * vec2(aspectRatio, 1)) * 4. * (1. - uMixRadius)));

    // #ifelseopen
    if (uMixRadiusInvert == 1) {
        dist = max(0., (0.5 - dist));
    }
    // #ifelseclose
  
    uv = mix(uv, wave, dist);

    vec4 color = texture(uTexture, uv);
    ${computeFragColor('color')}
  }
`;

const sinParams = {
  fragmentShader: sinShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  widthSegments: 250,
  heightSegments: 250,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    frequency: {
      name: 'uFrequency',
      type: '1f',
      value: 0.5,
    },
    amplitude: {
      name: 'uAmplitude',
      type: '1f',
      value: 0.3,
    },
    falloff: {
      name: 'uFalloff',
      type: '1f',
      value: 0.5,
    },
    rotation: {
      name: 'uRotation',
      type: '1f',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const SINE = {
  id: 'sine',
  label: 'Sine',
  params: sinParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    frequency: {
      label: 'Frequency',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the density of the wave pattern',
    },
    amplitude: {
      label: 'Amplitude',
      value: 0.3,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the strength of the wave pattern',
    },
    rotation: {
      label: 'Direction',
      value: 0,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the strength of the wave pattern',
    },
    phase: {
      label: 'Phase',
      value: 0,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the time element of the effect',
    },
    ...mixRadiusProperties,
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
