import { colorSwatchesV2 } from './Constants.js';
import { randomInt } from './Helpers.js';

export const getFillStyle = (fill, ang) => {
  let angle = ang || 45;
  let isWhite = fill[0] === '#FFFFFF';
  const isTransparent = fill[0] === 'transparent';
  if (fill.length > 1) {
    return {
      background: `linear-gradient(${angle}deg, ${fill})`,
    };
  } else {
    return {
      background: isTransparent ? `linear-gradient(45deg, #fff, #fff 47%, red 50%, #fff 53%, #fff)` : fill[0],
      border: isWhite || isTransparent ? '1px solid #eaeaea' : 'none',
    };
  }
};

export function isValidHexCode(hexCode) {
  const hexCodePattern = /^#?([a-f\d]{3}|[a-f\d]{6}|[a-f\d]{8})$/i;
  return hexCode.toLowerCase() === 'transparent' || hexCodePattern.test(hexCode);
}

const diffBrightness = function (a, b) {
  a = getBrightness(a);
  b = getBrightness(b);
  return Math.abs(a - b);
};

const vDistance = (v1, v2) => {
  var i,
    d = 0;

  for (i = 0; i < v1.length; i++) {
    d += (v1[i] - v2[i]) * (v1[i] - v2[i]);
  }
  return Math.sqrt(d);
};

const rgbToHsl = rgb => {
  var r = rgb[0] / 255;
  var g = rgb[1] / 255;
  var b = rgb[2] / 255;

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }
  return [h, s, l];
};

const hue2rgb = (p, q, t) => {
  if (t < 0) {
    t += 1;
  }
  if (t > 1) {
    t -= 1;
  }
  if (t < 1 / 6) {
    return p + (q - p) * 6 * t;
  }
  if (t < 1 / 2) {
    return q;
  }
  if (t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }
  return p;
};

const hslToRgb = hsl => {
  var h = hsl[0];
  var s = hsl[1];
  var l = hsl[2];
  var r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

export const labToRgb = lab => {
  var y = (lab[0] + 16) / 116,
    x = lab[1] / 500 + y,
    z = y - lab[2] / 200,
    r,
    g,
    b;

  x = 0.95047 * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787);
  y = 1.0 * (y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787);
  z = 1.08883 * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787);

  r = x * 3.2406 + y * -1.5372 + z * -0.4986;
  g = x * -0.9689 + y * 1.8758 + z * 0.0415;
  b = x * 0.0557 + y * -0.204 + z * 1.057;

  r = r > 0.0031308 ? 1.055 * Math.pow(r, 1 / 2.4) - 0.055 : 12.92 * r;
  g = g > 0.0031308 ? 1.055 * Math.pow(g, 1 / 2.4) - 0.055 : 12.92 * g;
  b = b > 0.0031308 ? 1.055 * Math.pow(b, 1 / 2.4) - 0.055 : 12.92 * b;

  return [Math.max(0, Math.min(1, r)) * 255, Math.max(0, Math.min(1, g)) * 255, Math.max(0, Math.min(1, b)) * 255];
};

export const rgbToLab = rgb => {
  var r = rgb[0] / 255,
    g = rgb[1] / 255,
    b = rgb[2] / 255,
    x,
    y,
    z;

  r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
  g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
  b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

  x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
  y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
  z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

  x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
  y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
  z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;

  return [116 * y - 16, 500 * (x - y), 200 * (y - z)];
};

const rgbToyuv = rgb => {
  var y = rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114;
  var u = rgb[0] * -0.168736 + rgb[1] * -0.331264 + rgb[2] * 0.5 + 128;
  var v = rgb[0] * 0.5 + rgb[1] * -0.418688 + rgb[2] * -0.081312 + 128;
  return [y, u, v];
};

function clamp(n, low, high) {
  if (n < low) {
    return low;
  }
  if (n > high) {
    return high;
  }
}

const yuv2Rgb = yuv => {
  var y = parseInt(yuv[0], 10),
    u = (parseInt(yuv[1], 10) / 255) * 222 - 111,
    v = (parseInt(yuv[2], 10) / 255) * 312 - 155,
    r,
    g,
    b;

  r = Math.round(y + v / 0.877);
  g = Math.round(y - 0.39466 * u - 0.5806 * v);
  b = Math.round(y + u / 0.493);
  return [r, g, b];
};

export const rgbToHex = (r, g, b) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

const sumArrayValues = values => {
  return values.reduce((p, c) => p + c, 0);
};

export const weightedMean = (factorsArray, weightsArray) => {
  return (
    sumArrayValues(factorsArray.map((factor, index) => factor * weightsArray[index])) / sumArrayValues(weightsArray)
  );
};

export const getAveRgb = function (a, b, convert, diff) {
  let result;
  if (diff) {
    result = a.map(function (n, index) {
      return weightedMean([n, b[index]], [diff, 1 - diff]);
    });
  } else {
    result = a.map(function (n, index) {
      return (n + b[index]) / 2;
    });
  }
  if (convert) {
    result = labToRgb(result);
    result = result.map(function (n) {
      return +n.toFixed(0);
    });
  }
  return result;
};

export const getSwatches = (hues, steps) => {
  const swatches = [];
  for (let prop in colorSwatches) {
    swatches.push(colorSwatches[prop]);
  }
  return swatches;
};

export const getBrightness = function (color) {
  return 0.2126 * color[0] + 0.7152 * color[1] + 0.0722 * color[2];
  // return (299 * color[0] + 587 * color[1] + 114 * color[2]) / 1000;
};

export const hexToRgb = hex => {
  // Return null if hex is not a string or is undefined
  if (!hex || typeof hex !== 'string') {
    return null;
  }

  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

const gamma = x => (x >= 0.0031308 ? 1.055 * Math.pow(x, 1 / 2.4) - 0.055 : 12.92 * x);
const gamma_inv = x => (x >= 0.04045 ? Math.pow((x + 0.055) / (1 + 0.055), 2.4) : x / 12.92);

export const rgb2oklab = rgb => {
  const r = gamma_inv(rgb[0] / 255);
  const g = gamma_inv(rgb[1] / 255);
  const b = gamma_inv(rgb[2] / 255);
  const l = 0.412165612 * r + 0.536275208 * g + 0.0514575653 * b;
  const m = 0.211859107 * r + 0.6807189584 * g + 0.107406579 * b;
  const s = 0.0883097947 * r + 0.2818474174 * g + 0.6302613616 * b;

  const l_ = Math.cbrt(l);
  const m_ = Math.cbrt(m);
  const s_ = Math.cbrt(s);

  return [
    0.2104542553 * l_ + 0.793617785 * m_ - 0.0040720468 * s_,
    1.9779984951 * l_ - 2.428592205 * m_ + 0.4505937099 * s_,
    0.0259040371 * l_ + 0.7827717662 * m_ - 0.808675766 * s_,
  ];
};

export const oklab2rgb = (L, a, b) => {
  const l_ = L + 0.3963377774 * a + 0.2158037573 * b;
  const m_ = L - 0.1055613458 * a - 0.0638541728 * b;
  const s_ = L - 0.0894841775 * a - 1.291485548 * b;
  const l = l_ * l_ * l_;
  const m = m_ * m_ * m_;
  const s = s_ * s_ * s_;

  return [
    255 * gamma(+4.0767245293 * l - 3.3072168827 * m + 0.2307590544 * s),
    255 * gamma(-1.2681437731 * l + 2.6093323231 * m - 0.341134429 * s),
    255 * gamma(-0.0041119885 * l - 0.7034763098 * m + 1.7068625689 * s),
  ];
};

let hueSpeed = 120;
export const lerpGradient = (arr1, arr2, progress, speed) => {
  progress = progress * speed;
  console.log(progress);
  const lab1 = arr1.map(n => hexToRgb(n));
  const lab2 = arr2.map(n => hexToRgb(n));
  const weighted = lab1.map((n, i) => {
    return n.map((o, j) => {
      return weightedMean([o, lab2[i][j]], [progress, 1 - progress]);
    });
  });
  return weighted.map(n => n.map(o => Math.round(o)));
};

class Colors {
  constructor() {
    this.grads = [];
    this.swatches = Object.values(colorSwatchesV2);

    for (let i = 0; i < this.swatches.length; i++) {
      const grad = [];
      for (let j = 0; j < this.swatches[i].length / 2; j++) {
        grad.push(this.swatches[i][j * 2]);
      }
      this.grads.push(grad);
    }

    this.grads.push(
      ['#F5A2DF', '#8E4BFB', '#EF5E21'],
      ['#20063B', '#771768', '#68A5ED', '#002FD6'],
      ['#DDD623', '#367C41', '#2B50D7', '#A966FF'],
      ['#EEBBB9', '#6DB6EE', '#E69E3C', '#D22D23'],
      ['#F589B0', '#3353E7', '#69005A', '#E5782F'],
      ['#F2B25C', '#EA3363', '#EA94E1', '#204FD9'],
      ['#023413', '#8A05DB', '#DE37CC', '#E7EE9D'],
      ['#20063B', '#E957BA', '#F0B35C', '#AFFA34'],
      ['#F0B5FF', '#FF8743', '#F900A9', '#0E0548', '#0B550A'],
      ['#8BFFFF', '#D9FF43', '#EB7390', '#320072', '#0256B9'],
      ['#350699', '#2D0337', '#A26AE9', '#43D2FF', '#C7FCAE']
    );
  }

  getSimilarValueIndex(color) {
    let valueIndex = false;
    this.swatches.forEach(swatch => {
      if (swatch.includes(...color)) {
        valueIndex = swatch.indexOf(...color);
      }
    });
    return valueIndex;
  }

  getSimilarColors(color) {
    const index = this.getSimilarValueIndex(color);
    if (index) {
      return this.swatches.map(n => n[index]);
    } else {
      return color;
    }
  }

  getRandomSimilarColor(color) {
    const similarColors = this.getSimilarColors(color);
    if (similarColors) {
      let newColor = similarColors[randomInt(0, similarColors.length - 1)];
      while (color === newColor) {
        newColor = similarColors[randomInt(0, similarColors.length - 1)];
      }
      return newColor;
    } else {
      return color;
    }
  }

  getBand(amt, variance) {
    const bands = [];
    let colors = this.swatches[randomInt(1, this.swatches.length)];
    let color = colors[randomInt(3, colors.length)];
    let lab = rgbToLab(hexToRgb(color));
    for (let i = 0; i < amt; i++) {
      for (let j = 0; j < 3; j++) {
        let space;
        if (j === 0) {
          space = ((0.5 - Math.random()) * variance) / 2;
          lab[j] += lab[j] + space > 100 && lab[j] - space > 0 ? -space : space;
        } else {
          space = (0.5 - Math.random()) * variance;
          lab[j] += lab[j] + space > 160 && lab[j] - space > -160 ? -space : space;
        }
      }
      bands.push(rgbToHex(...labToRgb(lab).map(n => Math.round(n))));
    }
    return bands;
  }

  randomFill(fill, similar) {
    let newFill = fill;
    if (fill.length > 1) {
      newFill = this.getBand(randomInt(2, 8), randomInt(80, 280));
    } else {
      if (similar) {
        newFill = [this.getRandomSimilarColor(fill)];
      } else {
        newFill = this.swatches[randomInt(0, this.swatches.length - 1)];
        newFill = [newFill[randomInt(0, newFill.length - 1)]];
        while (fill === newFill) {
          newFill = [newFill[randomInt(0, newFill.length - 1)]];
        }
      }
    }
    return newFill;
  }
}

export const ColorService = new Colors();
