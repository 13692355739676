<script>
import { StudioStore } from '../stores/StudioStore';
import { UserStore } from '../stores/UserStore';
import Icon from './Icon.vue';
import Button from './Button.vue';
import RadioToggle from './RadioToggle.vue';
import TrialInfo from './TrialInfo.vue';
import * as UnicornStudio from '../lib/unicornStudio';
import { logsnagTrack } from '../scripts/LogSnag.js';
import { FirebaseStore } from '../stores/FirebaseStore';

export default {
  components: {
    Icon,
    Button,
    TrialInfo,
    RadioToggle
  },
  emits: ['close'],
  data() {
    return {
      activeGroup: null,
      legendScene: null,
      startedFreeTrial: false,
      purchaseCompleteProject: 'ubKdtRAlv0CQ0FoSRO60',
      bullets: [
        {
          label: 'Commercial license',
          tooltip: 'Use unicorn.studio creations for commercial projects (Terms and Conditions apply)'
        },
        {
          label: 'Public URLs without logo',
          tooltip: 'Removes the logo from your published project links'
        },
        {
          label: 'Code export',
          tooltip: 'Own your compiled project code and self host it by exporting it as a JSON file'
        },
        {
          label: 'CDN / Asset optimization',
          tooltip: 'Make your scenes load faster with compressed assets served over global CDN'
        },
        {
          label: 'Custom fonts',
          tooltip: 'Upload your own fonts to use in projects.'
        },
        {
          label: 'Folders',
          tooltip: 'Easily organize your projects'
        },
        {
          label: 'Access to all new features and updates',
        },
      ],
      plan: 'yearly',
      toggleOptions: [
        {value: 'yearly', label: 'Yearly'},
        {value: 'monthly', label: 'Monthly'}
      ]
    }
  },
  created() {
    window.addEventListener('focus', this.checkIfPurchaseWasMade)
    UnicornStudio.addScene({
      elementId: 'legend_embed',
      dpi: 2,
      projectId: '0psqsaNRhSle0eUdze5I?production=true'
    }).then(scene => {
      this.legendScene = scene;
    });
  },
  mounted() {
    document.addEventListener("mousedown", this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('focus', this.checkIfPurchaseWasMade)
    document.removeEventListener("mousedown", this.handleClickOutside);
    if(this.legendScene) {
      this.legendScene.destroy();
    }
  },
  
  methods: {
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeProModal();
      }
    },
    closeProModal() {
      StudioStore.state.signUpForPro = false;
      this.$emit('close');
    },
    checkIfPurchaseWasMade() {
      UserStore.fetchSubscriptions().then(subs => {
        if(UserStore.getActiveSubscription()) {
          requestAnimationFrame(() => {
            this.legendScene.resize();
          })
          window.removeEventListener('focus', this.checkIfPurchaseWasMade)
        }
      });
    },
    startFreeTrial() {
      FirebaseStore.startFreeTrial().then(resp => {
        UserStore.getUser();
        this.startedFreeTrial = true;
        requestAnimationFrame(() => {
          this.legendScene.resize();
        })
        logsnagTrack({
          channel: 'events',
          event: 'Started free trial',
          description: `email: ${UserStore.email}`,
          icon: "🔮",
          user_id: UserStore.id,
          tags: {
            email: UserStore.email,
            uid: UserStore.id,
          }
        });
      });
    }
  },
  computed: {
    userId() {return UserStore.id},
    hasMadePurchase() {
      return UserStore.getActiveSubscription();
    },
    hasActiveTrial() {
      return UserStore.hasActiveTrial 
    },
    hasEndedTrial() {
      return UserStore.trialEnded
    },
  },
}
</script>

<template>
  <div class="modal modal__pro" :class="{'has-made-purchase': startedFreeTrial}">
      <div class="pro-modal-content flex relative">
        <div id="legend_embed" class="iframe" :class="{'iframe_full': startedFreeTrial}"></div>
        <div v-if="!startedFreeTrial" class="price-content mx-auto">
          <Button 
            @click="closeProModal"
            class="button button__icon ml-3 font-secondary-color close-button">
            <Icon icon="x" size="15" />
          </Button>
          <div class="tag mb-2 inline-block" style="background-color: transparent; border: 1px solid var(--primary-color-50a); color: var(--primary-color)">closed beta discount</div>
          <p class="h2 my-0 price">$8 <span class="per-month">/mo</span></p>
          <p class="font-secondary-color mt-1">Billed yearly, or $11 billed monthly.</p>
          <p class="font-secondary-color mt-4">Unicorn.Studio Legend unlocks:</p>
          <ul class="list mt-2">
            <li v-for="bullet in bullets" :key="bullet" class="list-item flex align-center">
              <Icon icon="check" size="14" class="mr-1" :class="{'font-tertiary-color': bullet.soon}" /> {{bullet.label}}
              <Icon v-if="bullet.tooltip"  class="font-tertiary-color ml-1" icon="info" :tooltip="bullet.tooltip" />
              <div class="tag tag__neutral ml-1" v-if="bullet.soon">Soon</div>
            </li>
          </ul>
          <div class="flex align-center mt-5">            
            <template v-if="hasActiveTrial || hasEndedTrial">
              <div class="w-100">
                <Button class="primary w-100 lemonsqueezy-button" :href="`https://unicornstudioapp.lemonsqueezy.com/buy/52c61dfe-abeb-45de-8f0a-53da09bf8ac1?checkout[custom][user_id]=${userId}`" target="_blank">Upgrade now</Button>
                <div class="secondary mt-2 mx-auto w-100" style="text-align: center"><TrialInfo /></div>
              </div>
            </template>
            <template v-else>
              <div class="w-100">
                <Button @click="startFreeTrial" class="primary w-100">Try it 7 days free</Button>
                <p class="font-secondary-color mt-2 mx-auto w-100" style="text-align: center">No credit card required</p>
              </div>
            </template>
          </div>
        </div>
        <Button v-if="startedFreeTrial" @click="closeProModal" class="secondary-inverse close">Let's go!</Button>
      </div>
  </div>
</template>

<style scoped lang="scss">

.modal__pro {
  position: fixed;
  height: auto;
  top: 5.9rem;
  right: 0;
  left: 0;
  width: auto;
  max-width: 82rem;
  outline: 100em solid rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  border-radius: 0.8rem;
  z-index: 999999;
  padding: 0;
  font-size: 1.2rem;
  background-color: var(--artboard-color);

  &.has-made-purchase {
    max-width: 50rem;
  }
}

.close {
  position: absolute;
  margin: 0 auto;
  bottom: 5rem;
  left: 0;
  right: 0;
  max-width: 12rem;
}

.close-button {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.price-content {
  padding: 5rem;
  max-width: 42rem;
  width: 100%;
}

.price {
  font-size: 2.8rem;
}

.per-month {
  color: var(--font-secondary-color);
  font-size: 2rem;
  margin-left: -0.5rem;
}

.iframe {
  display: block;
  border: 0;
  outline: 0;
  max-width: 40rem;
  width: 100%;
  height: 50rem;

  canvas {
    width: 100%;
    height: 100%;
  }
}

.iframe_full {
  max-width: 50rem;
  margin: 0 auto;
}

.list {
  list-style: none;
  padding-left: 0;
}

.list-item + .list-item {
  margin-top: 1rem;
}

</style>