import { computeFragColor, vertexShader, FLOATING_POINT, UNIVERSAL_UNIFORMS, universalUniformParams } from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision mediump float;
in vec2 vTextureCoord;
uniform sampler2D uTexture;
uniform float uAmount; // The strength of the stretch effect
uniform vec2 uPos; // The center position of the stretch effect
uniform float uAngle; // The angle at which we apply the stretch
uniform float uScale; // The range of the stretch effect
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;

float pointLineSide(vec2 A, vec2 B, vec2 P) {
  vec2 d = B - A;
  vec2 AP = P - A;
  return d.y * AP.x - d.x * AP.y;
}

void main() {
    vec2 uv = vTextureCoord;
    float radians = (uAngle-0.25) * 3.1415926 * 2.;
    float rot1 = radians;
    float rot2 = (uAngle-0.5) * 3.1415926 * 2.;
    float scale = uScale * 0.25;
    float aspectRatio = uResolution.x/uResolution.y;

    vec2 aspectCorrection = vec2(aspectRatio - 1., 0.);
    vec2 pos = uPos + aspectCorrection * (aspectRatio < 1. ? 0.1 : 0.05);
    
    vec2 pointA = pos + vec2(0.1 * sin(rot1), 0.1 * cos(rot1));
    vec2 pointB = pos - vec2(0.1 * sin(rot1), 0.1 * cos(rot1));

    vec2 pointC = uv + vec2(0.1 * sin(rot2), 0.1 * cos(rot2));
    vec2 pointD = uv - vec2(0.1 * sin(rot2), 0.1 * cos(rot2));

    float m1 = (pointB.y - pointA.y)/(pointB.x - pointA.x + 0.0000001);
    float c1 = pointA.y - m1*pointA.x;
    float m2 = (pointD.y - pointC.y)/(pointD.x - pointC.x + 0.0000001);
    float c2 = pointC.y - m2*pointC.x;
    
    float intersectX = (c1 - c2) / (m2 - m1);
    float intersectY = intersectX * m1 + c1;
    
    vec2 stretchDir = vec2(cos(-rot2), sin(-rot2));
    vec2 perpStretchDir = vec2(-stretchDir.y, stretchDir.x); // This is the perpendicular stretch direction
    vec2 offset = vec2(intersectX, intersectY);
    float distToLine = 1.-distance(uv, offset);
    float insideStretch = distToLine > (1.-scale) ? 1. : 0.;

    float stretchOffsetDir = pointLineSide(pointA, pointB, uv) > 0. ? -1. : 1.;
    uv += mix(perpStretchDir * scale * stretchOffsetDir, vec2(0), insideStretch);
    uv = mix(uv, offset, insideStretch);

    // Sample the texture with displaced UV coordinates
    vec4 color = texture(uTexture, uv);
    ${computeFragColor('color')}
}
`

export const stretchParams = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    scale: {
      name: "uScale",
      type: "1f",
      value: 0.5,
    },
    angle: {
      name: "uAngle",
      type: "1f",
      value: 0,
    },
    pos: {
      name: "uPos",
      type: "2f",
      value: new Vec2(0.5)
    },
    ...universalUniformParams
  }
}

export const EXTEND = {
  id: 'extend',
  label: 'Extend',
  params: stretchParams,
  aspectRatio: 1,
  properties: {
    scale: {
      label: 'Amount',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    angle: {
      label: 'Angle',
      value: 0.0027,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees'
    }
  }
}