import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const godraysShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uBgTexture;
uniform sampler2D uBlueNoise;
uniform float uAmount;
uniform float uIntensity;
uniform float uExposure;
uniform float uVertical;
uniform float uDecay;
uniform float uDiffusion;
uniform int uFinal;
uniform vec2 uPos;
uniform vec3 uTint;
uniform float uTime;
${UNIVERSAL_UNIFORMS}

const float MAX_ITERATIONS = 64.;
const float PI2 = 6.28318530718;
const float TAU = 6.28318530718;
const float EPSILON = 0.0001;

float luma(vec4 color) {
  return dot(color.rgb, vec3(0.299, 0.587, 0.114));
}

float getBlueNoiseOffset(vec2 st) {
    ivec2 texSize = textureSize(uBlueNoise, 0);
    vec4 blueNoise = texelFetch(uBlueNoise, ivec2(fract(st * (uResolution)/vec2(texSize) * vec2(texSize.x/texSize.y, 1.0)) * vec2(texSize)) % texSize, 0);
    return mod((blueNoise.r * PI2) + (uTime * (1.0 / PI2)), PI2);
}

float isOdd(float value) {
  int intValue = int(floor(value)); // Convert the float to an integer by flooring it
  return mod(value, 2.) == 0. ? 0.0 : 1.0; // Check if the integer is even or odd
}


vec3 godRays(vec2 st, float decay) {
  vec3 color = vec3(0);
  float offset = (0.25 + min(1., uAmount)) * 0.0098;
  vec2 direction = mix(vec2(1, 0), vec2(0, 1), uVertical);
  vec2 pos = uPos - mix(vec2(0), (vec2(1. - uMousePos.x, 1. - uMousePos.y) - 0.5), uTrackMouse);
  float distToPos = length(pos - st);
  float weight = 1.0;
  float bnoz = getBlueNoiseOffset(st) * uAmount;

  for (float i = 0.0; i < MAX_ITERATIONS; i += 4.0) {
    float bno = getBlueNoiseOffset(st + vec2(i/MAX_ITERATIONS)) * uAmount;
    for (float j = 0.0; j < 4.0; j++) {
      float x = min(0.999, (i + j) * offset);
      x += bnoz * 0.01;
      x = min(0.999, x);
      vec2 marchPos = st * (1.0 - x) + vec2(x * 0.5) + (pos - 0.5) * x + vec2(cos(bno), sin(bno)) * 0.015 * uDiffusion * x;
      color += textureLod(uTexture, marchPos, 0.0).rgb * weight;
      weight *= decay;
    }
  }
  return color / MAX_ITERATIONS;
}

vec3 Tonemap_tanh(vec3 x)
{
    x = clamp(x, -40.0, 40.0);
    return (exp(x) - exp(-x)) / (exp(x) + exp(-x));
}


out vec4 fragColor;

void main() {	
  vec2 uv = vTextureCoord;
  vec4 color = vec4(0,0,0,1);
  
  if(uIntensity == 0.) {
    vec4 color = texture(uTexture, uv);
    ${computeFragColor('color')}
    return;
  }

  if(uFinal != 1) {
    color = texture(uTexture, uv);
    float lum = dot(color.rgb, vec3(0.299, 0.587, 0.114));
    color = color * smoothstep(uExposure - 0.1, uExposure, lum);
  } else {
    float decay = 0.972;
    vec3 rays = godRays(uv, decay);
    rays *= uTint;
    color.rgb = Tonemap_tanh(rays);
    vec4 bg = texture(uBgTexture, uv);
    color.rgb = bg.rgb + (color.rgb * uIntensity * 4.);
    color.a = bg.a + color.r;
  }
  ${computeFragColor('color')}
}
`;

const godraysParams = {
  fragmentShader: godraysShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.25,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    vertical: {
      name: 'uVertical',
      type: '1f',
      value: 0,
    },
    tint: {
      name: 'uTint',
      type: '3f',
      value: new Vec3(0.98, 0.12, 0.89),
    },
    intensity: {
      name: 'uIntensity',
      type: '1f',
      value: 0.5,
    },
    exposure: {
      name: 'uExposure',
      type: '1f',
      value: 0.5,
    },
    diffusion: {
      name: 'uDiffusion',
      type: '1f',
      value: 0.25,
    },
    final: {
      name: 'uFinal',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const GOD_RAYS = {
  id: 'godrays',
  label: 'God Rays',
  params: godraysParams,
  passes: [
    {
      prop: 'final',
      value: 1,
      includeBg: true,
    },
  ],
  aspectRatio: 1,
  texture: {
    src: 'https://assets.unicorn.studio/media/blue_noise_med.png',
    sampler: 'uBlueNoise',
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Spread',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    intensity: {
      label: 'Intensity',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    diffusion: {
      label: 'Diffuseness',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    exposure: {
      label: 'Exposure',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    tint: {
      label: 'Tint',
      value: new Vec3(1),
      output: 'color',
      responsiveDisabled: true,
    },
    ...interactiveProperties,
  },
};
