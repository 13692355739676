<script>
    import ParamLabel from './ParamLabel.vue'
    export default {
        components: {ParamLabel},
        props: ['label', 'value', 'modelValue', 'options', 'locked', 'tooltip', 'href'],
        methods: {
          updateValue(val) {
            this.$emit('update:modelValue', val);
            this.$emit('change');
          }
        }
    }
</script>

<template>
    <div class="parameter parameter__block">
      <label v-if="label" class="icon-label slider-label">
        <ParamLabel :label="label" :tooltip="tooltip">{{ label }}</ParamLabel>
      </label>
      <div class="toggle-group" :class="{'toggle-group__active': modelValue || value}">
        <a
          class="classic-toggle"
          :class="{'classic-toggle__active': !(modelValue || value)}"
          @click="updateValue(modelValue ? 0 : 1)"
        ></a>
        <a
          class="classic-toggle"
          :class="{'classic-toggle__active': modelValue || value}"
          @click="updateValue(modelValue ? 0 : 1)"
        ></a>
      </div>
    </div>
</template>

<style lang="scss" scoped>

  .toggle-group {
    margin: 0.6rem 0;

    &.toggle-group__active {
      background-color: var(--primary-color);
      
      .classic-toggle:first-child {
        background-color: var(--primary-bg);
      }
    }
  }

  .classic-toggle {
    color: var(--font-secondary-color);
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.3rem;
    line-height: 1.33333333;
    text-align: center;
    border-radius: 0.2rem;
    cursor: pointer;

    &:hover {
      background-color: var(--border-color);
    }

    &.classic-toggle__active {
      &:first-child {
        background-color: var(--font-tertiary-color);
      }
      &:last-child {
        background-color: white; 
        box-shadow: 0 0.1rem 0.4rem -0.05rem rgba(#000, 0.4);
      }
    }
  }

</style>