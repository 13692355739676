export const createSize = (name, dims) => {
  const getAspectRatio = (dims, scale) => {
    const ratio = dims[0] / dims[1];
    const width = Math.sqrt(ratio * (300000 * (scale || 1)));
    return [width, width / ratio];
  };

  const scaledDims = getAspectRatio(dims);
  const paddingDivisor = scaledDims[0] / scaledDims[1] > 1 ? 4 : scaledDims[0] / scaledDims[1] < 1 ? 6 : 4;
  const longestSide = Math.max(...dims);
  const iconScale = 14 / longestSide;
  const iconDimensions = dims.map(n => Math.round(n * iconScale));
  return {
    name: name,
    dimensions: scaledDims.map(n => Math.round(n)),
    scaleRatio: dims[0] / scaledDims[0],
    realDimensions: dims,
    placeholderPadding: `calc(${((dims[1] / dims[0]) * 100) / paddingDivisor}% - 2rem)`,
    aspectRatio: dims[0] / dims[1],
    iconDimensions: iconDimensions,
  };
};

export const SCREEN_SIZES = [
  createSize('Desktop', [1440, 900]),
  createSize('Tablet', [768, 1024]),
  createSize('Mobile', [390, 844]),
  createSize('iPhone 14 Pro Max', [430, 932]),
  createSize('iPhone 14 Plus', [428, 926]),
  createSize('iPhone 14 Pro', [393, 852]),
  createSize('iPhone 14', [390, 844]),
  createSize('iPhone 13 Pro Max', [428, 926]),
  createSize('iPhone 13 Pro', [390, 844]),
  createSize('iPhone 13 mini', [375, 812]),
  createSize('iPhone 11 Pro', [375, 812]),
  createSize('iPhone SE', [320, 568]),
  createSize('iPhone 8 Plus', [414, 736]),
  createSize('iPhone 8', [375, 667]),
  createSize('iPad mini 8.3', [744, 1133]),
  createSize('iPad Pro 11"', [834, 1194]),
  createSize('iPad Pro 12.9"', [1024, 1366]),
  createSize('Instagram Story', [1080, 1920]),
  createSize('Tabloid', [792, 1224]),
  createSize('A4', [595, 842]),
  createSize('Letter', [612, 792]),
  createSize('Square', [1080, 1080]),
  createSize('Instagram post', [1080, 1080]),
  createSize('iPad (legacy)', [1024, 768]),
  createSize('Slide 4:3', [1024, 768]),
  createSize('Macbbook Pro', [1440, 900]),
  createSize('Macbook Pro', [1440, 900]),
  createSize('Twitter post', [1200, 675]),
  createSize('iMac', [1280, 720]),
  createSize('Macbook Air', [1280, 832]),
  createSize('Macbook Pro 14"', [1512, 982]),
  createSize('Macbook Pro 16"', [1728, 1117]),
  createSize('iMac', [1280, 720]),
  createSize('Slide 16:9', [1920, 1080]),
  createSize('Twitter header', [1500, 500]),
  createSize('Dribbble shot', [800, 600]),
  createSize('LinkedIn cover', [1584, 396]),
  createSize('TikTok post', [1080, 1920]),
  createSize('Window', [window.innerWidth, window.innerHeight]),
  createSize('Custom', [1080, 1080]),
];

const ICONS = {
  google: `<svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="#000000" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M128,128h88a88.1,88.1,0,1,1-25.8-62.2" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>`,
};

export const colorSwatchesV2 = {
  white: [
    '#000000',
    '#151515',
    '#292929',
    '#464646',
    '#646464',
    '#7D7D7D',
    '#9D9D9D',
    '#B7B7B7',
    '#D0D0D0',
    '#EEEEEE',
    '#FFFFFF',
  ],
  red: [
    '#50000a',
    '#690010',
    '#8B0018',
    '#AE0020',
    '#D10029',
    '#F50032',
    '#FF5458',
    '#FF8480',
    '#FFAAA5',
    '#FFCCC8',
    '#FFE4E2',
  ],
  orange: [
    '#441700',
    '#5A2200',
    '#772F00',
    '#953D00',
    '#B34B00',
    '#D35A00',
    '#F36900',
    '#FF894D',
    '#FFAE87',
    '#FFCEB7',
    '#FFEDE4',
  ],
  amber: [
    '#392100',
    '#4B2E00',
    '#643F00',
    '#7D5100',
    '#986300',
    '#B37500',
    '#CE8800',
    '#EA9B01',
    '#FFB341',
    '#FFD198',
    '#FFEED9',
  ],
  gold: [
    '#2f2700',
    '#403400',
    '#564700',
    '#6D5A00',
    '#846E00',
    '#9C8200',
    '#B49700',
    '#CDAC00',
    '#E6C100',
    '#FFD820',
    '#FFF1BD',
  ],
  lime: [
    '#1f2d00',
    '#2B3C00',
    '#3B5100',
    '#4B6700',
    '#5C7D00',
    '#6D9300',
    '#7FAA00',
    '#91C200',
    '#A3DA00',
    '#B6F200',
    '#DEFFAB',
  ],
  green: [
    '#013017',
    '#034121',
    '#05572E',
    '#086E3C',
    '#0B854A',
    '#0E9D58',
    '#10B667',
    '#12CF76',
    '#14E885',
    '#53FF9D',
    '#CEFFDD',
  ],
  mint: [
    '#002e2c',
    '#003E3B',
    '#00544F',
    '#006A64',
    '#00817A',
    '#009890',
    '#00B0A7',
    '#00C8BE',
    '#00E1D6',
    '#00FAEE',
    '#C2FFF9',
  ],
  cyan: [
    '#002c3b',
    '#003B4F',
    '#00506A',
    '#006585',
    '#007AA0',
    '#0090BD',
    '#00A7DA',
    '#00BEF8',
    '#6BD2FF',
    '#ABE4FF',
    '#E0F5FF',
  ],
  blue: [
    '#002651',
    '#00346A',
    '#00478C',
    '#005AAF',
    '#006DD3',
    '#0081F7',
    '#459AFF',
    '#73B1FF',
    '#9CC7FF',
    '#C2DDFF',
    '#E8F2FF',
  ],
  ultramarine: [
    '#1b0c70',
    '#251392',
    '#331DBF',
    '#4229EA',
    '#5150F7',
    '#6570FC',
    '#7D8DFD',
    '#97A7FE',
    '#B3C0FE',
    '#CFD8FE',
    '#EDF0FF',
  ],
  violet: [
    '#320065',
    '#420084',
    '#5900AD',
    '#7001D7',
    '#8712FF',
    '#9555FF',
    '#A57AFF',
    '#B69AFF',
    '#C9B7FF',
    '#DDD3FF',
    '#F2EEFF',
  ],
  purple: [
    '#41004c',
    '#560064',
    '#730085',
    '#8F00A6',
    '#AD00C8',
    '#CC00EA',
    '#E13EFF',
    '#E978FF',
    '#F0A2FF',
    '#F6C7FF',
    '#FCEAFF',
  ],
  magenta: [
    '#4a0035',
    '#610046',
    '#81005D',
    '#A10075',
    '#C2008E',
    '#E400A7',
    '#FF2EBE',
    '#FF75CA',
    '#FFA1D8',
    '#FFC7E6',
    '#FFEAF5',
  ],
};

export const BLEND_MODES = {
  NORMAL: 'Normal',
  ADD: 'Add',
  SUBTRACT: 'Subtract',
  MULTIPLY: 'Multiply',
  SCREEN: 'Screen',
  OVERLAY: 'Overlay',
  DARKEN: 'Darken',
  LIGHTEN: 'Lighten',
  COLOR_DODGE: 'Color dodge',
  COLOR_BURN: 'Color burn',
  LINEAR_BURN: 'Linear burn',
  HARD_LIGHT: 'Hard light',
  SOFT_LIGHT: 'Soft light',
  DIFFERENCE: 'Difference',
  EXCLUSION: 'Exclusion',
  LINEAR_LIGHT: 'Linear light',
  PIN_LIGHT: 'Pin light',
  VIVID_LIGHT: 'Vivid light',
};

export const ZOOM_LEVELS = {
  'Zoom in': 'in',
  'Zoom out': 'out',
  'Fit to window': 'fit',
  '50%': 0.5,
  '100%': 1,
  '200%': 2,
};

export const LEFTRAIL_WIDTH = 0;
export const RIGHTRAIL_WIDTH = 0;
export const PRIMARY_COLOR = '#8e6ce4';
export const PRIMARY_COLOR_ALPHA_25 = '#8e6ce425';

export const EASING_FUNC = {
  linear: 'Linear',
  easeInQuad: 'Ease In Quad',
  easeOutQuad: 'Ease Out Quad',
  easeInOutQuad: 'Ease In Out Quad',
  easeInCubic: 'Ease In Cubic',
  easeInOutCubic: 'Ease In Out Cubic',
  easeOutCubic: 'Ease Out Cubic',
  easeInQuart: 'Ease In Quart',
  easeOutQuart: 'Ease Out Quart',
  easeInOutQuart: 'Ease In Out Quart',
  easeInQuint: 'Ease In Quint',
  easeOutQuint: 'Ease Out Quint',
  easeInOutQuint: 'Ease In Out Quint',
  easeOutElastic: 'Ease Out Elastic',
  easeInElastic: 'Ease In Elastic',
  easeInOutElastic: 'Ease In Out Elastic',
  easeInSine: 'Ease In Sine',
  easeOutSine: 'Ease Out Sine',
  easeInOutSine: 'Ease In Out Sine',
  easeInCirc: 'Ease In Circ',
  easeOutCirc: 'Ease Out Circ',
  easeInOutCirc: 'Ease In Out Circ',
  easeInExpo: 'Ease In Expo',
  easeOutExpo: 'Ease Out Expo',
  easeInOutExpo: 'Ease In Out Expo',
};
