<script setup>
import router from './router';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firestore/init.js";
import { UserStore } from "./stores/UserStore.js";
import { DesignsStore } from "./stores/DesignsStore.js";
import { logsnagIdentify, logsnagSetUserId } from './scripts/LogSnag.js';

function isPublicRoute() {
  let isPublic = false;
  let publicRouteStrings = ['/embed', '/terms', '/reset-password']
  publicRouteStrings.forEach(string => {
    if(router.currentRoute.value.path.includes(string)) {
      isPublic = true;
    }
  });
  return isPublic;
}

onAuthStateChanged(auth, (user) => {
  if (user) {
    logsnagSetUserId(user.uid)
    logsnagIdentify({
      user_id: user.uid,
      properties: {
        email: user.email,
      }
    });
    
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User

    DesignsStore.state.designs.length = 0;
    DesignsStore.lastDoc = null;

    UserStore.setUser(user);
    UserStore.getUser();
    
    if(router.currentRoute.value.path.includes('/login')) {
      if(user.uid) {
        router.push({ path: '/dashboard', replace: true })
      }
    }

  } else {
    // User is signed out
    // ...
    if(router.currentRoute.value.path !== '/' && !isPublicRoute()) {
      router.push({ path: '/login', replace: true })
    }
  }
});

</script>

<template>
  <RouterView />
</template>

<style lang="scss">
  @import "./styles/_shared.scss";
</style>
