<script>
import { randomInt } from '../scripts/Helpers.js';

export default {
    props: ['template'],
    data() {
        return {
            fakeSizes: [[8, 14], [14,14], [14, 9], [11, 14]]
        }
    },
    mounted() {
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        const ctx = this.$refs.placeholder.getContext('2d');
        const size = this.fakeSizes[randomInt(0, 3)];
        this.$refs.placeholder.width = size[0];
        this.$refs.placeholder.height = size[1];
        ctx.fillStyle = darkThemeMq ? '#3b3c42' : '#eaeaea';
        ctx.fillRect(0,0,size[0], size[1]);
    }
}

</script>

<template>
    <div class="design-listing-item-loading" :class="{'template-loading-item': template}">
      <div >
        <canvas ref="placeholder"></canvas>
      </div>
      <div class="design-listing-info">
        <div class="design-listing-name">██████</div>
      </div>
    </div>
</template>

<style lang="scss">

.design-listing-item-loading {
  position: relative;
  width: 16.6666666%;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  overflow: hidden;

  &.template-loading-item {
    width: 12%;

    img, canvas {
      aspect-ratio: 1;
      padding: 1rem;
      object-fit: cover;
      height: auto;
    }

    .design-listing-info {
      padding: 0 1rem 1rem;
    }

    .design-listing-name {
      font-size: 1.2rem;
    }
  }

  img, canvas {
    position: relative;
    width: 100%;
    z-index: -1;
    object-fit: contain;
    height: calc(20vw - 2rem);
    padding: 2rem;
    animation: listing-loading 1s linear infinite;
  }

  .design-listing-name {
    color: var(--border-color);
    animation: listing-loading 1s linear infinite;
  }
}

@keyframes listing-loading {
  0% {
    opacity: 0.65;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.65;
  }
}

</style>