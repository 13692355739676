<script>
import { PRIMARY_COLOR, PRIMARY_COLOR_ALPHA_25 } from '../scripts/Constants.js';
import { rotate, getShapeBoundingBox } from '../scripts/Draw.js';
import { interpolatePath } from '../scripts/Path.js';
import { EFFECTS } from '../scripts/Shaders.js';
import { getPointDistToLine, throttle } from '../scripts/Helpers.js';
import { StudioStore } from '../stores/StudioStore.js';
import ActionFlyout from './ActionFlyout.vue';
import { Vec2, Vec3 } from 'curtainsjs';

const EFFECT_COLOR = PRIMARY_COLOR;
const RAIL_WIDTH = 0;
const EDITOR_WIDTH = 700;

function dragShapeHandle(event, mouse) {
  const offsetPos = event.item.getPositionOffset();
  let deltaX = Math.round(mouse.downPos.x + mouse.delta.x - offsetPos.x);
  let deltaY = Math.round(mouse.downPos.y + mouse.delta.y - offsetPos.y);
  const rotated = rotate(
    event.item.local.boxStart.center.x,
    event.item.local.boxStart.center.y,
    deltaX,
    deltaY,
    event.item.rotation * 360
  );

  const isShiftPressed = StudioStore.state.hotkeys.isPressed('shift');
  const originalWidth = Math.abs(event.item.coords[1][0] - event.item.coords[0][0]);
  const originalHeight = Math.abs(event.item.coords[2][1] - event.item.coords[1][1]);
  const aspectRatio = originalWidth / originalHeight;

  if ('corner' in event) {
    const oppositeCorner = (event.corner + 2) % 4;
    let newX = rotated[0];
    let newY = rotated[1];

    if (isShiftPressed) {
      const dx = newX - event.item.coords[oppositeCorner][0];
      const dy = newY - event.item.coords[oppositeCorner][1];
      if (Math.abs(dx) > Math.abs(dy * aspectRatio)) {
        newY = event.item.coords[oppositeCorner][1] + Math.sign(dy) * Math.abs(dx / aspectRatio);
      } else {
        newX = event.item.coords[oppositeCorner][0] + Math.sign(dx) * Math.abs(dy * aspectRatio);
      }
    }

    event.item.coords[event.corner][0] = newX;
    event.item.coords[event.corner][1] = newY;

    if (event.corner === 0 || event.corner === 2) {
      event.item.coords[(event.corner + 3) % 4][0] = newX;
      event.item.coords[(event.corner + 1) % 4][1] = newY;
    } else {
      event.item.coords[(event.corner + 1) % 4][0] = newX;
      event.item.coords[(event.corner + 3) % 4][1] = newY;
    }

    // Update width and height based on new coords
    event.item.width = [event.item.coords[0][0], event.item.coords[1][0]];
    event.item.height = [event.item.coords[1][1], event.item.coords[2][1]];
  } else {
    // Edge dragging
    if (isShiftPressed) {
      if (event.edge % 2 === 0) {
        // Top or bottom edge
        const newHeight = Math.abs(rotated[1] - event.item.coords[(event.edge + 2) % 4][1]);
        const newWidth = newHeight * aspectRatio;
        const currentWidth = Math.abs(event.item.coords[1][0] - event.item.coords[0][0]);
        const widthDiff = (newWidth - currentWidth) / 2;

        event.item.coords[0][0] += widthDiff;
        event.item.coords[3][0] += widthDiff;
        event.item.coords[1][0] -= widthDiff;
        event.item.coords[2][0] -= widthDiff;
      } else {
        // Left or right edge
        const newWidth = Math.abs(rotated[0] - event.item.coords[(event.edge + 2) % 4][0]);
        const newHeight = newWidth / aspectRatio;
        const currentHeight = Math.abs(event.item.coords[2][1] - event.item.coords[1][1]);
        const heightDiff = (newHeight - currentHeight) / 2;

        event.item.coords[0][1] -= heightDiff;
        event.item.coords[1][1] -= heightDiff;
        event.item.coords[2][1] += heightDiff;
        event.item.coords[3][1] += heightDiff;
      }
    }

    if (!event.edge) {
      event.item.coords[0][1] = rotated[1];
      event.item.coords[1][1] = rotated[1];
    } else if (event.edge === 1) {
      event.item.coords[1][0] = rotated[0];
      event.item.coords[2][0] = rotated[0];
    } else if (event.edge === 2) {
      event.item.coords[2][1] = rotated[1];
      event.item.coords[3][1] = rotated[1];
    } else if (event.edge === 3) {
      event.item.coords[0][0] = rotated[0];
      event.item.coords[3][0] = rotated[0];
    }

    // Update width and height based on new coords
    event.item.width = [event.item.coords[0][0], event.item.coords[1][0]];
    event.item.height = [event.item.coords[1][1], event.item.coords[2][1]];
  }
}

function dragDraw(event, mouse, w, h) {
  if ('corner' in event) {
    if (event.corner === 0) {
      let top = event.box.corners[2][1];
      let right = event.box.corners[1][0];
      event.item.coords = event.coords.map(([x, y]) => [
        roundXToNearestY(x + mouse.delta.x * ((right - x) / event.box.width), 0.1),
        roundXToNearestY(y + (mouse.delta.y * (top - y)) / event.box.height, 0.1),
      ]);
    } else if (event.corner === 1) {
      let top = event.box.corners[2][1];
      let left = event.box.corners[0][0];
      event.item.coords = event.coords.map(([x, y]) => [
        roundXToNearestY(x + mouse.delta.x * ((x - left) / event.box.width), 0.1),
        roundXToNearestY(y + (mouse.delta.y * (top - y)) / event.box.height, 0.1),
      ]);
    } else if (event.corner === 2) {
      let bottom = event.box.corners[0][1];
      let left = event.box.corners[0][0];
      event.item.coords = event.coords.map(([x, y]) => [
        roundXToNearestY(x + mouse.delta.x * ((x - left) / event.box.width), 0.1),
        roundXToNearestY(y + (mouse.delta.y * (y - bottom)) / event.box.height, 0.1),
      ]);
    } else if (event.corner === 3) {
      let bottom = event.box.corners[0][1];
      let right = event.box.corners[1][0];
      event.item.coords = event.coords.map(([x, y]) => [
        roundXToNearestY(x + mouse.delta.x * ((right - x) / event.box.width), 0.1),
        roundXToNearestY(y + (mouse.delta.y * (y - bottom)) / event.box.height, 0.1),
      ]);
    }
    event.item.coordsHiRes = event.item.coords;
  } else {
    if (event.edge === 1) {
      let left = event.box.corners[0][0];
      event.item.coords = event.coords.map(([x, y]) => [
        roundXToNearestY(x + mouse.delta.x * ((x - left) / event.box.width), 0.1),
        y,
      ]);
    } else if (event.edge === 2) {
      let bottom = event.box.corners[0][1];
      event.item.coords = event.coords.map(([x, y]) => [
        x,
        roundXToNearestY(y + (mouse.delta.y * (y - bottom)) / event.box.height, 0.1),
      ]);
    } else if (event.edge === 3) {
      let right = event.box.corners[1][0];
      event.item.coords = event.coords.map(([x, y]) => [
        roundXToNearestY(x + mouse.delta.x * ((right - x) / event.box.width), 0.1),
        y,
      ]);
    } else if (event.edge === 0) {
      let top = event.box.corners[2][1];
      event.item.coords = event.coords.map(([x, y]) => [
        x,
        roundXToNearestY(y + (mouse.delta.y * (top - y)) / event.box.height, 0.1),
      ]);
    }
    event.item.coordsHiRes = event.item.coords;
  }
}

function dragTextBox(event, point, mouse) {
  const offsetPosition = event.item.getPositionOffset();
  const isShiftPressed = StudioStore.state.hotkeys.isPressed('shift');

  let deltaX = mouse.delta.x;
  let deltaY = mouse.delta.y;

  const originalAspectRatio = event.box.width / event.heightStart;

  if ('corner' in event) {
    let newWidth, newHeight;
    const isLeft = event.corner === 0 || event.corner === 3;
    const isTop = event.corner === 0 || event.corner === 1;

    newWidth = isLeft ? event.box.width - deltaX : event.box.width + deltaX;
    newHeight = isTop ? event.heightStart - deltaY : event.heightStart + deltaY;

    if (isShiftPressed) {
      const ratio = Math.abs(deltaX / deltaY);
      if (ratio > originalAspectRatio) {
        newHeight = newWidth / originalAspectRatio;
      } else {
        newWidth = newHeight * originalAspectRatio;
      }
    }

    if (isLeft) {
      event.item.translateX = event.pos.x + event.box.width - newWidth;
    }
    if (isTop) {
      event.item.translateY = event.pos.y + event.heightStart - newHeight;
    }

    event.item.width = newWidth;
    const fontSizeScale = newHeight / event.heightStart;
    event.item.lineHeight = event.lineHeightStart * fontSizeScale;
    event.item.fontSize = event.fontSizeStart * fontSizeScale;
  } else {
    if (event.edge === 0 || event.edge === 2) {
      let newHeight = event.edge === 0 ? event.heightStart - deltaY : event.heightStart + deltaY;
      if (isShiftPressed) {
        const newWidth = newHeight * originalAspectRatio;
        const widthDiff = newWidth - event.box.width;
        event.item.width = newWidth;
        event.item.translateX = event.pos.x - widthDiff / 2;
      }
      if (event.edge === 0) {
        event.item.translateY = event.pos.y + event.heightStart - newHeight;
      }
      const fontSizeScale = newHeight / event.heightStart;
      event.item.lineHeight = event.lineHeightStart * fontSizeScale;
      event.item.fontSize = event.fontSizeStart * fontSizeScale;
    } else if (event.edge === 1 || event.edge === 3) {
      let newWidth = event.edge === 3 ? event.box.width - deltaX : event.box.width + deltaX;
      event.item.width = newWidth;
      if (event.edge === 3) {
        event.item.translateX = event.pos.x + event.box.width - newWidth;
      }
      if (isShiftPressed) {
        const newHeight = newWidth / originalAspectRatio;
        const heightDiff = newHeight - event.heightStart;
        const fontSizeScale = newHeight / event.heightStart;
        event.item.lineHeight = event.lineHeightStart * fontSizeScale;
        event.item.fontSize = event.fontSizeStart * fontSizeScale;
        event.item.translateY = event.pos.y - heightDiff / 2;
      }
    }
  }
}

function calculateCursorIndex(factor, rot, offset) {
  return Math.round((factor * 90 + rot + offset) / 45) % 8;
}

function roundXToNearestY(x, y) {
  return Math.ceil(x / y) * y;
}

function dotProduct(ax, ay, bx, by) {
  return ax * bx + ay * by;
}

function normalize(ax, ay) {
  const length = Math.sqrt(ax * ax + ay * ay);
  return { x: ax / length, y: ay / length };
}

function projectPolygon(axis, polygon) {
  let min = dotProduct(axis.x, axis.y, polygon[0][0], polygon[0][1]);
  let max = min;
  for (let i = 1; i < polygon.length; i++) {
    let p = dotProduct(axis.x, axis.y, polygon[i][0], polygon[i][1]);
    if (p < min) {
      min = p;
    } else if (p > max) {
      max = p;
    }
  }
  return { min, max };
}

function polygonsOverlap(polygon1, polygon2) {
  const polygons = [polygon1, polygon2];
  for (let i = 0; i < polygons.length; i++) {
    for (let j = 0; j < polygons[i].length; j++) {
      let next = j + 1 === polygons[i].length ? 0 : j + 1;
      let edge = { x: polygons[i][j][0] - polygons[i][next][0], y: polygons[i][j][1] - polygons[i][next][1] };
      let axis = normalize(-edge.y, edge.x);
      let projection1 = projectPolygon(axis, polygon1);
      let projection2 = projectPolygon(axis, polygon2);
      if (projection1.max < projection2.min || projection2.max < projection1.min) {
        return false; // No overlap on this axis
      }
    }
  }
  return true; // Overlap on all axes
}

function calculateTotalBoundingBox(rects) {
  let minX = rects[0][0][0];
  let minY = rects[0][0][1];
  let maxX = rects[0][0][0];
  let maxY = rects[0][0][1];

  rects.forEach(rect => {
    rect.forEach(([x, y]) => {
      if (x < minX) minX = x;
      if (x > maxX) maxX = x;
      if (y < minY) minY = y;
      if (y > maxY) maxY = y;
    });
  });

  // Return the bounding box as four points: top-left, top-right, bottom-right, bottom-left
  return [
    [minX, minY],
    [maxX, minY],
    [maxX, maxY],
    [minX, maxY],
  ];
}

export default {
  props: ['updater', 'cW', 'cH'],
  components: {
    ActionFlyout,
  },
  data() {
    return {
      ctx: '',
      event: {},
      contextItem: null,
      contextPos: null,
      canvasWidth: (window.innerWidth - RAIL_WIDTH * 2) * 2,
      canvasHeight: window.innerHeight * 2,
      corners: [
        [-1, -1],
        [1, -1],
        [1, 1],
        [-1, 1],
      ],
      resizeCursors: [
        'nwse-resize',
        'ns-resize',
        'nesw-resize',
        'ew-resize',
        'nwse-resize',
        'ns-resize',
        'nesw-resize',
        'ew-resize',
      ],
      mouseDownX: null,
      mouseDownY: null,
      isMultiSelecting: false,
      selectionBoxStart: { x: 0, y: 0 },
      selectionBoxEnd: { x: 0, y: 0 },
      selectionBoundingBox: [],
      selectedItemStartPositions: [],
      textEditWindow: null,
      clickToEditText: false,
      sizeKeys: {
        image: 'size',
        ripple: 'frequency',
        blinds: 'frequency',
        chromab: 'amount',
        beam: 'radius',
        bokeh: 'radius',
        blur: 'amount',
        bloom: 'amount',
        polar: 'scale',
        projection: 'scale',
        bulge: 'scale',
        reflectiveSurface: 'amount',
        pixelate: 'amount',
        scan: 'amount',
        ascii: 'scale',
        godrays: 'amount',
        neon: 'amount',
        custom: 'scale',
        extend: 'scale',
        landscape: 'scale',
        stars: 'scale',
        doublepointlight: 'scale',
        spotlight: 'scale',
        noiseBlur: 'scale',
        waterRipple: 'scale',
        halftone: 'amount',
        noise: 'frequency',
        hologram: 'frequency',
        liquify: 'frequency',
        diffuse: 'amount',
        pattern: 'scale',
        wisps: 'scale',
        guilloche: 'scale',
        gradient: 'scale',
        gradientFill: 'scale',
        twodlight: 'scale',
        gradientMap: 'scale',
        mouse: 'radius',
        marquee: 'scale',
        retro_screen: 'scale',
        flowField: 'spread',
        replicate: 'amount',
        sphere: 'radius',
        sine: 'frequency',
        sdf_shape: 'scale',
        voronoi: 'amount',
        progressiveBlur: 'amount',
        angle: 'angle',
        fbm: 'frequency',
        swirl: 'radius',
        noiseField: 'spread',
        vignette: 'radius',
      },
      angleKeys: {
        chromab: 'angle',
        pattern: 'angle',
        custom: 'angle',
        stars: 'angle',
        extend: 'angle',
        beam: 'angle',
        fbm: 'angle',
        bulge: 'angle',
        doublepointlight: 'angle',
        replicate: 'angle',
        hologram: 'angle',
        noiseBlur: 'angle',
        noise: 'angle',
        guilloche: 'angle',
        gradient: 'gradientAngle',
        gradientFill: 'gradientAngle',
        blinds: 'angle',
        marquee: 'angle',
        swirl: 'phase',
        sdf_shape: 'axis.z',
        scan: 'angle',
        halftone: 'rotation',
        progressiveBlur: 'angle',
        wisps: 'angle',
        polar: 'angle',
        neon: 'angle',
        voronoi: 'angle',
        liquify: 'angle',
      },
    };
  },
  computed: {
    offsetLeft() {
      return this.customCodeItemId
        ? StudioStore.state.panLeft
        : (window.innerWidth - this.width) / 2 + StudioStore.state.panLeft;
    },
    offsetTop() {
      return (window.innerHeight - this.height) / 2 + StudioStore.state.panTop;
    },
    dragging() {
      return StudioStore.state.mouse.dragging;
    },
    width() {
      return this.cW * this.zoom || 1;
    },
    height() {
      return this.cH * this.zoom || 1;
    },
    items() {
      return StudioStore.getCanvasUIItems();
    },
    selectedItems() {
      return this.items.filter(n => StudioStore.isSelected(n));
    },
    zoom() {
      return StudioStore.state.userZoom;
    },
    scale() {
      return StudioStore.state.scale;
    },
    editingItem() {
      return StudioStore.getSelectedItem();
    },
    customCodeItemId() {
      return StudioStore.state.customCodeItemId;
    },
    mouse() {
      return this.items?.find(n => n?.layerType === 'effect' && n?.type === 'mouse');
    },
    canvasDpi() {
      return 2 || 1;
    },
    textBoxStyles() {
      return {
        width: this.editingItem.width * this.zoom + 'px',
        height: this.editingItem.height * this.zoom + 'px',
        top: this.editingItem.local.textBoxPos.y * this.zoom + this.offsetTop + 'px',
        left: this.editingItem.local.textBoxPos.x * this.zoom + this.offsetLeft + 'px',
        fontSize: this.editingItem.fontSize * this.zoom + 'px',
        lineHeight: this.editingItem.lineHeight * this.zoom + 'px',
        letterSpacing: this.editingItem.letterSpacing * this.zoom + 'px',
        fontFamily: this.editingItem.fontFamily,
        fontStyle: this.editingItem.fontStyle,
        fontWeight: this.editingItem.fontStyle,
        textAlign: this.editingItem.textAlign,
        wordBreak: 'break-word',
        transform: `rotateZ(${Math.round(this.editingItem.rotation * 360)}deg)`,
      };
    },
    dpiScale() {
      return 2 / 2;
    },
  },
  mounted() {
    this.$refs.drawUICanvas.width = this.canvasWidth;
    this.$refs.drawUICanvas.height = this.canvasHeight;
    this.ctx = this.$refs.drawUICanvas.getContext('2d');
    this.ctx.scale(this.canvasDpi, this.canvasDpi);
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = PRIMARY_COLOR;
    this.renderItems();
    window.addEventListener('resize', this.handleWindowResize);
    this.$refs.drawUICanvas.addEventListener('contextmenu', this.handleContextMenu);
    window.addEventListener('click', this.handleContextMenuClose);
    window.addEventListener('mousemove', this.handleMouseMoveThrottled);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('click', this.handleContextMenuClose);
    window.removeEventListener('mousemove', this.handleMouseMoveThrottled);
  },
  watch: {
    updater() {
      if (!this.mouse) {
        this.renderItems();
      }
    },
    zoom() {
      this.renderItems();
    },
    customCodeItemId() {
      this.$nextTick(() => {
        this.handleWindowResize();
      });
    },
    editingItem() {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      if (this.editingItem) {
        this.items.forEach(item => {
          if (item.local.editing) {
            item.local.editing = false;
          }
        });

        if (this.editingItem.layerType === 'text') {
          if (this.editingItem.justCreated) {
            this.editingItem.local.editing = true;
            this.$nextTick(() => {
              this.$refs.textBox.focus();
            });
          }
        }
        this.renderItems();
      } else {
        this.selectionBoundingBox = [];
        this.selectedItemStartPositions = [];
      }
    },
  },
  methods: {
    renderItems() {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      this.ctx.strokeStyle = PRIMARY_COLOR;

      if (this.isMultiSelecting) {
        const startX = Math.min(this.selectionBoxStart.x, this.selectionBoxEnd.x);
        const startY = Math.min(this.selectionBoxStart.y, this.selectionBoxEnd.y);
        const width = Math.abs(this.selectionBoxStart.x - this.selectionBoxEnd.x);
        const height = Math.abs(this.selectionBoxStart.y - this.selectionBoxEnd.y);

        this.ctx.strokeStyle = PRIMARY_COLOR; // Example color
        this.ctx.lineWidth = 1;
        this.ctx.strokeRect(startX, startY, width, height);
        this.ctx.setLineDash([]); // Reset dash to solid
        this.ctx.fillStyle = PRIMARY_COLOR_ALPHA_25;
        this.ctx.fillRect(startX, startY, width, height);

        this.selectItemsInBox({
          x: startX,
          y: startY,
          width,
          height,
        });
      }

      if (StudioStore.state.selectedItems.length > 1) {
        this.ctx.lineWidth = 1.5;
        this.calculateSelectionBoundingBox(StudioStore.state.selectedItems);
        this.renderShapeBoundingBox(null, this.selectionBoundingBox);
        StudioStore.state.selectedItems.forEach(item => {
          this.renderShapeBoundingBox(item);
        });
        //return;
      }

      this.items.forEach(item => {
        let selected = StudioStore.isSelected(item);
        if (item && (selected || item.local.isBeingHovered)) {
          this.ctx.lineWidth = selected && !item.local.isBeingHovered ? 1.5 : 2;
          if (item.layerType === 'effect') {
            this.renderEffectUI(item);
          } else if (item.layerType === 'draw') {
            this.renderPathLine(item);
          } else {
            this.renderShapeBoundingBox(item);
          }
        }
      });
    },
    handleWindowResize() {
      if (this.$refs.drawUICanvas) {
        this.$nextTick(() => {
          this.canvasWidth = (window.innerWidth - (this.customCodeItemId ? 980 : 0)) * 2;
          this.canvasHeight = window.innerHeight * 2;
          this.$refs.drawUICanvas.width = this.canvasWidth;
          this.$refs.drawUICanvas.height = this.canvasHeight;
          this.ctx.scale(this.canvasDpi, this.canvasDpi);
          this.ctx.lineWidth = 2;
          this.renderItems();
        });
      }
    },
    handleTextBoxInput(e) {
      this.editingItem.textContent = this.$refs.textBox.value;
      this.$emit('update');
    },
    handleContextMenu(e) {
      e.preventDefault();
      const item = StudioStore.getSelectedItem();
      requestAnimationFrame(() => {
        this.contextItem = item;
        this.contextPos = {
          x: e.pageX - 10 - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH),
          y: e.pageY + 45,
        };
      });
    },
    handleContextMenuClose() {
      if (document.querySelector('.flyout-options')) {
        this.contextItem = null;
      }
    },
    calculateSelectionBoundingBox(items) {
      let boxes = [];
      items.forEach(item => {
        if (item && (item.local.corners || item.coords)) {
          const box = item.local.corners || this.getDrawnCoords(item);
          boxes.push(box);
        }
      });
      if (boxes.length) {
        this.selectionBoundingBox = calculateTotalBoundingBox(boxes);
      }
    },
    handleDragMultiSelection(e) {
      StudioStore.state.selectedItems.forEach((item, index) => {
        const startState = this.selectedItemStartPositions[index];
        if (item.getTranslateX) {
          item.setTranslateX(roundXToNearestY(startState.x + StudioStore.state.mouse.delta.x, 1));
          item.setTranslateY(roundXToNearestY(startState.y + StudioStore.state.mouse.delta.y, 1));
        } else {
          item.translateX = roundXToNearestY(startState.x + StudioStore.state.mouse.delta.x, 1);
          item.translateY = roundXToNearestY(startState.y + StudioStore.state.mouse.delta.y, 1);
        }
      });
      this.calculateSelectionBoundingBox(StudioStore.state.selectedItems);
      this.$emit('update');
    },
    handleItemEvent(e) {
      const offsetPos = this.event.item.getPositionOffset();

      if (this.event.type === 'is-on-angle-handle') {
        this.handleAngleEvent(offsetPos);
      } else if ('edge' in this.event || 'corner' in this.event) {
        this.handleEdgeOrCornerEvent(offsetPos);
      } else if (this.event.item.coords && !this.event.item.local.editing) {
        this.handleCoordsEvent();
      }
    },

    handleAngleEvent(offsetPos) {
      const delta_x = StudioStore.state.mouse.movePos.x - (this.event.box.center.x + this.event.pos.x + offsetPos.offX);
      const delta_y = StudioStore.state.mouse.movePos.y - (this.event.box.center.y + this.event.pos.y + offsetPos.offY);
      this.event.item.rotation = roundXToNearestY(
        Math.atan2(delta_y, delta_x) / (Math.PI * 2) - this.event.rotPos + this.event.rot,
        0.0027
      );
      this.$emit('update');
    },

    handleEdgeOrCornerEvent(offsetPos) {
      const centerX = this.event.box.center.x + this.event.pos.x + offsetPos.offX;
      const centerY = this.event.box.center.y + this.event.pos.y + offsetPos.offY;
      const point = rotate(
        centerX,
        centerY,
        StudioStore.state.mouse.movePos.x,
        StudioStore.state.mouse.movePos.y,
        this.event.rot * 360
      );

      if (this.event.item.layerType === 'image') {
        this.handleImageEvent(point, centerX, centerY);
      } else {
        if (this.event.item.layerType === 'text') {
          dragTextBox(this.event, point, StudioStore.state.mouse);
        } else if (this.event.item.layerType === 'shape') {
          dragShapeHandle(this.event, StudioStore.state.mouse);
        } else if (this.event.item.layerType === 'draw') {
          dragDraw(this.event, StudioStore.state.mouse, this.cW, this.cH);
        }
      }
      this.$emit('update');
    },

    handleImageEvent(point, centerX, centerY) {
      const imgAspectRatio = this.event.item.width / this.event.item.height;
      const canvasAspectRatio = StudioStore.state.currentSize.aspectRatio;
      const widthDist = Math.abs(centerX - point[0]) * (imgAspectRatio > 1 ? 1 : 1 / imgAspectRatio);
      const heightDist = Math.abs(centerY - point[1]) * (imgAspectRatio > 1 ? imgAspectRatio : 1);

      const dist = (Math.max(widthDist, heightDist) * (1 + canvasAspectRatio)) / 2 / this.cW;

      this.event.item.size = roundXToNearestY(dist, 0.001);
    },

    handleCoordsEvent() {
      this.event.item.translateX = roundXToNearestY(this.event.pos.x + StudioStore.state.mouse.delta.x, 1);
      this.event.item.translateY = roundXToNearestY(this.event.pos.y + StudioStore.state.mouse.delta.y, 1);
      if (this.event.maskedPos) {
        const maskedItem = this.getMaskedItem(this.event.item);
        maskedItem.translateX = roundXToNearestY(this.event.maskedPos.x + StudioStore.state.mouse.delta.x, 1);
        maskedItem.translateY = roundXToNearestY(this.event.maskedPos.y + StudioStore.state.mouse.delta.y, 1);
        maskedItem.render();
      }
      this.$emit('update');
    },

    getMaskedItem(item) {
      return StudioStore.state.history[item.getIndex() - 1];
    },

    handleHoverStates(e) {
      this.$refs.drawUIContainer.style.cursor = 'auto';
      this.event = {};

      if (this.selectionBoundingBox.length) {
        let onPath = this.isOnPath(this.getShapeBoundingBoxPath(null, this.selectionBoundingBox), e);
        if (onPath) {
          this.$refs.drawUIContainer.style.cursor = 'grab';
        }
      }

      let items =
        StudioStore.state.selectedItems.length > 1 ? this.items.filter(n => !StudioStore.isSelected(n)) : this.items;
      items.forEach(item => {
        item.local.isBeingHovered = false; // reset hover state
        const { layerType, rotation, translateX: x, translateY: y } = item;
        const rot = Math.abs(rotation * 360);

        if (layerType === 'effect') {
          item.pos = item.pos || new Vec2(0.5);
          this.event = this.isOnPath(this.getEffectHandlePath(item), e) ? { type: 'grab', item } : this.event;
        } else {
          const angle = this.isOnAngleHandle(item, e);
          const handle = this.isOnHandle(item, e);
          const boundingBox = this.isOnPath(this.getShapeBoundingBoxPath(item), e);
          const edge = this.isOnEdgePath(item, e);
          const notDrawNotSelected = !(item.layerType === 'draw' && !StudioStore.isSelected(item));

          if (handle) {
            const index = calculateCursorIndex(handle, rot, layerType === 'shape' ? 0 : 90);
            this.event = {
              type: this.resizeCursors[index],
              item,
              pos: { x, y },
            };
          } else if (edge && notDrawNotSelected) {
            const index = calculateCursorIndex(edge, rot, -45);
            this.event = { type: this.resizeCursors[index], item, edge };
          } else if (angle && !boundingBox) {
            this.event = { type: 'alias', item, pos: { x, y } };
          } else if (notDrawNotSelected && boundingBox) {
            this.event = { type: 'grab', item };
          } else {
            const offsetPos = item.getPositionOffset();
            const hit = item.coords?.some(coord => {
              const dist = Math.hypot(
                coord[0] + offsetPos.x - StudioStore.state.mouse.movePos.x,
                coord[1] + offsetPos.y - StudioStore.state.mouse.movePos.y
              );
              return dist < item.size / 2;
            });
            if (hit) {
              this.event = {
                type: 'grab',
                item,
                pos: { x: offsetPos.x, y: offsetPos.y },
              };
            }
          }
        }
      });
      if (this.event.type) {
        this.event.item.local.isBeingHovered = true;
        this.$refs.drawUIContainer.style.cursor = this.event.type;
      }
    },
    handleEffectMouseMove(e) {
      if (this.event.type === 'is-on-path') {
        if (this.event.prop === 'pos') {
          this.event.item[this.event.prop] = new Vec2(
            this.event.pos._x + (e.pageX - this.mouseDownX) / this.width,
            this.event.pos._y + (e.pageY - this.mouseDownY) / this.height
          );
        } else if (this.event.prop === 'axis') {
          this.event.item[this.event.prop] = new Vec3(
            this.event.pos._x + (e.pageX - this.mouseDownX) / this.width,
            this.event.pos._y + (e.pageY - this.mouseDownY) / this.height,
            this.event.pos.z
          );
        }
      } else if (this.event.type === 'is-on-angle-handle') {
        if (this.sizeKeys[this.event.item.type]) {
          this.event.item[this.sizeKeys[this.event.item.type]] = this.getDist(e);
        }
        if (this.angleKeys[this.event.item.type]) {
          if (this.angleKeys[this.event.item.type].split('.').length > 1) {
            let path = this.angleKeys[this.event.item.type].split('.');
            this.event.item[path[0]][path[1]] = this.getAngle(e);
          } else {
            this.event.item[this.angleKeys[this.event.item.type]] = this.getAngle(e);
          }
        }
      }
      this.$emit('render');
    },

    handleMouseMoveThrottled(e) {
      return throttle(this.handleMouseMove, 4)(e);
    },
    handleMouseMove(e) {
      if (StudioStore.state.tool === 'selector' && !this.contextItem) {
        if (this.selectionBoundingBox.length && this.selectedItemStartPositions.length) {
          this.handleDragMultiSelection(e);
        }
        if (this.isMultiSelecting) {
          this.selectionBoxEnd = { x: e.pageX, y: e.pageY };
          this.handleHoverStates(e);
        } else if (this.dragging && this.event.pos) {
          if (this.event.item.layerType === 'effect' && this.event.item[this.event.prop || 'pos']) {
            this.handleEffectMouseMove(e);
          } else {
            this.handleItemEvent(e);
          }
        } else {
          this.handleHoverStates(e);
        }
      }
      if (StudioStore.state.tool === 'shape') {
        this.$refs.drawUIContainer.style.cursor = 'crosshair';
      }
      this.renderItems();
    },
    handleMouseDownEffect(item, e) {
      if (item.pos) {
        if (this.isOnPath(this.getEffectAngleHandlePath(item), e)) {
          this.event = {
            type: 'is-on-angle-handle',
            item: item,
            pos: item.pos,
          };
        } else if (item.axis && this.isOnPath(this.getMoveHandlePath(item, 'axis'), e)) {
          this.event = { type: 'is-on-path', item: item, pos: item.axis, prop: 'axis' };
        } else if (this.isOnPath(this.getMoveHandlePath(item, 'pos'), e)) {
          this.event = { type: 'is-on-path', item: item, pos: item.pos, prop: 'pos' };
        }
      }
    },
    handleMouseDown(e) {
      if (StudioStore.state.tool !== 'selector') {
        return false;
      }

      this.mouseDownX = e.pageX;
      this.mouseDownY = e.pageY;

      if (this.selectionBoundingBox.length && !StudioStore.state.hotkeys.isPressed('cmd')) {
        let onPath = this.isOnPath(this.getShapeBoundingBoxPath(null, this.selectionBoundingBox), e);
        if (onPath) {
          this.selectedItemStartPositions = StudioStore.state.selectedItems.map(n => {
            return {
              x: n.getTranslateX ? n.getTranslateX() : n.translateX,
              y: n.getTranslateY ? n.getTranslateY() : n.translateY,
            };
          });
          this.items.forEach(n => {
            n.local.isBeingHovered = false;
          });
          return;
        }
      }

      this.event = {};
      this.adjustItemsForSelected().forEach(item => {
        this.handleItemOnMouseDown(item, e);
        item.local.isBeingHovered = false;
      });

      if (!this.event.item) {
        this.isMultiSelecting = true;
        this.selectionBoxStart = { x: e.pageX, y: e.pageY };
        this.selectionBoxEnd = { x: e.pageX, y: e.pageY };
        this.selectionBoundingBox.length = 0;
        StudioStore.setSelectedItem('');
      } else {
        if (StudioStore.state.hotkeys.isPressed('shift')) {
          // Ensure no duplicate addition and reactive update
          if (!StudioStore.state.selectedItems.includes(this.event.item)) {
            StudioStore.state.selectedItems.push(this.event.item);
          }
        } else {
          StudioStore.setSelectedItem(this.event.item);
        }
      }

      this.renderItems();
    },

    deselectItems() {
      this.items.forEach(item => {
        item.local.isSelected = false;
      });
    },

    handleTextBoxSelection() {
      clearTimeout(this.textEditWindow);
      if (this.clickToEditText) {
        this.event.item.local.editing = true;
        this.$nextTick(() => {
          if (this.$refs.textBox) {
            this.$refs.textBox.focus();
          }
        });
      }
      this.clickToEditText = true;
      this.textEditWindow = setTimeout(() => {
        this.clickToEditText = false;
      }, 250);
    },

    adjustItemsForSelected() {
      let items = this.items;

      if (StudioStore.state.selectedItems.length) {
        items = [...items.filter(n => !StudioStore.isSelected(n)), ...items.filter(n => StudioStore.isSelected(n))];
      }

      return items;
    },

    handleItemOnMouseDown(item, e) {
      let boundingBox = item.coords ? getShapeBoundingBox(item.coords) : null;
      if (item.layerType === 'effect') {
        this.handleMouseDownEffect(item, e);
      } else {
        this.handleMouseOnItem(item, e, boundingBox);
      }
    },

    handleMouseOnItem(item, e, boundingBox) {
      if (StudioStore.state.reorderingItem) {
        return false;
      }
      if (this.isOnHandle(item, e)) {
        this.handleMouseOnItemHandle(item, e, boundingBox);
      } else if (this.isOnEdgePath(item, e)) {
        this.handleMouseOnItemEdge(item, e, boundingBox);
      } else if (this.isOnPath(this.getShapeBoundingBoxPath(item), e)) {
        this.handleMouseOnItemPath(item, e, boundingBox);
      } else if (this.isOnAngleHandle(item, e)) {
        this.handleMouseOnAngleHandle(item, e, boundingBox);
      }
    },

    handleMouseOnItemHandle(item, e, boundingBox) {
      const handle = this.isOnHandle(item, e);
      this.event = {
        type: 'is-on-handle',
        item: item,
        corner: handle - 1,
        pos: { x: item.translateX, y: item.translateY },
        rot: item.rotation,
        box: boundingBox,
        aspectRatioOffset: StudioStore.state.aspectRatioOffset,
        coords: item.coords,
      };
      this.event.item.local.boxStart = boundingBox;
      if (item.layerType === 'text') {
        item.local.editing = false;
        this.event.lineHeightStart = item.lineHeight;
        this.event.heightStart = item.height;
        this.event.fontSizeStart = item.fontSize;
      }
    },

    handleMouseOnItemEdge(item, e, boundingBox) {
      this.event = {
        type: 'is-on-edge',
        item: item,
        edge: this.getEdgeIndex(
          [e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH), e.pageY],
          this.getDrawnCoords(item)
        ),
        aspectRatioOffset: StudioStore.state.aspectRatioOffset,
        pos: { x: item.translateX, y: item.translateY },
        rot: item.rotation,
        box: boundingBox,
        coords: item.coords,
      };
      item.local.boxStart = boundingBox;
      if (item.layerType === 'text') {
        item.local.editing = false;
        this.event.lineHeightStart = item.lineHeight;
        this.event.heightStart = item.height;
        this.event.fontSizeStart = item.fontSize;
      }
    },

    handleMouseOnItemPath(item, e, boundingBox) {
      if (item.layerType === 'draw' && !StudioStore.isSelected(item)) {
        const offsetPos = item.getPositionOffset();
        let len = item.coords.length;
        let hit = false;
        for (let j = 0; j < len; j++) {
          let coord = item.coords[j];
          const dist = Math.hypot(
            coord[0] + offsetPos.x - StudioStore.state.mouse.movePos.x,
            coord[1] + offsetPos.y - StudioStore.state.mouse.movePos.y
          );
          if (dist < item.size / 2) {
            hit = true;
          }
        }
        if (hit) {
          this.event = {
            type: 'is-on-path',
            item: item,
            pos: { x: item.translateX, y: item.translateY },
            box: boundingBox,
          };
        }
      } else {
        this.event = {
          type: 'is-on-path',
          item: item,
          pos: { x: item.translateX, y: item.translateY },
          box: boundingBox,
        };
      }
      if (item.mask) {
        const maskedItem = this.getMaskedItem(item);
        if (maskedItem && maskedItem.isElement) {
          this.event.maskedPos = {
            x: maskedItem.translateX,
            y: maskedItem.translateY,
          };
        }
      }
      if (item.layerType === 'text') {
        this.handleTextBoxSelection();
      }
    },

    handleMouseOnAngleHandle(item, e, boundingBox) {
      const offsetPos = item.getPositionOffset();
      const delta_x = StudioStore.state.mouse.movePos.x - (boundingBox.center.x + offsetPos.x);
      const delta_y = StudioStore.state.mouse.movePos.y - (boundingBox.center.y + offsetPos.y);
      this.event = {
        type: 'is-on-angle-handle',
        item: item,
        box: boundingBox,
        pos: { x: item.translateX, y: item.translateY },
        rot: item.rotation,
        rotPos: roundXToNearestY(Math.atan2(delta_y, delta_x) / (Math.PI * 2), 0.0027),
      };
    },

    handleMouseUp() {
      if (
        (this.event.type === 'is-on-edge' || this.event.type === 'is-on-handle') &&
        this.event.item.layerType === 'draw'
      ) {
        this.event.item.coordsHiRes = interpolatePath(this.event.item.coords, this.event.item.size);
      }
      if (this.isMultiSelecting) {
        this.isMultiSelecting = false;
        this.renderItems(); // Final render to clear selection box
      }
      if (this.event.box) {
        let newbox = getShapeBoundingBox(this.event.item.coords);
        let rotatedA = this.event.item.coords.map(([x, y]) =>
          rotate(this.event.box.center.x, this.event.box.center.y, x, y, -this.event.item.rotation * 360)
        );
        let rotatedB = this.event.item.coords.map(([x, y]) =>
          rotate(newbox.center.x, newbox.center.y, x, y, -this.event.item.rotation * 360)
        );
        let boxA = getShapeBoundingBox(rotatedA);
        let boxB = getShapeBoundingBox(rotatedB);
        let diffX = boxA.center.x - boxB.center.x;
        let diffY = boxA.center.y - boxB.center.y;
        this.event.item.translateX += diffX;
        this.event.item.translateY += diffY;
        this.event.item.local.boxStart = undefined;
      }
      this.event = {};
      this.selectedItemStartPositions = [];
    },
    selectItemsInBox({ x, y, width, height }) {
      const selectionBounds = getShapeBoundingBox([
        [x, y],
        [x + width, y],
        [x + width, y + height],
        [x, y + height],
      ]);
      this.items
        .filter(n => n.coords)
        .forEach(item => {
          const box = item.local.corners || this.getDrawnCoords(item);
          if (box) {
            const isOverlapping = polygonsOverlap(box, selectionBounds.corners);
            if (isOverlapping && !StudioStore.isSelected(item)) {
              StudioStore.state.selectedItems.push(item);
            }
          }
        });
      this.calculateSelectionBoundingBox(StudioStore.state.selectedItems);
    },

    getEdgeIndex(c, corners, padding) {
      let sides = corners.map((n, index) => (index < 3 ? [n, corners[index + 1]] : [n, corners[0]]));
      let sideIndex;
      let dist;
      sides.forEach((side, index) => {
        //TRBL
        let d = getPointDistToLine(c[0], c[1], side[0][0], side[0][1], side[1][0], side[1][1]);
        if (index === 0 || d < dist) {
          dist = d;
          sideIndex = index;
        }
      });
      if (padding) {
        return dist < padding ? sideIndex + 1 : false;
      } else {
        return sideIndex;
      }
    },
    getCornerIndex(c, corners, padding) {
      let sideIndex;
      let dist;
      corners.forEach((corner, index) => {
        //TRBL
        let d = getPointDistToLine(c[0], c[1], corner[0] - 1, corner[1] - 1, corner[0] + 1, corner[1] + 1);
        if (index === 0 || d < dist) {
          dist = d;
          sideIndex = index;
        }
      });
      if (padding) {
        return dist < padding ? sideIndex + 1 : false;
      } else {
        return sideIndex;
      }
    },
    isOnHandle(item, e) {
      return this.getCornerIndex(
        [e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH), e.pageY],
        item.local.corners || this.getDrawnCoords(item),
        6
      );
    },
    isOnAngleHandle(item, e) {
      return this.getCornerIndex(
        [e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH), e.pageY],
        this.getDrawnCoords(item),
        24
      );
    },
    isOnPath(path, e) {
      return this.ctx.isPointInPath(path, e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH), e.pageY);
    },
    isOnEdgePath(item, e) {
      return this.getEdgeIndex(
        [e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH), e.pageY],
        item.local.corners || this.getDrawnCoords(item),
        12
      );
    },
    getMoveHandlePath(effect, prop) {
      const path = new Path2D();
      let x = effect[prop || 'pos']._x * this.width + this.offsetLeft;
      let y = effect[prop || 'pos']._y * this.height + this.offsetTop;
      let dist = this.sizeKeys[effect.type] ? (effect[this.sizeKeys[effect.type]] * this.width) / 2 : 50;
      if (prop === 'axis') {
        dist = 50;
      }
      path.arc(x / 2, y / 2, Math.abs(dist) / 4, 0, 2 * Math.PI);
      return path;
    },
    getShapeBoundingBoxPath(item, coords) {
      const path = new Path2D();
      const corners = coords || item.local.corners || this.getDrawnCoords(item);
      for (let i = 0; i < corners.length; i++) {
        path.lineTo(corners[i][0] / 2, corners[i][1] / 2);
      }
      path.closePath();
      return path;
    },
    getSquareHandlePath(x, y, angle, size) {
      const path = new Path2D();
      path.moveTo(...rotate(x, y, x - size, y - size, -angle * 360));
      path.lineTo(...rotate(x, y, x + size, y - size, -angle * 360));
      path.lineTo(...rotate(x, y, x + size, y + size, -angle * 360));
      path.lineTo(...rotate(x, y, x - size, y + size, -angle * 360));
      return path;
    },
    getEffectAngleHandlePath(effect) {
      const path = new Path2D();
      const dist = this.sizeKeys[effect.type] ? (effect[this.sizeKeys[effect.type]] * this.width) / 2 : 50;
      let angle = 0;
      if (this.angleKeys[effect.type]) {
        if (this.angleKeys[effect.type].split('.').length > 1) {
          let path = this.angleKeys[effect.type].split('.');
          angle = effect[path[0]][path[1]];
        } else {
          angle = effect[this.angleKeys[effect.type]];
        }
      }
      const x =
        effect.pos._x * this.width +
        this.offsetLeft +
        (Math.cos((((angle - 0.75) * 360 - 180) * Math.PI) / 180) * dist) / 2;
      const y =
        effect.pos._y * this.height +
        this.offsetTop +
        (Math.sin((((angle - 0.75) * 360 - 180) * Math.PI) / 180) * dist) / 2;
      path.arc(x / 2, y / 2, 8, 0, 2 * Math.PI);
      return path;
    },
    getEffectHandlePath(effect) {
      const path = new Path2D();
      const x = effect.pos._x * this.width + this.offsetLeft;
      const y = effect.pos._y * this.height + this.offsetTop;
      let dist = this.sizeKeys[effect.type] ? (effect[this.sizeKeys[effect.type]] * this.width) / 2 : 50;
      dist = Math.max(dist, 50);
      path.arc(x / 2, y / 2, dist / 4, 0, 2 * Math.PI);
      return path;
    },
    getBoxCoords(item, box) {
      return (box || item.coords).map(coord => {
        return [Math.round(coord[0] * this.zoom + this.offsetLeft), Math.round(coord[1] * this.zoom + this.offsetTop)];
      });
    },
    getDist(e) {
      const x = this.event.item.pos._x * this.width + this.offsetLeft;
      const y = this.event.item.pos._y * this.height + this.offsetTop;
      const a = x - e.pageX + (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH);
      const b = y - e.pageY;
      return roundXToNearestY((Math.sqrt(a * a + b * b) / this.width) * 2, 0.001) * 2;
    },
    getAngle(e) {
      const x = this.event.item.pos._x * this.width + this.offsetLeft;
      const y = this.event.item.pos._y * this.height + this.offsetTop;
      const delta_x = e.pageX - (this.customCodeItemId ? EDITOR_WIDTH : RAIL_WIDTH) - x;
      const delta_y = e.pageY - y;
      return roundXToNearestY(Math.atan2(delta_y, delta_x) / (Math.PI * 2) + 0.25, 0.0027);
    },
    getDrawnCoords(item) {
      if (item.local.boxStart || item.coords) {
        const box = item.local.boxStart || getShapeBoundingBox(item.coords);
        const coords = (item.layerType === 'draw' ? box.corners : item.coords).map(([x, y]) =>
          rotate(box.center.x, box.center.y, x, y, -item.rotation * 360)
        );
        const position = item.getPositionOffset();

        return coords.map(([x, y]) => {
          return [
            Math.round((x + position.x) * this.zoom + this.offsetLeft),
            Math.round((y + position.y) * this.zoom + this.offsetTop),
          ];
        });
      } else {
        return [];
      }
    },
    renderMoveHandle(effect, prop) {
      const x = effect[prop || 'pos']._x * this.width + this.offsetLeft;
      const y = effect[prop || 'pos']._y * this.height + this.offsetTop;

      const dist =
        !prop || (prop === 'pos' && this.sizeKeys[effect.type])
          ? (effect[this.sizeKeys[effect.type]] * this.width) / 2
          : 50;
      this.ctx.beginPath();
      this.ctx.arc(x, y, Math.max(dist / 2, 30), 0, 2 * Math.PI);
      this.ctx.closePath();

      if (EFFECTS[effect.type]) {
        if (!this.dragging) {
          this.ctx.setLineDash([1, 3]);
        }

        let label = prop && prop !== 'pos' ? prop : effect.isBackground ? 'Background' : EFFECTS[effect.type].label;

        this.ctx.stroke();
        this.ctx.setLineDash([]);
        let textWidth = this.ctx.measureText(label).width;
        this.ctx.fillStyle = EFFECT_COLOR;
        if (this.ctx.roundRect) {
          this.ctx.beginPath();
          this.ctx.roundRect(x - textWidth / 2 - 5, y - 8, textWidth + 10, 18, 4);
          this.ctx.fill();
        } else {
          this.ctx.fillRect(x - textWidth / 2 - 5, y - 8, textWidth + 10, 18);
        }
        this.ctx.font = '14px Arial';
        this.ctx.fillStyle = '#FFFFFF';
        this.ctx.fillText(label, x - textWidth / 2, y + 6);
      }
    },
    renderSquareHandle(x, y, angle, size) {
      this.ctx.fillStyle = '#fff';
      this.ctx.beginPath();
      this.ctx.moveTo(...rotate(x, y, x - size, y - size, -angle * 360));
      this.ctx.lineTo(...rotate(x, y, x + size, y - size, -angle * 360));
      this.ctx.lineTo(...rotate(x, y, x + size, y + size, -angle * 360));
      this.ctx.lineTo(...rotate(x, y, x - size, y + size, -angle * 360));
      this.ctx.closePath();
      this.ctx.fill();
      this.ctx.stroke();
    },
    renderAngleHandle(x, y) {
      this.ctx.beginPath();
      this.ctx.fillStyle = '#fff';
      this.ctx.arc(x, y, 8, 0, 2 * Math.PI);
      this.ctx.closePath();
      this.ctx.fill();
      this.ctx.stroke();
    },
    renderEffectUI(item) {
      this.ctx.strokeStyle = EFFECT_COLOR;
      if (item.pos) {
        this.renderMoveHandle(item, 'pos');
      } else {
        return;
      }
      if (item.axis) {
        this.renderMoveHandle(item, 'axis');
      }

      if (this.angleKeys[item.type] || this.sizeKeys[item.type]) {
        const dist = this.sizeKeys[item.type] ? (item[this.sizeKeys[item.type]] * this.width) / 2 : 50;

        let angle = 0;
        if (this.angleKeys[item.type]) {
          if (this.angleKeys[item.type].split('.').length > 1) {
            let path = this.angleKeys[item.type].split('.');
            angle = item[path[0]][path[1]];
          } else {
            angle = item[this.angleKeys[item.type]];
          }
        }

        const aX =
          item.pos._x * this.width +
          this.offsetLeft +
          (Math.cos((((angle - 0.75) * 360 - 180) * Math.PI) / 180) * dist) / 2;
        const aY =
          item.pos._y * this.height +
          this.offsetTop +
          (Math.sin((((angle - 0.75) * 360 - 180) * Math.PI) / 180) * dist) / 2;
        this.renderAngleHandle(aX, aY);

        if (this.dragging) {
          const x = item.pos._x * this.width + this.offsetLeft;
          const y = item.pos._y * this.height + this.offsetTop;
          this.ctx.moveTo(aX, aY);
          this.ctx.lineTo(x, y);
          this.ctx.setLineDash([1, 3]);
          this.ctx.stroke();
          this.ctx.setLineDash([]);
        }
      }
      this.ctx.strokeStyle = PRIMARY_COLOR;
    },
    renderShapeBoundingBox(item, coords) {
      const corners = coords || this.getDrawnCoords(item);
      this.ctx.beginPath();
      for (let i = 0; i < corners.length; i++) {
        this.ctx.lineTo(...corners[i]);
      }
      this.ctx.closePath();
      this.ctx.stroke();

      if (item) {
        item.local.drawnCoords = corners;
        if (StudioStore.isSelected(item) && StudioStore.state.selectedItems.length === 1) {
          for (let i = 0; i < corners.length; i++) {
            this.renderSquareHandle(...corners[i], item?.rotation || 0, 5);
          }
        }
      }
    },
    scalePathCoord(coord, item, skew) {
      const offsetPos = item.getPositionOffset();
      return [
        Math.round(((coord[0] * skew.x) / this.scale + item.translateX + offsetPos.offX) * this.zoom + this.offsetLeft),
        Math.round(((coord[1] * skew.y) / this.scale + item.translateY + offsetPos.offY) * this.zoom + this.offsetTop),
      ];
    },
    renderPathLine(item) {
      const skew = {
        x: Math.sqrt(this.cW / this.cH / item.aspectRatio) * this.scale,
        y: Math.sqrt((this.cH / this.cW) * item.aspectRatio) * this.scale,
      };
      const scaledCoords = item.coords.map(n => this.scalePathCoord(n, item, skew));

      this.ctx.beginPath();
      for (let i = 1; i < item.coords.length; i++) {
        this.ctx.lineTo(...scaledCoords[i]);
      }
      this.ctx.stroke();
      if (StudioStore.isSelected(item)) {
        const corners = getShapeBoundingBox(scaledCoords).corners.map(([x, y], index) => {
          return [
            x + (item.size / 2) * this.zoom * this.corners[index][0],
            y + (item.size / 2) * this.zoom * this.corners[index][1],
          ];
        });

        item.local.corners = corners;

        this.ctx.beginPath();
        for (let i = 0; i < corners.length; i++) {
          this.ctx.lineTo(...corners[i]);
        }
        this.ctx.closePath();
        this.ctx.stroke();
        for (let i = 0; i < corners.length; i++) {
          this.renderSquareHandle(...corners[i], item.rotation || 0, 5);
        }
      }
    },
  },
  handleTextBoxEscape() {
    if (e.key === 'Escape') {
      this.$refs.textBox.blur();
      StudioStore.setSelectedItem('');
    }
  },
};
</script>

<template>
  <div
    style="position: absolute; width: 100%; height: 100%"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
    @mouseleave="handleMouseLeave"
    ref="drawUIContainer"
  >
    <ActionFlyout
      v-if="contextItem"
      :pos="contextPos"
      :item="contextItem"
      @delete-item="$emit('delete-item', $event)"
      @add-effect="$emit('add-effect', $event)"
    ></ActionFlyout>
    <textarea
      v-if="editingItem && editingItem.local.editing"
      ref="textBox"
      id="textBoxInput"
      v-model="editingItem.textContent"
      class="text-box-editor"
      :style="textBoxStyles"
      @input="handleTextBoxInput"
      @contextmenu="handleContextMenu"
    ></textarea>
    <canvas class="position-adjuster-container" ref="drawUICanvas"></canvas>
  </div>
</template>

<style lang="scss" scoped>
.position-adjuster-container {
  position: absolute;
  height: 100%;
  z-index: 1;
}

.text-box-editor {
  position: absolute;
  padding: 0;
  color: transparent;
  caret-color: var(--primary-color);
  z-index: 2;
  outline: none;
  background-color: transparent;
  border: none;
  resize: none;
  overflow: hidden;
  //white-space: nowrap;

  &::selection {
    background: rgba(30, 144, 255, 0.45);
  }
}
</style>
