<script>
function getFillStyle(fill, ang) {
  let angle = ang || 45;
  let isWhite = fill[0] === "#FFFFFF";
  const isTransparent = fill[0] === "transparent";
  if (fill.length > 1) {
    return {
      background: `linear-gradient(${angle}deg, ${fill})`,
    };
  } else {
    return {
      background: isTransparent
        ? `linear-gradient(45deg, #fff, #fff 47%, red 50%, #fff 53%, #fff)`
        : fill[0],
      border: isWhite || isTransparent ? "1px solid #eaeaea" : "none",
    };
  }
}

export default {
  name: 'ColorPicker',
  props: {
    modelValue: {
      type: String,
      default: '#000000'
    }
  },
  emits: ['update:modelValue'],
  methods: {
    updateColor(event) {
      this.$emit('update:modelValue', event.target.value)
    },
    getFillStyle(fill) {
      fill = this.fill.type ? [fill] : fill;
      return getFillStyle(fill);
    },
  }
}
</script>

<template>
  <div class="selected-color" :style="{'backgroundColor': modelValue}">
    <input 
      type="color"
      class="color-picker-input"
      :value="modelValue" 
      @input="updateColor"
    >
  </div>
</template>

<style scoped>
.color-picker-input {
  position: absolute;
  opacity: 0;
  appearance: none;
  z-index: 1;
}
.color-picker {
  max-width: 10rem;
  width: 10rem;
  margin-right: -0.75rem;
}
</style>