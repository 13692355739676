<script>
import Icon from './Icon.vue';
import InputField from './InputField.vue';
import { StudioStore } from '../stores/StudioStore';
import { SCREEN_SIZES, createSize } from '../scripts/Constants.js';

function size(name) {
  return SCREEN_SIZES.find(n => n.name === name);
}


const groupings = [
  {
    label: 'Basic',
    items: [
      size('Square'),
      size('Window'),
    ]
  },
  {
    label: 'Mobile',
    items: [
      size('iPhone 14 Pro Max'),
      size('iPhone 14 Plus'),
      size('iPhone 14 Pro'),
      size('iPad mini 8.3'),
      size('iPad Pro 11"'),
      size('iPad Pro 12.9"'),
    ]
  },
  {
    label: 'Desktop',
    items: [
      size('Macbook Air'),
      size('Macbook Pro'),
      size('Macbook Pro 14"'),
      size('Macbook Pro 16"'),
      size('iMac'),
    ]
  },
  {
    label: 'Social Media',
    items: [
      size('Twitter post'),
      size('Twitter header'),
      size('Instagram post'),
      size('Instagram Story'),
      size('TikTok post'),
      size('Dribbble shot'),
      size('LinkedIn cover'),
    ]
  },
  {
    label: 'Print',
    items: [
      size('A4'),
      size('Letter'),
      size('Tabloid'),
    ]
  },
  {
    label: 'Presentation',
    items: [
      size('Slide 16:9'),
      size('Slide 4:3'),
    ]
  },
]

export default {
  components: {
    Icon,
    InputField
  },
  props: ['currentSize'],
  data() {
    return {
      state: StudioStore.state,
      groupings,
      activeGroup: null,
      customWidth: StudioStore.state.currentSize.realDimensions[0],
      customHeight: StudioStore.state.currentSize.realDimensions[1]
    }
  },
  mounted() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.$nextTick(() => {
      const effectsWrapper = this.$el.querySelector('.sizes');
      effectsWrapper.addEventListener('scroll', this.handleScroll);
      this.handleScroll({ target: effectsWrapper });
    });
  },
  beforeUnmount() { // Vue 3 uses beforeUnmount instead of beforeDestroy
    document.removeEventListener("mousedown", this.handleClickOutside);
    const effectsWrapper = this.$el.querySelector('.sizes');
    effectsWrapper.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      const scrollTop = event.target.scrollTop;
      let currentGroup = null;

      this.groupings.forEach(group => {
        const groupElement = this.$el.querySelector(`.size-group-label[id="${group.label}"]`);
        if (groupElement && groupElement.offsetTop - 5 <= scrollTop) {
          currentGroup = group.label;
        }
      });

      this.activeGroup = currentGroup;
    },
    handleClickOutside(event) {
      // Check if the click was outside the component's element
      if (!this.$el.contains(event.target)) {
        this.state.browsingSizes = false; // Close the component
      }
    },
    handleSizeChange(size) {
      this.state.size = size.name;
      this.state.design.size = size.name;
      this.customWidth = this.state.currentSize.realDimensions[0];
      this.customHeight = this.state.currentSize.realDimensions[1];
    },
    handleAnchorClick(group) {
      // Get the element
      const element = document.querySelector(`.sizes .size-group-label[id="${group}"]`);

      // Check if the element exists
      if (element) {
        // Scroll the element into view
        element.scrollIntoView({
          behavior: 'smooth', // Smooth scrolling
          block: 'start' // Scroll to the top of the element
        });
      }
    },
    setCustomWidth(value) {
      if(isNaN(value)) return;
      this.customWidth = value;
      this.handleCustomSizeChange();
    },
    setCustomHeight(value) {
      if(isNaN(value)) return;
      this.customHeight = value;
      this.handleCustomSizeChange();
    },
    handleCustomSizeChange() {
      this.state.customWidth = this.customWidth;
      this.state.customHeight = this.customHeight;

      if(this.state.size === 'Custom') {
        this.$emit('resize-canvas')
      }

      this.state.size = 'Custom';
      this.state.design.size = 'Custom';
      this.state.design.customWidth = this.customWidth;
      this.state.design.customHeight = this.customHeight;
    }
  },
  computed: {
    userEffects() {
      const userEffects = {}
      const keys = Object.keys(EFFECTS);
      keys.forEach(key => {
        if(!EFFECTS[key].params.hidden) {
          userEffects[key] = EFFECTS[key];
        }
      })
      return userEffects;
    },
  },
}
</script>

<template>
  <div id="size-browser" class="modal modal__effects modal__pop-in">
    <div class="flex sizes-wrapper">
      <div class="size-groups mr-2">
        <a v-for="group in groupings" @click="handleAnchorClick(group.label)" class="context-menu-link" :class="{ 'context-menu-link__active': group.label === activeGroup }" href="javascript:void(0)" :key="group.label">{{ group.label }}</a>
      </div>
      <div class="sizes">
        <div class="mt-3 parameter parameter_block w-100">
          <div class="size-group-label" style="padding-top: 0">Custom</div>
          <div class="coords-input-group ml-auto">
            <InputField
              label="W"
              :value="customWidth"
              output="px"
              min="1"
              max="1920"
              @change="setCustomWidth"
            >
            </InputField>
            <InputField
              label="H"
              :value="customHeight"
              output="px"
              min="1"
              max="1920"
              @change="setCustomHeight"
            >
            </InputField>
          </div>
        </div>
        <template v-for="group in groupings" :key="group.label">
          <div class="size-group-label mb-1" :id="group.label">{{group.label}}</div>
          <div class="group-wrapper">
            <a class="size-container flex align-center justify-between"
              v-for="size in group.items"
              :class="{'size-container__active': size.name === state.size}"
              @click="handleSizeChange(size)"
              :key="size.name"
            >
              <div class="flex align-center">
                <div class="size-icon-container mr-2">
                  <div class="size-icon" :style="{width: size.iconDimensions[0]+'px', height: size.iconDimensions[1]+'px'}"></div>
                </div>
                {{ size.name }}
              </div>
              <div class="gray">{{ size.realDimensions.join(' x ') }}</div>
            </a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.modal__effects {
  width: 48rem;
  height: 60rem;
  top: 5.9rem;
  right: unset;
  left: 28.5rem;
  padding: 0 1.5rem;
  border-radius: 0.8rem;
  z-index: 99999;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2rem;
    width: 100%;
    background: linear-gradient(to top, var(--bg-color), var(--bg-color-no-alpha))
  }
}

#size-browser {
  right: 5rem;
  top: 5.4rem;
  left: unset;
}

.sizes-wrapper {
  height: 100%;
}

.animate-icon {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #FFFFFF75;
}

.sizes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: auto;
  padding-bottom: 45rem;
}

.size-groups {
  min-width: 15rem;
  margin-top: 1.5rem;
}

.size-group-label {
  color: var(--font-secondary-color);
  padding-top: var(--unit4);
  text-transform: uppercase;
}

.group-wrapper {
  display: flex;
  flex-wrap: wrap;
  
}

.context-menu-link {
  color: var(--font-secondary-color);

  &:hover {
    color: var(--font-primary-color);
  }

  &.context-menu-link__active {
    color: var(--font-primary-color);
    background-color: var(--accent-color);
  }
}

.size-container {
  position: relative;
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 0.4rem;

  &:empty {
    display: none;
  }

  &.size-container__active {
    background-color: var(--primary);
    color: var(--selected-fill-color);
    box-shadow: inset 0 0 0 0.1rem var(--primary-color);
  }
  &.size-container__active:hover {
    //background-color: var(--primary-active-bg);
    box-shadow: inset 0 0 0 0.1rem var(--primary-color);
  }
  &:hover {
    background-color: var(--accent-color);
  }
  .parameter {
    padding-left: 0;
  }
  img {
    width: 100%;
    border-radius: 0.4rem;
  }
}

.size-icon-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1rem;
}

.size-icon {
  border: 1px solid var(--font-tertiary-color);
  border-radius: 0.1rem;
}

</style>