import {
  query,
  where,
  orderBy,
  limit,
  collection,
  addDoc,
  setDoc,
  getDocs,
  doc,
  getDoc,
  startAt,
  startAfter,
  writeBatch,
  deleteDoc,
} from 'firebase/firestore';
import { ref } from 'firebase/storage';
import { db, storage } from '../firestore/init.js';
import { UserStore } from './UserStore.js';

let prod = window.location.hostname.includes('unicorn.studio');

function collectionRef(col) {
  if (prod) {
    return collection(db, col);
  } else {
    return collection(db, 'environments', 'development', col);
  }
}

function createSubscriptionFromWebhook(webhookData) {
  const { data, meta } = webhookData;
  const { attributes } = data;

  const subscriptionObject = {
    lemonSqueezyId: data.id,
    type: data.type,
    status: attributes.status,
    statusFormatted: attributes.status_formatted,
    customerId: attributes.customer_id,
    renewsAt: attributes.renews_at || null,
    createdAt: attributes.created_at,
    updatedAt: attributes.updated_at,
    email: attributes.user_email,
    name: attributes.user_name,
    orderItemId: attributes.order_item_id || null,
    productId: attributes.product_id,
    productName: attributes.product_name,
    variantId: attributes.variant_id,
    variantName: attributes.variant_name,
    userId: meta.custom_data?.user_id || null,
    links: attributes.urls,
    testMode: attributes.test_mode,
  };

  // Convert any undefined values to null
  Object.keys(subscriptionObject).forEach(key => {
    if (subscriptionObject[key] === undefined) {
      subscriptionObject[key] = null;
    }
  });

  return subscriptionObject;
}

// async function createSubscription(webhook) {
//   let subscriptionData = createSubscriptionFromWebhook(webhook);
//   try {
//     if (!subscriptionData.lemonSqueezyId) {
//       throw new Error('Invalid lemonSqueezyId: cannot be empty or undefined');
//     }

//     const subscriptionsRef = collection(db, 'subscriptions');
//     const docRef = doc(subscriptionsRef, subscriptionData.lemonSqueezyId);

//     // Fetch the existing document
//     const existingDoc = await getDoc(docRef);

//     let mergedData;
//     if (existingDoc.exists()) {
//       const existingData = existingDoc.data();
//       mergedData = {
//         ...existingData,
//         ...subscriptionData,
//         userId: subscriptionData.userId || existingData.userId || null,
//       };
//     } else {
//       mergedData = subscriptionData;
//     }

//     // Set the merged document
//     //await setDoc(docRef, mergedData);

//     console.log('Subscription created/updated with ID: ', subscriptionData.lemonSqueezyId);
//     return docRef;
//   } catch (error) {
//     console.error('Error creating/updating subscription: ', error);
//     throw error;
//   }
// }

function docRef(col, id) {
  if (prod) {
    if (id) {
      return doc(db, col, id);
    } else {
      return collection(db, col);
    }
  } else {
    if (id) {
      return doc(db, 'environments', 'development', col, id);
    } else {
      return collection(db, 'environments', 'development', col);
    }
  }
}

function docNew(col, id) {
  if (prod) {
    if (id) {
      return doc(db, col, id);
    } else {
      return collection(db, col);
    }
  } else {
    if (id) {
      return doc(db, 'environments', 'development', col, id);
    } else {
      return collection(db, 'environments', 'development', col);
    }
  }
}

export const FirebaseStore = {
  async getDesigns(amt, startAfter) {
    let q;

    if (startAfter) {
      q = query(
        collectionRef('designs'),
        where('creatorId', '==', UserStore.id),
        where('deleted', '==', false),
        orderBy('updatedAt', 'desc'),
        startAt(startAfter),
        limit(amt)
      );
    } else {
      q = query(
        collectionRef('designs'),
        where('creatorId', '==', UserStore.id),
        where('deleted', '==', false),
        orderBy('updatedAt', 'desc'),
        limit(amt)
      );
    }

    return await getDocs(q);
  },

  async getFolderDesigns(folderId) {
    let q;

    if (folderId) {
      // Original query when folderId is provided
      q = query(
        collectionRef('designs'),
        where('creatorId', '==', UserStore.id),
        where('deleted', '==', false),
        where('folderId', '==', folderId),
        orderBy('updatedAt', 'desc')
      );
    } else {
      // Query for documents where folderId exists
      q = query(
        collectionRef('designs'),
        where('creatorId', '==', UserStore.id),
        where('deleted', '==', false),
        where('folderId', '!=', null),
        orderBy('updatedAt', 'desc')
      );
    }

    return await getDocs(q);
  },

  async deleteFolder(id) {
    try {
      // Get all designs in the folder
      const querySnapshot = await this.getFolderDesigns(id);
      const batch = writeBatch(db);

      // Update each design to move to default folder
      querySnapshot.forEach(designDoc => {
        batch.update(designDoc.ref, { folderId: null });
      });

      await batch.commit();

      // Delete the folder itself
      return await deleteDoc(this.getFolderRef(id));
    } catch (err) {
      console.error('Error deleting folder and updating designs:', err);
    }
  },

  async getVersions(id) {
    const q = query(
      collectionRef('versions'),
      where('creatorId', '==', UserStore.id),
      where('designId', '==', id),
      orderBy('createdAt', 'desc')
    );
    return await getDocs(q);
  },
  async getTemplateDesigns() {
    const q = query(
      collectionRef('designs'),
      where('creatorId', '==', 'template'),
      orderBy('updatedAt', 'desc'),
      limit(100)
    );
    return await getDocs(q);
  },
  async getFolders() {
    const q = query(
      collectionRef('folders'),
      where('creatorId', '==', UserStore.id),
      orderBy('createdAt', 'desc'),
      limit(100)
    );

    return await getDocs(q);
  },

  async getSubscriptions() {
    const q = query(collection(db, 'subscriptions'), where('userId', '==', UserStore.id));
    //const q = query(collection(db, 'subscriptions'), where('userId', '==', UserStore.id));
    return await getDocs(q);
  },
  async getUser() {
    return await getDoc(docRef('users', UserStore.id));
  },
  async createUser(data, id) {
    return await setDoc(docNew('users', id), data);
  },
  async createCustomFont(data) {
    return await addDoc(docNew('custom_fonts'), data);
  },
  async deleteCustomFont(id) {
    try {
      const fontDocRef = docRef('custom_fonts', id);
      await deleteDoc(fontDocRef);
      console.log(`Custom font with id ${id} deleted successfully.`);
    } catch (error) {
      console.error('Error deleting custom font: ', error);
    }
  },
  async getCustomFonts() {
    const q = query(collectionRef('custom_fonts'), where('user_id', '==', UserStore.id), orderBy('family', 'desc'));
    return await getDocs(q);
  },
  async createPreSignup(data) {
    return await addDoc(docNew('presignups'), data);
  },
  getDesignRef(id) {
    return docRef('designs', id);
  },
  getFolderRef(id) {
    return docRef('folders', id);
  },
  getVersionRef(id) {
    return docRef('versions', id);
  },
  storageRef(id) {
    return ref(storage, id);
  },
  async getVersionParallel(id) {
    const q = query(collectionRef('versions'), where('designId', '==', id));
    return await getDocs(q);
  },
  async getDesign(id) {
    return await getDoc(docRef('designs', id));
  },
  async getVersion(id) {
    return await getDoc(docRef('versions', id));
  },
  async createDesign(data) {
    return await addDoc(docNew('designs'), data);
  },
  async createVersion(version) {
    return await addDoc(docNew('versions'), version);
  },
  async createFolder(folder) {
    return await addDoc(docNew('folders'), folder);
  },
  async startFreeTrial(userId) {
    const now = new Date();
    const trialEndDate = new Date(now.setDate(now.getDate() + 7));

    try {
      await setDoc(
        docRef('users', UserStore.id),
        {
          trialEndDate: trialEndDate.toISOString(),
        },
        { merge: true }
      );
      console.log('Free trial started successfully');
    } catch (error) {
      console.error('Error starting free trial:', error);
    }
  },
  async setFirstName(value) {
    try {
      await setDoc(
        docRef('users', UserStore.id),
        {
          firstName: value,
        },
        { merge: true }
      );
    } catch (error) {
      console.error(error);
    }
  },
  async setLastName(value) {
    try {
      await setDoc(
        docRef('users', UserStore.id),
        {
          lastName: value,
        },
        { merge: true }
      );
    } catch (error) {
      console.error(error);
    }
  },
};
