export const rotate = (cx, cy, x, y, angle) => {
  var radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (x - cx) + sin * (y - cy) + cx,
    ny = cos * (y - cy) - sin * (x - cx) + cy;
  return [+nx.toFixed(1), +ny.toFixed(1)];
};

export const getShapeBoundingBox = (coords, scale) => {
  const resize = scale || 1;
  const leftMostX = Math.min(...coords.map(n => n[0]));
  const rightMostX = Math.max(...coords.map(n => n[0]));
  const topMostY = Math.min(...coords.map(n => n[1]));
  const bottomMostY = Math.max(...coords.map(n => n[1]));
  const width = Math.abs(rightMostX - leftMostX);
  const height = Math.abs(bottomMostY - topMostY);
  return {
    width: Math.round(width / resize),
    height: Math.round(height / resize),
    aspectRatio: width / resize / (height / resize),
    center: {
      x: Math.round((width / 2 + leftMostX) / resize),
      y: Math.round((height / 2 + topMostY) / resize),
    },
    corners: [
      [leftMostX, topMostY],
      [rightMostX, topMostY],
      [rightMostX, bottomMostY],
      [leftMostX, bottomMostY],
    ],
  };
};

export const getShapeFill = (ctx, item, coords) => {
  let fill;
  const box = getShapeBoundingBox(coords);

  if (item.fill.length > 1) {
    let angle = item.gradientAngle ? +item.gradientAngle * 2 * Math.PI : 0;
    let x = box.center.x;
    let y = box.center.y;

    if (item.gradientType === 'radial') {
      fill = ctx.createRadialGradient(x, y, Math.max(box.width, box.height) * 0.7, x, y, 0);
    }

    const widthAngle = Math.cos(angle) * box.width;
    const heightAngle = Math.sin(angle) * box.height;

    if (item.gradientType === 'linear') {
      fill = ctx.createLinearGradient(x - widthAngle / 2, y - heightAngle / 2, x + widthAngle / 2, y + heightAngle / 2);
    }

    if (item.gradientType === 'conic') {
      fill = ctx.createConicGradient(-Math.PI + angle, x, y);

      const colors = [...item.fill, ...item.fill.slice().reverse()];

      colors.forEach((color, index) => {
        fill.addColorStop(index * (1 / (colors.length - 1)), color);
      });
    } else {
      item.fill.forEach((color, index) => {
        fill.addColorStop(index * (1 / (item.fill.length - 1)), color);
      });
    }
  } else {
    fill = item.fill[0];
  }
  return fill;
};
