<script>
import { basicSetup } from "codemirror";
import { EditorState } from "@codemirror/state";
import { EditorView } from '@codemirror/view';
import { oneDark } from "@codemirror/theme-one-dark";
import { html } from "@codemirror/lang-html";

export default {
  props: ['value', 'maxHeight', 'copyable'],
  data() {
    return {
      editor: {},
      editorState: {},
      copyText: 'Copy' // Initial text for the copy link
    };
  },
  mounted() {
    const state = EditorState.create({
      doc: this.value,
      extensions: [
        html(),
        basicSetup,
        oneDark,
        EditorView.lineWrapping,
        EditorState.readOnly.of(true),
        EditorView.updateListener.of(update => {
          if (update.docChanged) {
            this.debounced.fire([update.state.doc.toString()]);
          }
        })
      ]
    });

    this.editor = new EditorView({
      state,
      parent: this.$refs.editor,
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    copyToClipboard() {
      const text = this.editor.state.doc.toString();
      navigator.clipboard.writeText(text).then(() => {
        this.copyText = 'Copied'; // Change text to "Copied" after clicking
        setTimeout(() => {
          this.copyText = 'Copy'; // Revert text back to "Copy" after 2 seconds
        }, 2000);
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    }
  }
};
</script>

<template>
  <div class="editor-container editor-container__viewer" :class="{'max__height': maxHeight}">
    <a v-if="copyable" href="javascript:void(0)" class="a copy-link font-secondary-color" @click="copyToClipboard">{{ copyText }}</a>
    <div ref="editor" class="editor"></div>
  </div>
</template>

<style lang="scss">
.editor-container {
  position: relative;
  max-width: 70rem;
  width: 100%;
  font-size: 1.1rem;
  background-color: #141419;
  z-index: 9999;
  overflow: auto;

  &.max__height {
    max-height: 40rem;
  }
}

.editor-container__viewer {
  .cm-gutters {
    display: none !important;
    background-color: #1e1d25 !important; 
  }

  .ͼo .cm-activeLine {
    background-color: transparent;
  }

  .cm-content {
    font-size: 1.2rem;
  }
}

.copy-link {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  z-index: 2;
  text-decoration: underline;
  cursor: pointer;
}
</style>
