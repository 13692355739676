import { Vec2 } from 'curtainsjs';
import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';

const fragmentShader = `#version 300 es
precision mediump float;
            
in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;

uniform vec2 uResolution;

const float FXAA_REDUCE_MIN = 0.0078125;
const float FXAA_REDUCE_MUL = 0.125;
const float FXAA_SPAN_MAX = 8.0;

out vec4 fragColor;

void main() {
    vec2 res = 1.0 / uResolution;

    vec3 rgbNW = texture(uTexture, (vTextureCoord.xy + vec2(-1.0, -1.0) * res)).xyz;
    vec3 rgbNE = texture(uTexture, (vTextureCoord.xy + vec2(1.0, -1.0) * res)).xyz;
    vec3 rgbSW = texture(uTexture, (vTextureCoord.xy + vec2(-1.0, 1.0) * res)).xyz;
    vec3 rgbSE = texture(uTexture, (vTextureCoord.xy + vec2(1.0, 1.0) * res)).xyz;
    vec4 rgbaM = texture(uTexture, vTextureCoord.xy * res);
    vec3 rgbM = rgbaM.xyz;
    vec3 luma = vec3(0.299, 0.587, 0.114);

    float lumaNW = dot(rgbNW, luma);
    float lumaNE = dot(rgbNE, luma);
    float lumaSW = dot(rgbSW, luma);
    float lumaSE = dot(rgbSE, luma);
    float lumaM  = dot(rgbM,  luma);
    float lumaMin = min(lumaM, min(min(lumaNW, lumaNE), min(lumaSW, lumaSE)));
    float lumaMax = max(lumaM, max(max(lumaNW, lumaNE) , max(lumaSW, lumaSE)));

    vec2 dir;
    dir.x = -((lumaNW + lumaNE) - (lumaSW + lumaSE));
    dir.y = ((lumaNW + lumaSW) - (lumaNE + lumaSE));

    float dirReduce = max((lumaNW + lumaNE + lumaSW + lumaSE) * (0.25 * FXAA_REDUCE_MUL), FXAA_REDUCE_MIN);

    float rcpDirMin = 1.0 / (min(abs(dir.x), abs(dir.y)) + dirReduce);
    dir = min(vec2(FXAA_SPAN_MAX, FXAA_SPAN_MAX),
        max(vec2(-FXAA_SPAN_MAX, -FXAA_SPAN_MAX),
                dir * rcpDirMin)) * res;
    vec4 rgbA = (1.0/2.0) * (
    texture(uTexture, vTextureCoord.xy + dir * (1.0/3.0 - 0.5)) +
    texture(uTexture, vTextureCoord.xy + dir * (2.0/3.0 - 0.5)));
    vec4 rgbB = rgbA * (1.0/2.0) + (1.0/4.0) * (
    texture(uTexture, vTextureCoord.xy + dir * (0.0/3.0 - 0.5)) +
    texture(uTexture, vTextureCoord.xy + dir * (3.0/3.0 - 0.5)));
    float lumaB = dot(rgbB, vec4(luma, 0.0));

    if ((lumaB < lumaMin) || (lumaB > lumaMax)) {
        fragColor = rgbA;
    } else {
        fragColor = rgbB;
    }
}
`;

export const fxaaParams = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depth: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    resolution: {
      name: 'uResolution',
      type: '2f',
      value: new Vec2(1080), // will be updated after having called super()
    },
  },
};

export const FXAA = {
  id: 'fxaa',
  label: 'FXAA',
  params: fxaaParams,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
