import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  EASE,
  mixRadiusProperties,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const voronoiShader = `#version 300 es
  precision mediump float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;

  uniform sampler2D uTexture;

  uniform float uAmount;
  uniform float uSpread;
  uniform float uAngle;
  uniform float uTime;
  uniform float uSkew;
  uniform vec2 uPos;
  uniform float uMixRadius;
  uniform int uMixRadiusInvert;
  uniform int uEasing;
  ${UNIVERSAL_UNIFORMS}
  ${EASE}
  
  vec2 random2( vec2 p ) {
    return fract(sin(vec2(dot(p,vec2(127.1,311.7)),dot(p,vec2(269.5,183.3))))*43758.5453);
  }

  const float PI = 3.14159265359;
  mat2 rot(float a) {
    return mat2(cos(a),-sin(a),sin(a),cos(a));
  }
  
  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
    float aspectRatio = uResolution.x/uResolution.y;

    vec2 skew = mix(vec2(1), vec2(1, 0), uSkew);

    vec2 st = (uv - uPos) * vec2(aspectRatio, 1.) * 50. * uAmount;
    st = st * rot(uAngle * 2. * PI) * skew;
    vec2 i_st = floor(st);
    vec2 f_st = fract(st);

    float m_dist = 15.;  // minimum distance
    vec2 m_point;        // minimum point
    vec2 d;

    for (int j=-1; j<=1; j++ ) {
      for (int i=-1; i<=1; i++ ) {
          vec2 neighbor = vec2(float(i),float(j));
          vec2 point = random2(i_st + neighbor);

          point = 0.5 + 0.5 * sin(5. + uTime * 0.2 + 6.2831*point);
          vec2 diff = neighbor + point - f_st;
          float dist = length(diff);

          if( dist < m_dist ) {
              m_dist = dist;
              m_point = point;
              d = diff;
          }
      }
    }

    vec2 offset = (m_point * 0.2 * uSpread * 2.) - (uSpread * 0.2);

    vec2 mPos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    vec2 pos = uMixRadius == 1.00 ? mPos : uPos;
    float dist = ease(uEasing, max(0.,1.-distance(uv * vec2(aspectRatio, 1), mPos * vec2(aspectRatio, 1)) * 4. * (1. - uMixRadius)));

    // #ifelseopen
    if (uMixRadiusInvert == 1) {
        dist = max(0., (0.5 - dist));
    }
    // #ifelseclose

    vec4 color = texture(uTexture, uv + offset * dist);
              
    ${computeFragColor('color')}
  }
`;

const voronoiParams = {
  fragmentShader: voronoiShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    spread: {
      name: 'uSpread',
      type: '1f',
      value: 0.25,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    skew: {
      name: 'uSkew',
      type: '1f',
      value: 0,
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const SHATTER = {
  id: 'voronoi',
  label: 'Shatter',
  params: voronoiParams,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Scale of the voronoi pattern',
    },
    spread: {
      label: 'Amount',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the strength of the offset',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
      tooltip: 'Controls the angle of the voronoi offset',
    },
    skew: {
      label: 'Skew',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the xy skew of the voronoi offset',
    },
    ...mixRadiusProperties,
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
