import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import ResetPasswordView from '../views/ResetPasswordView.vue';
import StudioView from '../views/StudioView.vue';
import EmbedView from '../views/EmbedView.vue';
import AccountView from '../views/AccountView.vue';
import DashboardView from '../views/DashboardView.vue';
import FolderView from '../views/FolderView.vue';
import HomeView from '../views/HomeView.vue';
import TermsView from '../views/TermsView.vue';

let prod = window.location.hostname.includes('unicorn.studio');

const ROUTES = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/reset-password',
    name: 'reset password',
    component: ResetPasswordView,
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
  },
  {
    path: '/dashboard/:folderId',
    name: 'folder',
    component: FolderView,
  },
  {
    path: '/edit/:designId',
    name: 'studio',
    component: StudioView,
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
  },
];

if (!prod) {
  ROUTES.push({
    path: '/embed/:designId',
    name: 'embed',
    component: EmbedView,
  });
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: ROUTES,
});

export default router;
