import { computeFragColor, vertexShader, FLOATING_POINT, UNIVERSAL_UNIFORMS, universalUniformParams } from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const neonShader = `#version 300 es
  precision mediump float;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uAmount;
  uniform float uLightAMix;
  uniform float uLightBMix;
  uniform float uAngle;
  uniform vec3 uLightA;
  uniform vec3 uLightB;
  uniform float uLights;
  uniform float uVividness;
  uniform float uTime;
  ${UNIVERSAL_UNIFORMS}

  const float SIZE = 7.;
  const float RES = 4.;

  //https://github.com/hughsk/glsl-luma/blob/master/index.glsl
  float luma(vec3 color) {
    return dot(color, vec3(0.299, 0.587, 0.114));
  }

  vec4 sampleAvg(vec2 uv) {
    
    vec4 sum = vec4(0.);
    float iter = 0.;
    
    for(float y = -SIZE/2.; y <= SIZE/2.; y += RES) {
        for(float x = -SIZE/2.; x <= SIZE/2.; x += RES) {
            sum += texture(uTexture, uv + vec2(x, y)/uResolution);
            iter += 1.; // figures out how many things get added
        }
    }
    
    return sum / vec4(iter);
  }

  float dist(vec2 p){
    p = abs(p);
    return pow(dot(pow(p, vec2(3)), vec2(1)), 1./3.); // 1.666, 4., etc.
  }

  vec4 photoshop_desaturate(vec3 color)
  {
    float bw = (min(color.r, min(color.g, color.b)) + max(color.r, max(color.g, color.b))) * 0.5;
    return vec4(bw, bw, bw, 1.0);
  }

  float getHeight(vec2 uv) {
    vec4 col = sampleAvg(uv);
    col = photoshop_desaturate(col.rgb);
    return smoothstep(0., 1., col.r);
  }

  mat4 brightnessMatrix( float brightness )
  {
      return mat4( 1, 0, 0, 0,
                  0, 1, 0, 0,
                  0, 0, 1, 0,
                  brightness, brightness, brightness, 1 );
  }

  vec4 computeNoise(vec2 uv, float offset) {
    float rotation = (-(uAngle + offset + uTime/100.) * 360.) * 3.1415926 / 180.;
    vec2 ste = 1. / uResolution;
    float height = getHeight(uv);
    
    // Create rotated sampling offsets
    vec2 rotatedSteX = ste.x * vec2(cos(rotation), sin(rotation));
    vec2 rotatedSteY = ste.y * vec2(-sin(rotation), cos(rotation));
    
    vec2 dxy = height - vec2(getHeight(uv + rotatedSteX),
                             getHeight(uv + rotatedSteY));
    
    return vec4( normalize(vec3(dxy * (0.03 * uAmount) / ste, 1.0)), height);
  }

  vec3 screen(vec3 src, vec3 dst) {
    return (src + dst) - (src * dst);
  }

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
    float rotation = ((-(uAngle - 0.5 + uTime/100.) + 0.75) * 360.) * 3.1415926 / 180.;
    vec4 normalMapX = computeNoise(uv, -0.25);
    vec4 normalMapY = computeNoise(uv, 0.25);
    
    normalMapX = vec4(normalMapX.rgb * 0.5 + 0.25, 1.);
    normalMapY = vec4(normalMapY.rgb * 0.5 + 0.25, 1.);
   
    vec4 color = texture(uTexture, uv);
    
    vec3 lightmapX = vec3( 
      smoothstep(0., 1., normalMapX.r),
      smoothstep(0., 1., normalMapX.g),
      smoothstep(0., 1., normalMapX.b)
    );

    vec3 lightmapY = vec3( 
      smoothstep(0., 1., normalMapY.r),
      smoothstep(0., 1., normalMapY.g),
      smoothstep(0., 1., normalMapY.b)
    );

    
    vec3 lighting = ((uLightA * lightmapX.r) * uLightAMix * 2.) + ((uLightB * lightmapY.r) * uLightBMix * 2.) + 
    ((uLightA * lightmapX.g) * uLightAMix * 2.) + ((uLightB * lightmapY.g) * uLightBMix * 2.);
    
    vec2 pointA = vec2(0.5) + vec2(0.5 * sin(-rotation), 0.5 * cos(-rotation));
    vec2 pointB = vec2(0.5) - vec2(0.5 * sin(-rotation), 0.5 * cos(-rotation));
    vec2 ba = pointB - pointA;
    float t = dot(uv - pointA, ba) / dot(ba, ba);
    
    color = brightnessMatrix((-1. + uLights)) * color;
    
    vec3 backgroundAmbient = mix(uLightB * uLightBMix, uLightA * uLightAMix, smoothstep(0., 1., t));
    vec3 foregroundAmbient = mix(uLightA * uLightAMix, uLightB * uLightBMix, smoothstep(0., 1., t));
    
    backgroundAmbient *= mix(lightmapX.b, lightmapY.b, t);

    lighting *= backgroundAmbient;

    color.rgb += mix(lighting, color.rgb + lighting, uVividness);
    
    ${computeFragColor('color')}
  }
`;

const neonParams = {
  fragmentShader: neonShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: "uAmount",
      type: "1f",
      value: 0.5,
    },
    lightBMix: {
      name: "uLightBMix",
      type: "1f",
      value: 0.5,
    },
    lightAMix: {
      name: "uLightAMix",
      type: "1f",
      value: 0.5,
    },
    lights: {
      name: "uLights",
      type: "1f",
      value: 0.5,
    },
    angle: {
      name: "uAngle",
      type: "1f",
      value: 0,
    },
    vividness: {
      name: "uVividness",
      type: "1f",
      value: 0.5,
    },
    lightA: {
      name: "uLightA",
      type: "3f",
      value: new Vec3(0.98, 0.12, 0.89),
    },
    lightB: {
      name: "uLightB",
      type: "3f",
      value: new Vec3(0.12, 0.77, 0.89),
    },
    time: {
      name: "uTime",
      type: "1f",
      value: 0,
    },
    ...universalUniformParams
  }
}

export const NEON = {
  id: 'neon',
  label: 'Neon',
  params: neonParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1
  },
  properties: {
    lightA: {
      label: 'Light A',
      value: new Vec3(0.98, 0.12, 0.89),
      output: 'color'
    },
    lightAMix: {
      label: 'LightA Mix',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    lightB: {
      label: 'Light B',
      value: new Vec3(0.12, 0.77, 0.89),
      output: 'color'
    },
    lightBMix: {
      label: 'LightB Mix',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    amount: {
      label: 'Highlights',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    vividness: {
      label: 'Vividness',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    lights: {
      label: 'Exposure',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    speed: {
      label: 'Speed',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
  }
}