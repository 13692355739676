<script>
import { StudioStore } from '../stores/StudioStore.js';

export default {
  props: ['zoomStyle', 'canvasPlaneStyle', 'outputStyle'],
  data() {
    return {
      state: StudioStore.state,
    }
  }
}
</script>

<template>
  <div class="zoom-wrapper" :style="zoomStyle">
    <div id="canvas-plane" class="canvas-plane" :style="canvasPlaneStyle">
      <div id="plane_source" ref="plane_source" class="plane-src" :style="{position: 'absolute'}"></div>
      <div ref="filter-output" id="filter-output" :style="outputStyle"></div>
    </div>
  </div>
</template>


<style lang="scss">

  .canvas-plane {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    will-change: filter;
    box-shadow: inset 0 0 0 0.1rem rgba(var(--bg-color), 0.5);
    z-index: 1;
  }

  .zoom-wrapper {
    position: absolute;
    overflow: hidden;
    will-change: transform;
    z-index: 0;
    background-image: linear-gradient(45deg, var(--bg-color) 25%, transparent 25%), linear-gradient(135deg, var(--bg-color) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--bg-color) 75%), linear-gradient(135deg, transparent 75%, var(--bg-color) 75%);
    background-size: 2.5rem 2.5rem;
    /* Must be a square */
    background-position: 0 0, 1.25rem 0, 1.25rem -1.25rem, 0px 1.25rem;
  }

  .plane-src {
    position: absolute;
    width: 120%;
    height: 120%;
  }

  .flicker-preventer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 0;
  }

  #filter-output canvas {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  #filter-output {
    width: 100%;
    height: 100%;
  }

  #filter-output canvas {
    width: 100% !important;
    height: 100% !important;
  }
  
  .plane-src {
    position: absolute;
    width: 100%;
    height: 100%;
  }

</style>