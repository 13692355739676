import { Vec2, Vec3, Curtains, Plane, PingPongPlane, RenderTarget } from 'curtainsjs';
import { getShapeFill, rotate, getShapeBoundingBox } from '../scripts/Draw.js';

let hidden, visibilityChange, mouseMoved;
let eventsBound = false;
let viewportHeight = window.innerHeight;
let viewportWidth = window.innerWidth;
let scrollTop = window.scrollY || window.pageYOffset;
let lastScrollPos = null;
let scrollDelta = 0;
let lastCheckTimeScroll = 0;
let lastCheckTimeWindow = 0;
let deviceCheckResults;

if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

const generateUUID = () => {
  var d = new Date().getTime();
  var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

function deserializeNestedArray(obj) {
  if (obj && typeof obj === 'string') {
    obj = JSON.parse(obj);
  }
  return Object.values(obj);
}

function weightedLerp(current, last, amount) {
  for (let i = 0; i < amount; i++) {
    current = (current + last) / 2;
  }
  return +((current + last) / 2).toFixed(4);
}

function getDrawnCoords(item) {
  const box = getShapeBoundingBox(item.coords);
  const offsetPosition = item.getPositionOffset();

  let coords = item.coords.map(([x, y]) => rotate(box.center.x, box.center.y, x, y, -item.rotation * 360));

  coords = coords.map(([x, y]) => {
    return [Math.round(x + offsetPosition.x), Math.round(y + offsetPosition.y)];
  });

  return coords;
}

function getScaledDims(dims, scale) {
  const ratio = dims[0] / dims[1];
  const width = Math.sqrt(ratio * (300000 * (scale || 1)));
  return [width, width / ratio];
}

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function hasPingPongPlane(type) {
  return ['mouse', 'waterRipple'].includes(type);
}

function isDynamic(item) {
  const isTrackingMouse =
    ('trackMouse' in item && item.trackMouse > 0) ||
    ('axisTilt' in item && item.axisTilt > 0) ||
    ('trackMouseMove' in item && item.trackMouseMove > 0);
  let hasEvents = item.states && [...item.states.appear, ...item.states.scroll, ...item.states.hover].length;
  let isAnimated = item.layerType === 'effect' && (item.animating || hasPingPongPlane(item.type));
  return isTrackingMouse || isAnimated || hasEvents;
}

function unpackageHistory(history, id, initOptions) {
  const unpackagedHistory = [];
  history.forEach(item => {
    switch (item.layerType) {
      case 'text':
        unpackagedHistory.push(new TextBox(item, id, null, initOptions).unpackage());
        break;
      case 'image':
        unpackagedHistory.push(new Img(item, id, initOptions).unpackage());
        break;
      case 'fill':
        unpackagedHistory.push(new Effect(item, id, initOptions).unpackage());
        break;
      case 'shape':
        unpackagedHistory.push(new Shape(item, id, initOptions).unpackage());
        break;
      case 'effect':
        unpackagedHistory.push(new Effect(item, id, initOptions).unpackage());
        break;
    }
  });
  return unpackagedHistory;
}

function addLogo(id, element) {
  const logoContainer = document.createElement('a');
  logoContainer.href = 'https://unicorn.studio?utm_source=public-url';
  logoContainer.style =
    'position: absolute; display: flex; bottom: 30px; left: 0; width: 190px; margin: 0 auto; right: 0rem; padding: 10px; border-radius: 6px; background-color: rgba(255, 255, 255, 1); box-shadow: 0 3px 9px 0 rgba(0, 0, 0, .2); z-index: 99999999; box-sizing: border-box;';
  logoContainer.target = '_blank';
  const logoImage = document.createElement('img');
  logoImage.src = 'https://assets.unicorn.studio/media/made_in_us_small_web.svg';
  logoImage.alt = 'Made in unicorn.studio';
  logoImage.style = 'width: 170px; height: auto;';
  logoContainer.appendChild(logoImage);
  element.appendChild(logoContainer);
}

function addTextToScene(item, element) {
  const scaled = getScaledDims([element.offsetWidth || item.width, element.offsetHeight || item.height]);
  const divisor = scaled[0] / element.offsetWidth;

  const offset = item.getPositionOffset();
  const text = document.createElement('div');
  text.setAttribute('data-us-text', 'loading');
  text.setAttribute('data-us-project', item.local.sceneId);
  text.style.width = item.width / divisor + 'px';
  text.style.height = item.height / divisor + 'px';
  text.style.top = offset.y / divisor + element.offsetTop + 'px';
  text.style.left = offset.x / divisor + element.offsetLeft + 'px';
  text.style.fontSize = item.fontSize / divisor + 'px';
  text.style.lineHeight = item.lineHeight / divisor + 'px';
  text.style.letterSpacing = item.letterSpacing / divisor + 'px';
  text.style.fontFamily = item.fontFamily;
  text.style.fontWeight = item.fontWeight;
  text.style.textAlign = item.textAlign;
  text.style.wordBreak = 'break-word';
  text.style.transform = `rotateZ(${Math.round(item.rotation * 360)}deg)`;
  text.style.color = 'transparent';
  text.style.zIndex = 2;
  text.innerText = item.textContent;
  element.appendChild(text);
}

let rafId;

function cleanupScenes() {
  scenes.forEach((scene, index) => {
    if (!document.body.contains(scene.element)) {
      if(scene.curtain) {
        scene.curtain.dispose();
      }
      scenes.splice(index, 1);
    }
  });
}

function isCurtainsValid(curtain) {
  return (
    curtain &&
    curtain.renderer &&
    curtain.renderer.nextRender &&
    typeof curtain.renderer.nextRender.execute === 'function'
  );
}

function renderScenes() {
  cancelAnimationFrame(rafId);

  const animatedScenes = scenes.filter(scene => scene.getAnimatingEffects().length > 0);

  const animateCurtain = now => {
    let needsRaf = false;

    animatedScenes.forEach(scene => {
      if (scene.isInView && scene.initialized) {
        scene.rendering = true;

        if (now - (scene.lastTime || 0) >= scene.frameDuration) {
          scene.updateMouseTrail();
          scene.renderFrame();
          scene.lastTime = now;
        }

        needsRaf = true;
      } else {
        scene.rendering = false;
      }
    });

    if (!lastCheckTimeWindow || now - lastCheckTimeWindow > 32) {
      scrollTop = window.scrollY || window.pageYOffset;
      scrollDelta = scrollTop - lastScrollPos;
      lastScrollPos = scrollTop;
    }

    if (needsRaf) {
      updateMouseMove();
      rafId = requestAnimationFrame(animateCurtain);
    } else {
      cancelAnimationFrame(rafId);
    }
  };

  if (animatedScenes.length) {
    rafId = requestAnimationFrame(animateCurtain);
  }
}

function waitForItemLoad(item, prop) {
  return new Promise(resolve => {
    const checkInterval = setInterval(() => {
      if (item.local[prop]) {
        clearInterval(checkInterval);
        resolve();
      }
    }, 20);
  });
}

function interpolate(start, end, progress) {
  return start + (end - start) * progress;
}

function getEaseFunction(ease) {
  switch (ease) {
    case 'linear':
      return x => x;
    case 'easeInQuad':
      return x => x * x;
    case 'easeOutQuad':
      return x => 1 - (1 - x) * (1 - x);
    case 'easeInOutQuad':
      return x => (x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2);
    case 'easeInCubic':
      return x => x * x * x;
    case 'easeInOutCubic':
      return x => (x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2);
    case 'easeOutCubic':
      return x => 1 - Math.pow(1 - x, 3);
    case 'easeInQuart':
      return x => x * x * x * x;
    case 'easeOutQuart':
      return x => 1 - Math.pow(1 - x, 4);
    case 'easeInOutQuart':
      return x => (x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2);
    case 'easeInQuint':
      return x => x * x * x * x * x;
    case 'easeOutQuint':
      return x => 1 - Math.pow(1 - x, 5);
    case 'easeInOutQuint':
      return x => (x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2);
    case 'easeOutElastic':
      return x => {
        const c4 = (2 * Math.PI) / 3;
        return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
      };
    case 'easeInElastic':
      return x => {
        const c4 = (2 * Math.PI) / 3;
        return x === 0 ? 0 : x === 1 ? 1 : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4);
      };
    case 'easeInOutElastic':
      return x => {
        const c5 = (2 * Math.PI) / 4.5;
        return x === 0
          ? 0
          : x === 1
          ? 1
          : x < 0.5
          ? -(Math.pow(2, 20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2
          : (Math.pow(2, -20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1;
      };
    case 'easeInSine':
      return x => 1 - Math.cos((x * Math.PI) / 2);
    case 'easeOutSine':
      return x => Math.sin((x * Math.PI) / 2);
    case 'easeInOutSine':
      return x => -(Math.cos(Math.PI * x) - 1) / 2;
    case 'easeInCirc':
      return x => 1 - Math.sqrt(1 - Math.pow(x, 2));
    case 'easeOutCirc':
      return x => Math.sqrt(1 - Math.pow(x - 1, 2));
    case 'easeInOutCirc':
      return x =>
        x < 0.5 ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2 : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
    case 'easeInExpo':
      return x => (x === 0 ? 0 : Math.pow(2, 10 * x - 10));
    case 'easeOutExpo':
      return x => (x === 1 ? 1 : 1 - Math.pow(2, -10 * x));
    case 'easeInOutExpo':
      return x =>
        x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ? Math.pow(2, 20 * x - 10) / 2 : (2 - Math.pow(2, -20 * x + 10)) / 2;
    default:
      return x => x;
  }
}

function cloneVector(vector, invertY) {
  let result = vector;
  if (vector.type === 'Vec2') {
    result = new Vec2(vector._x, vector._y);
    if (invertY) {
      result.y = 1 - result.y;
    }
  } else if (vector.type === 'Vec3') {
    result = new Vec3(vector._x, vector._y, vector._z);
  } else {
    result = vector;
  }
  return result;
}

class StateEffectAppear {
  constructor({ prop, value, transition, uniformData }) {
    this.prop = prop;
    this.transition = transition;
    this.complete = false;
    this.progress = 0;
    this.initialStateSet = false;
    this.uniformData = uniformData;
    this.value = cloneVector(value, this.prop === 'pos');
  }

  initializeState(uniform, endVal) {
    const isVector = typeof endVal === 'object';
    if (endVal !== undefined) {
      if (isVector) {
        this.endVal = cloneVector(endVal, this.prop === 'pos');
        this.startVal = cloneVector(this.value, this.prop === 'pos');
      } else {
        this.endVal = endVal;
      }
    }
    if (uniform) {
      const isVector = typeof this.value === 'object';
      if (isVector) {
        let value;
        if (this.value.type === 'Vec2') {
          value = new Vec2(this.value._x, this.value._y);
        } else if (this.value.type === 'Vec3') {
          value = new Vec3(this.value._x, this.value._y, this.value._z);
        }
        uniform.value = value;
      } else {
        uniform.value = this.value;
      }
      this.initialStateSet = true;
    }
  }

  updateEffect(plane) {
    const isVector = typeof this.value === 'object';
    if (this.complete || !plane.userData.createdAt || !this.initialStateSet) {
      return false;
    }
    const currentTime = performance.now();
    const uniform = plane.uniforms[this.prop];
    const easeFunction = getEaseFunction(this.transition.ease);
    const effectStart = plane.userData.createdAt + this.transition.delay;
    const progress = Math.max(0, Math.min(1, (currentTime - effectStart) / this.transition.duration));

    let startVal = this.value;

    if (progress > 0 && progress <= 1) {
      let easing = easeFunction(progress);
      if (isVector) {
        uniform.value.x = interpolate(startVal.x, this.endVal.x, easing);

        if (this.prop === 'pos') {
          uniform.value.y = interpolate(1 - startVal.y, this.endVal.y, easing);
        } else {
          uniform.value.y = interpolate(startVal.y, this.endVal.y, easing);
        }
        if (startVal.type === 'Vec3') {
          uniform.value.z = interpolate(startVal.z, this.endVal.z, easing);
        }
      } else {
        uniform.value = interpolate(startVal, this.endVal, easing);
      }
    } else {
      if (isVector) {
        uniform.value = cloneVector(this.value, this.prop === 'pos');
      } else {
        uniform.value = this.value;
      }
    }

    if (progress >= 1) {
      this.complete = true;
      this.progress = 0;
    }

    this.lastTick = currentTime;

    return true;
  }

  resetState() {
    this.progress = 0;
    this.complete = false;
    this.initialStateSet = false;
  }
}

class StateEffectScroll {
  type = 'scroll';
  constructor({
    prop,
    value,
    range,
    offset,
    momentum,
    uniformData,
    mode = 'scrollIntoView',
    delta = 0.01,
    absScrollValue = true,
  }) {
    this.prop = prop;
    this.progress = 0;
    this.momentum = momentum;
    this.range = range;
    this.offset = offset;
    this.mode = mode;
    this.delta = delta;
    this.absScrollValue = absScrollValue;
    this.uniformData = uniformData;

    this.value = cloneVector(value, this.prop === 'pos');
  }

  updateEffect(plane, startVal, { top, height, isFixed }) {
    if (startVal === undefined) {
      return false;
    } else if (!this.startVal) {
      if (typeof this.value === 'object') {
        this.startVal = cloneVector(startVal, this.prop === 'pos');
      } else {
        this.startVal = startVal;
      }
    }

    const isVector = typeof this.value === 'object';

    const uniform = plane.uniforms[this.prop];

    if (isFixed) {
      top -= scrollTop;
    }

    if (this.mode === 'scrollIntoView') {
      const startScroll = top + scrollTop - viewportHeight * this.offset;
      const endScroll = startScroll + (viewportHeight + height) * this.range;

      let progress = (scrollTop - startScroll) / (endScroll - startScroll);

      let endVal = this.value;

      if (!uniform) return false;

      let easing = Math.max(0, Math.min(1, progress));
      if (this.lastTick !== undefined) {
        easing = weightedLerp(easing, this.lastTick, this.momentum * 2);
      }

      if (this.lastTick !== undefined && Math.abs(this.lastTick - easing) < 0.001) {
        return false;
      }

      if (isVector) {
        uniform.value.x = interpolate(this.startVal.x, endVal.x, easing);

        if (this.prop === 'pos') {
          uniform.value.y = interpolate(1 - this.startVal.y, endVal.y, easing);
        } else {
          uniform.value.y = interpolate(this.startVal.y, endVal.y, easing);
        }
        if (this.startVal.type === 'Vec3') {
          uniform.value.z = interpolate(this.startVal.z, endVal.z, easing);
        }
      } else {
        uniform.value = interpolate(this.startVal, endVal, easing);
      }

      this.lastTick = easing;

      return true;
    } else if (this.mode === 'whileScrolling') {
      let easing = scrollDelta * this.delta;
      let endVal = this.value;

      if (this.absScrollValue) {
        easing = Math.abs(easing);
      }

      if (this.lastTick !== undefined) {
        easing = weightedLerp(easing, this.lastTick, this.momentum * 2);

        if (Math.abs(easing) < 0.001) {
          return false;
        }
      }

      if (isVector) {
        uniform.value.x = interpolate(startVal.x, endVal.x, easing);
        uniform.value.y = interpolate(startVal.y, endVal.y, easing);

        if (startVal.type === 'Vec3') {
          uniform.value.z = interpolate(startVal.z, endVal.z, easing);
        }
      } else {
        uniform.value = interpolate(startVal, endVal, easing);
      }

      this.lastScrollPos = scrollTop;
      this.lastTick = easing;
    }

    return true;
  }

  resetState() {
    this.lastTick = undefined;
  }
}

class StateEffectHover {
  type = 'hover';
  constructor({ prop, value, transition, uniformData }) {
    this.prop = prop;
    this.transition = transition;
    this.progress = 0;
    this.rawProgress = 0;
    this.lastProgress = null;
    this.value = cloneVector(value, this.prop === 'pos');
    this.uniformData = uniformData;
  }

  updateEffect(plane, startVal, enterTime) {
    if (startVal === undefined) {
      return false;
    }
    const isVector = typeof this.value === 'object';

    let currentTime = performance.now();
    let effectStart;
    let reverse = false;

    if (enterTime === null) {
      reverse = true;
      effectStart = this.lastTick || currentTime;
      this.lastProgress = this.rawProgress;
    } else {
      effectStart = enterTime + this.transition.delay;
    }

    const uniform = plane.uniforms[this.prop];
    const rawProgress = Math.max(0, Math.min(1, (currentTime - effectStart) / this.transition.duration));

    let progress = reverse ? this.rawProgress - rawProgress : this.lastProgress + rawProgress;
    this.rawProgress = Math.max(0, Math.min(1, progress));
    this.progress = getEaseFunction(this.transition.ease)(this.rawProgress);

    const updateUniform = () => {
      if (isVector) {
        uniform.value.x = interpolate(startVal.x, this.value.x, this.progress);

        if (this.prop === 'pos') {
          uniform.value.y = interpolate(startVal.y, 1 - this.value.y, this.progress);
        } else {
          uniform.value.y = interpolate(startVal.y, this.value.y, this.progress);
        }
        if (this.value.type === 'Vec3') {
          uniform.value.z = interpolate(startVal.z, this.value.z, this.progress);
        }
      } else {
        uniform.value = interpolate(startVal, this.value, this.progress);
      }
    };

    if (!uniform) return false;
    if (!enterTime && this.progress === 0) {
      if (this.lastProgress !== this.progress) {
        updateUniform();
      }
      return false;
    }
    if (!enterTime && this.transition.forwardsOnly) {
      this.progress = 0;
      this.rawProgress = 0;
    }

    updateUniform();

    this.lastTick = currentTime;
    this.lastEnterTime = enterTime;

    return this.progress > 0 && this.progress < 1;
  }

  resetState() {
    this.progress = 0;
  }
}

function unpackBreakpoints(bps) {
  bps.forEach(breakpoint => {
    for (let prop in breakpoint.props) {
      if (breakpoint.props[prop]?.type === 'Vec2') {
        breakpoint.props[prop] = new Vec2(breakpoint.props[prop]._x, breakpoint.props[prop]._y);
      } else if (breakpoint.props[prop]?.type === 'Vec3') {
        breakpoint.props[prop] = new Vec3(
          breakpoint.props[prop]._x,
          breakpoint.props[prop]._y,
          breakpoint.props[prop]._z
        );
      } else if (typeof breakpoint.props[prop] === 'object') {
        breakpoint.props[prop] = deserializeNestedArray(breakpoint.props[prop]);
      }
    }
  });
  return bps;
}

class Layer {
  local = { id: '', projectId: '' };
  constructor(args, sceneId) {
    this.visible = args.visible !== undefined ? args.visible : !args.hidden || true;
    this.locked = args.locked || false;
    this.aspectRatio = args.aspectRatio || 1;
    this.breakpoints = unpackBreakpoints(args.breakpoints || []);
    this.local.sceneId = sceneId;
    this.local.id = generateUUID();
  }

  state() {
    return scenes.find(n => n.id === this.local.sceneId) || this.initOptions;
  }

  getIndex() {
    return this.state()
      .layers.map(n => n.local.id)
      .indexOf(this.local.id);
  }

  getPlane() {
    return this.state()?.curtain?.planes?.find(n => n.type !== 'PingPongPlane' && n.userData.id === this.local.id);
  }

  getPlanes() {
    return this.state()?.curtain?.planes?.filter(n => n.type !== 'PingPongPlane' && n.userData.id === this.local.id) || [];
  }

  getMaskedItem() {
    if (this.mask) {
      return this.state().layers.filter(n => n.visible && !n.parentLayer)[this.getIndex() - 1];
    } else {
      return false;
    }
  }

  getChildEffectItems() {
    if (this.effects && this.effects.length) {
      const state = this.state();
      if (!state || !state.layers) {
        return [];
      }
      
      const childEffects = state.layers.filter(n => this.effects.includes(n.parentLayer));
      const orderedChildEffects = this.effects
        .map(effectId => childEffects.find(n => n.parentLayer === effectId))
        .filter(effect => effect !== undefined);

      return orderedChildEffects;
    } else {
      return [];
    }
  }

  setBreakpointValues() {
    const ww = viewportWidth;
    const sortedbps = this.breakpoints.sort((a, b) => b.min - a.min);
    const props = {};

    if (sortedbps.length === 1 && sortedbps[0].name === 'Desktop') {
      return;
    }

    if (sortedbps.length >= 1 && !sortedbps.find(n => n.name === 'Desktop')) {
      throw new Error('Malfored breakpoint data, missing Desktop');
    }

    for (let i = sortedbps.length - 1; i >= 0; i--) {
      const bp = sortedbps[i];

      if (bp.max === null || ww <= bp.max) {
        for (let prop in bp.props) {
          if (!props.hasOwnProperty(prop)) {
            props[prop] = bp.props[prop];
          }
        }
      }
    }

    const base = this.breakpoints.find(n => n.name === 'Desktop');
    if (base) {
      for (let prop in base.props) {
        if (!props.hasOwnProperty(prop)) {
          props[prop] = base.props[prop];
        }
      }
    }

    for (let prop in props) {
      if (this.hasOwnProperty(prop)) {
        let value = props[prop];
        if (value.type) {
          this[prop].x = value._x;
          this[prop].y = value._y;
          if (value._z !== undefined) {
            this[prop].z = value._z;
          }
        } else {
          this[prop] = value;
        }
      }
    }

    this.local.bpProps = props;
  }
}

class Element extends Layer {
  isElement = true;
  constructor(args, id, initOptions) {
    super(args, id);
    this.initOptions = initOptions;
    this.opacity = args.opacity || 1;
    this.displace = args.displace || 0;
    this.trackMouse = args.trackMouse || 0;
    this.axisTilt = args.axisTilt || 0;
    this.bgDisplace = args.bgDisplace || 0;
    this.dispersion = args.dispersion || 0;
    this.mouseMomentum = args.mouseMomentum || 0;
    this.blendMode = args.blendMode || 'NORMAL';
    this.compiledFragmentShaders = args.compiledFragmentShaders || [];
    this.compiledVertexShaders = args.compiledVertexShaders || [];
  }

  createLocalCanvas() {
    const scene = this.state();
    const canvas = document.createElement('canvas');

    const screenScale = scene.dpi * scene.scale;

    canvas.width = scene.element.offsetWidth * screenScale;
    canvas.height = scene.element.offsetHeight * screenScale;

    const scaled = getScaledDims([scene.element.offsetWidth, scene.element.offsetHeight]);
    const divisor = scaled[0] / scene.element.offsetWidth;

    const ctx = canvas.getContext('2d');
    ctx.scale(screenScale / divisor, screenScale / divisor);

    this.local.canvas = canvas;
    this.local.ctx = ctx;
  }

  resize() {
    const scene = this.state();
    if (this.local.canvas) {
      const screenScale = +scene.dpi * scene.scale;
      const scaled = getScaledDims([scene.element.offsetWidth, scene.element.offsetHeight]);
      const divisor = scaled[0] / scene.element.offsetWidth;

      this.local.canvas.width = scene.canvasWidth;
      this.local.canvas.height = scene.canvasHeight;
      this.local.ctx.scale(screenScale / divisor, screenScale / divisor);
    }
  }

  getPositionOffset() {
    const scene = this.state();
    const currentAspectRatio = scene.canvasWidth / scene.canvasHeight;
    const adjustedRatio = this.aspectRatio / currentAspectRatio;
    const originalWidth = scene.canvasWidth * Math.sqrt(adjustedRatio);
    const originalHeight = scene.canvasHeight / Math.sqrt(adjustedRatio);

    const scaled = getScaledDims([scene.element.offsetWidth, scene.element.offsetHeight]);
    const divisor = scaled[0] / scene.element.offsetWidth;

    let offsetX = (scene.canvasWidth * divisor - originalWidth * divisor) / (scene.dpi * 2);
    let offsetY = (scene.canvasHeight * divisor - originalHeight * divisor) / (scene.dpi * 2);

    if (this.layerType === 'image') {
      offsetX += (originalWidth * divisor) / (scene.dpi * 2);
      offsetY += (originalHeight * divisor) / (scene.dpi * 2);
    }

    let newX = this.translateX + offsetX;
    let newY = this.translateY + offsetY;

    return { x: newX, y: newY, offX: offsetX, offY: offsetY };
  }

  dispose() {
    if (this.local.canvas) {
      this.local.canvas.width = 1;
      this.local.canvas.height = 1;
      this.local.canvas = null;
    }
    if (this.local.ctx) {
      this.local.ctx = null;
    }
  }
}

class Shape extends Element {
  layerType = 'shape';
  isElement = true;

  constructor(args, id, initOptions) {
    super(args, id);
    this.initOptions = initOptions;
    let props = this.default(args || {});
    for (let prop in props) {
      this[prop] = props[prop];
    }

    if (this.breakpoints.length) {
      this.setBreakpointValues();
    }

    if (Object.keys(args).length) {
      this.createLocalCanvas();
    }
  }

  default(args) {
    return {
      blendMode: args.blendMode || 'NORMAL',
      borderRadius: args.borderRadius || 0,
      coords: args.coords || [],
      displace: args.displace || 0,
      dispersion: args.dispersion || 0,
      bgDisplace: args.bgDisplace || 0,
      effects: args.effects || [],
      fill: args.fill || ['#777777'],
      fitToCanvas: args.fitToCanvas || false,
      gradientAngle: args.gradientAngle || args.gradAngle || 0,
      gradientType: args.gradientType || args.gradType || 'linear',
      mask: args.mask || 0,
      numSides: args.numSides || 3,
      opacity: args.opacity || 1,
      rotation: args.rotation || 0,
      translateX: args.translateX || 0,
      translateY: args.translateY || 0,
      type: args.type || 'rectangle',
      stroke: args.stroke || ['#000000'],
      strokeWidth: args.strokeWidth || 0,
      width: args.width || null,
      height: args.height || null,
    };
  }

  unpackage() {
    this.fill = deserializeNestedArray(this.fill);
    this.stroke = deserializeNestedArray(this.stroke);
    this.coords = deserializeNestedArray(this.coords);

    if (this.coords.length && (!this.width || !this.height)) {
      this.width = [this.coords[0][0], this.coords[1][0]];
      this.height = [this.coords[1][1], this.coords[2][1]];
    }

    this.effects = deserializeNestedArray(this.effects);

    this.render();
    return this;
  }

  render() {
    let coords;
    if (this.fitToCanvas) {
      const state = this.state();
      const scaled = getScaledDims([state.element.offsetWidth, state.element.offsetHeight]);
      const divisor = scaled[0] / state.element.offsetWidth;
      const screenScale = state.dpi * state.scale;
      let canvasWidth = (state.canvasWidth * divisor) / screenScale;
      let canvasHeight = (state.canvasHeight * divisor) / screenScale;

      this.coords = [
        [0, 0],
        [canvasWidth, 0],
        [canvasWidth, canvasHeight],
        [0, canvasHeight],
      ];

      coords = this.coords;
    } else {
      this.coords = [
        [this.width[0], this.height[0]],
        [this.width[1], this.height[0]],
        [this.width[1], this.height[1]],
        [this.width[0], this.height[1]],
      ];

      coords = getDrawnCoords(this);
    }

    this.local.ctx.beginPath();

    if (this.type === 'rectangle') {
      const box = getShapeBoundingBox(this.coords);
      let borderRadius = (this.borderRadius * Math.min(box.width, box.height)) / 2;

      const rotate = (x, y, angle) => {
        const cos = Math.cos(angle);
        const sin = Math.sin(angle);
        return [x * cos - y * sin, x * sin + y * cos];
      };

      const rotationAngle = this.rotation * 2 * Math.PI;

      if (coords.length) {
        this.local.ctx.beginPath();

        let directions = [-1, 1, -1, 1];

        if (!this.fitToCanvas) {
          let invertedX = this.coords[0][0] < this.coords[1][0];
          let invertedY = this.coords[0][1] > this.coords[2][1];

          if (invertedX) {
            directions = [-1, -1, -1, -1];
          }

          if (invertedY) {
            directions = [1, 1, 1, 1];
          }

          if (invertedX && invertedY) {
            directions = [1, -1, 1, -1];
          }
        }

        for (let i = 0; i < coords.length; i++) {
          const [x1, y1] = coords[i];
          const [x2, y2] = coords[(i + 1) % coords.length];
          const angle = ((i + 1) * Math.PI) / 2 + rotationAngle;
          const [dx, dy] = rotate(borderRadius, 0, angle);

          let direction = directions[i];

          this.local.ctx.lineTo(x1 - dx * direction, y1 - dy * direction);
          this.local.ctx.arcTo(x1, y1, x2, y2, borderRadius);
        }

        this.local.ctx.closePath();
        this.local.ctx.stroke();
      }
    } else if (this.type === 'circle') {
      let box = getShapeBoundingBox(coords);
      const boxLocal = getShapeBoundingBox(this.coords);
      this.local.ctx.ellipse(
        box.center.x,
        box.center.y,
        boxLocal.width / 2,
        boxLocal.height / 2,
        this.rotation * Math.PI * 2,
        0,
        2 * Math.PI
      );
    } else if (this.type === 'polygon') {
      const sides = this.numSides;
      if (coords.length >= 2) {
        const bbox = getShapeBoundingBox(coords);
        const bboxD = getShapeBoundingBox(this.coords);

        const flipped = this.coords[0][1] > this.coords[2][1];

        const centerY = bbox.center.y;
        const centerX = bbox.center.x;

        const rotate = (x, y, angle, cX, cY) => {
          const cos = Math.cos(angle);
          const sin = Math.sin(angle);
          x -= cX;
          y -= cY;
          const xNew = x * cos - y * sin;
          const yNew = x * sin + y * cos;
          x = xNew + cX;
          y = yNew + cY;
          return [x, y];
        };

        const rotationAngle = (this.rotation + (flipped ? 0.5 : 0)) * 2 * Math.PI;

        const width = (bboxD.width / Math.sqrt(3)) * 0.86;
        const height = (bboxD.height / Math.sqrt(3)) * 0.86;

        this.local.ctx.beginPath();
        for (let i = 0; i < sides; i++) {
          let baseAngle = -Math.PI / 2;
          const angle = baseAngle + (2 * Math.PI * i) / sides;
          let x = centerX + width * Math.cos(angle);
          let y = centerY + height * Math.sin(angle);
          [x, y] = rotate(x, y, rotationAngle, centerX, centerY);

          if (i === 0) {
            this.local.ctx.moveTo(x, y);
          } else {
            this.local.ctx.lineTo(x, y);
          }
        }
        this.local.ctx.closePath();
      }
    }

    this.local.ctx.fillStyle = getShapeFill(this.local.ctx, this, coords);
    this.local.ctx.clearRect(0, 0, this.state().canvasWidth, this.state().canvasHeight);
    this.local.ctx.fill();

    if (this.strokeWidth) {
      this.local.ctx.strokeStyle = this.stroke[0];
      this.local.ctx.lineWidth = this.strokeWidth;
      this.local.ctx.stroke();
    }
  }
}

class Effect extends Layer {
  layerType = 'effect';

  constructor(args, id, initOptions) {
    super(args, id);
    this.initOptions = initOptions;
    this.type = args.type || 'sine';
    this.speed = args.speed || 0.5;
    this.data = args.data || {};
    this.parentLayer = args.parentLayer || false;
    this.animating = args.animating || false;
    this.isMask = args.isMask || 0;
    this.texture = args.texture || null;
    this.mouseMomentum = args.mouseMomentum || 0;
    this.compiledFragmentShaders = args.compiledFragmentShaders || [];
    this.compiledVertexShaders = args.compiledVertexShaders || [];
    this.states = {
      appear: args.states && args.states.appear ? args.states.appear.map(n => new StateEffectAppear(n)) : [],
      scroll: args.states && args.states.scroll ? args.states.scroll.map(n => new StateEffectScroll(n)) : [],
      hover: args.states && args.states.hover ? args.states.hover.map(n => new StateEffectHover(n)) : [],
    };

    for (let prop in args) {
      if (!this[prop]) {
        this[prop] = args[prop];
      }
    }

    if (this.breakpoints.length) {
      this.setBreakpointValues();
    }
  }

  unpackage() {
    for (let prop in this) {
      if (this[prop] && this[prop].type) {
        if (this[prop].type === 'Vec2') {
          this[prop] = new Vec2(this[prop]._x, this[prop]._y);
        } else if (this[prop].type === 'Vec3') {
          this[prop] = new Vec3(this[prop]._x, this[prop]._y, this[prop]._z);
        }
      }
    }

    return this;
  }

  getParent() {
    return this.state()
      .layers.filter(n => n.effects && n.effects.length)
      .find(n => n.effects.includes(this.parentLayer));
  }
}

class Img extends Element {
  layerType = 'image';
  isElement = true;

  constructor(args, id, initOptions) {
    super(args, id);
    this.initOptions = initOptions;
    let props = this.default(args || {});
    for (let prop in props) {
      this[prop] = props[prop];
    }

    if (this.breakpoints.length) {
      this.setBreakpointValues();
    }

    if (Object.keys(args).length) {
      this.createLocalCanvas();
      this.loadImage();
    }
  }

  default(args) {
    return {
      bgDisplace: args.bgDisplace || 0,
      dispersion: args.dispersion || 0,
      effects: args.effects || [],
      size: args.size || 0.25,
      rotation: args.rotation || args.angle || 0,
      height: args.height || 50,
      fitToCanvas: args.fitToCanvas || false,
      displace: args.displace || 0,
      repeat: args.repeat || 0,
      mask: args.mask || 0,
      rotation: args.rotation || 0,
      scaleX: args.scaleX || 1,
      scaleY: args.scaleY || 1,
      src: args.src || '',
      speed: args.speed || 0.5,
      translateX: args.translateX || 0,
      translateY: args.translateY || 0,
      width: args.width || 50,
    };
  }

  unpackage() {
    this.effects = deserializeNestedArray(this.effects);
    return this;
  }

  loadImage() {
    const img = new Image();

    img.crossOrigin = 'Anonymous';

    img.addEventListener(
      'load',
      () => {
        const state = this.state();
        this.local.img = img;
        this.width = img.width;
        this.height = img.height;
        this.render = this.renderImage;
        this.render();

        this.local.loaded = true;
        this.local.fullyLoaded = true;

        if (this.getPlane()) {
          this.getPlane()
            .textures.filter(n => n.sourceType === 'canvas')
            .forEach(tex => {
              tex.needUpdate();
              tex.shouldUpdate = false;
            });
        } else if (!this.rendering) {
          if (state?.renderFrame) {
            state.renderFrame();
          }
        }
      },
      false
    );

    img.src = this.src;
  }

  getRelativeScale() {
    return Math.min(1080 / this.width, 1080 / this.height);
  }

  renderImage() {
    if(!this.local.ctx) return;
    const offsetPosition = this.getPositionOffset();
    const state = this.state();
    let x = offsetPosition.x;
    let y = offsetPosition.y;
    const angle = this.rotation * 360 * (Math.PI / 180);
    const relativeScale = this.getRelativeScale();

    let width = this.width * relativeScale * this.scaleX;
    let height = this.height * relativeScale * this.scaleY;

    const screenScale = state.dpi * state.scale;
    this.local.ctx.clearRect(0, 0, state.canvasWidth, state.canvasHeight);

    if (this.fitToCanvas) {
      const scaled = getScaledDims([state.element.offsetWidth, state.element.offsetHeight]);
      const divisor = scaled[0] / state.element.offsetWidth;

      let aspectRatio = this.width / this.height;
      let canvasWidth = (state.canvasWidth * divisor) / screenScale;
      let canvasHeight = (state.canvasHeight * divisor) / screenScale;

      let canvasAspectRatio = canvasWidth / canvasHeight;

      if (canvasAspectRatio < aspectRatio) {
        height = canvasHeight;
        width = canvasHeight * aspectRatio;
      } else {
        width = canvasWidth;
        height = canvasWidth / aspectRatio;
      }

      x = canvasWidth / 2;
      y = canvasHeight / 2;

      this.local.ctx.save();
      this.local.ctx.translate(x, y);
      this.local.ctx.drawImage(this.local.img, -width / 2, -height / 2, width, height);
      this.local.ctx.restore();
    } else {
      this.local.ctx.save();
      this.local.ctx.translate(x, y);
      this.local.ctx.rotate(angle);
      this.local.ctx.scale(this.size, this.size);
      this.local.ctx.drawImage(this.local.img, -width / 2, -height / 2, width, height);
      this.local.ctx.restore();
    }
  }

  render() {
    // loading...
  }
}

class TextBox extends Element {
  layerType = 'text';
  isElement = true;
  justCreated = false;

  constructor(args, id, local, initOptions) {
    super(args, id);
    this.initOptions = initOptions;
    let props = this.default(args || {});
    for (let prop in props) {
      this[prop] = props[prop];
    }

    if (this.breakpoints.length) {
      this.setBreakpointValues();
    }

    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    addTextToScene(this, initOptions.element);

    if (Object.keys(args).length) {
      this.createLocalCanvas();
    }

    this.loadFont();
  }

  default(args) {
    return {
      bgDisplace: args.bgDisplace || 0,
      dispersion: args.dispersion || 0,
      effects: args.effects || [],
      fill: args.fill || ['#ffffff'],
      highlight: args.highlight || ['transparent'],
      fontSize: args.fontSize || 24,
      fontCSS: args.fontCSS || null,
      lineHeight: args.lineHeight || 25,
      letterSpacing: args.letterSpacing || 0,
      mask: args.mask || 0,
      fontFamily: args.fontFamily || 'arial',
      fontStyle: args.fontStyle || 'normal',
      fontWeight: args.fontWeight || 'normal',
      textAlign: args.textAlign || 'left',
      textContent: args.textContent || '',
      gradientAngle: args.gradientAngle || args.gradAngle || 0,
      gradientType: args.gradientType || args.gradType || 'linear',
      coords: args.coords || [],
      rotation: args.rotation || 0,
      translateX: args.translateX || 0,
      translateY: args.translateY || 0,
      width: args.width || 200,
      height: args.height || 50,
    };
  }

  unpackage() {
    this.fill = deserializeNestedArray(this.fill);
    this.highlight = deserializeNestedArray(this.highlight);
    this.coords = deserializeNestedArray(this.coords);
    this.effects = deserializeNestedArray(this.effects);

    return this;
  }

  loadFont() {
    const fontStyle = this.fontStyle.includes('italic') ? 'italic' : 'normal';

    // Normalize weight properly - allow for 'normal' or numeric
    const normalizeWeight = weight => {
      if (weight === 'normal' || weight === '400' || weight === 400) return 'normal';
      return weight.toString();
    };

    const fontWeight = isNaN(parseInt(this.fontWeight)) ? 'normal' : normalizeWeight(this.fontWeight);

    const isLoaded = Array.from(document.fonts).some(
      font =>
        font.family === this.fontFamily &&
        font.style === fontStyle &&
        normalizeWeight(font.weight) === fontWeight &&
        font.status === 'loaded'
    );

    if (isLoaded) {
      this.handleFontLoaded();
      return;
    }

    let src = this.fontCSS.src.split(' ').join('%20');
    const font = new FontFace(this.fontFamily, `url(${src})`, {
      style: fontStyle,
      weight: fontWeight,
    });

    document.fonts.add(font);
    font
      .load()
      .then(() => {
        this.handleFontLoaded();
      })
      .catch(err => {
        console.error('Font loading error:', err);
      });
  }

  handleFontLoaded() {
    this.local.loaded = true;
    this.render();

    const state = this.state();

    if (state.id && this.getPlane()) {
      this.getPlane()
        .textures.filter(n => n.sourceType === 'canvas')
        .forEach(tex => {
          tex.needUpdate();
          tex.shouldUpdate = false;
          if (!this.rendering) {
            if(state?.renderFrame) {
              state.renderFrame();
            }
          }
        });
    }
  }

  render() {
    if (!this.local.loaded) {
      return;
    }

    const position = this.getPositionOffset();
    let x = position.x;
    let y = position.y;

    let index = 0;
    let width = this.width;
    let height = this.height;
    let size = this.fontSize > 0 ? this.fontSize : 0;
    let lineHeight = this.lineHeight > 0 ? this.lineHeight : 0;
    let style = this.fontStyle.includes('italic') ? 'italic' : 'normal';
    let weight = '400';

    this.local.textBoxPos = { x: x, y: y };

    this.local.ctx.clearRect(0, 0, this.state().canvasWidth, this.state().canvasHeight);

    this.local.ctx.font = `${style} ${weight} ${size}px/${lineHeight}px ${this.fontFamily}, -apple-system, BlinkMacSystemFont, Helvetica, Arial`;

    if (!this.isSafari) {
      this.local.ctx.textAlign = this.textAlign;
      this.local.ctx.letterSpacing = this.letterSpacing + 'px';
    }

    const minWidth = this.local.ctx.measureText('m').width;
    width = Math.max(width, minWidth);

    this.local.ctx.save();
    this.local.ctx.translate(x + width / 2, y + height / 2);
    this.local.ctx.rotate((this.rotation * 360 * Math.PI) / 180);
    this.local.ctx.translate(-(x + width / 2), -(y + height / 2));

    if (this.textAlign === 'center') {
      x += width / 2;
    }
    if (this.textAlign === 'right') {
      x += width;
    }

    this.local.ctx.fillStyle = getShapeFill(this.local.ctx, this, this.coords);

    const drawTextWithSpacing = (ctx, text, startX, startY, spacing, align, containerWidth) => {
      let textTotalWidth = text
        .split('')
        .reduce((acc, char, i) => acc + ctx.measureText(char).width + (i < text.length - 1 ? spacing : 0), 0);

      let currentX;
      if (align === 'center') {
        currentX = startX + (containerWidth - textTotalWidth) / 2 - containerWidth / 2;
      } else if (align === 'right') {
        currentX = startX;
      } else {
        currentX = startX;
      }

      if (align === 'right') {
        for (let i = text.length - 1; i >= 0; i--) {
          const char = text[i];
          currentX -= ctx.measureText(char).width;
          ctx.fillText(char, currentX, startY);
          if (i > 0) currentX -= spacing;
        }
      } else {
        for (let i = 0; i < text.length; i++) {
          ctx.fillText(text[i], currentX, startY);
          currentX += ctx.measureText(text[i]).width + spacing;
        }
      }
    };

    const render = (content, index) => {
      let textY = y + lineHeight * index + lineHeight / 2 + size / 3;

      if (this.isSafari) {
        drawTextWithSpacing(this.local.ctx, content, x, textY, this.letterSpacing, this.textAlign, width);
      } else {
        this.local.ctx.fillText(content, x, textY);
      }
    };

    const lines = this.textContent ? this.textContent.split('\n') : [''];
    let line_count = lines.length;

    const measureTextWithSpacing = (ctx, text, spacing) => {
      let totalWidth = text.split('').reduce((acc, char, index) => {
        acc += ctx.measureText(char).width;
        if (index < text.length - 1) acc += spacing;
        return acc;
      }, 0);
      return totalWidth;
    };

    for (let i = 0; i < line_count; i++) {
      let line = '';
      let words = lines[i].split(/(\s|\n)/);

      for (let wordidx = 0; wordidx < words.length; wordidx++) {
        const word = words[wordidx];
        const potentialLine = line + word;

        let potentialLineWidth =
          this.isSafari && this.letterSpacing
            ? measureTextWithSpacing(this.local.ctx, potentialLine, this.letterSpacing)
            : this.local.ctx.measureText(potentialLine).width;

        if (potentialLineWidth > width || word === '\n') {
          if (line !== '') {
            lines[i] = line.trim();

            if (wordidx !== words.length - 1) {
              lines.splice(i + 1, 0, words.slice(wordidx).join(''));
              line_count++;
            } else if (word !== '\n') {
              lines.push(word);
            }
          } else {
            let remainingWord = word;
            let currentLine = i;

            while (remainingWord.length > 0) {
              let wordFragment = '';
              for (let c = 0; c < remainingWord.length; c++) {
                if (this.local.ctx.measureText(wordFragment + remainingWord[c]).width <= width || c == 0) {
                  wordFragment += remainingWord[c];
                } else {
                  break;
                }
              }

              remainingWord = remainingWord.slice(wordFragment.length);
              lines[currentLine] = wordFragment.trim();

              if (remainingWord.length > 0) {
                lines.splice(currentLine + 1, 0, remainingWord);
                currentLine++;
                line_count++;
              }
            }

            if (words.slice(wordidx + 1).length > 0) {
              lines[currentLine] += words.slice(wordidx + 1).join('');
            }
          }
          break;
        } else {
          line = potentialLine;
        }

        if (wordidx === words.length - 1) {
          lines[i] = line.trim();
        }
      }
    }

    lines.forEach((ln, i) => {
      render(ln, index);
      if (i < lines.length - 1) {
        index++;
      }
    });

    this.local.ctx.translate(-(x + width / 2), -(y + height / 2));
    this.local.ctx.restore();

    this.height = this.lineHeight * index + this.lineHeight;
  }
}

function handleVisibilityChange() {
  if (document[hidden]) {
    cancelAnimationFrame(rafId);
  } else {
    renderScenes();
  }
}

function handleWindowResize() {
  scenes.forEach(scene => {
    scene.refresh();
  });
  viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  viewportWidth = window.innerWidth || document.documentElement.clientWidth;
}

function isElementNearViewport(el, bbox, proximityThreshold = 50) {
  const rect = bbox || el.getBoundingClientRect();

  const isCloseVertically =
    (rect.top >= -proximityThreshold && rect.top <= viewportHeight + proximityThreshold) ||
    (rect.bottom >= -proximityThreshold && rect.bottom <= viewportHeight + proximityThreshold) ||
    (rect.top <= 0 && rect.bottom >= viewportHeight);

  const isCloseHorizontally =
    (rect.left >= -proximityThreshold && rect.left <= viewportWidth + proximityThreshold) ||
    (rect.right >= -proximityThreshold && rect.right <= viewportWidth + proximityThreshold) ||
    (rect.left <= 0 && rect.right >= viewportWidth);

  return isCloseVertically && isCloseHorizontally;
}

function updateViewportMeasurements() {
  viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  scenes
    .filter(n => n.getAnimatingEffects().length)
    .forEach(scene => {
      let bbox = scene.element.getBoundingClientRect();

      if (scene.lastBbox) {
        const deltaTop = Math.abs(bbox.top - scene.lastBbox.top);
        if (deltaTop === 0 && scrollDelta > 0) {
          scene.fixedCounter = (scene.fixedCounter || 0) + 1;
          if (scene.fixedCounter > 3) {
            scene.isFixed = true;
          }
        } else {
          scene.fixedCounter = 0;
        }
      }

      scene.lastBbox = bbox;
    });

  lastCheckTimeScroll = performance.now();
}

function handleWindowScroll(e) {
  const animatedScenes = scenes.filter(n => n.getAnimatingEffects().length);
  const animatingScenes = scenes.filter(n => n.rendering);

  if (animatedScenes.length && !animatingScenes.length) {
    renderScenes();
  }

  const now = performance.now();

  if (!lastCheckTimeScroll || now - lastCheckTimeScroll > 32) {
    updateViewportMeasurements();
  }

  animatedScenes.forEach(scene => {
    const isInViewport = isElementNearViewport(scene.element, scene.lastBbox, 50);

    if (isInViewport) {
      scene.isInView = true;
      if (scene.lazyLoad && !scene.initialized && !scene.initializing) {
        scene.curtain.renderer.setSize();
        scene.initializePlanes();
      }
    } else {
      scene.isInView = false;
    }

    if (!scene.isFixed) {
      scene.mouse.movePos.y += scrollDelta / 2;
    }
  });
}

function updateMouseMove() {
  scenes.forEach(scene => {
    if (scene.isInView && scene.curtain.planes.find(n => n.uniforms.mousePos)) {
      if (isMobile() && scene.interactivity?.mouse?.disableMobile) {
      } else {
        scene.mouse.pos.y = scene.mouse.movePos.y;
        scene.mouse.pos.x = scene.mouse.movePos.x;

        scene.mouse.lastPos.x = scene.mouse.pos.x;
        scene.mouse.lastPos.y = scene.mouse.pos.y;

        let top = scene.isFixed ? scene.element.offsetTop : scene.bbox.top + scene.scrollY;
        let left = scene.isFixed ? scene.element.offsetLeft : scene.bbox.left;

        if (
          scene.mouse.page.x > left &&
          scene.mouse.page.y > top &&
          scene.mouse.page.x < scene.lastBbox.width + left &&
          scene.mouse.page.y < scene.lastBbox.height + top
        ) {
          if (!scene.mouse.enterTime) {
            scene.mouse.enterTime = performance.now();
          }
        } else {
          scene.mouse.enterTime = null;
        }
      }
    }
  });
}

function handleMouseLeave(e) {
  scenes
    .filter(scene => scene.isInView)
    .forEach(scene => {
      scene.mouse.page.x = 99999999999;
      scene.mouse.page.y = 99999999999;
      scene.mouse.enterTime = null;
    });
}

function handleMouseMove(e) {
  scenes
    .filter(scene => scene.isInView)
    .forEach(scene => {
      let rect = scene.bbox;
      let pageX;
      let pageY;

      if (e.targetTouches) {
        pageX = e.targetTouches[0].pageX;
        pageY = e.targetTouches[0].pageY;
      } else {
        pageX = e.pageX;
        pageY = e.pageY;
      }

      if (scene.isFixed) {
        scene.scrollY = 0;
        if (e.targetTouches) {
          pageY = e.targetTouches[0].clientY;
        } else {
          pageY = e.clientY;
        }
      }

      const center = {
        x: rect.left / 2,
        y: (rect.top + scene.scrollY) / 2,
      };

      const xPos = pageX / 2 - center.x;
      const yPos = pageY / 2 - center.y;

      scene.mouse.page.x = pageX;
      scene.mouse.page.y = pageY;

      scene.mouse.movePos.x = xPos;
      scene.mouse.movePos.y = yPos;
    });

  mouseMoved = true;
}

export let scenes = [];

class Scene {
  scrollY = 0;
  constructor(args) {
    this.id = args.id;
    this.projectId = args.projectId;
    this.canvasWidth = args.width || args.element.offsetWidth || viewportWidth;
    this.canvasHeight = args.height || args.element.offsetHeight || viewportHeight;
    this.curtain = undefined;
    this.curtainRafId = undefined;
    this.dpi = +args.dpi || Math.min(1.5, window.devicePixelRatio);
    this.element = args.element;
    this.fps = args.fps || 60;
    this.name = args.name;
    this.iframe = args.iframe || false;
    this.frameDuration = Math.floor(1000 / (args.fps || 60));
    this.layers = args.layers;
    this.lazyLoad = args.lazyLoad;
    this.initialized = false;
    this.lasTick = null;
    this.isInView = this.iframe || false;
    this.lastTime = 0;
    this.rendering = false;
    this.bbox = {};
    this.isFixed = window.getComputedStyle(this.element).position === 'fixed';
    this.interactivity = {
      mouse: {
        disableMobile: false,
      },
    };
    this.mouse = {
      downPos: { x: 0, y: 0 },
      movePos: { x: viewportWidth / 4, y: viewportHeight / 4 },
      lastPos: { x: viewportWidth / 4, y: viewportHeight / 4 },
      delta: { x: 0, y: 0 },
      page: { x: 0, y: 0 },
      dragging: false,
      trail: [],
      recordTrail: false,
      enterTime: null,
      pos: { x: viewportWidth / 4, y: viewportHeight / 4 },
    };
    this.renderingScale = args.renderingScale || 1;
    this.scale = args.scale || 1;
    this.split = false;
    this.versionId = '';

    if (args.width && args.height) {
      this.element.style.width = args.width + 'px';
      this.element.style.height = args.height + 'px';
    }

    this.bbox = this.element.getBoundingClientRect();
    this.lastBbox = this.bbox;

    this.createCurtains();
    this.setCanvasScale();
  }

  preloadCanvasTexture(plane, item) {
    plane.loadCanvas(
      item.local.canvas,
      {
        sampler: 'uTexture',
        premultiplyAlpha: true,
      },
      texture => {
        item.preloadedCanvasTexture = texture;
      },
      error => {
        console.error('Error loading canvas texture:', error);
      }
    );
  }

  setCanvasScale() {
    this.canvasWidth = this.element.offsetWidth * this.dpi * this.scale;
    this.canvasHeight = this.element.offsetHeight * this.dpi * this.scale;
  }

  destroy() {
    if (this.element) {
      this.element.removeAttribute('data-us-initialized');
      this.element.removeAttribute('data-scene-id');
    }
    this.layers.filter(n => n.dispose).forEach(layer => layer.dispose());
    this.curtain.dispose();

    scenes = scenes.filter(n => n.id !== this.id);

    if (!scenes.length) {
      unbindEvents();
    }
  }

  resize() {
    this.setCanvasScale();
    this.layers
      .filter(n => n.isElement)
      .forEach(item => {
        item.resize();
        const plane = item.getPlane();
        if (plane) {
          plane.textures
            .filter(n => n.sourceType === 'canvas')
            .forEach(tex => {
              tex.needUpdate();
            });
        }
      });
    this.layers
      .filter(n => n.render)
      .forEach(item => {
        item.render();
      });
    this.curtain.resize();
    this.bbox = this.element.getBoundingClientRect();
  }

  refresh() {
    this.initialized = false;
    this.curtain.planes.forEach(plane => plane.type !== 'PingPongPlane' && plane.remove());

    this.layers.forEach(layer => {
      layer.states?.scroll.forEach(state => state.resetState());
      layer.states?.appear.forEach(state => (state.disabled = true));

      if (layer.breakpoints.length) {
        layer.setBreakpointValues();
      }
    });
    this.lazyLoad = true;

    requestAnimationFrame(() => {
      this.resize();
      this.scrollY = scrollTop;
      if (this.isInView) {
        this.initializePlanes();
      }
    });
  }

  updateMouseTrail() {
    if (mouseMoved) {
      this.mouse.trail.unshift([
        this.mouse.pos.x / (this.lastBbox.width * 0.5),
        1 - this.mouse.pos.y / (this.lastBbox.height * 0.5),
      ]);
      if (this.mouse.trail.length > 4) {
        this.mouse.trail.pop();
      }
    }
  }

  getAnimatingEffects() {
    return this.layers.filter(n => isDynamic(n) && n.visible);
  }

  createCurtains() {
    this.curtain = new Curtains({
      container: this.element,
      premultipliedAlpha: true,
      antialias: false,
      autoRender: false,
      autoResize: false,
      watchScroll: false,
      renderingScale: Math.min(Math.max(0.25, this.renderingScale), 1),
      production: false,
      pixelRatio: this.dpi,
    });

    this.curtain.onError((err) => {
      console.warn('Unicorn.studio scene encountered an error', err);
      this.webglError = true;
    });

    this.curtain.onContextLost(() => {
      console.warn('Unicorn.studio scene lost WebGL context');
      this.curtain.restoreContext();
    });

    this.scrollY = window.scrollY || window.pageYOffset;

    document.querySelectorAll(`[data-us-text="loading"][data-us-project="${this.id}"]`).forEach(node => {
      node.style.position = 'absolute';
    });
  }

  renderFrame() {
    if(isCurtainsValid(this.curtain)) {
      this.curtain.render();
    } else {
      console.warn('Curtains instance is not valid');
    }
  }

  renderNFrames(count, callback) {
    let index = 0;

    const renderFrame = () => {
      this.renderFrame();
      if (index < count) {
        index++;
        requestAnimationFrame(renderFrame);
      } else if (callback) {
        callback();
      }
    };

    if (!this.rendering) {
      renderFrame();
    }
  }

  setInteractiveParams(params, options) {
    let interactivity = {
      mouse: {
        disableMobile: false,
      },
    };
    if (options && options.mouse) {
      if ('disableMobile' in options.mouse) {
        interactivity.mouse.disableMobile = options.mouse.disableMobile;
      }
    }
    if (params && params.interactivity && params.interactivity.mouse) {
      if ('disableMobile' in params.interactivity.mouse) {
        interactivity.mouse.disableMobile = params.interactivity.mouse.disableMobile;
      }
    }

    this.interactivity = interactivity;
  }

  getSplitOrderedItems() {
    let orderedItems = this.getOrderedItems();
    let index = 0;
    let item = orderedItems[index];
    if (item) {
      let parent = item.parentLayer ? item.getParent() : null;
      let dynamicParent = parent && isDynamic(parent);
      let dynamicParentChildren =
        parent &&
        parent.effects &&
        parent.effects.length &&
        parent.getChildEffectItems().filter(n => isDynamic(n)).length;
      while (item && !isDynamic(item) && !dynamicParent && !dynamicParentChildren) {
        index++;
        item = orderedItems[index];
        if (item) {
          parent = item.parentLayer ? item.getParent() : null;
          dynamicParent = parent && isDynamic(parent);
          dynamicParentChildren =
            parent &&
            parent.effects &&
            parent.effects.length &&
            parent.getChildEffectItems().filter(n => isDynamic(n)).length;
        }
      }
      return {
        static: this.getOrderedItems().splice(0, index),
        dynamic: this.getOrderedItems().splice(index),
      };
    } else {
      return {
        static: [],
        dynamic: [],
      };
    }
  }

  initializePlanes(callback) {
    this.initializing = true;

    this.handleItemPlanes(() => {
      this.handlePlaneCreation();

      if (callback) callback(this);
    });
  }

  getPassPlane(item, index) {
    return this.curtain.planes.find(n => n.userData.id === item.local.id && n.userData.passIndex === index);
  }

  getRenderTargets() {
    return this.curtain.renderTargets.filter(n => n.userData.id);
  }

  getPlanes() {
    return this.curtain.planes.filter(n => n.type !== 'PingPongPlane');
  }

  removeUnusedPlanes() {
    this.curtain.planes.forEach(plane => {
      plane.remove();
    });
    this.curtain.renderTargets.forEach(target => {
      target.remove();
    });
  }

  getPlaneParams(item, index) {
    let segments = ['noise', 'noiseField', 'sine', 'ripple', 'bulge'].includes(item.type) ? 500 : 1;
    const uniforms = {
      resolution: {
        name: 'uResolution',
        type: '2f',
        value: new Vec2(this.canvasWidth, this.canvasHeight),
      },
      mousePos: {
        name: 'uMousePos',
        type: '2f',
        value: new Vec2(0.5),
      },
      time: {
        name: 'uTime',
        type: '1f',
        value: 0,
      },
      dpi: {
        name: 'uDpi',
        type: '1f',
        value: this.dpi * +this.renderingScale,
      },
    };

    if (item.isElement) {
      uniforms.sampleBg = {
        name: 'uSampleBg',
        type: '1i',
        value: 1,
      };
    }

    if (hasPingPongPlane(item.type)) {
      uniforms.previousMousePos = {
        name: 'uPreviousMousePos',
        type: '2f',
        value: new Vec2(0.5),
      };
    }

    if (item.states) {
      [...item.states.appear, ...item.states.scroll, ...item.states.hover].forEach(stateEffect => {
        if (!uniforms[stateEffect.prop]) {
          if (stateEffect.uniformData) {
            uniforms[stateEffect.prop] = stateEffect.uniformData;
            uniforms[stateEffect.prop].value = stateEffect.value;
          }
        }
      });
    }

    if (item.data?.uniforms) {
      for (let prop in item.data.uniforms) {
        let itemUniforms = item.data.uniforms[prop];
        uniforms[prop] = item.data.uniforms[prop];
        if (itemUniforms.value?.type === 'Vec3') {
          uniforms[prop].value = new Vec3(itemUniforms.value._x, itemUniforms.value._y, itemUniforms.value._z);
        } else if (itemUniforms.value?.type === 'Vec2') {
          uniforms[prop].value = new Vec2(itemUniforms.value._x, itemUniforms.value._y);
        } else if (typeof itemUniforms.value === 'object') {
          uniforms[prop].value = deserializeNestedArray(itemUniforms.value);
        }
      }
    }

    let fragmentShader = item.compiledFragmentShaders[index] || item.compiledFragmentShaders[0];
    let vertexShader = item.compiledVertexShaders[index] || item.compiledVertexShaders[0];

    return {
      fragmentShader,
      vertexShader,
      widthSegments: segments,
      heightSegments: segments,
      texturesOptions: {
        floatingPoint: 'half-float',
        premultiplyAlpha: true,
      },
      uniforms,
    };
  }

  createPlane(item, renderOrder, passValues) {
    let params;
    if (item.isElement) {
      params = this.getPlaneParams(item);
    } else {
      params = this.getPlaneParams(item, passValues ? passValues.index : null);
    }

    params.watchScroll = false;

    let shouldDownSample = (item.data?.downSample && !passValues) || passValues?.downSample;
    let halfScale = this.scale * 0.5;

    if (shouldDownSample && this.curtain.renderer._renderingScale !== halfScale) {
      this.curtain.renderer._renderingScale = halfScale;
      this.curtain.renderer.setSize();
    } else if (this.curtain.renderer._renderingScale !== this.scale) {
      this.curtain.renderer._renderingScale = this.scale;
      this.curtain.renderer.setSize();
    }

    let plane;
    try {

      if(!this.curtain.container) {
        throw new Error('Can\'t find scene container');
      }

      plane = new Plane(this.curtain, this.curtain.container, params);
      
      if (!plane || !plane.userData || !plane.textures) {
        throw new Error('Plane not properly initialized');
      }

      plane.textures.length = 0;

      plane.userData.id = item.local.id;
      plane.userData.layerType = item.layerType;
      plane.userData.type = item.type;

      if (item.texture || item.data?.texture) {
        plane.userData.textureLoaded = false;
      }

      plane.setRenderOrder(renderOrder);

      return plane;
      
    } catch (error) {
      console.error('Error creating plane:', error);
      return null;
    }
  }

  createPingPongPlane(item, i, passValues) {
    let params = this.getPlaneParams(item, 1 + (passValues?.length || 0));

    let pingpong = this.curtain.planes.find(n => n.type === 'PingPongPlane' && n.userData.id === item.local.id);

    if (!pingpong) {
      pingpong = new PingPongPlane(this.curtain, this.curtain.container, params);
      pingpong.userData.id = item.local.id;
      pingpong.userData.pingpong = true;
      pingpong.setRenderOrder(i);
      this.setInitialEffectPlaneUniforms(pingpong, item, item.getParent(), passValues);
      pingpong
        .onReady(() => {
          pingpong.userData.isReady = true;
        })
        .onRender(() => {
          this.setEffectPlaneUniforms(pingpong, item);
        });
    } else {
      pingpong.setRenderOrder(i);
    }

    if (!pingpong) return;

    return pingpong;
  }

  createEffectPlane(item, i, passValues) {
    const plane = this.createPlane(item, i, passValues);
    const parent = item.getParent();

    if (!plane || !plane.userData || !plane.textures) {
      throw new Error('Plane not properly initialized', plane);
    }

    if (passValues) {
      plane.userData.passIndex = passValues.index;
      plane.userData.downSample = passValues.downSample;
      plane.userData.includeBg = passValues.includeBg;
      plane.userData.length = item.data.passes.length;

      Object.entries(passValues).forEach(([prop, value]) => {
        if (plane.uniforms[prop]) plane.uniforms[prop].value = value;
      });
    } else {
      plane.userData.downSample = item.data.downSample;
    }

    this.setInitialEffectPlaneUniforms(plane, item, parent, passValues);
    plane
      .onReady(() => {
        plane.userData.isReady = true;
      })
      .onRender(() => this.setEffectPlaneUniforms(plane, item));
  }

  createElementPlane(item, i) {
    const plane = this.createPlane(item, i);
    this.preloadCanvasTexture(plane, item);
    this.setInitialElementPlaneUniforms(plane, item);

    plane
      .onReady(() => {
        plane.userData.isReady = true;
      })
      .onRender(() => this.setElementPlaneUniforms(plane, item));
  }

  handleEffectPlane(item, i, data) {
    const plane = 'passIndex' in data ? this.getPassPlane(item, data.passIndex) : item.getPlane();
    let target = this.getRenderTargets()[i - 1];
    let pingpong = this.curtain.planes.find(n => n.type === 'PingPongPlane' && n.userData.id === item.local.id);

    if (!plane) return false;

    if (pingpong) {
      plane.createTexture({
        sampler: 'uPingPongTexture',
        fromTexture: pingpong.getTexture(),
      });
    }

    if (target) {
      plane.createTexture({
        sampler: 'uTexture',
        fromTexture: target.getTexture(),
      });
    } else {
      plane.createTexture({
        sampler: 'uTexture',
      });
    }

    if (data.passIndex > 0) {
      if (plane && this.getRenderTargets()[i - (1 + data.passIndex)]) {
        plane.createTexture({
          sampler: 'uBgTexture',
          fromTexture: this.getRenderTargets()[i - (1 + data.passIndex)].getTexture(),
        });
      }
    }

    [item.texture, item.data?.texture]
      .filter(n => n?.src)
      .forEach(texture => {
        plane.loadImage(
          texture.src,
          {
            sampler: texture.sampler,
            premultipliedAlpha: false,
          },
          tex => {
            plane.userData.textureLoaded = true;
          }
        );
      });
  }

  handleElementPlane(item, i) {
    const plane = item.getPlane();
    const effects = item.getChildEffectItems();
    const items = this.layers.filter(n => !n.parentLayer);
    let target = this.getRenderTargets()[i - 1];
    let previousLayer = items[items.indexOf(item) - 2];
    let previousLayerTarget;

    if (item.mask && previousLayer) {
      previousLayerTarget = previousLayer.local.lastTarget;
    }

    if (!effects.length) {
      plane.textures.length = 0;
    }

    if (target && effects.length && plane) {
      plane.createTexture({
        sampler: 'uTexture',
        premultipliedAlpha: true,
        fromTexture: target.getTexture(),
      });
    } else if (plane) {
      plane.addTexture(item.preloadedCanvasTexture);
    }

    if (target) {
      if (effects.length) {
        let planeCount = effects.reduce((a, b) => a + b.getPlanes().length, 0);
        target = this.getRenderTargets()[i - (1 + planeCount)];
      }
      if (target) {
        plane.createTexture({
          sampler: 'uBgTexture',
          premultipliedAlpha: true,
          fromTexture: target.getTexture(),
        });

        if (previousLayerTarget && item.mask) {
          plane.createTexture({
            sampler: 'uPreviousLayerTexture',
            premultipliedAlpha: true,
            fromTexture: previousLayerTarget.getTexture(),
          });
        }
      }
    }
  }

  handleChildEffectPlane(item, i, data) {
    const plane = 'passIndex' in data ? this.getPassPlane(item, data.passIndex) : item.getPlane();
    const parent = item.getParent();
    let target = this.getRenderTargets()[i - 1];
    let pingpong = this.curtain.planes.find(n => n.type === 'PingPongPlane' && n.userData.id === item.local.id);
    let effects = parent.effects.filter(n => {
      if (this.layers.find(o => o.parentLayer === n)) {
        return this.layers.find(o => o.parentLayer === n).visible;
      }
    });

    let effectIndex = effects.indexOf(item.parentLayer);
    let lastEffect = effects.at(-1) === effects[effectIndex];
    let lastPass = data.passIndex === data.length;

    if (pingpong && hasPingPongPlane(item.type)) {
      plane.createTexture({
        sampler: 'uPingPongTexture',
        fromTexture: pingpong.getTexture(),
      });
    }

    if (plane && target && (effectIndex || data.passIndex > 0)) {
      if (item.isMask) {
        if (!data.length || (lastEffect && lastPass)) {
          plane.addTexture(parent.preloadedCanvasTexture);
        }
      }
      plane.createTexture({
        sampler: 'uTexture',
        premultipliedAlpha: true,
        fromTexture: target.getTexture(),
      });
    } else {
      if (plane && item.isMask) {
        if (lastEffect && lastPass) {
          plane.addTexture(parent.preloadedCanvasTexture);
        }
        if (target) {
          plane.createTexture({
            sampler: 'uTexture',
            premultipliedAlpha: true,
            fromTexture: target.getTexture(),
          });
        }
      } else if (plane) {
        plane.addTexture(parent.preloadedCanvasTexture);
      }
    }

    if (plane.userData.includeBg) {
      plane.createTexture({
        sampler: 'uBgTexture',
        fromTexture: parent.preloadedCanvasTexture,
      });
    }

    if (item.type === 'custom') {
      plane.createTexture({
        sampler: 'uCustomTexture',
        premultipliedAlpha: true,
        fromTexture: this.getRenderTargets()[i],
      });
    }

    [item.texture, item.data?.texture]
      .filter(n => n?.src)
      .forEach(texture => {
        plane.loadImage(
          texture.src,
          {
            sampler: texture.sampler,
            premultipliedAlpha: false,
          },
          tex => {
            plane.userData.textureLoaded = true;
          }
        );
      });
  }

  createPlanes() {
    this.getOrderedItems().forEach((item, i) => {
      if (!item.getPlanes().length) {
        if (item.isElement) {
          this.createElementPlane(item, i);
        } else {
          this.createEffectPlanes(item, i);
        }
      } else {
        item.getPlanes().forEach(plane => plane.setRenderOrder(i));
      }
    });
  }

  createEffectPlanes(item, i) {
    const effect = item.data;
    if (effect.passes && effect.passes.length) {
      this.createEffectPlane(item, i, {
        index: 0,
        downSample: effect.downSample,
        length: effect.passes.length + 1,
      });
      effect.passes.forEach((pass, index) => {
        this.createEffectPlane(item, i, {
          index: index + 1,
          length: effect.passes.length + 1,
          downSample: pass.downSample,
          [pass.prop]: pass.value,
          includeBg: pass.includeBg,
        });
      });
      if (hasPingPongPlane(item.type)) {
        this.createPingPongPlane(item, i, effect.passes);
      }
    } else {
      this.createEffectPlane(item, i);
      if (hasPingPongPlane(item.type)) {
        this.createPingPongPlane(item, i);
      }
    }
  }

  createTextures() {
    const orderedPlanes = this.getPlanes()
      .filter(n => n.visible)
      .sort((a, b) => a.renderOrder - b.renderOrder);
    const len = orderedPlanes.length;
    for (let i = 0; i < len; i++) {
      const plane = orderedPlanes[i];
      let item = this.layers.find(n => n.local.id === plane.userData.id);
      if (i < len - 1) {
        this.assignRenderTargetToPlane(orderedPlanes, i, item, plane);
      }
      this.handleTextures(item, i, plane.userData);
      item.local.lastTarget = plane.target;
    }
  }

  assignRenderTargetToPlane(orderedPlanes, i, item, plane) {
    let renderTargetParams = this.getTextureParams(orderedPlanes, i, item);
    let renderTarget = this.getRenderTargets()[i] || new RenderTarget(this.curtain, renderTargetParams);
    renderTarget.userData.id = plane.userData.id;
    plane.setRenderTarget(renderTarget);
  }

  handleTextures(item, i, planeUserData) {
    if (item.isElement) {
      this.handleElementPlane(item, i);
    } else {
      item.parentLayer
        ? this.handleChildEffectPlane(item, i, planeUserData)
        : this.handleEffectPlane(item, i, planeUserData);
    }
  }

  handleItemPlanes(callback) {
    this.createPlanes();
    this.createTextures();
    this.checkIfReady(callback);
  }

  isNotReady(plane) {
    const item = this.layers.find(n => n.local.id === plane.userData.id);

    if (item.layerType === 'image' && !item.local.loaded) {
      return true;
    }

    if (item.layerType === 'text' && !item.local.loaded) {
      return true;
    }

    if ('textureLoaded' in plane.userData && !plane.userData.textureLoaded) {
      return true;
    }

    return false;
  }

  checkIfReady(callback) {
    const performCheck = () => {
      let waitingForAssets = false;
      let planeNotReady = false;
      const planes = this.curtain.planes;

      for (let i = 0; i < planes.length; i++) {
        if (!planes[i].userData.isReady) {
          planeNotReady = true;
          break;
        } else if (this.isNotReady(planes[i])) {
          waitingForAssets = true;
          break;
        }
      }

      if (planeNotReady || waitingForAssets) {
        if (planeNotReady) {
          this.renderFrame();
        }
        requestAnimationFrame(performCheck);
      } else {
        callback();
      }
    };

    performCheck();
  }

  setInitialEffectPlaneUniforms(plane, item, parent, passValues) {
    if (!plane.userData.initialUniformsSet || !plane.userData.isReady) {
      for (let prop in plane.uniforms) {
        if (item.local.bpProps && prop in item.local.bpProps) {
          if (prop === 'pos') {
            plane.uniforms[prop].value.x = item.local.bpProps[prop].x;
            plane.uniforms[prop].value.y = 1 - item.local.bpProps[prop].y;
          } else {
            plane.uniforms[prop].value = item.local.bpProps[prop];
          }
        } else if (prop in item) {
          plane.uniforms[prop].value = item[prop];
        }
      }

      if (parent && passValues && passValues.index < passValues.length - 1 && plane.uniforms.isMask) {
        plane.uniforms.isMask.value = 0;
      }

      if (item.states && item.states.appear.length) {
        item.states.appear
          .filter(n => !n.disabled)
          .forEach(state => {
            if (plane.uniforms[state.prop]) {
              state.initializeState(plane.uniforms[state.prop], item[state.prop]);
            }
          });
      }

      if (parent && item.isMask && !item.mouseMomentum) {
        item.mouseMomentum = parent.mouseMomentum;
      }

      plane.userData.initialUniformsSet = true;
    }
  }

  handleStateEffects(plane, item) {
    if (this.isInView && !plane.userData.createdAt) {
      plane.userData.createdAt = performance.now();
    }
    if (!item.states || ![...item.states.appear, ...item.states.scroll, ...item.states.hover].length) return false;

    item.states.appear.forEach(effect => {
      effect.updateEffect(plane);
    });

    item.states.scroll.forEach(effect => {
      effect.updateEffect(plane, item[effect.prop], {
        top: this.isFixed ? 0 : this.lastBbox?.top,
        height: this.lastBbox?.height,
        isFixed: this.isFixed,
      });
    });

    item.states.hover.forEach(effect => {
      effect.updateEffect(plane, item[effect.prop], this.mouse.enterTime);
    });
  }

  setInitialElementPlaneUniforms(plane, item) {
    plane.uniforms.resolution.value.x = this.curtain.canvas.width;
    plane.uniforms.resolution.value.y = this.curtain.canvas.height;

    if (plane.uniforms.sampleBg) {
      if (plane.renderOrder - item.effects.length === 0) {
        plane.uniforms.sampleBg.value = 0;
      } else {
        plane.uniforms.sampleBg.value = 1;
      }
    }
  }

  setElementPlaneUniforms(plane, item) {
    let canvasWidth = this.element.offsetWidth * 0.5;
    let canvasHeight = this.element.offsetHeight * 0.5;
    
    if (plane.uniforms.mousePos) {
      let xPos = this.mouse.pos.x;
      let yPos = this.mouse.pos.y;
      let scaledX = xPos / canvasWidth;
      let scaledY = 1 - yPos / canvasHeight;


      if (item.mouseMomentum && item.type !== 'mouse') {
        if (!item.local.lastMousePos) {
          item.local.lastMousePos = {
            x: scaledX,
            y: scaledY,
          };
        }
        let lastXPos = item.local.lastMousePos.x * canvasWidth;
        let lastYPos = (1 - item.local.lastMousePos.y) * canvasHeight;
        xPos = weightedLerp(xPos, lastXPos, item.mouseMomentum * 2);
        yPos = weightedLerp(yPos, lastYPos, item.mouseMomentum * 2);

        item.local.lastMousePos.x = xPos / canvasWidth;
        item.local.lastMousePos.y = 1 - yPos / canvasHeight;
      }

      if (plane.uniforms.mousePos.value.x !== scaledX || plane.uniforms.mousePos.value.y !== scaledY) {
        plane.uniforms.mousePos.value.x = scaledX;
        plane.uniforms.mousePos.value.y = scaledY;
      }
    }
  }

  setEffectPlaneUniforms(plane, item) {
    if (item.animating && plane.uniforms.time) {
      plane.uniforms.time.value += ((item.speed || 1) * 60) / this.fps;
    }

    this.handleStateEffects(plane, item);

    let canvasWidth = this.bbox.width / 2;
    let canvasHeight = this.bbox.height / 2;

    if (plane.uniforms.mousePos) {
      let xPos = this.mouse.pos.x;
      let yPos = this.mouse.pos.y;

      if (item.mouseMomentum && item.type !== 'mouse') {
        if (!item.local.lastMousePos) {
          item.local.lastMousePos = {
            x: xPos / canvasWidth,
            y: 1 - yPos / canvasHeight,
          };
        }
        let lastXPos = item.local.lastMousePos.x * canvasWidth;
        let lastYPos = (1 - item.local.lastMousePos.y) * canvasHeight;
        xPos = weightedLerp(xPos, lastXPos, item.mouseMomentum * 2);
        yPos = weightedLerp(yPos, lastYPos, item.mouseMomentum * 2);

        item.local.lastMousePos.x = xPos / canvasWidth;
        item.local.lastMousePos.y = 1 - yPos / canvasHeight;
      }

      plane.uniforms.mousePos.value.x = xPos / canvasWidth;
      plane.uniforms.mousePos.value.y = 1 - yPos / canvasHeight;
    }

    if (plane.uniforms.previousMousePos) {
      if (this.mouse.trail.length > 2) {
        plane.uniforms.previousMousePos.value.x = this.mouse.trail.at(2)[0];
        plane.uniforms.previousMousePos.value.y = this.mouse.trail.at(2)[1];
      } else {
        plane.uniforms.previousMousePos.value.x = plane.uniforms.mousePos.value.x;
        plane.uniforms.previousMousePos.value.y = plane.uniforms.mousePos.value.y;
      }
    }

    plane.uniforms.resolution.value.x = this.curtain.canvas.width;
    plane.uniforms.resolution.value.y = this.curtain.canvas.height;
  }

  getOrderedItems() {
    let orderedItems = [];
    this.layers
      .filter(n => !n.parentLayer && n.visible)
      .forEach(item => {
        if (item.effects && item.effects.length) {
          orderedItems.push(...item.getChildEffectItems());
        }
        orderedItems.push(item);
      });
    return orderedItems;
  }

  getTextureParams(orderedPlanes, i, item) {
    const plane = orderedPlanes[i];
    const downSample = plane.userData.downSample ? 0.5 : 1;
    const params = {
      maxWidth: this.curtain.canvas.width,
      maxHeight: this.curtain.canvas.height,
      depth: item?.data?.depth || item?.type === 'bulge',
    };
    if (downSample) {
      params.maxWidth = this.canvasWidth * downSample;
      params.maxHeight = this.canvasHeight * downSample;
    }
    return params;
  }

  handlePlaneCreation() {
    this.initialized = true;
    this.initializing = false;

    if (!this.rendering) {
      this.renderNFrames(2);
    }

    this.removePlanes();

    renderScenes();
  }

  async removePlanes() {
    const items = this.getSplitOrderedItems();

    if (!items.dynamic.length) {
      items.static.pop();
    }

    for (const item of items.static) {
      const textNotLoaded = item.layerType === 'text' && !item.local.loaded;
      const imageNotLoaded = item.layerType === 'image' && !item.local.fullyLoaded;
      if (textNotLoaded || imageNotLoaded) {
        await waitForItemLoad(item, textNotLoaded ? 'loaded' : 'fullyLoaded');
      }
      const planes = item.getPlanes();
      for (const plane of planes) {
        plane.remove();
      }
    }
  }
}

function isElement(o) {
  return typeof HTMLElement === 'object'
    ? o instanceof HTMLElement //DOM2
    : o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string';
}

function bindEvents() {
  window.addEventListener('mousemove', handleMouseMove);
  window.addEventListener('touchmove', handleMouseMove);
  window.addEventListener('scroll', handleWindowScroll);
  window.addEventListener('routeChange', cleanupScenes);
  document.addEventListener('mouseleave', handleMouseLeave);

  if (isMobile()) {
    window.addEventListener('orientationchange', debounce(handleWindowResize, 20));
  } else {
    window.addEventListener('resize', debounce(handleWindowResize, 200));
  }

  document.addEventListener(visibilityChange, handleVisibilityChange, false);

  eventsBound = true;
}

export function unbindEvents() {
  window.removeEventListener('mousemove', handleMouseMove);
  window.removeEventListener('touchmove', handleMouseMove);
  window.removeEventListener('scroll', handleWindowScroll);
  window.removeEventListener('routeChange', cleanupScenes);
  document.removeEventListener('mouseleave', handleMouseLeave);

  window.removeEventListener('resize', debounce(handleWindowResize, 200));
  window.removeEventListener('orientationchange', debounce(handleWindowResize, 20));

  document.removeEventListener(visibilityChange, handleVisibilityChange, false);

  eventsBound = false;
}

function getCanvasScale(element, scale, dpi) {
  return {
    canvasWidth: element.offsetWidth * dpi,
    canvasHeight: element.offsetHeight * dpi,
    scale: scale,
    dpi: dpi,
    element: element,
  };
}

export function destroy() {
  scenes.forEach(scene => {
    scene.destroy();
  });
  scenes.length = 0;
  unbindEvents();
}

function fetchSceneData(projectId, queryString, filePath, reject, production) {
  let url;
  if (filePath) {
    url = filePath;

    if (document.getElementById(filePath)) {
      return new Promise((resolve, reject) => {
        try {
          let resp = JSON.parse(document.getElementById(filePath).innerText);
          if (resp.options && resp.history) {
            resolve(resp);
          } else {
            reject(new Error(`Did not find valid JSON inside ${filePath}`));
          }
        } catch (error) {
          reject(new Error(`Error parsing JSON from ${filePath}: ${error.message}`));
        }
      });
    }
  } else {
    let prefix = 'https://storage.googleapis.com/unicornstudio-production';
    if (production || queryString?.includes('production=true')) {
      prefix = 'https://assets.unicorn.studio';
      queryString = `v=${Date.now()}`;
    } else if (!queryString?.includes('update=')) {
      queryString = `v=${Date.now()}`;
    }
    url = `${prefix}/embeds/${projectId}${queryString ? '?' + queryString : ''}`;
  }
  return fetch(url)
    .then(response => {
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => console.error('Error fetching data:', error));
}

export function addScene(params) {
  let projectId = params.projectId ? params.projectId.split('?')[0] : null;
  let queryString = params.projectId ? params.projectId.split('?')[1] : null;
  return new Promise((resolve, reject) => {
    fetchSceneData(projectId, queryString, params.filePath, reject, params.production)
      .then(resp => {
        if (!resp) {
          reject(new Error(`Unable to fetch embed/file with id '${params.projectId}'`));
        }

        const options = resp.options || {};
        const element = isElement(params.element) ? params.element : document.getElementById(params.elementId);

        if (!element) {
          reject(new Error(`Couldn't find an element with id '${params.elementId}' on the page.`));
          return;
        }

        const id = generateUUID();
        element.setAttribute('data-scene-id', id);

        let baseScale = params.scale || options.scale || 1;
        let baseDpi = params.dpi || Math.min(1.5, window.devicePixelRatio);

        let adjustedDpi = deviceCheckResults?.settings?.dpi ? 
          Math.min(baseDpi, deviceCheckResults.settings.dpi) : 
          baseDpi;

        let adjustedScale = deviceCheckResults?.settings?.scale ? 
          Math.min(baseScale, deviceCheckResults.settings.scale) : 
          baseScale;

        const layers = unpackageHistory(
          resp.history,
          id,
          getCanvasScale(
            element,
            adjustedScale,
            adjustedDpi
          )
        );

        const scene = new Scene({
          id: id,
          fps: params.fps || options.fps || 60,
          dpi: adjustedDpi,
          name: options.name,
          iframe: params.iframe,
          projectId: projectId || params.filePath.split('.')[0],
          renderingScale: adjustedScale,
          element: element,
          lazyLoad: params.lazyLoad,
          width: params.width,
          height: params.height,
        });

        if (params.altText) {
          scene.curtain.canvas.innerText = params.altText;
        }

        if (params.ariaLabel) {
          scene.curtain.canvas.setAttribute('aria-label', params.ariaLabel);
        }

        scene.curtain.canvas.setAttribute('role', 'img');
        if (options.freePlan || options.includeLogo) {
          addLogo(id, element);
        }
        if (options.freePlan) {
          console.log('Made with unicorn.studio');
        }

        scenes.push(scene);
        scene.layers = layers;
        scene.mouse.recordTrail = !!scene.layers.find(n => n.type == 'mouse');
        scene.setInteractiveParams(params, options);
        scene.isInView = scene.isFixed || isElementNearViewport(scene.element, scene.bbox, 50);

        if (!scene.lazyLoad || scene.isInView) {
          scene.initializePlanes();
        }

        if (!eventsBound) {
          bindEvents();
        }

        resolve(scene);
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });
}

export function init() {
  return new Promise((resolve, reject) => {
    const elements = [
      ...document.querySelectorAll('[data-us-project]'),
      ...document.querySelectorAll('[data-us-project-src]'),
    ];
    [...elements]
      .filter(n => !n.getAttribute('data-us-initialized'))
      .forEach((element, index) => {
        const id = element.getAttribute('data-us-project');
        const filePath = element.getAttribute('data-us-project-src');
        const dpi = element.getAttribute('data-us-dpi');
        const scale = element.getAttribute('data-us-scale');
        const lazyLoad = element.getAttribute('data-us-lazyload');
        const isProduction = element.getAttribute('data-us-production');
        const fps = element.getAttribute('data-us-fps');
        const altText = element.getAttribute('data-us-altText') || element.getAttribute('data-us-alttext');
        const ariaLabel = element.getAttribute('data-us-ariaLabel') || element.getAttribute('data-us-arialabel');
        const disableMobile =
          element.getAttribute('data-us-disableMobile') || element.getAttribute('data-us-disablemobile');
        element.setAttribute('data-us-initialized', true);

        addScene({
          projectId: id,
          filePath: filePath,
          element: element,
          dpi: +dpi,
          scale: +scale,
          production: isProduction,
          fps: +fps,
          lazyLoad: lazyLoad,
          altText,
          ariaLabel,
          interactivity: disableMobile
            ? {
                mouse: {
                  disableMobile: true,
                },
              }
            : null,
        }).then(scene => {
          if (index === elements.length - 1) {
            resolve(scenes);
          }
        });
      });
  });
}

class DevicePerformanceChecker {
  static hasChecked = false;
  static results = null;

  static async checkPerformance() {
    if (this.hasChecked) {
      return this.results;
    }

    const checks = {
      isLowEndDevice: this.checkIsLowEndDevice(),
      isOldDevice: this.checkIsOldDevice(),
      hasGoodGPU: await this.checkGPUCapability(),
    };

    let recommendedSettings = {
      dpi: window.devicePixelRatio || 1,
      scale: 1,
    };

    if (checks.isLowEndDevice || checks.isOldDevice || !checks.hasGoodGPU) {
      recommendedSettings.dpi = Math.min(window.devicePixelRatio, 1);
      recommendedSettings.scale = 0.75;
    }

    this.results = {
      checks,
      settings: recommendedSettings
    };
    
    this.hasChecked = true;
    return this.results;
  }

  static checkIsLowEndDevice() {
    const memory = navigator.deviceMemory;
    const processors = navigator.hardwareConcurrency;
    return (memory && memory <= 4) || (processors && processors <= 4);
  }

  static checkIsOldDevice() {
    const ua = navigator.userAgent;
    const isOldIOS = /iPhone|iPad/.test(ua) && 
      !window.MSStream && 
      parseInt((ua.match(/OS (\d+)_/i) || [,0])[1], 10) < 13;
    const isOldAndroid = /Android/.test(ua) && 
      parseInt((ua.match(/Android (\d+)/i) || [,0])[1], 10) < 8;
    return isOldIOS || isOldAndroid;
  }

  static async checkGPUCapability() {
    if ('gpu' in navigator) {
      try {
        const adapter = await navigator.gpu.requestAdapter();
        if (adapter) {
          const info = await adapter.requestAdapterInfo();
          return !/(intel|microsoft|swiftshader|llvmpipe)/i.test(info.renderer);
        }
      } catch (e) {
        // Fall back to WebGL check
      }
    }

    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (!gl) return false;

    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    if (!debugInfo) return true;

    const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL).toLowerCase();
    return !/(intel|microsoft|swiftshader|llvmpipe)/i.test(renderer);
  }
}

DevicePerformanceChecker.checkPerformance().then(() => {
  deviceCheckResults = DevicePerformanceChecker.results;
});
