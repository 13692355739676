import {
  computeFragColor,
  vertexShader,
  UNIVERSAL_UNIFORMS,
  EASE,
  mixRadiusProperties,
  FLOATING_POINT,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const blindsShader = `#version 300 es
precision mediump float;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform int uStyle;
uniform float uFrequency;
uniform float uDistortion;
uniform float uDispersion;
uniform float uAmount;
uniform vec2 uPos;
uniform float uTime;
uniform float uAngle;
uniform float uMixRadius;
uniform int uEasing;
uniform int uMixRadiusInvert;
${UNIVERSAL_UNIFORMS}
${EASE}

const float STEPS = 10.0;
const float PI = 3.14159265359;

mat2 rot(float a) {
    return mat2(cos(a), -sin(a), sin(a), cos(a));
}

vec3 chromatic_abberation(vec2 st, vec2 aberrated) {
  vec4 red = vec4(0);
  vec4 blue = vec4(0);
  vec4 green = vec4(0);

  float invSteps = 1.0 / STEPS;
  float invStepsHalf = invSteps * 0.5;

  for(float i = 1.0; i <= STEPS; i++) {
    vec2 offset = aberrated * (i * invSteps);
    red += texture(uTexture, st - offset) * invSteps;
    blue += texture(uTexture, st + offset) * invSteps;
    green += texture(uTexture, st - offset * 0.5) * invStepsHalf;
    green += texture(uTexture, st + offset * 0.5) * invStepsHalf;
  }

  return vec3(red.r, green.g, blue.b);
}

vec2 scaleAspect(vec2 st, float aspectRatio) {
  return st * vec2(aspectRatio, 1.0);
}

vec2 unscaleAspect(vec2 st) {
  float aspectRatio = uResolution.x / uResolution.y;
  return st * vec2(1.0/aspectRatio, 1.0);
}

vec2 rotate(vec2 st, float angle) {
  float s = sin(angle);
  float c = cos(angle);
  mat2 rot = mat2(c, -s, s, c);
  return rot * st;
}

struct StructFunc {
  vec2 st;
  vec3 distort;
};


StructFunc style0(vec2 st, vec2 pos, float divisions, float dist, float amount, vec3 first, vec3 second, vec3 third) {
    float segment = fract((st.y + 1. - pos.y - 1. + uTime * 0.01) * divisions);
    vec3 distort = mix(mix(first, second, segment * 2.), mix(second, third, (segment - 0.5) / (1. - 0.5)), step(0.5, segment));
    st.y -= pow(distort.r, dist) / 10. * amount;
    st.y += pow(distort.b, dist) / 10. * amount;

    st = rot(uAngle * 2. * PI) * (st - pos) + pos;
    st = unscaleAspect(st);

    return StructFunc(st, distort);
}

StructFunc style1(vec2 st, vec2 pos, float divisions, float dist, float amount, vec3 first, vec3 second, vec3 third) {
    float segment = fract((st.x + 1. - pos.x - 1. + uTime * 0.01) * divisions);
    vec3 distort = mix(mix(first, second, segment * 2.), mix(second, third, (segment - 0.5) / (1. - 0.5)), step(0.5, segment));
    st.x -= pow(distort.r, dist) / 10. * amount;
    st.x += pow(distort.b, dist) / 10. * amount;

    st = rot(uAngle * 2. * PI) * (st - pos) + pos;
    st = unscaleAspect(st);

    return StructFunc(st, distort);
}

StructFunc style2(vec2 st, vec2 pos, float divisions, float dist, float amount, vec3 first, vec3 second, vec3 third) {
    float segment = fract((st.x + 1. - pos.x - 1. + uTime * 0.01) * divisions);
    vec3 distort = mix(mix(first, second, segment * 2.), mix(second, third, (segment - 0.5) / (1. - 0.5)), step(0.5, segment));
    st.x -= pow(distort.r, dist) / 10. * amount;
    st.x += pow(distort.b, dist) / 10. * amount;

    segment = fract((st.y + 1. - pos.y - 1. + uTime * 0.01) * divisions);
    distort = mix(mix(first, second, segment * 2.), mix(second, third, (segment - 0.5) / (1. - 0.5)), step(0.5, segment));
    st.y -= pow(distort.r, dist) / 10. * amount;
    st.y += pow(distort.b, dist) / 10. * amount;

    st = rot(uAngle * 2. * PI) * (st - pos) + pos;
    st = unscaleAspect(st);

    return StructFunc(st, distort);
}

StructFunc style3(vec2 st, vec2 pos, float divisions, float dist, float amount, vec3 first, vec3 second, vec3 third) {
    vec2 diff = st - pos;
    float angle = atan(diff.y, diff.x);
    float segment = fract((angle + uTime * 0.01 + PI) / (2. * PI) * divisions);
    vec3 distort = mix(mix(first, second, segment * 2.), mix(second, third, (segment - 0.5) / (1. - 0.5)), step(0.5, segment));
    st -= pow(distort.r, dist) / 10. * amount * normalize(diff);
    st += pow(distort.b, dist) / 10. * amount * normalize(diff);

    st = rot(uAngle * 2. * PI) * (st - pos) + pos;
    st = unscaleAspect(st);

    return StructFunc(st, distort);
}

StructFunc style4(vec2 st, vec2 pos, float divisions, float dist, float amount, vec3 first, vec3 second, vec3 third) {
    vec2 diff = st - pos;
    float radius = length(diff);
    float segment = fract(radius * divisions - uTime * 0.01);
    vec3 distort = mix(mix(first, second, segment * 2.), mix(second, third, (segment - 0.5) / (1. - 0.5)), step(0.5, segment));
    st -= pow(distort.r, dist) / 10. * amount * normalize(diff);
    st += pow(distort.b, dist) / 10. * amount * normalize(diff);

    st = rot(uAngle * 2. * PI) * (st - pos) + pos;
    st = unscaleAspect(st);

    return StructFunc(st, distort);
}

StructFunc style5(vec2 st, vec2 pos, float divisions, float dist, float amount, vec3 first, vec3 second, vec3 third) {
    vec2 diff = st - pos;
    float angle = -PI / 4.; // rotate by -45 degrees
    vec2 rotatedDiff = vec2(
        diff.x * cos(angle) - diff.y * sin(angle),
        diff.x * sin(angle) + diff.y * cos(angle)
    );
    float manhattanDist = abs(rotatedDiff.x) + abs(rotatedDiff.y);
    float segment = fract(manhattanDist * divisions - uTime * 0.01);
    vec3 distort = mix(mix(first, second, segment * 2.), mix(second, third, (segment - 0.5) / (1. - 0.5)), step(0.5, segment));
    st -= pow(distort.r, dist) / 10. * amount * normalize(diff);
    st += pow(distort.b, dist) / 10. * amount * normalize(diff);

    st = rot(uAngle * 2. * PI) * (st - pos) + pos;
    st = unscaleAspect(st);
    
    return StructFunc(st, distort);
}

StructFunc getStyle(vec2 st, vec2 pos, float divisions, float dist, float amount, vec3 first, vec3 second, vec3 third) {
  switch (uStyle) {
    case 0: return style0(st, pos, divisions, dist, amount, first, second, third); break;
    case 1: return style1(st, pos, divisions, dist, amount, first, second, third); break;
    case 2: return style2(st, pos, divisions, dist, amount, first, second, third); break;
    case 3: return style3(st, pos, divisions, dist, amount, first, second, third); break;
    case 4: return style4(st, pos, divisions, dist, amount, first, second, third); break;
    case 5: return style5(st, pos, divisions, dist, amount, first, second, third); break;
    default: return StructFunc(st, vec3(0.0)); break;
  }
}

vec4 blinds(vec2 st, float mDist) {
    float aspectRatio = uResolution.x / uResolution.y;
    vec2 pos = uPos + mix(vec2(0), (uMousePos - 0.5), uTrackMouse) * floor(uMixRadius);
    pos = scaleAspect(pos, aspectRatio);
    st = scaleAspect(st, aspectRatio);

    st = rotate(st - pos, -1. * uAngle * 2.0 * PI) + pos;

    vec3 first = vec3(1, 0, 0);
    vec3 second = vec3(0, 1, 0);
    vec3 third = vec3(0, 0, 1);
    float divisions = 2. + uFrequency * 30.;
    float dist = uDistortion * 4. + 1.;
    float amount = uAmount * mDist;

    StructFunc result = getStyle(st, pos, divisions, dist, amount, first, second, third);
    vec4 color = texture(uTexture, result.st);

    // #ifelseopen
    if (uDispersion > 0.) {
        vec2 offset = vec2(pow(result.distort.r, dist), pow(result.distort.b, dist)) * vec2(0.1) * amount * uDistortion;
        color.rgb = chromatic_abberation(result.st, offset * uDispersion);
    }
    // #ifelseclose

    return color;
}

out vec4 fragColor;

void main() {
    vec2 uv = vTextureCoord;
    float aspectRatio = uResolution.x / uResolution.y;

    vec2 mPos = uPos + mix(vec2(0), (uMousePos - 0.5), uTrackMouse);
    vec2 pos = uMixRadius == 1.00 ? mPos : uPos;
    float mDist = ease(uEasing, max(0., 1. - distance(uv * vec2(aspectRatio, 1), mPos * vec2(aspectRatio, 1)) * 4. * (1. - uMixRadius)));

    // #ifelseopen
    if (uMixRadiusInvert == 1) {
        mDist = max(0., (0.5 - mDist));
    }
    // #ifelseclose

    vec4 col = blinds(uv, mDist);

    ${computeFragColor('col')}
}`;

const blindsParams = {
  fragmentShader: blindsShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    frequency: {
      name: 'uFrequency',
      type: '1f',
      value: 0.5,
    },
    style: {
      name: 'uStyle',
      type: '1i',
      value: 0,
    },
    gradient: {
      name: 'uAmount',
      type: '1f',
      value: 1,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    distortion: {
      name: 'uDistortion',
      type: '1f',
      value: 0.5,
    },
    dispersion: {
      name: 'uDispersion',
      type: '1f',
      value: 0,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const BLINDS = {
  id: 'blinds',
  label: 'Blinds',
  params: blindsParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    style: {
      label: 'Style',
      value: 0,
      options: {
        0: 'Blinds',
        2: 'Tiles',
        3: 'Radial',
        4: 'Circles',
        5: 'Squares',
      },
      responsiveDisabled: true,
    },
    frequency: {
      label: 'Frequency',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the scale of the blinds pattern',
    },
    gradient: {
      label: 'Amount',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount of refraction',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    distortion: {
      label: 'Distortion',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Warps the refraction on a curve',
    },
    dispersion: {
      label: 'Dispersion',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Distorts the RGB channel on the edges with chromatic dispersion',
    },
    ...mixRadiusProperties,
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
