import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision mediump float;

in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform float uTime;
uniform float uScale;
uniform float uAmount;
uniform float uAngle;
uniform float uFlare;
uniform float uGlow;
uniform float uChroma;
uniform int uPoints;
uniform float uFrequency;
uniform float uThreshold;
uniform vec2 uPos;
uniform vec3 uTint;
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;

float luma(vec4 color) {
  return 0.299 * color.r + 0.587 * color.g + 0.114 * color.b;
}

// Pseudo-random number generator
float random(vec2 seed) {
  return fract(sin(dot(seed, vec2(12.9898, 78.233))) * 43758.5453);
}

vec2 hash(vec2 p) {
  p = vec2(dot(p, vec2(127.1, 311.7)), dot(p, vec2(269.5, 183.3)));
  return fract(sin(p) * 18.5453);
}

float cubicOut(float t) {
  float f = t - 1.0;
  return f * f * f + 1.0;
}

float exponentialOut(float t) {
  return t == 1.0 ? t : 1.0 - pow(2.0, -10.0 * t);
}

float circularOut(float t) {
  return sqrt((2.0 - t) * t);
}
const float PI = 3.14159265359;
mat2 rot(float a) {
  return mat2(cos(a), -sin(a), sin(a), cos(a));
}

vec3 hue(float h, float angle) {
  const float PI = 3.14159265358979323846;
  return vec3(0.5) + 0.5 * cos(h + 2.0 * PI * angle + vec3(0, 2, 4));
}

void main() {
  vec2 scaleRatio = vec2(1080) * vec2(uResolution.x/uResolution.y, 1);
  vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
  vec2 uv = (vTextureCoord - pos) * scaleRatio * uScale * 0.01 * rot(uAngle * PI * 2.);
  float time = floor(uTime * 0.5) * 2.;

  vec2 i_uv = floor(uv);
  vec2 f_uv = fract(uv);
  vec2 point = vec2(0);

  vec3 d = vec3(1e10); // Initialize to a large value
  vec2 closestPoint;

  for (int y = -1; y <= 1; y++) {
    for (int x = -1; x <= 1; x++) {
      vec2 tile_offset = vec2(float(x), float(y));
      vec2 o = hash(i_uv + tile_offset + vec2(time * 0.05));
      tile_offset += o - f_uv;

      float dist = dot(tile_offset, tile_offset);
      if (dist < d.x) {
        d.y = d.x; // Store the second closest distance
        d.x = dist; // Store the closest distance
        closestPoint = tile_offset;
        point = o;
      } else if (dist < d.y) {
        d.y = dist; // Update the second closest distance if needed
      }
    }
  }

  d = sqrt(d);
  float r = sqrt(d.x);

  // Create a single cross at the center of each Voronoi cell
  vec2 toCenter = closestPoint;
  vec2 toCenterRot45 = toCenter * rot(-PI/4.);

  vec2 closestPointOriginal = closestPoint * rot(-abs(uAngle) * PI * 2.) / (uScale * 0.01) / scaleRatio + vTextureCoord; // Rotate back

  // Sample the underlying texture at the closest point
  vec4 closestPointCol = texture(uTexture, closestPointOriginal);
  float closestPointR = luma(closestPointCol);
  float mixThresh = mix(1., closestPointR, uThreshold);
  float stepThresh = step(uThreshold, closestPointR);
  closestPointR = mixThresh;

  float crossShape;
  float crossShape2;

  if(uPoints > 0) {
    crossShape = min(abs(toCenter.x), abs(toCenter.y)); // Use min to make tines uniform
    crossShape2 = min(abs(toCenterRot45.x), abs(toCenterRot45.y));
  } else {
    crossShape = abs(toCenter.x), abs(toCenter.y); // Use min to make tines uniform
    crossShape2 = abs(toCenterRot45.x), abs(toCenterRot45.y);
  }

  // Adjust the size and brightness based on proximity to the nearest star
  float proximityFactor = sqrt(d.y) - sqrt(d.x);
  float radialGradient = (1.0 - length(toCenter)) * closestPointR * (uGlow*1.5);
  crossShape = 1.0 - smoothstep(-0.04, 0.04 * (proximityFactor - smoothstep(1., -0.5, uFlare)) * closestPointR * closestPointR, crossShape);
  
  if(uPoints == 2) {
    crossShape += 1.0 - smoothstep(-0.04, 0.04 * (proximityFactor - smoothstep(1., -0.5, uFlare)) * closestPointR * closestPointR, crossShape2);
  }

  vec3 cross = mix(vec3(crossShape), vec3(crossShape) * hue(closestPointR, proximityFactor*5.), uChroma);
  vec3 bloom = vec3(smoothstep(0., 4., radialGradient * proximityFactor));

  vec4 color = texture(uTexture, vTextureCoord);
  vec3 rgb = mix(uTint, mix(uTint, closestPointCol.rgb, 0.5), uThreshold);
  color.rgb += rgb * (cross + bloom) * 10.;
  ${computeFragColor('color')}
}
`;

const params = {
  fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.25,
    },
    flare: {
      name: 'uFlare',
      type: '1f',
      value: 0.5,
    },
    glow: {
      name: 'uGlow',
      type: '1f',
      value: 0.5,
    },
    tint: {
      name: 'uTint',
      type: '3f',
      value: new Vec3(1),
    },
    threshold: {
      name: 'uThreshold',
      type: '1f',
      value: 0.5,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    points: {
      name: 'uPoints',
      type: '1i',
      value: 1,
    },
    chroma: {
      name: 'uChroma',
      type: '1f',
      value: 0.25,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const STARS = {
  id: 'stars',
  label: 'Sparkle',
  params: params,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    points: {
      label: 'Points',
      value: 1,
      options: {
        0: 'Two',
        1: 'Four',
        2: 'Six',
      },
      responsiveDisabled: true,
    },
    flare: {
      label: 'Flare',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    glow: {
      label: 'Glow',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    chroma: {
      label: 'Chroma',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    threshold: {
      label: 'Threshold',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    tint: {
      label: 'Tint',
      value: new Vec3(1),
      output: 'color',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
