<script>
import ParamLabel from "./ParamLabel.vue";
import Icon from "./Icon.vue";

function appendThumbnailToImageUrl(url) {
    // Split the URL into parts
    let urlParts = url.split('/');
    
    // Get the last part (the file name)
    let fileName = urlParts.pop();
    
    // Split the file name into name and extension
    let [name, extension] = fileName.split('.');
    
    // Append "_@thumbnail" to the name
    let newFileName = `${name}_@thumbnail.${extension}`;
    
    // Reconstruct the URL
    urlParts.push(newFileName);
    let newUrl = urlParts.join('/');
    
    return newUrl;
}

export default {
  components: { ParamLabel, Icon },
  props: ['modelValue','label', 'tooltip', 'prop'],
  emits: ['replace-image', 'update:modelValue'],
  methods: {
    handleClick(e) {
      this.$emit('click-label', e);
    }
  },
  computed: {
    thumbSrc() {
      return appendThumbnailToImageUrl(this.modelValue.src)
    }
  },
};
</script>

<template>
  <div class="parameter parameter__block flex">
    <ParamLabel
      :prop="prop"
      :label="label"
      :tooltip="tooltip"
    >{{label}}</ParamLabel>
    <div class="selected-color-container flex align-center">
      <template v-if="modelValue?.thumb">
        <div class="mini-thumb" @click="$emit('replace-image')">
          <img :src="modelValue.thumb">
        </div>
        <div class="clip ml-2" :title="modelValue.name"  @click="$emit('replace-image')">{{ modelValue.name }}</div>
      </template>
      <template v-else>
        <div class="mini-thumb flex align-center justify-center" @click="$emit('replace-image')">
          <Icon icon="plus" />
        </div>
        <div class="ml-1">None selected</div>
      </template>
    </div>
  </div>
</template>