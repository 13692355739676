import {
  computeFragColor,
  CLIP_EFFECT,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  GAUSSIAN_WEIGHTS_36,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision mediump float;

// Want a quick intro?
// Select "Shader tutorial" from the dropdown above

in vec2 vTextureCoord;

uniform sampler2D uTexture; // Underlying scene
uniform sampler2D uBgTexture;
${UNIVERSAL_UNIFORMS}

uniform vec3 uTint;
uniform vec2 uPos;
uniform float uScale;
uniform int uPass;
uniform float uIntensity;
uniform float uAmbience;
uniform float uDiffusion;
uniform float uSpecular;

out vec4 fragColor;

const int kernelSize = 36;
${GAUSSIAN_WEIGHTS_36}


vec4 GaussianBlur(sampler2D tex, vec2 uv, vec2 direction) {
  vec4 color = vec4(0.0);
  float weightSum = 0.0;

  float amount = (uDiffusion + 0.1) * 2.;
  float aspectRatio = uResolution.x/uResolution.y;

  direction.x /= aspectRatio;

  color += texture(tex, uv) * getGaussianWeight(0);
  
  for (int i = 0; i < kernelSize; i++) {
    float x = float(i - kernelSize / 2) * amount;
    color += texture(tex, uv + vec2(x/1000.) * direction) * getGaussianWeight(i);
  }
  
  return color;
}

float angleBetweenVec2(vec2 A, vec2 B) {
    float dotProduct = dot(A, B);
    float magnitudeA = length(A);
    float magnitudeB = length(B);
    float cosTheta = dotProduct / (magnitudeA * magnitudeB);
    
    // Clamp the value to the domain of arccos, which is [-1, 1]
    cosTheta = clamp(cosTheta, -1.0, 1.0);
    
    float angle = acos(cosTheta);
    
    // Normalize to [0, 1] by dividing by pi
    float normalizedAngle = angle / 3.14159265359;
    
    return normalizedAngle;
}

  float getHeight(vec2 uv) {
    vec4 col = texture(uTexture, uv);
    return col.r;
  }

vec4 computeNoise(vec2 uv) {
    vec2 ste = (1. / uResolution);
    float height = getHeight(uv);
    
    vec2 dxy = height - vec2(getHeight(uv + vec2(ste.x, 0.)),
                             getHeight(uv + vec2(0., ste.y)));

    return vec4(normalize(vec3(dxy * (0.02 + 0.04 * uDiffusion) / ste,  2.)), height);
}

float random(vec2 seed) {
  return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

float circularOut(float t) {
    return sqrt((2.0 - t) * t);
  }

  vec3 Tonemap_tanh(vec3 x)
{
    x = clamp(x, -40.0, 40.0);
    return (exp(x) - exp(-x)) / (exp(x) + exp(-x));
}

float light(vec3 p, vec3 normal, vec3 lightPos) {
  vec3 direction = normalize(lightPos - p);
  float specular = 0.5 * pow(max(dot(direction, reflect(-direction, normal)), 0.0), 2.0);
  return max(0.1, dot(normal, direction) * 1.2 - specular);
}

vec4 getPointlight(vec2 uv) {
  vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
  vec2 aspectRatio = vec2(uResolution.x/uResolution.y, 1);
  vec4 color = texture(uBgTexture, uv);
  float luma = color.r;
  
  float dist = distance(uv * aspectRatio, pos * aspectRatio) / (uScale + 0.5);
  float hightlighDist = distance(uv * aspectRatio, pos * aspectRatio) / (uScale + 0.5)/2.;
  
  float spot = max(0., max(0., (1.-dist)) * max(0., (1.-dist)));
  float highlightSpot = max(0., max(0., (1.-hightlighDist)) * max(0., (1.-hightlighDist)));
  
  vec3 normal = computeNoise(uv).rgb*luma; 
  vec2 lightDir = pos * aspectRatio - uv * aspectRatio;
  
  float lightDistance = 1.-length(lightDir);
  lightDir = normalize(lightDir);
  
  vec3 diff = vec3(max(dot(normal, vec3(lightDir, 0.5)), 0.0));

  float specular = light(vec3(uv, 1.), normal, vec3(pos, 1.));
  specular = smoothstep(0., 1., specular) * 2.;
  
  float intensity = uIntensity * 3.;
  vec3 base = color.rgb * uTint * max(0., spot) * intensity;
  color.rgb = color.rgb * uAmbience + base * diff * uTint * max(0., spot) * intensity;
  color.rgb += specular * uTint * max(0., spot * 4.) * uSpecular;
  color.rgb = Tonemap_tanh(color.rgb);
  float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;
  color.rgb += dither;
  return color;
}

vec4 getColor(vec2 uv) {
  switch(uPass) {
    case 0: return GaussianBlur(uTexture, uv, vec2(1, 0)); break;
    case 1: return GaussianBlur(uTexture, uv, vec2(0, 1)); break;
    case 2: return getPointlight(uv); break;
    default: return getPointlight(uv);
  }
}

void main() {
  ${CLIP_EFFECT}

    vec2 uv = vTextureCoord;
    vec4 color = getColor(uv);

    ${computeFragColor('color')}
}`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.5,
    },
    intensity: {
      name: 'uIntensity',
      type: '1f',
      value: 0.5,
    },
    ambient: {
      name: 'uAmbience',
      type: '1f',
      value: 0.5,
    },
    specular: {
      name: 'uSpecular',
      type: '1f',
      value: 0.25,
    },
    diffusion: {
      name: 'uDiffusion',
      type: '1f',
      value: 0.5,
    },
    tint: {
      name: 'uTint',
      type: '3f',
      value: new Vec3(0.98, 0.12, 0.89),
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    pass: {
      name: 'uPass',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const POINTLIGHT = {
  id: 'spotlight',
  label: 'Point light',
  params: params,
  aspectRatio: 1,
  passes: [
    {
      prop: 'pass',
      value: 1,
    },
    {
      prop: 'pass',
      value: 2,
      includeBg: true,
    },
  ],
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the spread of the point light',
    },
    intensity: {
      label: 'Intensity',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ambient: {
      label: 'Ambience',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount of ambient light or color from the underlying scene',
    },
    specular: {
      label: 'Specular',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the intensity of the specular highlights',
    },
    tint: {
      label: 'Tint',
      output: 'color',
      value: new Vec3(0.98, 0.12, 0.89),
      responsiveDisabled: true,
    },
    diffusion: {
      label: 'Diffusion',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Smooths the surface of the objects in your scene reflecting light',
    },
    ...interactiveProperties,
  },
};
