import { Vec2 } from 'curtainsjs';
import { vertexShader, FLOATING_POINT } from '../ShaderHelpers.js';

const fragmentShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uLogoTexture;
uniform vec2 uResolution;
uniform float uDpi;

out vec4 fragColor;

void main() {
    float scale = 512./uResolution.x;
    vec2 logoSize = vec2(202.0, 47.0) * uDpi;
    vec2 logoPos = vec2((uResolution.x - logoSize.x) / 2.0, 25.0 * uDpi);

    // Fragment coordinates in screen space
    vec2 fragCoord = gl_FragCoord.xy;
    vec2 logoTopLeft = vec2(logoPos.x, logoPos.y);

    // Sample the main texture
    vec4 color = texture(uTexture, vTextureCoord);

    vec2 uv = (fragCoord - logoTopLeft) / logoSize;
    vec4 logoColor = texture(uLogoTexture, uv);

    // Blend the logo color with the main texture color
    color = mix(color, logoColor, logoColor.a);

    fragColor = color;
}


`;

export const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depth: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },

  uniforms: {
    resolution: {
      name: 'uResolution',
      type: '2f',
      value: new Vec2(1080),
    },
    dpi: {
      name: 'uDpi',
      type: '1f',
      value: 1.5,
    },
  },
};

export const FREE_LOGO = {
  id: 'freeLogo',
  label: 'Logo',
  texture: {
    src: 'https://assets.unicorn.studio/media/free_user_logo.png',
    sampler: 'uLogoTexture',
  },
  params: params,
};
