import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  BLEND,
  universalUniformParams,
} from '../ShaderHelpers.js';
import { BLEND_MODES } from '../../scripts/Constants.js';
import { Vec2 } from 'curtainsjs';

const grainShader = `#version 300 es
  precision mediump float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;

  uniform sampler2D uTexture;
  uniform float uTime;
  uniform float uAmount;
  uniform float uNoise;
  uniform int uRgb;
  uniform int uBlendMode;
  ${UNIVERSAL_UNIFORMS}
  ${BLEND}

  //https://www.shadertoy.com/view/ltB3zD
  const float PHI = 1.61803398874989484820459; // Φ = Golden Ratio 
  float gold_noise(in vec2 xy, in float seed)
  {
      return fract(tan(distance(xy*PHI, xy)*seed)*xy.x);
  }

  out vec4 fragColor;

  void main() {
    vec2 uv = vTextureCoord;
    vec4 color = texture(uTexture, uv);

    if(color.a == 0.) {
      fragColor = vec4(0);
      return;
    }

    vec2 st = uv;
    vec3 grainRGB = vec3(0);

    st *= uResolution;

    float delta = fract((floor(uTime)/20.));

    if(uRgb == 1) {
      grainRGB = vec3(
        gold_noise(st, delta + 1.),
        gold_noise(st, delta + 2.),
        gold_noise(st, delta + 3.)
      );
    } else {
      grainRGB = vec3(gold_noise(st, delta + 1.));
    }
    
    if(uBlendMode > 0) {
      color.rgb = mix(color.rgb, blend(uBlendMode, grainRGB, color.rgb), uAmount);
    }
          
    ${computeFragColor('color')}
  }
`;

const grainParams = {
  fragmentShader: grainShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    time: {
      name: 'uTime',
      type: '1f',
      value: 1,
    },
    intensity: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    rgb: {
      name: 'uRgb',
      type: '1i',
      value: 0,
    },
    blendMode: {
      name: 'uBlendMode',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const GRAIN = {
  id: 'grain',
  label: 'Grain',
  params: grainParams,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  dpi: 2,
  properties: {
    intensity: {
      label: 'Amount',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    blendMode: {
      label: 'Blend mode',
      value: 'ADD',
      options: BLEND_MODES,
      responsiveDisabled: true,
    },
    rgb: {
      label: 'RGB',
      value: 0,
      options: [
        { value: 0, label: 'Off' },
        { value: 1, label: 'On' },
      ],
      classic: true,
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
