import {
  computeFragColor,
  vertexShader,
  EASE,
  EASING_FUNCTIONS,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const bokehShader = `#version 300 es
precision lowp float;
in vec3 vVertexPosition;
in vec2 vTextureCoord;
uniform sampler2D uTexture;
uniform float uAmount;
uniform int uEasing;
uniform float uTilt;
uniform float uTime;
uniform vec2 uPos;

${UNIVERSAL_UNIFORMS}
${EASE}

const float PI = 3.14159265;
const int SAMPLES = 128;
vec2 getDiskSample(int index) {
  switch(index) {
      case 0: return vec2(0, 0);
      case 1: return vec2(0.7062550187110901, 0.03469608351588249);
      case 2: return vec2(0.49759235978126526, 0.0490085706114769);
      case 3: return vec2(0.8566519618034363, 0.1270723193883896);
      case 4: return vec2(0.34675997495651245, 0.06897484511137009);
      case 5: return vec2(0.7668770551681519, 0.19209270179271698);
      case 6: return vec2(0.5860038995742798, 0.17776232957839966);
      case 7: return vec2(0.880733847618103, 0.31513160467147827);
      case 8: return vec2(0.2309698760509491, 0.09567085653543472);
      case 9: return vec2(0.6779919862747192, 0.3206663131713867);
      case 10: return vec2(0.49300897121429443, 0.2635187804698944);
      case 11: return vec2(0.7731460928916931, 0.46340593695640564);
      case 12: return vec2(0.3600369095802307, 0.24056896567344666);
      case 13: return vec2(0.6659845113754272, 0.4939277768135071);
      case 14: return vec2(0.5112983584403992, 0.41961172223091125);
      case 15: return vec2(0.7174228429794312, 0.6502341628074646);
      case 16: return vec2(0.125, 0.125);
      case 17: return vec2(0.4894784986972809, 0.5400562882423401);
      case 18: return vec2(0.336437851190567, 0.40995070338249207);
      case 19: return vec2(0.5265287756919861, 0.7099418640136719);
      case 20: return vec2(0.21960841119289398, 0.32866722345352173);
      case 21: return vec2(0.41647082567214966, 0.694839596748352);
      case 22: return vec2(0.3004576563835144, 0.5621167421340942);
      case 23: return vec2(0.40702033042907715, 0.8605721592903137);
      case 24: return vec2(0.11717239022254944, 0.28287917375564575);
      case 25: return vec2(0.25959107279777527, 0.725508451461792);
      case 26: return vec2(0.17019447684288025, 0.5610560178756714);
      case 27: return vec2(0.2231915444135666, 0.8910306096076965);
      case 28: return vec2(0.0912451446056366, 0.45872029662132263);
      case 29: return vec2(0.12439680844545364, 0.8386151790618896);
      case 30: return vec2(0.06710775196552277, 0.6813564300537109);
      case 31: return vec2(0.048294905573129654, 0.9830654263496399);
      case 32: return vec2(7.654042828657299e-18, 0.125);
      case 33: return vec2(-0.03523404151201248, 0.7172054052352905);
      case 34: return vec2(-0.05051687732338905, 0.5129064917564392);
      case 35: return vec2(-0.1283891648054123, 0.8655294179916382);
      case 36: return vec2(-0.0731588676571846, 0.3677944839000702);
      case 37: return vec2(-0.19447903335094452, 0.7764038443565369);
      case 38: return vec2(-0.18142792582511902, 0.598087728023529);
      case 39: return vec2(-0.3179328143596649, 0.8885627388954163);
      case 40: return vec2(-0.10696326941251755, 0.2582321763038635);
      case 41: return vec2(-0.3250895142555237, 0.6873440146446228);
      case 42: return vec2(-0.27002641558647156, 0.5051838755607605);
      case 43: return vec2(-0.4678405523300171, 0.7805448174476624);
      case 44: return vec2(-0.2503921091556549, 0.37473827600479126);
      case 45: return vec2(-0.4995090663433075, 0.6735100150108337);
      case 46: return vec2(-0.4270390570163727, 0.5203486084938049);
      case 47: return vec2(-0.6556304097175598, 0.7233766317367554);
      case 48: return vec2(-0.1530931144952774, 0.1530931144952774);
      case 49: return vec2(-0.547940731048584, 0.49662455916404724);
      case 50: return vec2(-0.4211843013763428, 0.34565702080726624);
      case 51: return vec2(-0.7170061469078064, 0.5317679643630981);
      case 52: return vec2(-0.3447090983390808, 0.23032724857330322);
      case 53: return vec2(-0.7030628323554993, 0.42139965295791626);
      case 54: return vec2(-0.5728246569633484, 0.3061811625957489);
      case 55: return vec2(-0.867959201335907, 0.41051411628723145);
      case 56: return vec2(-0.3055444359779358, 0.12656064331531525);
      case 57: return vec2(-0.7349926233291626, 0.2629845440387726);
      case 58: return vec2(-0.5736655592918396, 0.17401954531669617);
      case 59: return vec2(-0.8992430567741394, 0.22524864971637726);
      case 60: return vec2(-0.47482064366340637, 0.09444769471883774);
      case 61: return vec2(-0.8476815819740295, 0.12574167549610138);
      case 62: return vec2(-0.692619264125824, 0.06821703910827637);
      case 63: return vec2(-0.9909616708755493, 0.04868282377719879);
      case 64: return vec2(-0.0883883461356163, 1.0824450754283193e-17);
      case 65: return vec2(-0.7117512822151184, -0.03496609628200531);
      case 66: return vec2(-0.5053074359893799, -0.04976843670010567);
      case 67: return vec2(-0.8611021637916565, -0.12773244082927704);
      case 68: return vec2(-0.35743197798728943, -0.07109764218330383);
      case 69: return vec2(-0.7716551423072815, -0.19328954815864563);
      case 70: return vec2(-0.5920765995979309, -0.17960448563098907);
      case 71: return vec2(-0.8846569657325745, -0.3165353238582611);
      case 72: return vec2(-0.2449805587530136, -0.10147427022457123);
      case 73: return vec2(-0.6826840043067932, -0.3228854835033417);
      case 74: return vec2(-0.49913355708122253, -0.2667924463748932);
      case 75: return vec2(-0.7768542766571045, -0.4656285345554352);
      case 76: return vec2(-0.36746111512184143, -0.2455296814441681);
      case 77: return vec2(-0.6697578430175781, -0.49672624468803406);
      case 78: return vec2(-0.5158433318138123, -0.42334166169166565);
      case 79: return vec2(-0.7204058766365051, -0.6529378294944763);
      case 80: return vec2(-0.1397542506456375, -0.1397542506456375);
      case 81: return vec2(-0.4930644631385803, -0.5440127849578857);
      case 82: return vec2(-0.34107857942581177, -0.41560545563697815);
      case 83: return vec2(-0.529154896736145, -0.7134827375411987);
      case 84: return vec2(-0.22503165900707245, -0.33678367733955383);
      case 85: return vec2(-0.41894248127937317, -0.6989632844924927);
      case 86: return vec2(-0.3033328950405121, -0.5674959421157837);
      case 87: return vec2(-0.40877094864845276, -0.8642735481262207);
      case 88: return vec2(-0.12195689231157303, -0.2944299876689911);
      case 89: return vec2(-0.26129332184791565, -0.7302659153938293);
      case 90: return vec2(-0.17211763560771942, -0.567395806312561);
      case 91: return vec2(-0.22422246634960175, -0.8951462507247925);
      case 92: return vec2(-0.09286022931337357, -0.46683987975120544);
      case 93: return vec2(-0.12507104873657227, -0.8431605696678162);
      case 94: return vec2(-0.06766466796398163, -0.6870108842849731);
      case 95: return vec2(-0.04848925396800041, -0.9870214462280273);
      case 96: return vec2(-2.81227478546514e-17, -0.1530931144952774);
      case 97: return vec2(0.03549996390938759, -0.7226183414459229);
      case 98: return vec2(0.05125438794493675, -0.5203945636749268);
      case 99: return vec2(0.12904255092144012, -0.8699342012405396);
      case 100: return vec2(0.0751635953783989, -0.37787291407585144);
      case 101: return vec2(0.1956612914800644, -0.7811236381530762);
      case 102: return vec2(0.18323321640491486, -0.6040389537811279);
      case 103: return vec2(0.31932422518730164, -0.8924514055252075);
      case 104: return vec2(0.11218402534723282, -0.27083620429039);
      case 105: return vec2(0.3272787034511566, -0.6919726729393005);
      case 106: return vec2(0.27322208881378174, -0.5111625790596008);
      case 107: return vec2(0.4700421690940857, -0.7842180132865906);
      case 108: return vec2(0.25516191124916077, -0.38187679648399353);
      case 109: return vec2(0.5022764205932617, -0.6772413849830627);
      case 110: return vec2(0.43070468306541443, -0.5248152017593384);
      case 111: return vec2(0.6583119034767151, -0.7263352870941162);
      case 112: return vec2(0.16535945236682892, -0.16535945236682892);
      case 113: return vec2(0.5518407225608826, -0.5001592636108398);
      case 114: return vec2(0.4266902208328247, -0.3501756191253662);
      case 115: return vec2(0.720512330532074, -0.5343683362007141);
      case 116: return vec2(0.35245633125305176, -0.23550379276275635);
      case 117: return vec2(0.7071385979652405, -0.42384254932403564);
      case 118: return vec2(0.5781042575836182, -0.30900317430496216);
      case 119: return vec2(0.8716292381286621, -0.41224992275238037);
      case 120: return vec2(0.31626853346824646, -0.13100272417068481);
      case 121: return vec2(0.7396891117095947, -0.26466497778892517);
      case 122: return vec2(0.5798675417900085, -0.1759008914232254);
      case 123: return vec2(0.9033212661743164, -0.22627019882202148);
      case 124: return vec2(0.4826694428920746, -0.09600891917943954);
      case 125: return vec2(0.8521785736083984, -0.12640875577926636);
      case 126: return vec2(0.6981825828552246, -0.06876497715711594);
      case 127: return vec2(0.9948862791061401, -0.04887562617659569);
      default: return vec2(0.0);
  }
}

vec4 bokehBlur(vec2 uv, float blurRadius, float intensity) {
  float aspectRatio = uResolution.x / uResolution.y;
  vec3 accumulatedColor = vec3(0.0);
  vec3 accumulatedWeights = vec3(0.0);
  vec2 pixelSize = vec2(1.0 / aspectRatio, 1.0) * blurRadius * 0.075;
  float accumulatedAlpha = 0.;

  for (int i = 0; i < 128; i++) {
      vec2 sampleOffset = getDiskSample(i) * pixelSize;
      vec4 colorSample = texture(uTexture, uv + sampleOffset);
      vec3 bokehWeight = vec3(5.0) + pow(colorSample.rgb, vec3(9.0)) * intensity;
      accumulatedAlpha += colorSample.a;

      accumulatedColor += colorSample.rgb * bokehWeight;
      accumulatedWeights += bokehWeight;
  }

  return vec4(accumulatedColor / accumulatedWeights, accumulatedAlpha / 128.0);
}

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;
  vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
  float dis = distance(uv, pos) * 1000.;
  float tilt = mix(1.-dis * 0.001 , dis * 0.001, uTilt);
  vec4 bg = texture(uTexture, uv);

  if(uAmount == 0.) {
    vec4 color = texture(uTexture, uv);
    ${computeFragColor('color')}
    return;
  }

  vec4 color = bokehBlur(uv, uAmount * ease(uEasing, tilt), 150.0);
  ${computeFragColor('color')}
}
`;

const bokehParams = {
  fragmentShader: bokehShader,
  crossorigin: 'Anonymous',
  vertexShader,
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    radius: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    tilt: {
      name: 'uTilt',
      type: '1f',
      value: 0,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const BOKEH = {
  id: 'bokeh',
  label: 'Bokeh',
  params: bokehParams,
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    radius: {
      label: 'Radius',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    tilt: {
      label: 'Tilt',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    easing: {
      label: 'Easing func',
      value: 0,
      options: Object.keys(EASING_FUNCTIONS).map((n, index) => n.split('_').join(' ').toLowerCase()),
    },
    ...interactiveProperties,
  },
};
