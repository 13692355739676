<script>
import ClassicToggle from './ClassicToggle.vue';
import SliderParam from './SliderParam.vue';
import InputField from './InputField.vue';
import DropdownMenu from './DropdownMenu.vue';
import ParamLabel from './ParamLabel.vue';
import ColorInput from './ColorInput.vue';

export default {
  components: {
    ClassicToggle,
    SliderParam,
    InputField,
    ParamLabel,
    DropdownMenu,
    ColorInput
  },
  props: ['item'],
  emits: ['input', 'change', 'edit-fill'],
  methods: {
    handleAlphaChange(e) {
      if(!(e instanceof Event)) {
        this.item.maskAlpha = e;
        this.$emit('change');
      }
    },
    handleColorChange(e) {
      if(!(e instanceof Event)) {
        this.item.maskBackground = e;
        this.$emit('change');
      }
    },
    toggleMask() {
      this.item.toggleMask();
      this.$emit('change');
    }
  }
}
</script>

<template>
  <span class="w-100 mb-1">
  <ClassicToggle label="Mask"
      :value="item.mask"
      @change="toggleMask"
  ></ClassicToggle>
  <div v-if="item.mask" class="parameter parameter__block child-block">
    <ParamLabel
      prop="prop.propName"
      label="Depth"
      tooltip="The type of mask behavior"
    >Type</ParamLabel>
    <DropdownMenu
      v-model="item.maskDepth"
      :options="{0: 'All', 1: 'Previous layer', 2: 'Knockout'}"
      :rolloverPreview="true"
      @update:modelValue="$emit('change')"
    ></DropdownMenu>
  </div>
  <ClassicToggle v-if="item.mask" class="child-block mt-1" label="Background"
      v-model="item.maskAlpha"
      tooltip="This sets the background of the area the mask hides"
      @change="$emit('change')"
  ></ClassicToggle>
  <div class="child-block parameter parameter__block mb-4" v-if="item.mask && item.maskAlpha">
    <ParamLabel label="Color">Color</ParamLabel>
    <div class="flex w-100 ml-auto" style="max-width: 16rem;">
      <ColorInput
        :fill="item.maskBackground"
        vec3="Vec3"
        @change="handleColorChange"
        @click-swatch="$emit('edit-fill')"
      ></ColorInput>
    </div>
  </div>
  </span>
</template>

<style lang="scss" scoped>

.child-block {
  width: calc(100% - 1rem) !important;
  margin-left: var(--unit2);
}

</style>