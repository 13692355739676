import {
  computeFragColor,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  EASE,
  mixRadiusProperties,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const vertexShader = `#version 300 es
  precision mediump float;
  in vec3 aVertexPosition;
  in vec2 aTextureCoord;
  uniform mat4 uMVMatrix;
  uniform mat4 uPMatrix;

  out vec3 vVertexPosition;
  out vec2 vTextureCoord;

  uniform float uAmplitude;
  uniform float uFrequency;
  uniform float uFalloff;
  uniform vec2 uPos;
  uniform int uEasing;
  uniform vec2 uPreviousMousePos;
  uniform float uTime;
  uniform float uMixRadius;
  uniform int uMixRadiusInvert;
  ${UNIVERSAL_UNIFORMS}
  ${EASE}

  void main() {	
    vec2 aspectRatio = vec2(uResolution.x/uResolution.y, 1);
    float freq = uFrequency * 50.;
    vec3 vertexPosition = aVertexPosition * 0.5;
    vec2 pos = uPos + mix(vec2(0), uMousePos - 0.5, uTrackMouse);
    float dist = length((vertexPosition.xy) * aspectRatio - (pos - 0.5) * aspectRatio);
    
    float easeDist = max(0.,1. - dist - (1. - uMixRadius));

    // #ifelseopen
    if (uMixRadiusInvert == 1) {
      easeDist = max(0., (0.5 - easeDist));
    }
    // #ifelseclose

    float easeFactor = ease(uEasing, easeDist);
    float strength = uAmplitude * 0.2 * easeFactor;

    float wave = sin(dist * freq - uTime * 0.05) * strength;
    vertexPosition = vertexPosition + normalize(vertexPosition + vec3(1.-pos - 0.5, 0)) * wave;
    vertexPosition.z = wave;
    
    gl_Position = uPMatrix * uMVMatrix * vec4(aVertexPosition, 1.0);

    vTextureCoord = aTextureCoord;
    vVertexPosition = vertexPosition;
  }
`;

const rippleShader = `#version 300 es
  precision mediump float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;

  uniform sampler2D uTexture;
  uniform float uTime;
  uniform float uAmplitude;
  uniform float uFrequency;
  uniform float uFalloff;
  uniform int uEasing;
  uniform vec2 uPos;
  ${UNIVERSAL_UNIFORMS}

  out vec4 fragColor;

  vec2 perspectiveUV(vec2 uv) {
    float aspectRatio = uResolution.x/uResolution.y;
    vec2 centeredUV = uv - 0.5;
    centeredUV.x *= aspectRatio;
    float strength = 1.0 + vVertexPosition.z * 2.;
    vec2 perspectiveUV = centeredUV / strength;
    perspectiveUV.x /= aspectRatio;
    perspectiveUV += 0.5;
    return perspectiveUV;
  }

  void main() {
    // Normalize texture coordinates
    vec2 uv = vVertexPosition.xy + 0.5;
    uv = perspectiveUV(uv);
    
    vec4 color = texture(uTexture, uv);

    // Set the output color
    ${computeFragColor('color')};
  }
`;

const rippleParams = {
  fragmentShader: rippleShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  widthSegments: 250,
  heightSegments: 250,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    frequency: {
      name: 'uFrequency',
      type: '1f',
      value: 0.5,
    },
    amplitude: {
      name: 'uAmplitude',
      type: '1f',
      value: 0.5,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    phase: {
      name: 'phase',
      type: '1f',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    previousMousePos: {
      name: 'uPreviousMousePos',
      type: '2f',
      value: new Vec2(0.5),
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const RIPPLE = {
  id: 'ripple',
  label: 'Ripple',
  params: rippleParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    frequency: {
      label: 'Frequency',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amplitude: {
      label: 'Amplitude',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...mixRadiusProperties,
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
