import { Vec2, Vec3 } from 'curtainsjs';
import {
  vertexShader,
  UNIVERSAL_UNIFORMS,
  FLOATING_POINT,
  universalUniformParams,
  interactiveProperties,
  computeFragColor,
} from '../ShaderHelpers.js';

const fragmentShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform vec3 uColor1;
uniform vec3 uColor2;
uniform float uAmplitude;
uniform float uScale;
uniform float uTime;
uniform vec2 uPos;
uniform vec3 uSunPos;
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;

// Hash function used for generating pseudorandom values
vec2 hash(vec2 p) {
    p = vec2(dot(p, vec2(127.1, 311.7)),
             dot(p, vec2(269.5, 183.3)));
    return -1.0 + 2.0 * fract(sin(p) * 43758.5453123);
}

// 2D Perlin noise function
float noise(vec2 st) {
    vec2 i = floor(st);
    vec2 f = fract(st);

    // Four corners in 2D of a tile
    float a = dot(hash(i), f - vec2(0.0, 0.0));
    float b = dot(hash(i + vec2(1.0, 0.0)), f - vec2(1.0, 0.0));
    float c = dot(hash(i + vec2(0.0, 1.0)), f - vec2(0.0, 1.0));
    float d = dot(hash(i + vec2(1.0, 1.0)), f - vec2(1.0, 1.0));

    // Smooth interpolation
    vec2 u = f * f * (3.0 - 2.0 * f);

    // Mix results
    return mix(a, b, u.x) +
           (c - a) * u.y * (1.0 - u.x) +
           (d - b) * u.x * u.y;
}

// FBM function
float fbm(vec2 st) {
    float value = 0.0;
    float amplitude = uAmplitude * 3.;
    float frequency = mix(0.01, 0.2, uScale);

    for (int i = 0; i < 4; i++) {
        value += amplitude * noise(st * frequency);
        frequency *= 2.0;
        amplitude *= 0.8;
    }

    return value;
}

// Get terrain height at position xz
float getHeight(vec2 xz) {
  return texture(uTexture, fract(xz * 0.01 + vec2(0.1, uTime * 0.002))).r * uAmplitude * 3.;
  //return fbm(xz + vec2(0.1, uTime * 0.1));
}

// Signed distance function for the scene
float map(vec3 p) {
    float height = getHeight(p.xz);
    return p.y - height;
}

// Estimate normal at point p
vec3 getNormal(vec3 p) {
    float eps = 0.005;
    vec3 n = vec3(
        map(p + vec3(eps, 0.0, 0.0)) - map(p - vec3(eps, 0.0, 0.0)),
        map(p + vec3(0.0, eps, 0.0)) - map(p - vec3(0.0, eps, 0.0)),
        map(p + vec3(0.0, 0.0, eps)) - map(p - vec3(0.0, 0.0, eps))
    );
    return normalize(n);
}

// Function to calculate sun color and intensity
vec3 getSunColor(vec3 rayDir) {
    vec3 sunDir = normalize(uSunPos); // Sun direction
    vec3 sunColor = vec3(1.0, 0.9, 0.7); // Warm sun color
    float sunIntensity = 1.0; // Adjust this to make the sun brighter or dimmer
    float sunSize = 0.2; // Adjust this to change the size of the sun
    
    float sunDot = dot(rayDir, sunDir);
    float sunDisk = smoothstep(1.0 - sunSize, 1.0, sunDot);
    
    return sunColor * sunDisk * sunIntensity;
}

void main() {
    // Compute normalized coordinates (from -1 to 1)
    vec2 uv = (gl_FragCoord.xy / uResolution.xy) * 2.0 - 1.0;
    float aspectRatio = uResolution.x / uResolution.y;
    uv *= vec2(aspectRatio, 1);

    // Set up camera parameters
    
    vec3 cameraPos = vec3(uPos.x, 3.0 * uPos.y, -5.0 * 10.0); // Adjust camera position with mouse
    vec3 target = vec3((uMousePos.x - 0.5) * 20., uMousePos.y * 10., 0.0);     // Target position
    vec3 up = vec3(0.0, 1.0, 0.0);         // Up vector

    // Compute camera basis vectors
    vec3 forward = normalize(target - cameraPos);
    vec3 right = normalize(cross(forward, up));
    vec3 camUp = cross(right, forward);

    // Compute ray direction
    float fov = 45.0;
    float tanFov = tan(radians(fov * 0.5));

    vec3 rayDir = normalize(forward + uv.x * tanFov * aspectRatio * right + uv.y * tanFov * camUp);

    // Raymarching parameters
    float maxDistance = 100.0;
    int maxSteps = 64;
    float epsilon = 0.01;

    // Initialize distance traveled
    float totalDistance = 0.0;
    vec3 position;

    // Perform raymarching
    for (int i = 0; i < maxSteps; i++) {
        position = cameraPos + totalDistance * rayDir;
        float height = getHeight(position.xz);

        float distance = map(position);
        if (distance < epsilon) {
            break;
        }
        
        totalDistance += distance * 0.9;
        if (totalDistance > maxDistance) {
            break;
        }
    }

    // Check if we hit something
    if (totalDistance > maxDistance) {
        vec3 skyColor = uColor2;
        vec3 sunColor = getSunColor(rayDir);
        fragColor = vec4(skyColor + sunColor, 1.0); // Sky color with sun
    } else {
        // Compute normal
        vec3 normal = getNormal(position);

        // Compute lighting
        vec3 lightDir = normalize(uSunPos); // Directional light
        float diffuse = max(dot(normal, lightDir), 0.0);

        // Simple shading
        vec3 baseColor = mix(uColor1, uColor2, position.y / uAmplitude * 0.25); // Color gradient based on height
        vec3 color = baseColor * diffuse;

        // Fog effect
        float fog = exp(-totalDistance * 0.02);
        color = mix(uColor2, color, fog); // Blend with sky color
        vec4 col = vec4(color, 1.0);

        ${computeFragColor('col')}
    }
}`;

export const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depth: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },

  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.5,
    },
    amplitude: {
      name: 'uAmplitude',
      type: '1f',
      value: 0.5,
    },
    skyColor: {
      name: 'uColor2',
      type: '3f',
      value: new Vec3(0.98, 0.12, 0.89),
    },
    landColor: {
      name: 'uColor1',
      type: '3f',
      value: new Vec3(0.98, 0.12, 0.89),
    },
    sunPos: {
      name: 'uSunPos',
      type: '3f',
      control: 'rotation',
      value: new Vec3(0.5, 0.6, 0.3),
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const LANDSCAPE = {
  id: 'landscape',
  label: 'Landscape',
  params: params,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Resolution of the cells',
    },
    amplitude: {
      label: 'Amplitude',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Resolution of the cells',
    },
    skyColor: {
      label: 'Sky color',
      value: new Vec3(1),
      output: 'color',
      responsiveDisabled: true,
    },
    landColor: {
      label: 'Land color',
      value: new Vec3(1, 0, 1),
      output: 'color',
      responsiveDisabled: true,
    },
    sunPos: {
      header: 'Sun',
      label: 'Position',
      value: new Vec3(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      control: 'rotation',
      output: 'percent',
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
