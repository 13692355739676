import axios from 'axios';
import { reactive, computed } from 'vue';
import { FirebaseStore } from './FirebaseStore.js';
import { auth } from '../firestore/init.js';
import { signOut } from 'firebase/auth';

export const UserStore = reactive({
  id: '',
  email: '',
  designCount: 0,
  subscriptions: [],
  fetchedSubscriptions: false,
  trialEndDate: null,
  hasActiveTrial: computed(() => {
    const now = new Date();
    return UserStore.trialEndDate && new Date(UserStore.trialEndDate) > now;
  }),
  trialEnded: computed(() => {
    const now = new Date();
    return UserStore.trialEndDate && new Date(UserStore.trialEndDate) <= now;
  }),
  hasSubscription: computed(() => {
    return UserStore.getActiveSubscription() || UserStore.hasPaidButCancelledSubscription();
  }),
  hasProAcess: computed(() => {
    return (
      UserStore.hasActiveTrial ||
      UserStore.grandFathered ||
      UserStore.getActiveSubscription() ||
      UserStore.hasPaidButCancelledSubscription()
    );
  }),

  setUser(user) {
    this.email = user.email;
    this.id = user.uid;
    this.firstName = user.displayName ? user.displayName.split(' ')[0] : '';
    this.lastName = user.displayName ? user.displayName.split(' ')[1] : '';
    this.avatar = user.reloadUserInfo?.photoUrl || user.photoURL || null;
    this.provider = user.providerData.find(n => n.providerId === 'google.com') ? 'google' : 'email';
    this.password = user.providerData.find(n => n.providerId === 'password');

    // whats up here? new users seem to have pro
    this.grandFathered = new Date(user.metadata.creationTime) < new Date(2024, 4, 23);
  },
  getUser() {
    FirebaseStore.getUser()
      .then(doc => {
        let userData = doc.data();
        if (userData) {
          this.trialEndDate = userData.trialEndDate;
          this.firstName = userData.firstName || this.firstName;
          this.lastName = userData.lastName || this.lastName;
          this.fetchSubscriptions();
        } else {
          this.fetchedSubscriptions = true;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  async fetchSubscriptions() {
    return await FirebaseStore.getSubscriptions()
      .then(resp => {
        this.fetchedSubscriptions = true;
        let subscriptions = [];
        resp.docs.forEach(doc => {
          let sub = {
            id: doc.id,
            ...doc.data(),
          };
          subscriptions.push(sub);
        });
        this.subscriptions = subscriptions;
        return subscriptions;
      })
      .catch(err => {
        this.fetchedSubscriptions = true;
        console.log(err);
      });
  },
  hasPaidButCancelledSubscription() {
    return this.subscriptions.find(n => n.status === 'cancelled' && new Date(n.renewsAt) > new Date());
  },
  hasCancelledSubscription() {
    return this.subscriptions.find(n => n.status === 'cancelled' && new Date(n.renewsAt) < new Date());
  },
  getActiveSubscription() {
    return this.subscriptions.find(n => n.status === 'active');
  },
  signOut() {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        // An error happened.
      });
  },
});
