import { Vec3, Vec2 } from 'curtainsjs';
import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  GAUSSIAN_WEIGHTS_36,
  universalUniformParams,
} from '../ShaderHelpers.js';

const fragmentShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uBlueNoise;
uniform sampler2D uBgTexture;
uniform float uAmount;
uniform float uExposure;
uniform float uDirection;
uniform float uStretch;
uniform int uPass;
uniform vec2 uPos;
uniform vec3 uTint;
uniform float uTime;
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;

const float PI2 = 6.28318530718; // 2 * PI
const float TAU = 6.28318530718; // 2 * PI, same as PI2

float random(vec2 seed) {
  return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

float easeOutQuart(float x) {
  return 1. - pow(1. - x, 4.);
}

float luma(vec4 color) {
  return dot(color.rgb, vec3(0.299, 0.587, 0.114));
}

const int kernelSize = 36;
${GAUSSIAN_WEIGHTS_36}

vec4 GaussianBlur(sampler2D tex, vec2 uv, vec2 direction) {
  vec4 color = vec4(0.0);
  float weightSum = 0.0;

  float y = max(0., distance(uv.y, uPos.y));

  float radius = uPass >= 2 ? 8. : 4.;

  float amount = (uAmount + 0.1) * radius * y * 4.;
  float aspectRatio = uResolution.x/uResolution.y;

  direction.x /= aspectRatio;

  color += texture(tex, uv) * getGaussianWeight(0);
  
  for (int i = 0; i < kernelSize; i++) {
    float x = float(i - kernelSize / 2) * amount;
    color += texture(tex, uv + vec2(x/1000.) * direction) * getGaussianWeight(i);
  }
  
  return color;
}

vec4 blurPass(vec2 uv) {
  float aspectRatio = uResolution.x / uResolution.y;
  vec2 direction = vec2(0);

  if (uPass % 2 == 1) {
      direction = vec2(1,0);
      direction.x *= (1.-uDirection);
  } else {
      direction = vec2(0,1);
  }

  uv = mix(uv, (uv + vec2(0., uPos.y)) * vec2(1., 0.5), uStretch - 0.5);


  return GaussianBlur(uTexture, uv, direction);
}

vec4 reflectAndMix(vec4 originalColor, vec4 blurredColor, float yPos) {
  // Calculate the reflected y-coordinate
  float reflectedY = yPos - (vTextureCoord.y - yPos);

  // Ensure reflectedY stays within valid texture coordinate boundaries
  reflectedY = clamp(reflectedY, 0., 1.);

  vec2 reflectedCoord = vec2(vTextureCoord.x, reflectedY);

  vec4 reflectedColor = texture(uTexture, reflectedCoord);

  reflectedColor.rgb = mix(uTint, reflectedColor.rgb, uExposure);

  float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;
  reflectedColor.rgb += dither;

  // Mixing based on the step function to switch at yPos
  return mix(originalColor, reflectedColor, step(vTextureCoord.y, yPos));
}

void main() {  
  vec2 uv = vTextureCoord;
  vec4 color = texture(uTexture, uv);
  vec4 blurredColor = blurPass(uv);

  if (uPass <= 3) {
    color = blurredColor;
  } else {
    vec4 bg = texture(uBgTexture, uv);
    color = reflectAndMix(bg, blurredColor, uPos.y);
  }

  ${computeFragColor('color')}
}

`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.25,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    pass: {
      name: 'uPass',
      type: '1i',
      value: 0,
    },
    direction: {
      name: 'uDirection',
      type: '1f',
      value: 0.5,
    },
    stretch: {
      name: 'uStretch',
      type: '1f',
      value: 0.5,
    },
    exposure: {
      name: 'uExposure',
      type: '1f',
      value: 0.5,
    },
    tint: {
      name: 'uTint',
      type: '3f',
      value: new Vec3(0),
    },
    ...universalUniformParams,
  },
};

export const REFLECTIVE_SURFACE = {
  id: 'reflectiveSurface',
  label: 'Reflective surface',
  params: params,
  downSample: true,
  passes: [
    {
      prop: 'pass',
      value: 2,
      downSample: true,
    },
    {
      prop: 'pass',
      value: 3,
      downSample: true,
    },
    {
      prop: 'pass',
      value: 4,
      includeBg: true,
    },
  ],
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Diffuseness',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the blur of the reflection',
    },
    exposure: {
      label: 'Reflectivity',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Mixes between the color of the surface and the reflection color',
    },
    stretch: {
      label: 'Stretch',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Stretches the reflection',
    },
    tint: {
      label: 'Tint',
      value: new Vec3(0),
      output: 'color',
      tooltip: 'The color of the surface',
    },
    direction: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Skews the blur of the reflection on the Y axis',
    },
  },
};
