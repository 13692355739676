<script>
export default {
    props: ['version', 'index'],
    data() {
        return {
            contextMenuOpen: false
        }
    },
}
</script>

<template>
    <div class="version">
      <div class="version-image">
        <img :src="version.thumbnail">
      </div>
      <div class="version-info">
        <b>Snapshot {{ index+1 }}</b>
        <div class="gray">{{ version.createdAt }}</div>
      </div>
      <a @click="contextMenuOpen = true" href="javascript:void(0)" class="edit-design-button button button__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><circle cx="128" cy="128" r="16"></circle><circle cx="64" cy="128" r="16"></circle><circle cx="192" cy="128" r="16"></circle></svg>
      </a>
      <div aria-modal v-if="contextMenuOpen"  @mouseleave="contextMenuOpen = false" class="design-listing-item-context-menu">
        <a @click="$emit('select-version', version)" class="context-menu-link" href="javascript:void(0)" title="Select">Select</a>
        <a @click="$emit('delete-version', version)" class="context-menu-link" href="javascript:void(0)" title="Delete">Delete</a>
        <a @click="$emit('new-design-from-version', version)" class="context-menu-link" href="javascript:void(0)" title="Delete">New design</a>
      </div>
    </div>
</template>

<style lang="scss" scoped>

.version-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.version-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  img {
    max-width: 5rem;
    max-height: 5rem;
  }
}

</style>