import {
  vertexShaderNoMatrix,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  BLEND,
  universalUniformParams,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';
import { BLEND_MODES } from '../../scripts/Constants.js';

const fragmentShader = `#version 300 es
  precision mediump float;
  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uWeight;
  uniform float uScale;
  uniform float uAngle;
  uniform int uPattern;
  uniform vec2 uPos;
  uniform vec3 uStroke;
  uniform int uBlendMode;
  ${UNIVERSAL_UNIFORMS}
  ${BLEND}

  mat2 rotate2d(float _angle){
    return mat2(cos(_angle),-sin(_angle),
                sin(_angle),cos(_angle));
  }

  float grid(vec2 st, float tile) {
    float result = 0.;
    result = fract(st.x) < tile ? 1. : 0.;
    if(result == 0.) {
      result += fract(st.y) < tile ? 1. : 0.;
    }
    return result;
  }

  float stripe(vec2 st, float tile) {
    return fract(st.x) < tile ? 1. : 0.;
  }

  float arrows(vec2 st, float tile) {
    float s = fract(st.y) < mod(floor(st.x + st.y), 2.) ? st.x : st.y;
    return mod(floor(s), 2.);
  }

  float concentric_circle( vec2 st, float tile ) {
      return fract(length(st) - tile/2.) < tile ? 1. : 0.;
  }

  float circle(vec2 st, float tile) {
    vec2 gridPos = floor(st);
    vec2 cellPos = fract(st);
    return (length(cellPos - 0.5) < tile * 0.5) ? 1. : 0.;
  }


  float checkerboard(vec2 st, float tile){
    vec2 pos = floor(st); 
    return mod(pos.x+pos.y,2.0);
  }

  // 2. Wavy lines pattern
  float wavy_lines(vec2 st, float tile) {
    float value = sin(st.x * 3.1415926 * 2.0 + st.y * 10.0) * 0.5 + 0.5;
    return value < tile ? 1. : 0.;
  }

  // 3. Hexagonal pattern
  float hexagonal_pattern(vec2 st, float tile) {
    const float sqrt3 = 1.7320508;
    vec2 hexSize = vec2(1.0, sqrt3) * tile;
    vec2 grid = fract(st / hexSize);
    vec3 grid3 = vec3(grid, grid.y * 0.5);
    vec3 corner = round(grid3);
    vec3 v1 = abs(grid3 - corner);
    vec3 v2 = abs(grid3 + vec3(-0.5, sqrt3 * 0.5, 0.0) - corner);
    vec3 v3 = abs(grid3 + vec3(0.5, sqrt3 * 0.5, 0.0) - corner);
    vec3 dist = min(min(v1, v2), v3);
    return (dist.x + dist.y) < tile * 0.5 ? 1. : 0.;
  }

  // 4. Diamond pattern
  float diamond_pattern(vec2 st, float tile) {
    vec2 diamond_coord = abs(st * 2.0);
    return (diamond_coord.x + diamond_coord.y) < tile ? 1. : 0.;
  }

  // 5. Spiral pattern
  float spiral_pattern(vec2 st, float tile) {
    float r = length(st);
    float theta = atan(st.y, st.x);
    float spiral = mod(theta + r * 5.0, 3.1415926 * 2.0) - 3.1415926;
    return abs(spiral) < tile ? 1. : 0.;
  }

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
    vec4 bg = texture(uTexture, uv);

    if(bg.a == 0.) {
      fragColor = vec4(0);
      return;
    }
    
    vec4 color = vec4(uStroke,0.);
    float aspectRatio = uResolution.x/uResolution.y;
    float px = (1./1080.);
    float py = (1./1080.) / aspectRatio;
    float scl = (40. * uScale);
    float minpx = min(px, py);
    float tile = (minpx + uWeight/scl)*scl;
    tile = round(tile / minpx) * minpx;

    vec2 st = (uv - uPos) * scl * vec2(aspectRatio, 1);
    st = st * rotate2d(uAngle * 360. * 3.1415926 / 180.);

    if(uPattern == 0) {
      color.a = grid(st, tile);
    }
    if(uPattern == 1) {
      color.a = stripe(st, tile);
    }
    if(uPattern == 2) {
      color.a = circle(st, tile);
    }
    if(uPattern == 3) {
      color.a = concentric_circle(st, tile);
    }
    if(uPattern == 4) {
      color.a = arrows(st, tile);
    }
    if(uPattern == 5) {
      color.a = checkerboard(st, tile);
    }
    if (uPattern == 6) {
      color.a = wavy_lines(st, tile);
    }
    if (uPattern == 7) {
      color.a = hexagonal_pattern(st, tile);
    }
    if (uPattern == 8) {
      color.a = diamond_pattern(st, tile);
    }
    if (uPattern == 9) {
      color.a = spiral_pattern(st, tile);
    }

    // #ifelseopen
    if(uBlendMode > 0) {
      color.rgb = blend(uBlendMode, color.rgb, bg.rgb);
    }
    // #ifelseclose
    
    fragColor = mix(bg, color, color.a);
  }
`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader: vertexShaderNoMatrix,
  crossorigin: 'Anonymous',
  depthTest: false,
  hidden: true,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    weight: {
      name: 'uWeight',
      type: '1f',
      value: 0,
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0,
    },
    stroke: {
      name: 'uStroke',
      type: '3f',
      value: new Vec3(0.5),
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    pattern: {
      name: 'uPattern',
      type: '1i',
      value: 0,
    },
    blendMode: {
      name: 'uBlendMode',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const PATTERN = {
  id: 'pattern',
  label: 'Pattern',
  params: params,
  aspectRatio: 1,
  properties: {
    pattern: {
      label: 'Pattern',
      value: 0,
      options: {
        0: 'Grid',
        5: 'Checkerboad',
        1: 'Stripes',
        2: 'Dots',
        3: 'Circles',
        4: 'Arrows',
        5: 'Checkerboard',
        9: 'Spiral',
      },
      responsiveDisabled: true,
    },
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    weight: {
      label: 'Weight',
      value: 0.01,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.001,
      output: 'percent',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    stroke: {
      label: 'Fill',
      value: new Vec3(0.98, 0.12, 0.89),
      output: 'color',
      responsiveDisabled: true,
    },
    blendMode: {
      label: 'Blend mode',
      value: 'NORMAL',
      options: BLEND_MODES,
      responsiveDisabled: true,
    },
  },
};
