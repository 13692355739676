import { computeFragColor, vertexShader, FLOATING_POINT, UNIVERSAL_UNIFORMS, universalUniformParams } from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision mediump float;
in vec2 vTextureCoord;
uniform sampler2D uTexture;
uniform vec2 uPos;
uniform float uScale;
uniform float uAngle;
uniform float uDistort;
uniform float uDispersion;
uniform float uTime;
uniform float uOffsetX;
uniform float uOffsetY;
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;

const float STEPS = 10.0;

const float PI = 3.14159265359;
mat2 rot(float a) {
  return mat2(cos(a),-sin(a),sin(a),cos(a));
}

vec3 chromatic_abberation(vec2 st, vec2 aberrated) {
  vec4 red = vec4(0);
  vec4 blue = vec4(0);
  vec4 green = vec4(0);

  float invSteps = 1.0 / STEPS;
  float invStepsHalf = invSteps * 0.5;

  for(float i = 1.0; i <= STEPS; i++) {
    vec2 offset = aberrated * (i * invSteps);
    red += texture(uTexture, st - offset) * invSteps;
    blue += texture(uTexture, st + offset) * invSteps;
    green += texture(uTexture, st - offset * 0.5) * invStepsHalf;
    green += texture(uTexture, st + offset * 0.5) * invStepsHalf;
  }

  return vec3(red.r, green.g, blue.b);
}

void main() {
  vec2 uv = vTextureCoord;
  uv = (uv - uPos) * rot(uAngle * 2. * PI);
  
  float frequency = max(1., 15.*uScale);

  float scaledX = fract(uv.x * frequency);
  float scaledY = fract(uv.y * frequency);

  float dir = step(fract(uv.y * frequency/2.), 0.5) > 0. ? 1. : -1.;
  float offset = (uTime * 0.01 * dir) + mix(0., floor((uv.y*frequency))/frequency, uOffsetX);
  

  // Warp effect on y-axis
  float distanceFromHorizontalCenter = 2.0 * abs(scaledY - 0.5);
  float warpAmount = 2. * uDistort * (0.5 - distanceFromHorizontalCenter*distanceFromHorizontalCenter*distanceFromHorizontalCenter);
  scaledY = scaledY - warpAmount * (scaledY - 0.5);
  uv.y = scaledY/(15. * uScale) + 1. * uOffsetY;

  uv.x = fract(uv.x + offset);

  vec4 color = texture(uTexture, uv);

  color.rgb = chromatic_abberation(uv, vec2(0.1) * (1.-min(scaledY, 1.0 - scaledY)) * warpAmount * uDispersion * distanceFromHorizontalCenter*distanceFromHorizontalCenter*distanceFromHorizontalCenter);
  ${computeFragColor('color')}
}`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    scale: {
      name: "uScale",
      type: "1f",
      value: 0.5,
    },
    angle: {
      name: "uAngle",
      type: "1f",
      value: 0,
    },
    offsetX: {
      name: "uOffsetX",
      type: "1f",
      value: 0.5,
    },
    offsetY: {
      name: "uOffsetY",
      type: "1f",
      value: 0.5,
    },
    distort: {
      name: "uDistort",
      type: "1f",
      value: 0,
    },
    dispersion: {
      name: "uDispersion",
      type: "1f",
      value: 0,
    },
    pos: {
      name: "uPos",
      type: "2f",
      value: new Vec2(0.5),
    },
    time: {
      name: "uTime",
      type: "1f",
      value: 0,
    },
    ...universalUniformParams
  }
}

export const MARQUEE = {
  id: 'marquee',
  label: 'Marquee',
  params: params,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    offsetX: {
      label: 'Offset X',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    offsetY: {
      label: 'Offset Y',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    distort: {
      label: 'Warp',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    dispersion: {
      label: 'Dispersion',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees'
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
  }
}