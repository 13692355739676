<script>
import EditableText from './EditableText.vue';
import { formatDate } from '../scripts/Helpers.js';
import { DesignsStore } from "../stores/DesignsStore.js";

export default {
  props: ['design'],
  components: {
    EditableText
  },
  data() {
    return {
      pendingDelete: null,
      renaming: false,
      contextMenuOpen: false,
      folderMenuOpen: false,
      state: DesignsStore.state
    }
  },
  computed: {
    updatedDate() {
      return formatDate(this.design.updatedAt)
    },
    folders() {
      return this.state.folders.filter(n => n.id !== this.design.folderId);
    },
    folderOptions() {
      if(this.$route.params.folderId) {
        return [{id: '', name: 'All projects'}, ...this.folders]
      } else {
        return this.folders;
      }
    }
  },
  methods: {
    editName() {
      this.renaming = true;
      this.contextMenuOpen = false;
      this.$nextTick(() => {
        this.$refs.rename.focus()
      })
    },
    renameDesign(val) {
      this.renaming = false;
      DesignsStore.renameDesign(this.design.id, val);
    },
    deleteDesign(id) {
      DesignsStore.deleteDesign(id);
    },
    duplicateDesign() {
      DesignsStore.duplicateDesign(this.design, this.$route.params.folderId);
    },
    moveToFolder(folderId) {
      DesignsStore.moveDesignToFolder(this.design, folderId);
    },
    handleDragStart(event) {
      // Set the drag data
      event.dataTransfer.setData('design-id', this.design.id);
      event.dataTransfer.effectAllowed = 'move';
      
      // Optional: Set a custom drag image
      const dragImage = this.$refs.item.cloneNode(true);
      dragImage.style.opacity = '0.7';
      document.body.appendChild(dragImage);
      event.dataTransfer.setDragImage(dragImage, 0, 0);
      setTimeout(() => document.body.removeChild(dragImage), 0);
    },
    handleDragEnd(event) {
      event.preventDefault();
      // Reset any visual states if needed
    }
  }
}

</script>

<template>
    <div 
      :key="design.id"
      ref="item"
      class="design-listing-item"
      :class="{'design-listing-item__pending-delete': pendingDelete === design.id}"
      :title="design.name"
      draggable="true"
      @dragstart="handleDragStart"
      @dragend="handleDragEnd"
    >
      <RouterLink
        :to=" '/edit/' + design.id"
        :event="renaming ? '' : 'click'"
        class="design-item-link"
        draggable="false"
      >
        <img :src="design.thumbnail" :alt="design.name">
      </RouterLink>
      <div class="design-listing-info">
        <EditableText
          :value="design.name"
          placeholder="Untitled project"
          @update:value="renameDesign"
        />
        <div class="design-listing-timestamp mt-1" v-if="updatedDate">Updated: {{ updatedDate }}</div>
      </div>
      <a @click="contextMenuOpen = true" href="javascript:void(0)" class="edit-design-button button button__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><circle cx="128" cy="128" r="16"></circle><circle cx="64" cy="128" r="16"></circle><circle cx="192" cy="128" r="16"></circle></svg>
      </a>
      <div aria-modal v-if="contextMenuOpen"  @mouseleave="contextMenuOpen = false" class="design-listing-item-context-menu">
        <RouterLink :to=" 'edit/' + design.id " class="context-menu-link" href="javascript:void(0)" title="Edit">Edit</RouterLink>
        <a @click="duplicateDesign" class="context-menu-link" href="javascript:void(0)" title="Duplicate">Duplicate</a>
        <a @click="folderMenuOpen = true; contextMenuOpen = false" class="context-menu-link" href="javascript:void(0)" title="Move to">Move to</a>
        <template v-if="design.hasEmbed">
          <hr>
          <a target="_blank" class="context-menu-link" :href="'embed/' + design.versionId" title="Public URL">Public URL</a>
        </template>
        <hr>
        <a @click="deleteDesign(design.id)" class="context-menu-link" href="javascript:void(0)" title="Delete">Delete</a>
      </div>
      <div aria-modal v-if="folderMenuOpen"  @mouseleave="folderMenuOpen = false" class="design-listing-item-context-menu design-listing-item-context-submenu">
        <a 
          v-for="folder in folderOptions"
          :key="folder.id"
          @click="moveToFolder(folder.id)"
          class="context-menu-link"
          href="javascript:void(0)" :title="folder.name"
        >{{folder.name}}</a>
      </div>
    </div>
</template>

<style lang="scss">

.design-listing-item {
  position: relative;
  width: 16.66666666%;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: var(--bg-color);

  &.design-listing-item__new {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 3rem;
    text-decoration: none;
    
    &:hover {
      box-shadow: inset 0 0 0 0.1rem var(--border-color);
    }

    &:hover .new-item-content {
      color: var(--font-color);
    }
  }

  &.design-listing-item__pending-delete {
    opacity: 0.5;
    pointer-events: none;
  }

  &[draggable="true"] {
    cursor: move;
    cursor: grab;
    
    &:active {
      cursor: grabbing;
    }
  }

  img {
    position: relative;
    width: 100%;
    z-index: 0;
    object-fit: contain;
    height: calc(16.666vw - 2rem);
    padding: 1.5rem;
  }

  &:hover {
    box-shadow: inset 0 0 0 0.1rem var(--border-color);
    //background-color: var(--accent-color);

    img {
      z-index: 0;
    }

    .edit-design-button.button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
}

.edit-design-button.button.button__icon {
  display: none;
  position: absolute;
  background-color: var(--bg-color);
  top: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  box-shadow: 0 0.1rem 0.3rem rgba(#000, 0.2);
}

.context-menu-link {
  display: block;
  text-align: left;
  font-size: 1.4rem;
  text-decoration: none;
  color: inherit;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;

  &:hover {
    background-color: var(--accent-color);
  }
}

.new-item-content {
  text-align: center;
}

.design-listing-info {
  padding: 0 2rem 2rem;
  width: 100%;
  color: inherit;
}

.design-item-link {
  width: 100%;
  position: relative;
  height: calc(16.6666vw - 2rem);
  text-decoration: none;
  display: block;
  text-align: center;
}

.design-listing-name {
  text-decoration: none;
  color: var(--font-color);
  margin-bottom: 0.5em;
}

.design-listing-timestamp {
  font-size: 1.2rem;
  color: var(--font-secondary-color);
}

</style>