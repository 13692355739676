import { FLOATING_POINT } from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const vertexShader = `#version 300 es
precision mediump float;

in vec3 aVertexPosition;
in vec2 aTextureCoord;

uniform mat4 uMVMatrix;
uniform mat4 uPMatrix;
uniform mat4 uTextureMatrix;

out vec2 vTextureCoord;
out vec3 vVertexPosition;

void main() {
  gl_Position = vec4(aVertexPosition, 1.0);
  vTextureCoord = (vec4(aTextureCoord, 0.0, 1.0)).xy;
}`;

const customShader = `#version 300 es
precision mediump float;

// Want a quick intro?
// Select "Shader tutorial" from the dropdown above

in vec2 vTextureCoord;

uniform sampler2D uTexture; // Underlying scene
uniform sampler2D uCustomTexture; // Custom texture from effect control panel
uniform vec2 uResolution;
uniform vec2 uMousePos;
uniform float uMouseClick;

uniform vec3 uColor1;
uniform vec3 uColor2;
uniform vec2 uPos;
uniform float uAmount;
uniform float uScale;
uniform float uFrequency;
uniform float uAngle;
uniform float uAmplitude;
uniform int uVariant;
uniform float uTime;

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;

  vec4 color = texture(uTexture, uv);

  fragColor = color;
}`;

export const customParams = {
  fragmentShader: customShader,
  vertexShader,
  crossorigin: 'Anonymous',
  widthSegments: 250,
  heightSegments: 250,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.5,
    },
    frequency: {
      name: 'uFrequency',
      type: '1f',
      value: 0.5,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    amplitude: {
      name: 'uAmplitude',
      type: '1f',
      value: 0.5,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    mousePos: {
      name: 'uMousePos',
      type: '2f',
      value: new Vec2(0.5),
    },
    color1: {
      name: 'uColor1',
      type: '3f',
      value: new Vec3(1, 0, 1),
    },
    color2: {
      name: 'uColor2',
      type: '3f',
      value: new Vec3(0, 1, 1),
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    click: {
      name: 'uMouseClick',
      type: '1f',
      value: 0,
    },
    variant: {
      name: 'uVariant',
      type: '1i',
      value: 0,
    },
    resolution: {
      name: 'uResolution',
      type: '2f',
      value: new Vec2(1080),
    },
  },
};

export const CUSTOM = {
  id: 'custom',
  label: 'Custom',
  params: customParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 0.25,
  },
  properties: {
    texture: {
      label: 'Texture',
      value: {
        src: '',
        name: '',
        thumb: '',
      },
      output: 'texture',
    },
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    color1: {
      label: 'Color 1',
      value: new Vec3(1, 0, 1),
      output: 'color',
    },
    color2: {
      label: 'Color 2',
      value: new Vec3(0, 1, 1),
      output: 'color',
    },
    amount: {
      label: 'Amount',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    frequency: {
      label: 'Frequency',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amplitude: {
      label: 'Amplitude',
      value: 0.75,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    variant: {
      label: 'Variant',
      value: 0,
      options: {
        0: 'Option 1',
        1: 'Option 2',
        2: 'Option 3',
        3: 'Option 4',
        4: 'Option 5',
      },
    },
    speed: {
      label: 'Speed',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    mouseMomentum: {
      label: 'Momentum',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
