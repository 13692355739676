<script>
import { UserStore } from "../stores/UserStore.js";
import AdminFlyout from './AdminFlyout.vue';
import ProModal from './ProModal.vue';
import TrialInfo from './TrialInfo.vue';
import Button from './Button.vue';
import Icon from './Icon.vue';

export default {
    components: {
      AdminFlyout,
      ProModal,
      TrialInfo,
      Button,
      Icon
    },
    data() {
      return {
          UserStore,
          contextMenuOpen: false,
      }
    }
}
</script>

<template>
  <div class="navigation flex align-center">
    <div class="flex align-center">
      <div class="logo-container">
        <RouterLink
          to="/dashboard"
          event="click"
          draggable="false"
        >
          <svg width="24px" height="24px" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M298.219 257.12 300 43.718C300 14.46 278.01.34 258.018.34c-23.59-1.243-35.984 15.615-41.982 30.971l-84.592 242.82L176.5 48C182.5 19 164 .05 134.79.05c-32.61-.206-71.413.29-93.808.29 0 0-15.493.66-27.988 12.16C-.162 24.61.106 40.387.106 40.387-.357 63.15.847 156.9.847 156.9.847 257.12 58 289.298 99.94 295.571c24.007 3.59 41.128-6.88 51.237-21.44l137.828-202.99c-24.157 58.337-69.97 165.148-72.251 170.58C203.542 275.727 231.748 300 256.237 300c30.769 0 42.149-28.587 41.982-42.88Z" fill="#fff"/></svg>
        </RouterLink>
      </div>
    </div>
    <div class="profile-container flex align-center">
      <a class="mr-4 link" target="_blank" href="https://unicornstudio.notion.site/Unicorn-Studio-9a47f6a0e4c74d29b638d72c8a504fbb?pvs=73">Help docs</a>
      <a href="https://discord.gg/yCvM5qeTbv" target="_blank" class="mr-5 link flex align-center"><Icon class="mr-1" icon="discord" /> Join Discord</a>
      <TrialInfo 
        v-if="(UserStore.hasActiveTrial || UserStore.trialEnded) && !UserStore.hasSubscription" class="mr-4" 
        @sign-up="$emit('open-promodal')"  
        :showButton="true"
      />
      <AdminFlyout v-if="UserStore.email" :letter="UserStore.email[0]">
        <RouterLink
          to="account"
          event="click"
          style="text-decoration: none"
          draggable="false"
        >
          <div class="context-menu-link">Account</div>
        </RouterLink>
        <a @click="UserStore.signOut" class="context-menu-link" href="javascript:void(0)" title="Delete">Logout</a>
      </AdminFlyout>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.logo-container {
  margin-top: 0.5rem;
  width: 3rem;

  svg {
    object-fit: contain;
  }
}

.navigation {
  width: 100%;
  justify-content: space-between;
  padding: 1rem 2rem;
  font-size: 1.4rem;
}

</style>