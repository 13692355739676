<script>
import { DesignsStore } from "../stores/DesignsStore.js";
import { FirebaseStore } from "../stores/FirebaseStore.js";
import { UserStore } from "../stores/UserStore.js";
import router from '../router';

export default {
  props: ['design'],
  data() {
    return {
      pendingDelete: null,
      renaming: false,
      contextMenuOpen: false,
      loading: false,
    }
  },
  methods: {
    createDesign() {
      if(!this.loading) {
        this.loading = true;
        FirebaseStore.getVersion(this.design.versionId).then(ver => {
          DesignsStore.createDesign((id, vid) => {
            router.push('edit/' + id + '?from=template');
          }, {
            history: ver.data().history,
            creatorId: UserStore.id,
            thumbnail: this.design.thumbnail,
            name: this.design.name,
            size: this.design.size,
            tags: [...this.design.tags],
          })
        }).catch(err => {
          this.loading = false;
          console.log(err)
        });
      }
    }
  }
}

</script>

<template>
    <div 
      :key="design.id"
      class="template-listing-item"
      :title="design.name"
    >
      <a href="#" @click="createDesign">
        <svg v-if="loading" class="spinner" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9.5" stroke="currentColor" />
            <path d="M16 10a6 6 0 01-12 0" stroke="currentColor" />
        </svg>
        <img :src="design.thumbnail" :alt="design.name">
      </a>
      <div class="design-listing-info">
        <div class="design-listing-name mb-0">{{ design.name }}</div>
      </div>
    </div>
</template>

<style lang="scss" scoped>

.template-listing-item {
  position: relative;
  width: 100%;
  min-width: 12rem;
  border-radius: 0.5rem;
  overflow: hidden;

  &:first-child {
    margin-left: 3rem;
  }

  img {
    position: relative;
    width: 100%;
    z-index: 1;
    object-fit: cover;
    //height: calc(12vw - 2rem);
    aspect-ratio: 1;
    padding: 1.2rem;
  }

  &:hover {
    box-shadow: inset 0 0 0 0.1rem var(--border-color);
    //background-color: var(--accent-color);

    .edit-design-button.button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
}

.new-item-content {
  text-align: center;
}

.design-listing-info {
  padding: 0 1.2rem 1.2rem 1rem;
  width: 100%;
  color: inherit;
}

.design-listing-name {
  text-decoration: none;
  color: var(--font-color);
  //margin-bottom: 0.5em;
  font-size: 1.4rem;
}


</style>