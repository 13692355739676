import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';

const texturizeShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform float uAmount;
uniform float uOffsetX;
uniform float uChromAbb;
uniform float uGlitch;
uniform float uTime;
uniform float uWaves;
uniform float uPhase;
${UNIVERSAL_UNIFORMS}

float random (in float x) { return fract(sin(x)*43758.5453); }


out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;
  float size = uAmount * 0.2 * random(floor(uTime * 0.5) * 2. + 0.001);
  float floorY = floor(uv.y/size);
  float floorX = floor(uv.x/size);
  float phase = uPhase * 0.01;
  float phaseTime = phase + uTime;
  float chromab = uChromAbb * 0.75;
  
  float offset = 0.;

  float glitchMod = max(0., sign(random(sin(floorY + offset + phase)) - 0.5 - (1. - uGlitch*2.)/2.));
  float offX = (
    (random(floorY + offset * glitchMod + phase)) * uOffsetX - uOffsetX/2.
  )/5.;

  offX = clamp(offX, -uOffsetX / 10.0, uOffsetX / 10.0);

  uv.x = mix(uv.x, uv.x + offX * 2., glitchMod);

  float waveFreq = 30.0;
  float waveAmp = 0.005 * uWaves;

  float rogue = smoothstep(0., 2., sin((uv.y + uPhase) * waveFreq * (1. - uAmount) * 2. + uTime * 0.05) - 0.5) * 0.2 * uWaves;
  uv.x += sin(uv.y * waveFreq + uTime) * waveAmp + rogue;
  uv.y += sin(uv.x * waveFreq + uTime) * waveAmp;
  float waveX = sin(uv.y * waveFreq + uTime) * waveAmp + rogue * chromab * 0.2;

  uv = clamp(uv, vec2(0.0), vec2(1.0));

  vec4 color = texture(uTexture, uv);
  color.r = texture(uTexture, vec2(clamp(uv.x + (glitchMod * -offX * chromab - waveX), 0.0, 1.0), uv.y)).r;
  color.b = texture(uTexture, vec2(clamp(uv.x + (glitchMod * offX * chromab + waveX), 0.0, 1.0), uv.y)).b;
  ${computeFragColor('color')}
}

`;

const texturizeParams = {
  fragmentShader: texturizeShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    offsetX: {
      name: 'uOffsetX',
      type: '1f',
      value: 0.5,
    },
    chromAbb: {
      name: 'uChromAbb',
      type: '1f',
      value: 0.5,
    },
    glitch: {
      name: 'uGlitch',
      type: '1f',
      value: 0.5,
    },
    waves: {
      name: 'uWaves',
      type: '1f',
      value: 0.2,
    },
    phase: {
      name: 'uPhase',
      type: '1f',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const GLITCH = {
  id: 'texturize',
  label: 'Glitch',
  params: texturizeParams,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  properties: {
    amount: {
      label: 'Size',
      value: 0.5,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    offsetX: {
      label: 'Offset',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    chromAbb: {
      label: 'Abberation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    waves: {
      label: 'Waves',
      value: 0.2,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    glitch: {
      label: 'Amount',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    phase: {
      label: 'Phase',
      value: 0,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    speed: {
      label: 'Speed',
      header: 'Interactivity',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
