import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const sphereShader = `#version 300 es
  precision mediump float;
  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uAmount;
  uniform float uDispersion;
  uniform float uRadius;
  uniform float uInvert;
  uniform float uLight;
  uniform int uStyle;
  uniform int uHideBg;
  uniform vec2 uPos;
  uniform float uTime;
  ${UNIVERSAL_UNIFORMS}

  const float STEPS = 24.0;
  const float PI = 3.1415926;

  vec3 chromaticAbberation(vec2 st, float angle, float amount, float blend) {
    float aspectRatio = uResolution.x/uResolution.y;
    float rotation = angle * 360.0 * PI / 180.0;
    vec2 aberrated = amount * vec2(0.1 * sin(rotation) * aspectRatio, 0.1 * cos(rotation));
    aberrated *= distance(st, vec2(0.5)) * 2.0;
  
    vec4 red = vec4(0);
    vec4 blue = vec4(0);
    vec4 green = vec4(0);
  
    float invSteps = 1.0 / STEPS;
    float invStepsHalf = invSteps * 0.5;
  
    for(float i = 1.0; i <= STEPS; i++) {
      vec2 offset = aberrated * (i * invSteps);
      red += texture(uTexture, st - offset) * invSteps;
      blue += texture(uTexture, st + offset) * invSteps;
      green += texture(uTexture, st - offset * 0.5) * invStepsHalf;
      green += texture(uTexture, st + offset * 0.5) * invStepsHalf;
    }
  
    return vec3(red.r, green.g, blue.b);
  }

  vec2 sphericalTransformation(
    float u,
    float v,
    float uCenter,
    float vCenter,
    float lensRadius,
    float tau)
  { 
      float aspectRatio = uResolution.x/uResolution.y;
      u -= uCenter;
      v -= vCenter;

      float s = sqrt(u * u + v * v);
      if (s > lensRadius)
          return vec2(u + uCenter, v + vCenter);

      float z = sqrt(lensRadius * lensRadius - s * s);

      float uAlpha = (1.0 - (1.0 / tau)) * asin(u / lensRadius);
      float vAlpha = (1.0 - (1.0 / tau)) * asin(v / lensRadius);

      u = uCenter + z * sin(uAlpha);
      v = vCenter + z * sin(vAlpha);

      return vec2(u/aspectRatio, v);
  }

  float easeInCirc(float x) {
    return sqrt(1.0 - pow(x - 1.0, 2.0));
  }

  float exponentialOut(float t) {
    return t == 1.0 ? t : 1.0 - pow(2.0, -10.0 * t);
  }

  float circularIn(float t) {
    return 1.0 - sqrt(1.0 - t * t);
  }  

  vec2 fisheyeTransformation(
    float u,
    float v,
    float uCenter,
    float vCenter,
    float lensRadius,
    float distortionScale
) {
    float aspectRatio = uResolution.x / uResolution.y;

    // Calculate the vector from the center to the current point
    vec2 dir = vec2(u - uCenter, v - vCenter);

    // Normalize coordinates to -1.0 -> 1.0
    dir.x /= lensRadius;
    dir.y /= lensRadius;

    // Calculate the radial distance from the center
    float dist = length(dir) * 0.15;

    // If we're within the lens radius
    if (dist < 1.0) {
        // Calculate the angle for polar coordinates
        float theta = atan(dir.y, dir.x);

        // Simulate spherical distortion
        // This formula simulates the bending of light through a spherical lens
        float r = dist * 2.0 * PI;
        float z = sqrt(1.0 - r * r) + 0.25;
        float rDist = atan(r, z) / PI;
        float newDist = mix(dist, rDist, 5.);

        // Convert back to Cartesian coordinates
        dir.x = newDist * cos(theta);
        dir.y = newDist * sin(theta);
    }

    // Rescale back to original size
    dir.x *= lensRadius;
    dir.y *= lensRadius;

    // Return the distorted point
    return mix(vec2(u/aspectRatio, v), vec2(uCenter/aspectRatio, vCenter) + dir, uAmount);
}


  vec2 discTransformation(
    float u,
    float v,
    float uCenter,
    float vCenter,
    float lensRadius,
    float distortionScale)
{
    float aspectRatio = uResolution.x/uResolution.y;
    u -= uCenter;
    v -= vCenter;

    float s = sqrt(u * u + v * v);
    if (s > lensRadius)
        return vec2(u + uCenter, v + vCenter);
    
    float r = sqrt(u * u + v * v) / lensRadius;
    if(r == 0.0)
        return vec2(uCenter, vCenter);

    r = pow(r, distortionScale); // modify r with distortionScale

    float theta = atan(r);
    float rad = theta / r;

    u = rad * u + uCenter;
    v = rad * v + vCenter;

    return vec2(u/aspectRatio, v);
}

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
    vec4 color = texture(uTexture, uv);
    float aspectRatio = uResolution.x/uResolution.y;
    uv.x = uv.x * aspectRatio;
    vec2 sphereCoords = uv;
    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    pos.x *= aspectRatio;

    float radius = uRadius * uResolution.x/max(uResolution.x, uResolution.y);

    if(uStyle == 0) {
      sphereCoords = sphericalTransformation(
        mix(sphereCoords.x, 1.-sphereCoords.x, uInvert),
        mix(sphereCoords.y, 1.-sphereCoords.y, uInvert),
        mix(pos.x, 1.-pos.x, uInvert),
        mix(pos.y, 1.-pos.y, uInvert),
        radius/2.,
        1. + uAmount * 9.
      );
    } else if(uStyle == 1) {
      sphereCoords = discTransformation(
        mix(sphereCoords.x, 1.-sphereCoords.x, uInvert),
        mix(sphereCoords.y, 1.-sphereCoords.y, uInvert),
        mix(pos.x, 1.-pos.x, uInvert),
        mix(pos.y, 1.-pos.y, uInvert),
        radius/2.,
        1. + uAmount * 9.
      );
    } else if(uStyle == 2) {
      sphereCoords = fisheyeTransformation(
        mix(sphereCoords.x, 1.-sphereCoords.x, uInvert),
        mix(sphereCoords.y, 1.-sphereCoords.y, uInvert),
        mix(pos.x, 1.-pos.x, uInvert),
        mix(pos.y, 1.-pos.y, uInvert),
        radius/2.,
        1. + uAmount * 9.
      );
    }


    vec2 scaledCoords = (sphereCoords - 0.5) + 0.5;
    vec4 sphere = texture(uTexture, clamp(scaledCoords, 0.0, 1.0));
    float distFromPos = distance(uv, pos);
    float insideSphere = distFromPos < radius/2. ? 1. : 0.;
    float insideSphereAlpha = distFromPos + 0.002 < radius/2. ? 1. : 0.;
    sphere.rgb = chromaticAbberation(scaledCoords, atan(scaledCoords.y, scaledCoords.x), distFromPos * uDispersion, 1.0);
    color = mix(color, sphere, insideSphere);
    color.rgb += vec3((uLight-0.5)*2.) * mix(0., circularIn(smoothstep(0., radius, distFromPos)), insideSphere);
    
    if(uHideBg == 1) {
      color.a = insideSphereAlpha;
    }

    ${computeFragColor('color')}
  }
`;

const sphereParams = {
  fragmentShader: sphereShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.2,
    },
    radius: {
      name: 'uRadius',
      type: '1f',
      value: 0.5,
    },
    mirror: {
      name: 'uInvert',
      type: '1f',
      value: 1,
    },
    hideBg: {
      name: 'uHideBg',
      type: '1i',
      value: 0,
    },
    style: {
      name: 'uStyle',
      type: '1i',
      value: 1,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    dispersion: {
      name: 'uDispersion',
      type: '1f',
      value: 0,
    },
    light: {
      name: 'uLight',
      type: '1f',
      value: 0.5,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const SPHERE = {
  id: 'sphere',
  label: 'Lens distort',
  params: sphereParams,
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    style: {
      label: 'Style',
      value: 0,
      options: {
        0: 'Lens',
        1: 'Disc',
        2: 'Fisheye',
      },
      responsiveDisabled: true,
    },
    radius: {
      label: 'Radius',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Amount',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    dispersion: {
      label: 'Dispersion',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    light: {
      label: 'Glow',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    mirror: {
      label: 'Mirror',
      value: 0,
      classic: true,
      options: {
        0: 'Off',
        1: 'On',
      },
    },
    hideBg: {
      label: 'Hide background',
      value: 0,
      classic: true,
      options: {
        0: 'Off',
        1: 'On',
      },
    },
    ...interactiveProperties,
  },
};
