import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';
import { Vec3 } from 'curtainsjs';

const duotoneShader = `#version 300 es
  precision mediump float;
  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform vec3 uColor1;
  uniform vec3 uColor2;
  uniform float uMix;
  ${UNIVERSAL_UNIFORMS}

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
    
    // Convert to grayscale
    vec4 color = texture(uTexture, uv);
    float gray = dot(color.rgb, vec3(0.299, 0.587, 0.114));
    
    // Map grayscale to duotone gradient
    vec3 duotoneColor = mix(uColor1, uColor2, gray);
    
    // Output final color
    color = vec4(mix(color.rgb, duotoneColor, uMix), color.a);
    ${computeFragColor('color')}
  }
`;

const duotoneParams = {
  fragmentShader: duotoneShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    color1: {
      name: 'uColor1',
      type: '3f',
      value: new Vec3(0.5, 0, 0.5),
    },
    color2: {
      name: 'uColor2',
      type: '3f',
      value: new Vec3(0, 1, 1),
    },
    mix: {
      name: 'uMix',
      type: '1f',
      value: 1,
    },
    ...universalUniformParams,
  },
};

export const DUOTONE = {
  id: 'duotone',
  label: 'Duotone',
  params: duotoneParams,
  aspectRatio: 1,
  properties: {
    color1: {
      label: 'Color 1',
      value: new Vec3(0, 0, 0),
      output: 'color',
      responsiveDisabled: true,
    },
    color2: {
      label: 'Color 2',
      value: new Vec3(1, 0, 1),
      output: 'color',
      responsiveDisabled: true,
    },
    mix: {
      label: 'Mix',
      value: 1,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
