import {
  computeFragColor,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  EASE,
  EASING_FUNCTIONS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const vertexShader = `#version 300 es
precision mediump float;

in vec3 aVertexPosition;
in vec2 aTextureCoord;

uniform mat4 uMVMatrix;
uniform mat4 uPMatrix;
uniform mat4 uTextureMatrix;

out vec2 vTextureCoord;
out vec3 vVertexPosition;
out float zPos;

uniform sampler2D uTexture;
uniform float uTime;
uniform float uScale;
uniform float uAmount;
uniform float uSkew;
uniform float uLuma;
uniform float uAngle;
uniform int uEasing;
uniform vec2 uPos;
${UNIVERSAL_UNIFORMS}
${EASE}

float bezier(float t) {
  return ease(uEasing, t);
}

mat2 rot(float a) {
  return mat2(cos(a),-sin(a),sin(a),cos(a));
}

vec3 bulge(vec3 pos) {
  vec2 aspectRatio = vec2(uResolution.x/uResolution.y, 1);
  vec2 mousePosAdjusted = mix((uPos - 0.5) * 2., (uMousePos - 0.5) * 2., uTrackMouse);
  vec2 skew = vec2(uSkew, 1.-uSkew);
  mat2 rotation = rot(uAngle * 2. * 3.14159);

  float dist = distance(pos.xy * aspectRatio * rotation * skew, mousePosAdjusted * aspectRatio * rotation * skew);

  float t = max(0., 1. - dist/uScale);
  float bulge = bezier(t) * (uAmount - 0.5);

  float luma = dot(texture(uTexture, (pos.xy + 1.) * 0.5).rgb, vec3(0.299, 0.587, 0.114));

  bulge += luma * bezier(t) * uLuma;
  bulge = min(1., bulge);

  pos.xy += bulge * pos.xy;
  pos.z -= bulge;
  return pos;
}

void main() {
  vec3 pos = bulge(aVertexPosition);
  vec3 pos_dx = bulge(aVertexPosition + vec3(0.01, 0.0, 0.0));
  vec3 pos_dy = bulge(aVertexPosition + vec3(0.0, 0.01, 0.0));

  // Approximate normals by perturbing positions
  vec3 dx = pos_dx - pos;
  vec3 dy = pos_dy - pos;
  vec3 normal = normalize(cross(dx, dy));

  vVertexPosition = normal;

  zPos = pos.z;

  gl_Position = vec4(pos, 1.0);
  vTextureCoord = aTextureCoord;
}
`;

const fragmentShader = `#version 300 es
  precision mediump float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  in float zPos;

  uniform sampler2D uTexture;
  uniform float uScale;
  uniform float uAngle;
  uniform float uLightAngle;
  uniform float uLightIntensity;
  uniform vec2 uPos;
  ${UNIVERSAL_UNIFORMS}

  float random(vec2 seed) {
    return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
  }

  // Narkowicz 2015, "ACES Filmic Tone Mapping Curve"
  vec3 Tonemap_ACES(vec3 x)
  {
      const float a = 2.51;
      const float b = 0.03;
      const float c = 2.43;
      const float d = 0.59;
      const float e = 0.14;
      return (x * (a * x + b)) / (x * (c * x + d) + e);
  }

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
  
    vec4 color = texture(uTexture, uv);

    float intensity = uLightIntensity * 0.5;

    float rad = (uLightAngle - 0.25) * -2. * 3.14159;
    vec2 rotatedLightPosition = vec2(cos(rad), sin(rad));
    vec3 lightPosition = vec3(vec2(0.5) * rotatedLightPosition * 2., 1.0);

    float diff = max(dot(normalize(vVertexPosition), lightPosition), 0.0);
    
    color.rgb += (diff * intensity - intensity);

    float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;
    color.rgb += dither;
  
    ${computeFragColor('color')}
  }
`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  // depthTest: false,
  widthSegments: 500,
  heightSegments: 500,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.25,
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.8,
    },
    skew: {
      name: 'uSkew',
      type: '1f',
      value: 0.5,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0.5,
    },
    luma: {
      name: 'uLuma',
      type: '1f',
      value: 0,
    },
    lightAngle: {
      name: 'uLightAngle',
      type: '1f',
      value: 0.5,
    },
    intensity: {
      name: 'uLightIntensity',
      type: '1f',
      value: 0.75,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 3,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const BULGE = {
  id: 'bulge',
  label: 'Bulge / Pinch',
  params,
  aspectRatio: 1,
  // animation: {
  //   active: false,
  //   speed: 1
  // },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Radius of the effect',
    },
    amount: {
      label: 'Amount',
      value: 0.75,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Amplitude of the bulge',
    },
    luma: {
      label: 'Luma extrude',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Adds luma value to the bulge',
    },
    easing: {
      label: 'Easing',
      value: 3,
      options: Object.keys(EASING_FUNCTIONS).map((n, index) => n.split('_').join(' ').toLowerCase()),
      tooltip: 'Easing function for the effect as it falls off from the position to the radius edge.',
      responsiveDisabled: true,
    },
    skew: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Stretches the bulge effect',
    },
    angle: {
      label: 'Rotation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
      tooltip: 'If applying a skew, this controls the angle of the skew on the Z axis.',
    },
    intensity: {
      label: 'Intensity',
      header: 'Light',
      value: 0.75,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Amount of light/shadow applied to the effect',
    },
    lightAngle: {
      label: 'Angle',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
      tooltip: 'Controls the angle of the light source',
    },
    // speed: {
    //   label: 'Speed',
    //   header: 'Animation',
    //   value: 0.25,
    //   min: 0,
    //   max: 1,
    //   step: 0.01,
    //   output: 'percent'
    // },
    ...interactiveProperties,
  },
};
