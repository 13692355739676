import {
  computeFragColor,
  UNIVERSAL_UNIFORMS,
  FLOATING_POINT,
  universalUniformParams,
  PERLIN_NOISE,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const vertexShader = `#version 300 es
  precision mediump float;

  in vec3 aVertexPosition;
  in vec2 aTextureCoord;
  
  uniform mat4 uMVMatrix;
  uniform mat4 uPMatrix;
  uniform mat4 uTextureMatrix;

  out vec3 vVertexPosition;
  out vec2 vTextureCoord;

  uniform float uAmount;
  uniform float uScale;
  uniform float uPhase;
  uniform float uEasing;
  uniform float uTime;
  uniform vec2 uPos;
  ${UNIVERSAL_UNIFORMS}

  ${PERLIN_NOISE}

  mat4 rotation(float angle) {
    return mat4(
        vec4( cos(angle), -sin(angle), 0.0,  0.0 ),
        vec4( sin(angle), cos(angle),  0.0,  0.0 ),
        vec4( 0.0,        0.0,         1.0,  0.0 ),
        vec4( 0.0,        0.0,         0.0,  1.0 ) ); 
  }
  
  void main() {	
    vec3 vertexPosition = aVertexPosition;
    vec3 waveCoord = aVertexPosition;
    float cumval = 0.;
    float spr = (uScale + 1.) / ((uResolution.x/uResolution.y + 1.) * 0.5) * 10.;
    float time = uPhase * 10. + uTime * 0.05;

    float value = perlin_noise(vec3(((waveCoord.xy * uScale * 10.) + (uPos - 0.5) * 20. * uScale) * (vec2(0.2, 1.)), time)) * uAmount;

    waveCoord.z = 0.;
    waveCoord.y += mix(value, smoothstep(-1., 0., value) - 1., uEasing);
    waveCoord.x += value * 0.02;
    
    if(vertexPosition.x == 1.) {
        waveCoord.x = 1.;
    }
    if(vertexPosition.x == -1.) {
        waveCoord.x = -1.;
    }
    if(vertexPosition.y == 1.) {
        waveCoord.y = 1.;
    }
    if(vertexPosition.y == -1.) {
        waveCoord.y = -1.;
    }

    gl_Position = uPMatrix * uMVMatrix * rotation(radians(180.0)) * vec4(waveCoord, 1.);

    vTextureCoord = aTextureCoord - vec2(0., mix(value, smoothstep(-1., 0., value) - 1., uEasing));
    vVertexPosition = vertexPosition;
  }
`;

const wavesShader = `#version 300 es
  precision mediump float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  ${UNIVERSAL_UNIFORMS}

  out vec4 fragColor;

  void main() {
    vec2 uv = vTextureCoord;
    uv = vec2(
        1. - uv.x,
        1. - uv.y
    );
    vec4 color = texture(uTexture, uv);
    ${computeFragColor('color')}
  }
`;

const wavesParams = {
  fragmentShader: wavesShader,
  crossorigin: 'Anonymous',
  vertexShader,
  widthSegments: 300,
  heightSegments: 300,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    spread: {
      name: 'uScale',
      type: '1f',
      value: 0.5,
    },
    easing: {
      name: 'uEasing',
      type: '1f',
      value: 0.5,
    },
    phase: {
      name: 'uPhase',
      type: '1f',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const WAVES = {
  id: 'noiseField',
  label: 'Waves',
  params: wavesParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 0.5,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    spread: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Amplitude',
      value: 0.5,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    easing: {
      label: 'Easing',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    phase: {
      label: 'Phase',
      value: 0,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    speed: {
      label: 'Speed',
      header: 'Speed',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
