import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const chromaticAbberationShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uPingPongTexture;
uniform float uAmount;
uniform float uAngle;
uniform float uWarp;
uniform int uBlend;
uniform vec2 uPos;
uniform float uTime;
uniform int uType;
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;
const float STEPS = 30.0;
const float PI = 3.1415926;

void main() {  
  vec2 uv = vTextureCoord;
  vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
  float angle = ((uAngle + uTime * 0.05) * 360.0) * PI / 180.0;
  vec2 rotation = vec2(sin(angle), cos(angle));
  vec2 aberrated = uAmount * rotation * 0.03 * mix(1.0, distance(uv, pos) * (1.0 + uWarp), uWarp);
  
  vec4 left = uBlend == 1 ? vec4(0) : texture(uTexture, uv - aberrated);
  vec4 right = uBlend == 1 ? vec4(0) : texture(uTexture, uv + aberrated);
  vec4 center = vec4(0);


  // #ifelseopen
  if (uBlend == 1) {
    float invSteps = 1.0 / STEPS;

    for (float i = 0.0; i <= STEPS; i++) {
      vec2 offset = aberrated * (i * invSteps);
      left += texture(uTexture, uv - offset) * invSteps;
      right += texture(uTexture, uv + offset) * invSteps;
      center += texture(uTexture, uv - offset * 0.5) * invSteps * 0.5;
      center += texture(uTexture, uv + offset * 0.5) * invSteps * 0.5;
    }
  }
  // #ifelseclose

  vec4 color = texture(uTexture, uv);
  
  if(uType == 0) {
    color.r = left.r;
    color.g = mix(color.g, center.g, float(uBlend));
    color.b = right.b;
  } else if(uType == 1) {
    color.r = mix(color.r, center.r, float(uBlend));
    color.g = left.g;
    color.b = right.b;
  } else if(uType == 2) {
    color.r = right.r;
    color.g = left.g;
    color.b = mix(color.b, center.b, float(uBlend));
  }

  color.a = max(max(left.a, center.a), right.a);
  ${computeFragColor('color')}
}
`;

const chromaticAbberationParams = {
  fragmentShader: chromaticAbberationShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.2,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    warp: {
      name: 'uWarp',
      type: '1f',
      value: 0,
    },
    colorMode: {
      name: 'uType',
      type: '1i',
      value: 0,
    },
    blend: {
      name: 'uBlend',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const CHROMATIC_ABBERATION = {
  id: 'chromab',
  label: 'Chromatic abb.',
  params: chromaticAbberationParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 0.5,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Spread',
      value: 0.2,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    warp: {
      label: 'Tilt shift',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    colorMode: {
      label: 'Color mode',
      value: 0,
      options: {
        0: 'Blue/Red',
        1: 'Blue/Green',
        2: 'Red/Green',
      },
    },
    blend: {
      label: 'Blend',
      value: 0,
      classic: true,
      options: {
        0: 'Off',
        1: 'On',
      },
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
