import {
  computeFragColor,
  vertexShader,
  EASE,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const fragmentShader = `#version 300 es
  precision mediump float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;

  uniform sampler2D uTexture;
  uniform float uAmount;
  uniform float uAngle;
  uniform int uDirection;
  uniform vec2 uPos;
  ${UNIVERSAL_UNIFORMS}

  out vec4 fragColor;

  float random(vec2 seed) {
    return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
  }

  const float PI = 3.141592;

  mat2 rot(float a) {
    return mat2(cos(a),-sin(a),sin(a),cos(a));
  }

  float easeInOutQuad(float t) {
    return t < 0.5 ? 2.0 * t * t : -1.0 + (4.0 - 2.0 * t) * t;
  }

  const int kernelSize = 36;
  float getGaussianWeight(int index) {
    switch(index) {
        case 0: return 0.00094768;
        case 1: return 0.00151965;
        case 2: return 0.00237008;
        case 3: return 0.00359517;
        case 4: return 0.0053041;
        case 5: return 0.00761097;
        case 6: return 0.01062197;
        case 7: return 0.01441804;
        case 8: return 0.01903459;
        case 9: return 0.0244409;
        case 10: return 0.03052299;
        case 11: return 0.03707432;
        case 12: return 0.04379813;
        case 13: return 0.05032389;
        case 14: return 0.05623791;
        case 15: return 0.06112521;
        case 16: return 0.06461716;
        case 17: return 0.06643724;
        case 18: return 0.06643724;
        case 19: return 0.06461716;
        case 20: return 0.06112521;
        case 21: return 0.05623791;
        case 22: return 0.05032389;
        case 23: return 0.04379813;
        case 24: return 0.03707432;
        case 25: return 0.03052299;
        case 26: return 0.0244409;
        case 27: return 0.01903459;
        case 28: return 0.01441804;
        case 29: return 0.01062197;
        case 30: return 0.00761097;
        case 31: return 0.0053041;
        case 32: return 0.00359517;
        case 33: return 0.00237008;
        case 34: return 0.00151965;
        case 35: return 0.00094768;
        default: return 0.0;
    }
  }

  vec4 GaussianBlur(sampler2D tex, vec2 uv, vec2 direction) {
    vec4 color = vec4(0.0);
    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);

    float amt = uDirection <= 1 ? 6. : 11.;

    vec2 st = uv * rot(uAngle * 2. * PI);
    pos *= rot(uAngle * 2. * PI);

    float dist = easeInOutQuad(pos.y - st.y);
    float jawn = st.y < pos.y ? 1. : 0.;

    float amount = uAmount * amt * dist * jawn;

    if (amount == 0.0) {
      return texture(tex, uv);
    }
 
    color += texture(tex, uv) * getGaussianWeight(0);
    
    for (int i = 0; i < kernelSize; i++) {
      float x = float(i - kernelSize / 2) * amount;
      color += texture(tex, uv + vec2(x * 0.001) * direction) * getGaussianWeight(i);
    }

    float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;
    color.rgb += dither;

    return color;
  }

  void main() {	
    vec2 uv = vTextureCoord;
    vec4 color = vec4(0);
    int dir = uDirection % 2;
    vec2 direction = dir == 1 ? vec2(0, uResolution.x/uResolution.y) : vec2(1, 0);
    color = GaussianBlur(uTexture, uv, direction);

    ${computeFragColor('color')}
  }
`;

const params = {
  fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    blurType: {
      name: 'uType',
      type: '1i',
      value: 0,
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.2,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    vertical: {
      name: 'uDirection',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const PROGRESSIVE_BLUR = {
  id: 'progressiveBlur',
  label: 'Progressive blur',
  params,
  aspectRatio: 1,
  passes: [
    {
      prop: 'vertical',
      value: 1,
    },
    {
      prop: 'vertical',
      value: 2,
    },
    {
      prop: 'vertical',
      value: 3,
    },
  ],
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Radius',
      value: 0.2,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount of blur',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    ...interactiveProperties,
  },
};
