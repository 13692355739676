import {
  computeFragColor,
  FLOATING_POINT,
  SIMPLEX_2S_NOISE,
  EASE,
  mixRadiusProperties,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const vertexShader = `#version 300 es
  precision highp float;

  in vec3 aVertexPosition;
  in vec2 aTextureCoord;

  uniform mat4 uMVMatrix;
  uniform mat4 uPMatrix;
  uniform mat4 uTextureMatrix;

  out vec3 vVertexPosition;
  out vec2 vTextureCoord;

  uniform float uTime;
  uniform float uPhase;
  uniform float uAngle;
  uniform float uFrequency;
  uniform float uTurbulence;
  uniform float uDirection;
  uniform float uTrackMouse;
  uniform vec2 uPos;
  uniform vec2 uMousePos;
  uniform vec2 uResolution;
  ${SIMPLEX_2S_NOISE}

  const float PI = 3.14159265359;

  mat2 rot(float a) {
    return mat2(cos(a),-sin(a),sin(a),cos(a));
  }
  
  void main() {	
    vec3 vertexPosition = aVertexPosition;
    vec2 textureCoord = (vertexPosition.xy+1.) / 2.;
    
    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    vec2 st = vec2(textureCoord.x * uResolution.x/uResolution.y, textureCoord.y) - pos;
    st = rot(uAngle * -1. * 2.0 * PI) * (st);
    vec4 noise = bccNoiseDerivatives_XYBeforeZ(vec3(st * vec2(uDirection, 1. - uDirection) * 9. * uFrequency, uPhase + uTime*0.02));
    st.xy = mix(textureCoord, (noise.xy/7. + 0.5), uTurbulence);
    
    gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);
    
    vTextureCoord = (uTextureMatrix * vec4(st, 0.0, 1.0)).xy;
    vVertexPosition = vec3(aTextureCoord, 0.);
  }
`;

const ungulateShader = `#version 300 es
  precision highp float;
  
  in vec2 vTextureCoord;
  in vec3 vVertexPosition;

  uniform sampler2D uTexture;
  uniform float uMixRadius;
  uniform vec2 uPos;
  uniform int uEasing;
  uniform int uMixRadiusInvert;
  ${UNIVERSAL_UNIFORMS}
  ${EASE}

  out vec4 fragColor;

  void main() {
    vec2 noise = vTextureCoord;
    vec2 uv = vVertexPosition.xy;
    float aspectRatio = uResolution.x/uResolution.y;

    vec2 mPos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    vec2 pos = uMixRadius == 1.00 ? mPos : uPos;
    float dist = ease(uEasing, max(0.,1.-distance(uv * vec2(aspectRatio, 1), mPos * vec2(aspectRatio, 1)) * 4. * (1. - uMixRadius)));

    // #ifelseopen
    if (uMixRadiusInvert == 1) {
        dist = max(0., (0.5 - dist));
    }
    // #ifelseclose

    uv = mix(uv, noise, dist);

    vec4 color = texture(uTexture, uv);
    ${computeFragColor('color')}
  }
`;

const noiseParams = {
  fragmentShader: ungulateShader,
  vertexShader: vertexShader,
  widthSegments: 250,
  heightSegments: 250,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    turbulence: {
      name: 'uTurbulence',
      type: '1f',
      value: 0.5,
    },
    frequency: {
      name: 'uFrequency',
      type: '1f',
      value: 0.2,
    },
    direction: {
      name: 'uDirection',
      type: '1f',
      value: 0.5,
    },
    phase: {
      name: 'uPhase',
      type: '1f',
      value: 0,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    mixRadius: {
      name: 'uMixRadius',
      type: '1f',
      value: 1,
    },
    mixRadiusInvert: {
      name: 'uMixRadiusInvert',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 1,
    },
    ...universalUniformParams,
  },
};

export const NOISE = {
  id: 'noise',
  label: 'Noise',
  params: noiseParams,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    frequency: {
      label: 'Scale',
      value: 0.2,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the scale of the noise pattern',
    },
    turbulence: {
      label: 'Amplitude',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the strength of the distortion',
    },
    direction: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Skews the distortion along the X or Y axis',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    phase: {
      label: 'Phase',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the time element of the effect',
    },
    ...mixRadiusProperties,
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
