<script>
import { Vec3 } from "curtainsjs";
import { isValidHexCode } from '../scripts/ColorHelpers.js'
import Icon from './Icon.vue';
import InputField from './InputField.vue';
  import ColorPicker from './ColorPicker.vue';


function getFillStyle(fill, ang) {
  let angle = ang || 45;
  let isWhite = fill[0] === "#FFFFFF";
  const isTransparent = fill[0] === "transparent";
  if (fill.length > 1) {
    return {
      background: `linear-gradient(${angle}deg, ${fill})`,
    };
  } else {
    return {
      background: isTransparent
        ? `linear-gradient(45deg, #fff, #fff 47%, red 50%, #fff 53%, #fff)`
        : fill[0],
      border: isWhite || isTransparent ? "1px solid #eaeaea" : "none",
    };
  }
}
function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}


export default {
  props: ["fill", "gradientType", "clearable", "hidable", "vec3", "alpha", "picker", "index"],
  components: {
    Icon,
    InputField,
    ColorPicker
  },
  data() {
    return {
      color: this.fill,
      typedColor: this.fill,
      previousColor: this.fill,
    };
  },
  created() {
    this.updateColorFromProps();
  },
  beforeUnmount() {
    if (this.fill[0] && typeof this.fill[0] === 'string' && this.typedColor !== this.fill[0].replace(/^#/, '')) {
      this.selectFill();
    }
  },
  watch: {
    fill: {
      immediate: true,
      handler(newValue) {
        if (newValue[0] !== this.color[0] && newValue[0] !== this.typedColor) {
          this.updateColorFromProps();
        }
      },
    },
  },
  methods: {
   updateColorFromProps() {
      if (this.fill.type) {
        if(this.fill.type) {
          this.color = [
            rgbToHex(
              Math.floor(this.fill.x * 255),
              Math.floor(this.fill.y * 255),
              Math.floor(this.fill.z * 255)
            ),
          ];
        } else {
          this.color = this.fill.map(n => {
            return rgbToHex(
              Math.floor(n[0] * 255),
              Math.floor(n[1] * 255),
              Math.floor(n[2] * 255)
            )
          })
        }
      } else {
        this.color = this.fill;
      }
      if (this.color[0] && !(this.color[0] instanceof Event)) {
        if(this.typedColor && typeof this.color[0] === 'string') {
          this.typedColor = this.color[0].replace(/^#/, ''); // Strip the hash
        }
        this.previousColor = this.color;
      }
    },

    selectFill(trans) {
      if (this.previousColor[0] && typeof this.previousColor[0] === 'string' && 
          this.typedColor === this.previousColor[0].replace(/^#/, '')) return;
      
      let colorToCheck = trans === true 
        ? 'transparent' 
        : (typeof this.typedColor === 'string' ? this.typedColor.replace(/^#/, '') : '');
      
      if (isValidHexCode('#' + colorToCheck)) {
        if (this.vec3) {
          const rgb = hexToRgb('#' + colorToCheck);
          this.$emit("change", new Vec3(rgb.r / 255, rgb.g / 255, rgb.b / 255));
        } else {
          this.color = ['#' + colorToCheck];
          this.typedColor = colorToCheck;
          this.$emit("change", this.color);
        }
        this.previousColor = this.color;
      } else {
        this.typedColor = this.previousColor[0].replace(/^#/, '');
      }
    },

    getFillStyle(fill) {
      fill = this.fill.type ? [fill] : fill;
      return getFillStyle(fill);
    },

    addFill() {
      this.$emit("change", ["#000000"]);
    },

    handlePaste(event) {
      // Prevent the default paste behavior to manually process the pasted text
      event.preventDefault();

      // Extract the text content from the ClipboardEvent
      const text = (event.clipboardData || window.clipboardData).getData('text').trim();

      // Update the typedColor with the sanitized value
      this.typedColor = text.replace(/^#/, '');

      // Since we're directly updating typedColor, manually trigger selectFill to process the new color
    },

    updateWithPicker() {
      this.$emit('change', this.color);
      this.updateColorFromProps();
    }

  },
};
</script>

<template>
  <div
    v-if="!clearable || (clearable && color[0] !== 'transparent')"
    class="selected-color-container"
    :class="{'has__alpha': alpha}"
  >
    <ColorPicker 
      v-if="picker"
      v-model="color[index || 0]" 
      class="selected-color"
      @update:modelValue="updateWithPicker"
     />
    <div
      v-else
      class="selected-color"
      @click="$emit('click-swatch')"
      :style="getFillStyle(color)"
    ></div>
    <div class="grad-type" v-if="fill.length > 1">Gradient</div>
    <input
      v-else
      class="color-input"
      :class="{'has_alpha': alpha}"
      v-model="typedColor"
      @blur="selectFill"
      @paste="handlePaste"
      @keyup.enter="selectFill"
    />
    <a
      v-if="clearable"
      @click="selectFill(true)"
      class="remove-background button button__icon"
    >
      <Icon icon="x" />
    </a>
  </div>
  <div v-else>
    <button class="button button__icon" @click="addFill">
      <Icon icon="plus" />
    </button>
  </div>
</template>

<style lang="scss">

.selected-color-container.has__alpha {
  max-width: 10rem;
  width: 10rem;
  margin-right: -0.75rem;
}

</style>