import { generateUUID } from './Helpers.js';
import { EFFECTS } from './Shaders.js';
import { MaskCommand, MoveCommand, VisibilityToggleCommand, ResetPropCommand } from '../scripts/Commands';
import { optimizeShaders } from '../scripts/ShaderOptimize';
import { basicParams, basicNoDispNoBlendParams } from '../scripts/BasicShader.js';
import { hexToRgb } from '../scripts/ColorHelpers';
import { getShapeFill, rotate, getShapeBoundingBox } from '../scripts/Draw.js';
import { StateEffectAppear, StateEffectScroll, StateEffectHover } from '../scripts/StateEffect';
import { Vec2, Vec3 } from 'curtainsjs';
import { StudioStore } from '../stores/StudioStore.js';
import { watch } from 'vue';

function serializeNestedArray(arr, skip) {
  if (skip) {
    return arr;
  }
  let obj = {};
  for (let i = 0; i < arr.length; i++) {
    obj[i] = arr[i];
  }
  return obj;
}

function deserializeNestedArray(obj) {
  if (!obj) return [];
  if (Array.isArray(obj)) {
    return obj;
  }
  if (obj && typeof obj === 'string') {
    obj = JSON.parse(obj);
  }
  return Object.values(obj);
}

// Helper function to convert hex to rgb float array
function hexToRgbFloat(hex) {
  const rgb = hexToRgb(hex);
  return rgb.map(val => val / 255);
}

// Convert fill colors to Float32Array
function convertFillColors(fill) {
  if (Array.isArray(fill)) {
    return new Float32Array(fill.map(hexToRgbFloat).flat());
  }
  return fill;
}

function prepBreakpoints(bps) {
  // Use map to transform the array and return a new array
  const newbps = bps.map(breakpoint => {
    // Use object destructuring to create a new object
    const newProps = {};

    // Transform each property in the breakpoint's props
    for (let prop in breakpoint.props) {
      if (breakpoint.props[prop] instanceof Array) {
        newProps[prop] = serializeNestedArray(breakpoint.props[prop]);
      } else if (breakpoint.props[prop]?.type === 'Vec2') {
        newProps[prop] = {
          type: 'Vec2',
          _x: breakpoint.props[prop]._x,
          _y: breakpoint.props[prop]._y,
        };
      } else if (breakpoint.props[prop]?.type === 'Vec3') {
        newProps[prop] = {
          type: 'Vec3',
          _x: breakpoint.props[prop]._x,
          _y: breakpoint.props[prop]._y,
          _z: breakpoint.props[prop]._z,
        };
      } else {
        // Preserve non-Vec2/Vec3 props
        newProps[prop] = breakpoint.props[prop];
      }
    }

    // Return a new breakpoint object with transformed properties
    return {
      ...breakpoint,
      props: newProps,
    };
  });

  return newbps;
}

function unpackBreakpoints(bps) {
  bps.forEach(breakpoint => {
    for (let prop in breakpoint.props) {
      if (breakpoint.props[prop]?.type === 'Vec2') {
        breakpoint.props[prop] = new Vec2(breakpoint.props[prop]._x, breakpoint.props[prop]._y);
      } else if (breakpoint.props[prop]?.type === 'Vec3') {
        breakpoint.props[prop] = new Vec3(
          breakpoint.props[prop]._x,
          breakpoint.props[prop]._y,
          breakpoint.props[prop]._z
        );
      } else if (typeof breakpoint.props[prop] === 'object') {
        breakpoint.props[prop] = deserializeNestedArray(breakpoint.props[prop]);
      }
    }
  });
  return bps;
}

function douglasPeucker(points, epsilon) {
  // Find the point with the maximum distance from line
  const distance = (point, lineStart, lineEnd) => {
    if (lineStart[0] === lineEnd[0]) {
      return Math.abs(point[0] - lineStart[0]);
    }
    const slope = (lineEnd[1] - lineStart[1]) / (lineEnd[0] - lineStart[0]);
    const intercept = lineStart[1] - slope * lineStart[0];
    const perpendicularDistance = Math.abs(slope * point[0] - point[1] + intercept) / Math.sqrt(slope * slope + 1);
    return perpendicularDistance;
  };

  const findMaxDistance = points => {
    let maxDist = 0;
    let index = 0;
    for (let i = 1; i < points.length - 1; i++) {
      let dist = distance(points[i], points[0], points[points.length - 1]);
      if (dist > maxDist) {
        maxDist = dist;
        index = i;
      }
    }
    return { maxDist, index };
  };

  const { maxDist, index } = findMaxDistance(points);

  // If max distance is greater than epsilon, recursively simplify
  if (maxDist > epsilon) {
    // Recursive call
    const leftSide = douglasPeucker(points.slice(0, index + 1), epsilon);
    const rightSide = douglasPeucker(points.slice(index), epsilon);

    // Combine results
    return [...leftSide.slice(0, -1), ...rightSide];
  } else {
    // If max distance is less than epsilon, return endpoints
    return [points[0], points[points.length - 1]];
  }
}

function getScaleFactor(aspectRatio) {
  return {
    x: Math.sqrt(StudioStore.state.canvasWidth / StudioStore.state.canvasHeight / aspectRatio),
    y: Math.sqrt((StudioStore.state.canvasHeight / StudioStore.state.canvasWidth) * aspectRatio),
  };
}

const breakpoints = [
  {
    name: 'Desktop',
    max: Infinity,
    min: 992,
  },
  {
    name: 'Tablet',
    max: 991,
    min: 576,
  },
  {
    name: 'Mobile',
    max: 575,
    min: 0,
  },
];

class Layer {
  local = {
    id: '',
    watches: 0,
    propertiesToWatch: [],
    initialBreakpoint: '',
  };
  breakpoints = [];

  constructor(args, id) {
    this.visible = args.visible !== undefined ? args.visible : !args.hidden || true;
    this.locked = args.locked || false;
    this.aspectRatio = args.aspectRatio || 1;
    this.layerName = args.layerName || '';
    this.breakpoints = unpackBreakpoints(args.breakpoints || []);

    breakpoints.forEach(n => {
      if (!this.getBreakpoint(n.name)) {
        this.breakpoints.push({
          name: n.name,
          max: n.max,
          min: n.min,
          props: {},
        });
      }
    });

    this.local.initialBreakpoint = this.getCurrentBreakpoint().name;

    this.local.id = id || generateUUID();
  }

  getIndex() {
    return StudioStore.state.history.map(n => n.local.id).indexOf(this.local.id);
  }

  deselect() {
    this.local.isSelected = false;
    if (this.justCreated) {
      this.justCreated = false;
    }
  }

  getPlane() {
    return StudioStore.state.curtain.planes.find(n => n.userData.id === this.local.id);
  }

  getPlanes() {
    return StudioStore.state.curtain.planes.filter(n => n.userData.id === this.local.id);
  }

  getMaskedItem() {
    if (this.mask) {
      return StudioStore.state.history.filter(n => n.visible && !n.parentLayer)[this.getIndex() - 1];
    } else {
      return false;
    }
  }

  toggleMask() {
    const command = new MaskCommand(this.mask ? 0 : 1, this.mask ? 1 : 0, this);
    StudioStore.performAction(command);
  }

  toggleVisibility() {
    const command = new VisibilityToggleCommand(this, !this.visible, this.visible);
    StudioStore.performAction(command);
  }

  toggleProp(prop) {
    this[prop] = !this[prop];
    if (prop === 'locked' && this[prop]) {
      StudioStore.setSelectedItem('');
    }
    if (prop === 'animating') {
      this.time = 0;
      if (this.updateUniforms) {
        this.updateUniforms();
      }
    }
  }

  moveToPosition(newpos, oldpos, bottom, top) {
    const command = new MoveCommand(newpos, oldpos, bottom, top);
    StudioStore.performAction(command);
  }

  getChildEffectItems() {
    if (this.effects && this.effects.length) {
      const childEffects = StudioStore.state.history.filter(n => n.visible && this.effects.includes(n.parentLayer));
      const orderedChildEffects = this.effects
        .map(effectId => childEffects.find(n => n.parentLayer === effectId))
        .filter(effect => effect !== undefined);
      return orderedChildEffects;
    } else {
      return [];
    }
  }

  isAnimating() {
    return (
      this.animating ||
      ('trackMouse' in this && this.trackMouse !== 0) ||
      ('axisTilt' in this && this.axisTilt !== 0) ||
      this.type === 'mouse' ||
      this.type === 'waterRipple' ||
      (this.states &&
        (this.states.appear.some(effect => !effect.complete) || this.states.scroll.length || this.states.hover.length))
    );
  }

  setupWatchers(properties) {
    properties.forEach(prop => {
      watch(
        () => {
          const layer = StudioStore.state.history.find(n => n.local.id === this.local.id);
          return layer ? layer[prop] : undefined;
        },
        (newVal, oldVal) => {
          if (newVal === undefined || oldVal === undefined) {
            return false;
          }

          const base = this.getBreakpoint('Desktop');

          if (this.hasValueChanged(newVal, oldVal)) {
            if (!base.props.hasOwnProperty(prop)) {
              base.props[prop] = oldVal.clone ? oldVal.clone() : oldVal;
            }
            this.updateBreakpoint(prop, newVal);
          }
        }
      );
    });
  }

  hasValueChanged(newVal, oldVal) {
    if (newVal === undefined || oldVal === undefined) {
      return false;
    }
    if (newVal.equals) {
      return !newVal.equals(oldVal);
    }
    if (Array.isArray(newVal) && Array.isArray(oldVal)) {
      if (newVal.length !== oldVal.length) {
        return true;
      }
      return newVal.some((val, index) => this.hasValueChanged(val, oldVal[index]));
    } else {
      return newVal !== oldVal;
    }
  }

  updateBreakpoint(propName, newVal) {
    const breakpoint = this.getCurrentBreakpoint();
    if (breakpoint && this.getBreakpoint(breakpoint.name)) {
      this.getBreakpoint(breakpoint.name).props[propName] = newVal.clone ? newVal.clone() : newVal;
    }
  }

  getBreakpoint(name) {
    return this.breakpoints.find(bp => bp.name === name);
  }

  getCurrentBreakpoint() {
    const width = StudioStore.state.currentSize.realDimensions[0];
    return breakpoints.find(bp => width <= bp.max && width >= bp.min);
  }

  isBreakpoint(prop) {
    const breakpoint = this.getCurrentBreakpoint();
    if (breakpoint && breakpoint.name !== 'Desktop') {
      let layerBreakpoint = this.getBreakpoint(breakpoint.name);
      let base = this.getBreakpoint('Desktop');
      if (layerBreakpoint) {
        return (
          layerBreakpoint.props.hasOwnProperty(prop) &&
          this.hasValueChanged(layerBreakpoint.props[prop], base.props[prop])
        );
      }
    }
    return false;
  }

  setBreakpointProp(prop, value) {
    if (value instanceof Vec2 || value instanceof Vec3) {
      this[prop].copy(value);
    } else if (value.type) {
      this[prop].x = value._x;
      this[prop].y = value._y;
      if (value._z !== undefined) {
        this[prop].z = value._z;
      }
    } else {
      this[prop] = value;
    }
  }

  resetBreakpointProp(prop) {
    const breakpoint = this.getCurrentBreakpoint();
    if (breakpoint) {
      let layerBreakpoint = this.getBreakpoint(breakpoint.name);
      if (layerBreakpoint && layerBreakpoint.props.hasOwnProperty(prop)) {
        // Remove the property from the current breakpoint
        delete layerBreakpoint.props[prop];

        // Check if there are any other properties left
        if (!Object.keys(layerBreakpoint.props).length) {
          // Remove the breakpoint if it has no properties
          this.breakpoints = this.breakpoints.filter(n => n.name !== layerBreakpoint.name);
        }

        if (this.render) {
          this.render();
        }
        if (this.updateUniforms) {
          this.updateUniforms();
        }
      }
    }
  }

  getInheritedProp(propName, currentBreakpointName) {
    const currentIndex = breakpoints.findIndex(bp => bp.name === currentBreakpointName);
    for (let i = currentIndex; i >= 0; i--) {
      const layerBreakpoint = this.getBreakpoint(breakpoints[i].name);
      if (layerBreakpoint && layerBreakpoint.props.hasOwnProperty(propName)) {
        return layerBreakpoint.props[propName];
      }
    }
    return null;
  }

  setBreakpointValues() {
    const currentBreakpoint = this.getCurrentBreakpoint();
    if (currentBreakpoint) {
      if (currentBreakpoint.name === 'Desktop') {
        this.local.initialBreakpoint = 'Desktop';
      }
      let inheritedValues = {};
      for (let prop of this.local.propertiesToWatch) {
        const inheritedValue = this.getInheritedProp(prop, currentBreakpoint.name);
        if (inheritedValue !== null) {
          inheritedValues[prop] = inheritedValue;
        }
      }

      for (let prop in inheritedValues) {
        if (this[prop] !== inheritedValues[prop]) {
          this.setBreakpointProp(prop, inheritedValues[prop]);
        }
      }

      if (this.render) {
        this.render();
      }
      if (this.updateUniforms) {
        this.updateUniforms();
      }
    }
  }

  watchWindow() {
    watch(
      () => StudioStore.state.currentSize.realDimensions[0],
      (newVal, oldVal) => {
        if (this.hasValueChanged(newVal, oldVal)) {
          this.setBreakpointValues();
        }
      }
    );
  }

  initializeBreakpoints() {
    this.setupWatchers(this.local.propertiesToWatch);
    this.watchWindow();
  }

  copy(id, obj, dontInit) {
    let copy;
    switch (obj?.layerType || this.layerType) {
      case 'shape':
        copy = new Shape(obj || this, id);
        break;
      case 'effect':
        copy = new Effect(obj || this, id);
        break;
      case 'image':
        copy = new Img(obj || this, id, dontInit);
        break;
      case 'text':
        copy = new TextBox(obj || this, id);
        break;
    }
    copy.breakpoints = unpackBreakpoints(prepBreakpoints(copy.breakpoints));
    return copy;
  }
}

class Element extends Layer {
  isElement = true;

  constructor(args, id) {
    super(args, id);
    this.opacity = args.opacity || 1;
    this.displace = args.displace || 0;
    this.trackMouse = args.trackMouse || 0;
    this.mouseMomentum = args.mouseMomentum || 0;
    this.blendMode = args.blendMode || 'NORMAL';
    this.bgDisplace = args.bgDisplace || 0;
    this.mask = args.mask || 0;
    this.maskBackground = args.maskBackground || new Vec3(0);
    this.maskAlpha = args.maskAlpha || 0;
    this.maskDepth = args.maskDepth || 0;
    this.dispersion = args.dispersion || 0;
    this.axisTilt = args.axisTilt || 0;
    this.states = {
      appear: [],
      scroll: [],
      hover: [],
    };
  }

  getParams() {
    return { params: basicParams, properties: this.default() };
  }

  getPixelRatio(scale) {
    const curtainResolution = StudioStore.state.currentSize.realDimensions[0] / StudioStore.state.canvasWidth;
    return Math.max(1.5, curtainResolution) * (scale || StudioStore.state.scale);
  }

  createLocalCanvas() {
    const canvas = document.createElement('canvas');
    const screenScale = this.getPixelRatio();

    canvas.width = StudioStore.state.canvasWidth * screenScale;
    canvas.height = StudioStore.state.canvasHeight * screenScale;

    const ctx = canvas.getContext('2d');
    ctx.scale(screenScale, screenScale);

    this.local.canvas = canvas;
    this.local.ctx = ctx;
  }

  resize(scale) {
    if (this.local.canvas) {
      const screenScale = this.getPixelRatio();
      this.local.canvas.width = StudioStore.state.canvasWidth * screenScale;
      this.local.canvas.height = StudioStore.state.canvasHeight * screenScale;

      this.local.ctx.scale(screenScale, screenScale);
    }
  }

  getPositionOffset() {
    const originalAspectRatio = this.aspectRatio || 1;
    const currentAspectRatio = StudioStore.state.currentSize.aspectRatio;
    const adjustedRatio = originalAspectRatio / currentAspectRatio;
    const originalWidth = StudioStore.state.canvasWidth * Math.sqrt(adjustedRatio);
    const originalHeight = StudioStore.state.canvasHeight / Math.sqrt(adjustedRatio);

    const translateX = this.translateX;
    const translateY = this.translateY;

    let offsetX = (StudioStore.state.canvasWidth - originalWidth) / 2;
    let offsetY = (StudioStore.state.canvasHeight - originalHeight) / 2;

    if (this.layerType === 'image') {
      offsetX += originalWidth / 2;
      offsetY += originalHeight / 2;
    }

    let newX = translateX + offsetX;
    let newY = translateY + offsetY;

    return { x: newX, y: newY, offX: offsetX, offY: offsetY };
  }

  packageShaders() {
    let params = basicParams;
    if (this.displace === 0 && this.blendMode === 'NORMAL' && !this.mask) {
      params = basicNoDispNoBlendParams;
    }

    if (this.trackMouse && this.getChildEffectItems().some(n => n.isMask)) {
      this.trackMouse = 0;
    }

    this.breakpoints = this.breakpoints.filter(n => n.props && Object.keys(n.props).length);
    // Remove "Desktop" if it's the only breakpoint remaining
    if (this.breakpoints.length === 1 && this.breakpoints[0].name === 'Desktop') {
      this.breakpoints = [];
    }

    this.effects = this.effects.filter(n =>
      StudioStore.state.history.filter(n => n.visible).find(o => o.parentLayer === n)
    );

    optimizeShaders(this, { params });
  }
}

export class Shape extends Element {
  layerType = 'shape';

  constructor(args, id) {
    super(args, id);
    let props = this.default(args || {});
    for (let prop in props) {
      this[prop] = props[prop];
    }

    if (Object.keys(args).length) {
      this.createLocalCanvas();
    }

    this.local.propertiesToWatch = [
      'translateX',
      'translateY',
      'borderRadius',
      'rotation',
      'fill',
      'gradientAngle',
      'gradientType',
      'type',
      'fitToCanvas',
      'opacity',
      'numSides',
      'trackMouse',
      'mouseMomentum',
      'strokeWidth',
      'stroke',
      'width',
      'height',
    ];
  }

  default(args) {
    return {
      blendMode: args.blendMode || 'NORMAL',
      borderRadius: args.borderRadius || 0,
      coords: args.coords || [],
      displace: args.displace || 0,
      dispersion: args.dispersion || 0,
      bgDisplace: args.bgDisplace || 0,
      effects: args.effects || [],
      fill: args.fill || ['#777777'],
      fitToCanvas: args.fitToCanvas || false,
      gradientAngle: args.gradientAngle || args.gradAngle || 0,
      gradientType: args.gradientType || args.gradType || 'linear',
      opacity: args.opacity || 1,
      rotation: args.rotation || 0,
      translateX: args.translateX || 0,
      translateY: args.translateY || 0,
      type: args.type || 'rectangle',
      stroke: args.stroke || ['#000000'],
      strokeWidth: args.strokeWidth || 0,
      numSides: args.numSides || 3,
      trackMouse: args.trackMouse || 0,
      width: args.width || null,
      height: args.height || null,
    };
  }

  package(skipSerialization) {
    return {
      layerType: 'shape',
      layerName: this.layerName,
      visible: this.visible,
      locked: this.locked,
      aspectRatio: this.aspectRatio,
      axisTilt: this.axisTilt,
      blendMode: this.blendMode,
      breakpoints: prepBreakpoints(this.breakpoints),
      borderRadius: this.borderRadius,
      dispersion: this.dispersion,
      bgDisplace: this.bgDisplace,
      displace: this.displace,
      effects: serializeNestedArray(this.effects, skipSerialization),
      fill: serializeNestedArray(this.fill, skipSerialization),
      fitToCanvas: this.fitToCanvas,
      gradientAngle: this.gradientAngle,
      gradientType: this.gradientType,
      mask: this.mask,
      maskAlpha: this.maskAlpha,
      maskDepth: this.maskDepth,
      maskBackground: {
        type: 'Vec3',
        _x: this.maskBackground._x,
        _y: this.maskBackground._y,
        _z: this.maskBackground._z,
      },
      numSides: this.numSides,
      opacity: this.opacity,
      rotation: this.rotation,
      stroke: serializeNestedArray(this.stroke, skipSerialization),
      strokeWidth: this.strokeWidth,
      translateX: this.translateX,
      translateY: this.translateY,
      type: this.type,
      trackMouse: this.trackMouse,
      mouseMomentum: this.mouseMomentum,
      width: this.width,
      height: this.height,
    };
  }

  unpackage() {
    this.fill = deserializeNestedArray(this.fill);
    this.stroke = deserializeNestedArray(this.stroke);
    this.coords = deserializeNestedArray(this.coords);

    if (this.coords.length && (!this.width || !this.height)) {
      this.width = [this.coords[0][0], this.coords[1][0]];
      this.height = [this.coords[1][1], this.coords[2][1]];
    }

    this.coords = [
      [this.width[0], this.height[0]],
      [this.width[1], this.height[0]],
      [this.width[1], this.height[1]],
      [this.width[0], this.height[1]],
    ];

    this.effects = deserializeNestedArray(this.effects);
    this.maskBackground = new Vec3(this.maskBackground._x, this.maskBackground._y, this.maskBackground._z);
    return this;
  }

  getTransformedCoords(box) {
    if (this.fitToCanvas) {
      let coords = [
        [0, 0],
        [StudioStore.state.canvasWidth, 0],
        [StudioStore.state.canvasWidth, StudioStore.state.canvasHeight],
        [0, StudioStore.state.canvasHeight],
      ];
      this.coords = coords;
      return coords;
    }
    const offsetPosition = this.getPositionOffset();
    const scale = getScaleFactor(this.aspectRatio);
    const minScale = 1 || Math.min(scale.x, scale.y);

    this.coords = [
      [this.width[0], this.height[0]],
      [this.width[1], this.height[0]],
      [this.width[1], this.height[1]],
      [this.width[0], this.height[1]],
    ];

    let coords = this.coords.map(([x, y]) => rotate(box.center.x, box.center.y, x, y, -this.rotation * 360));

    return coords.map(([x, y]) => {
      let translatedX = x - box.center.x;
      let translatedY = y - box.center.y;

      let scaledX = translatedX * minScale;
      let scaledY = translatedY * minScale;

      return [
        Math.round(scaledX + box.center.x + offsetPosition.x),
        Math.round(scaledY + box.center.y + offsetPosition.y),
      ];
    });
  }

  render() {
    const box = this.local.boxStart || getShapeBoundingBox(this.coords);
    const coords = this.getTransformedCoords(box);

    this.local.ctx.beginPath();

    if (this.type === 'rectangle') {
      const box = getShapeBoundingBox(this.coords);
      let borderRadius = (this.borderRadius * Math.min(box.width, box.height)) / 2;

      const rotate = (x, y, angle) => {
        const cos = Math.cos(angle);
        const sin = Math.sin(angle);
        return [x * cos - y * sin, x * sin + y * cos];
      };

      const rotationAngle = this.rotation * 2 * Math.PI;

      if (coords.length) {
        this.local.ctx.beginPath();

        let directions = [-1, 1, -1, 1];

        if (!this.fitToCanvas) {
          let invertedX = this.coords[0][0] < this.coords[1][0];
          let invertedY = this.coords[0][1] > this.coords[2][1];

          if (invertedX) {
            directions = [-1, -1, -1, -1];
          }

          if (invertedY) {
            directions = [1, 1, 1, 1];
          }

          if (invertedX && invertedY) {
            directions = [1, -1, 1, -1];
          }
        }

        for (let i = 0; i < coords.length; i++) {
          const [x1, y1] = coords[i];
          const [x2, y2] = coords[(i + 1) % coords.length];
          const angle = ((i + 1) * Math.PI) / 2 + rotationAngle;
          const [dx, dy] = rotate(borderRadius, 0, angle);

          let direction = directions[i];

          this.local.ctx.lineTo(x1 - dx * direction, y1 - dy * direction);
          this.local.ctx.arcTo(x1, y1, x2, y2, borderRadius);
        }

        this.local.ctx.closePath();
        this.local.ctx.stroke();
      }
    } else if (this.type === 'circle') {
      let box = getShapeBoundingBox(coords);
      const boxLocal = getShapeBoundingBox(this.coords);
      this.local.ctx.ellipse(
        box.center.x,
        box.center.y,
        boxLocal.width / 2,
        boxLocal.height / 2,
        this.rotation * Math.PI * 2,
        0,
        2 * Math.PI
      );
    } else if (this.type === 'polygon') {
      const sides = this.numSides;
      if (coords.length >= 2) {
        // Use the getShapeBoundingBox function to get the bounding box of the shape
        const bbox = getShapeBoundingBox(coords);

        // Determine if we've flipped the bounding box
        const flipped = this.coords[0][1] > this.coords[2][1];

        // The center for the triangle is at the midpoint of the bounding box's height
        const centerY = bbox.center.y;
        const centerX = bbox.center.x;

        // Define the rotation function
        const rotate = (x, y, angle, cX, cY) => {
          const cos = Math.cos(angle);
          const sin = Math.sin(angle);
          // Translate point back to origin:
          x -= cX;
          y -= cY;
          // Rotate point
          const xNew = x * cos - y * sin;
          const yNew = x * sin + y * cos;
          // Translate point back:
          x = xNew + cX;
          y = yNew + cY;
          return [x, y];
        };

        // Get the rotation angle in radians
        const rotationAngle = (this.rotation + (flipped ? 0.5 : 0)) * 2 * Math.PI;

        const width = (bbox.width / Math.sqrt(3)) * 0.86;
        const height = (bbox.height / Math.sqrt(3)) * 0.86;

        // Draw the polygon
        this.local.ctx.beginPath();
        for (let i = 0; i < sides; i++) {
          // The angle needs to start at -90 degrees for the first vertex to be at the top, adjusting for flip
          let baseAngle = -Math.PI / 2;
          const angle = baseAngle + (2 * Math.PI * i) / sides;
          let x = centerX + width * Math.cos(angle);
          let y = centerY + height * Math.sin(angle);
          [x, y] = rotate(x, y, rotationAngle, centerX, centerY);

          if (i === 0) {
            this.local.ctx.moveTo(x, y);
          } else {
            this.local.ctx.lineTo(x, y);
          }
        }

        this.local.ctx.closePath();
      } else {
        console.warn('Polygon drawing requires at least 2 coordinates to determine the reference point.');
      }
    }

    this.local.ctx.fillStyle = getShapeFill(this.local.ctx, this, coords);
    this.local.ctx.clearRect(0, 0, StudioStore.state.canvasWidth, StudioStore.state.canvasHeight);
    this.local.ctx.fill();

    if (this.strokeWidth) {
      this.local.ctx.strokeStyle = this.stroke[0];
      this.local.ctx.lineWidth = this.strokeWidth;
      this.local.ctx.stroke();
    }
  }

  getRelativeScale() {
    return Math.min(
      (StudioStore.state.canvasWidth * StudioStore.state.dpi) / this.width,
      (StudioStore.state.canvasHeight * StudioStore.state.dpi) / this.height
    );
  }
}

export class Effect extends Layer {
  layerType = 'effect';

  constructor(args, id, background) {
    super(args, id);

    this.type = args.type;

    if (background || args.isBackground) {
      this.isBackground = true;
      this.type = 'gradient';
    } else {
      this.isBackground = false;
    }

    const effectProperties = this.getParams().properties;

    if (effectProperties) {
      for (let prop in effectProperties) {
        if (
          prop === 'fill' &&
          (this.type === 'gradient' || this.type === 'gradientFill' || this.type === 'gradientMap') &&
          args.fill
        ) {
          this.fill = Object.values(args.fill);
        } else {
          this[prop] = prop in args ? args[prop] : effectProperties[prop].value;
        }
      }
    }

    this.effects = args.effects || [];
    this.texture = args.texture || false;
    this.parentLayer = args.parentLayer || false;
    this.animating = args.animating || false;
    this.mouseMomentum = args.mouseMomentum || 0;
    this.isMask = args.isMask || 0;
    this.states = {
      appear: args.states && args.states.appear ? args.states.appear.map(n => new StateEffectAppear(n)) : [],
      scroll: args.states && args.states.scroll ? args.states.scroll.map(n => new StateEffectScroll(n)) : [],
      hover: args.states && args.states.hover ? args.states.hover.map(n => new StateEffectHover(n)) : [],
    };
    this.customFragmentShaders = args.customFragmentShader
      ? [args.customFragmentShader]
      : args.customFragmentShaders || [];
    this.customVertexShaders = args.customVertexShader ? [args.customVertexShader] : args.customVertexShaders || [];
    this.compiledFragmentShaders = args.compiledFragmentShaders || [];
    this.compiledVertexShaders = args.compiledVertexShaders || [];

    if (effectProperties) {
      let filteredProperties = Object.keys(effectProperties).filter(key => !effectProperties[key].responsiveDisabled);
      this.local.propertiesToWatch = filteredProperties;
    }

    if (this.type === 'custom' && !this.customFragmentShaders.length) {
      this.customFragmentShaders = [this.getParams().params.fragmentShader];
      this.customVertexShaders = [this.getParams().params.vertexShader];
    }
  }

  getParams() {
    if (this.type === 'mouse') {
      let params = EFFECTS.mouse;
      params.params.uniforms = { ...params.params.uniforms, ...EFFECTS.mouseTrail.uniforms };
      return params;
    }
    return EFFECTS[this.type];
  }

  resetProp(prop) {
    const params = this.getParams();
    const originalValue = params.properties[prop].value;

    const command = new ResetPropCommand(this, prop, this[prop], originalValue);
    StudioStore.performAction(command);
  }

  updateUniforms() {
    const parent = this.getParent();
    this.getPlanes().forEach(plane => {
      StudioStore.updatePlaneUniforms(plane, this, parent);
    });
  }

  package(skipSerialization) {
    const params = this.getParams();
    let packaged = {
      states: {
        appear: this.states.appear ? this.states.appear.map(n => n.package()) : [],
        scroll: this.states.scroll ? this.states.scroll.map(n => n.package()) : [],
        hover: this.states.hover ? this.states.hover.map(n => n.package()) : [],
      },
      breakpoints: prepBreakpoints(this.breakpoints),
      layerType: this.layerType,
      layerName: this.layerName,
      customFragmentShaders: this.customFragmentShaders,
      customVertexShaders: this.customVertexShaders,
      visible: this.visible,
      locked: this.locked,
      isBackground: this.isBackground,
      aspectRatio: this.aspectRatio,
      type: this.type,
      texture: this.texture,
      mouseMomentum: this.mouseMomentum,
      isMask: this.isMask,
      parentLayer: this.parentLayer,
      animating: this.animating,
    };

    for (let prop in params.properties) {
      if (!skipSerialization) {
        if (params.properties[prop].value.type === 'Vec2') {
          packaged[prop] = {
            type: 'Vec2',
            _x: this[prop]._x,
            _y: this[prop]._y,
          };
        } else if (params.properties[prop].value.type === 'Vec3') {
          packaged[prop] = {
            type: 'Vec3',
            _x: this[prop]._x,
            _y: this[prop]._y,
            _z: this[prop]._z,
          };
        } else {
          packaged[prop] = this[prop];
        }
      } else {
        packaged[prop] = this[prop];
      }
    }
    if (this.fill && this.type === 'gradient' || this.type === 'gradientMap' || this.type === 'gradientFill') {
      packaged.count = this.fill.length;
    }

    return packaged;
  }

  unpackage() {
    for (let prop in this) {
      if (this[prop].type === 'Vec2') {
        if (this.getParams().properties[prop].value.type === 'Vec3') {
          this[prop] = new Vec3(this[prop]._x, this[prop]._y, 0);
        } else {
          this[prop] = new Vec2(this[prop]._x, this[prop]._y);
        }
      } else if (this[prop].type === 'Vec3') {
        this[prop] = new Vec3(this[prop]._x, this[prop]._y, this[prop]._z);
      }
    }

    return this;
  }

  getParent() {
    return StudioStore.state.history
      .filter(n => n.effects && n.effects.length)
      .find(n => n.effects.includes(this.parentLayer));
  }

  getChildEffectIndex() {
    const parentEffects = this.getParent().effects;
    return parentEffects.indexOf(this.parentLayer);
  }

  resetStateEffects() {
    this.getPlanes().forEach(plane => {
      plane.userData.createdAt = performance.now();
    });
    if (this.states) {
      this.states.appear
        .filter(n => n.resetState)
        .forEach(effect => {
          effect.resetState();
        });
      this.states.scroll
        .filter(n => n.resetState)
        .forEach(effect => {
          effect.resetState();
        });
    }
  }

  packageShaders() {
    const params = this.getParams();

    if (this.type === 'gradient' || this.type === 'gradientMap' || this.type === 'gradientFill') {
      this.count = this.fill.length;
      this.fill.forEach((color, i) => {
        const rgb = hexToRgb(color).map(v => v / 255);
        if (params.params.uniforms[`color${i}`]) {
          params.params.uniforms[`color${i}`].value = new Vec3(...rgb);
        }
      });
    }

    if (this.isMask && this.parentLayer && this.getParent()) {
      this.parentTrackMouse = this.getParent().trackMouse;
    }

    let pingpongParams = null;
    switch (this.type) {
      case 'mouse':
        pingpongParams = EFFECTS.mouseTrail;
        break;
      case 'waterRipple':
        pingpongParams = EFFECTS.waterRipplePingPong;
        break;
      default:
        null;
    }

    optimizeShaders(this, params, pingpongParams);

    let data = {
      downSample: params.downSample,
      depth: this.type === 'bulge',
      uniforms: {},
    };

    if (params.passes) {
      data.passes = params.passes;
    }
    if (params.texture) {
      data.texture = params.texture;
    }

    const retainedProps = [
      'animating',
      'aspectRatio',
      'breakpoints',
      'compiledFragmentShaders',
      'compiledVertexShaders',
      'data',
      'isMask',
      'visible',
      'mouseMomentum',
      'type',
      'layerType',
      'trackMouseMove',
      'trackMouse',
      'speed',
    ];

    if (this.type === 'custom') {
      retainedProps.push(...['customFragmentShaders', 'customVertexShaders', 'texture']);
    }

    if (this.texture && !(this.type === 'sdf_shape' && this.shape === 20)) {
      retainedProps.push('texture');
    }

    if (this.parentLayer) {
      retainedProps.push('parentLayer');
    }

    // Filter out breakpoints that have no props or have empty props
    this.breakpoints = this.breakpoints.filter(bp => bp.props && Object.keys(bp.props).length);

    // Convert fill colors if the prop is "fill" and ensure uniform properties are set for non-Desktop breakpoints
    this.breakpoints.forEach(bp => {
      if (bp.props.fill) {
        bp.props.fill = convertFillColors(bp.props.fill);
      }
      if (bp.name !== 'Desktop') {
        Object.keys(bp.props).forEach(prop => {
          if (!data.uniforms[prop]) {
            data.uniforms[prop] = params.params.uniforms[prop];
          }
        });
      }
    });

    // Remove "Desktop" if it's the only breakpoint remaining
    if (this.breakpoints.length === 1 && this.breakpoints[0].name === 'Desktop') {
      this.breakpoints = [];
    }

    if (this.states && [...this.states.appear, ...this.states.scroll, ...this.states.hover].length) {
      retainedProps.push('states');

      [...this.states.appear, ...this.states.scroll, ...this.states.hover].forEach(stateEffect => {
        retainedProps.push(stateEffect.prop);

        let props = params.params.uniforms[stateEffect.prop];
        if (props) {
          stateEffect.uniformData = {
            type: props.type,
            name: props.name,
          };
        }
      });
    }

    for (let prop in this) {
      if (!retainedProps.includes(prop)) {
        delete this[prop];
      }
    }

    this.data = data;
  }
}

export class Img extends Element {
  layerType = 'image';
  imageLoaded = false;

  constructor(args, id, dontInit) {
    super(args, id);
    let props = this.default(args || {});
    for (let prop in props) {
      this[prop] = props[prop];
    }

    if (Object.keys(args).length && !dontInit) {
      this.createLocalCanvas();
      this.loadImage();
    }

    this.local.propertiesToWatch = [
      'translateX',
      'translateY',
      'size',
      'fitToCanvas',
      'rotation',
      'opacity',
      'trackMouse',
      'mouseMomentum',
      'axisTilt',
    ];
  }

  default(args) {
    return {
      bgDisplace: args.bgDisplace || 0,
      dispersion: args.dispersion || 0,
      effects: args.effects || [],
      size: args.size || 0.25,
      rotation: args.rotation || args.angle || 0,
      height: args.height || 50,
      displace: args.displace || 0,
      fitToCanvas: args.fitToCanvas || false,
      rotation: args.rotation || 0,
      scaleX: args.scaleX || 1,
      scaleY: args.scaleY || 1,
      src: args.src || '',
      thumb: args.thumb || '',
      translateX: args.translateX || 0,
      translateY: args.translateY || 0,
      width: args.width || 50,
      trackMouse: args.trackMouse || 0,
      mouseMomentum: this.mouseMomentum,
    };
  }

  package(skipSerialization) {
    return {
      layerType: 'image',
      layerName: this.layerName,
      visible: this.visible,
      locked: this.locked,
      aspectRatio: this.aspectRatio,
      axisTilt: this.axisTilt,
      breakpoints: prepBreakpoints(this.breakpoints),
      dispersion: this.dispersion,
      bgDisplace: this.bgDisplace,
      blendMode: this.blendMode,
      displace: this.displace,
      height: this.height,
      effects: serializeNestedArray(this.effects, skipSerialization),
      rotation: this.rotation,
      fitToCanvas: this.fitToCanvas,
      size: this.size,
      mask: this.mask,
      maskAlpha: this.maskAlpha,
      maskBackground: {
        type: 'Vec3',
        _x: this.maskBackground._x,
        _y: this.maskBackground._y,
        _z: this.maskBackground._z,
      },
      maskDepth: this.maskDepth,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      src: this.src,
      opacity: this.opacity,
      thumb: this.thumb,
      translateX: this.translateX,
      translateY: this.translateY,
      trackMouse: this.trackMouse,
      mouseMomentum: this.mouseMomentum,
      width: this.width,
    };
  }

  unpackage() {
    this.effects = deserializeNestedArray(this.effects);
    this.maskBackground = new Vec3(this.maskBackground._x, this.maskBackground._y, this.maskBackground._z);
    return this;
  }

  loadImage() {
    const img = new Image();
    const thumb = new Image();
    let loaded = false;

    img.crossOrigin = 'Anonymous';
    thumb.crossOrigin = 'Anonymous';

    img.addEventListener(
      'load',
      () => {
        loaded = true;
        this.local.img = img;
        this.width = img.width;
        this.height = img.height;
        this.render = this.renderImage;
        this.render();
        StudioStore.renderNFrames(2);
      },
      false
    );

    thumb.addEventListener(
      'load',
      () => {
        if (!loaded) {
          this.local.img = thumb;
          this.width = thumb.width;
          this.height = thumb.height;
          this.render = this.renderImage;
          this.render();
          StudioStore.renderNFrames(1);
        }
      },
      false
    );

    thumb.src = this.thumb;
    img.src = this.src;
  }

  getImageCoords() {
    if (StudioStore.state.embedded) {
      return [];
    } else if (this.fitToCanvas) {
      let halfWidth = StudioStore.state.canvasWidth / 2;
      let halfHeight = StudioStore.state.canvasHeight / 2;
      return [
        [-halfWidth, -halfHeight],
        [halfWidth, -halfHeight],
        [halfWidth, halfHeight],
        [-halfWidth, halfHeight],
      ];
    } else {
      const relativeScale = this.getRelativeScale();

      let width = Math.round(this.width * relativeScale * this.scaleX * this.size);
      let height = Math.round(this.height * relativeScale * this.scaleY * this.size);
      return [
        [-width / 2, -height / 2],
        [width / 2, -height / 2],
        [width / 2, height / 2],
        [-width / 2, height / 2],
      ];
    }
  }

  getRelativeScale() {
    return Math.min(1080 / this.width, 1080 / this.height);
  }

  renderImage() {
    this.coords = this.getImageCoords();

    this.coords = this.getImageCoords();
    const offsetPosition = this.getPositionOffset();

    let x = offsetPosition.x;
    let y = offsetPosition.y;
    const angle = this.rotation * 360 * (Math.PI / 180);
    const relativeScale = this.getRelativeScale();

    let width = this.width * relativeScale * this.scaleX;
    let height = this.height * relativeScale * this.scaleY;

    this.local.ctx.clearRect(0, 0, StudioStore.state.canvasWidth, StudioStore.state.canvasHeight);

    if (this.fitToCanvas) {
      let aspectRatio = this.width / this.height;
      let canvasWidth = StudioStore.state.canvasWidth;
      let canvasHeight = StudioStore.state.canvasHeight;
      let canvasAspectRatio = canvasWidth / canvasHeight;

      if (canvasAspectRatio < aspectRatio) {
        height = canvasHeight;
        width = canvasHeight * aspectRatio;
      } else {
        width = canvasWidth;
        height = canvasWidth / aspectRatio;
      }

      x = StudioStore.state.canvasWidth / 2;
      y = StudioStore.state.canvasHeight / 2;

      this.local.ctx.save();
      this.local.ctx.translate(x, y);
      this.local.ctx.drawImage(this.local.img, -width / 2, -height / 2, width, height);
      this.local.ctx.restore();
    } else {
      this.local.ctx.save();
      this.local.ctx.translate(x, y);
      this.local.ctx.rotate(angle);
      this.local.ctx.scale(this.size, this.size);
      this.local.ctx.drawImage(this.local.img, -width / 2, -height / 2, width, height);
      this.local.ctx.restore();
    }
  }

  render() {
    // loading...
    this.coords = this.getImageCoords();
  }

  getTranslateX() {
    const relativeScale = this.getRelativeScale();
    let width = this.width * relativeScale * this.scaleX * this.size;
    return this.translateX + (StudioStore.state.canvasWidth - width) / 2;
  }

  getTranslateY() {
    const relativeScale = this.getRelativeScale();
    let height = this.height * relativeScale * this.scaleY * this.size;
    return this.translateY + (StudioStore.state.canvasHeight - height) / 2;
  }

  setTranslateX(value) {
    const relativeScale = this.getRelativeScale();
    let width = this.width * relativeScale * this.scaleX * this.size;
    this.translateX = value - (StudioStore.state.canvasWidth - width) / 2;
  }

  setTranslateY(value) {
    const relativeScale = this.getRelativeScale();
    let height = this.height * relativeScale * this.scaleY * this.size;
    this.translateY = value - (StudioStore.state.canvasHeight - height) / 2;
  }
}

export class TextBox extends Element {
  layerType = 'text';
  justCreated = false;

  constructor(args, id) {
    super(args, id);
    let props = this.default(args || {});
    for (let prop in props) {
      this[prop] = props[prop];
    }

    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (Object.keys(args).length) {
      this.createLocalCanvas();
      requestAnimationFrame(() => {
        this.coords = [
          [-2, 0],
          [-2 + this.width, 0],
          [-2 + this.width, 0 + this.height],
          [-2, 0 + this.height],
        ];
        this.render();
      });
    }

    // Properties to watch
    this.local.propertiesToWatch = [
      'translateX',
      'translateY',
      'fontSize',
      'lineHeight',
      'letterSpacing',
      'fontFamily',
      'fill',
      'fontStyle',
      'fontWeight',
      'gradientAngle',
      'gradientType',
      'textAlign',
      'textContent',
      'rotation',
      'trackMouse',
      'mouseMomentum',
      'width',
      'height',
    ];
  }

  default(args) {
    return {
      bgDisplace: args.bgDisplace || 0,
      dispersion: args.dispersion || 0,
      effects: args.effects || [],
      fill: args.fill || ['#000000'],
      fontSize: args.fontSize || 24,
      fontCSS: args.fontCSS || {
        family: 'Inter',
        src: '"http://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf"',
      },
      lineHeight: args.lineHeight || 25,
      letterSpacing: args.letterSpacing || 0,
      fontFamily: args.fontFamily || 'Inter',
      fontStyle: args.fontStyle || 'regular',
      fontWeight: args.fontWeight || '400',
      textAlign: args.textAlign || 'left',
      textContent: args.textContent || 'New text',
      gradientAngle: args.gradientAngle || args.gradAngle || 0,
      gradientType: args.gradientType || args.gradType || 'linear',
      coords: args.coords || [],
      rotation: args.rotation || 0,
      translateX: args.translateX || 0,
      translateY: args.translateY || 0,
      trackMouse: args.trackMouse || 0,
      mouseMomentum: args.mouseMomentum || 0,
      width: args.width || 200,
      height: args.height || 50,
    };
  }

  package(skipSerialization) {
    return {
      layerType: 'text',
      layerName: this.layerName,
      visible: this.visible,
      locked: this.locked,
      aspectRatio: this.aspectRatio,
      breakpoints: prepBreakpoints(this.breakpoints),
      dispersion: this.dispersion,
      axisTilt: this.axisTilt,
      bgDisplace: this.bgDisplace,
      blendMode: this.blendMode,
      coords: serializeNestedArray(this.coords, skipSerialization),
      displace: this.displace,
      effects: serializeNestedArray(this.effects, skipSerialization),
      fill: serializeNestedArray(this.fill, skipSerialization),
      gradientAngle: this.gradientAngle,
      gradientType: this.gradientType,
      rotation: this.rotation,
      translateX: this.translateX,
      translateY: this.translateY,
      fontFamily: this.fontFamily,
      fontCSS: this.fontCSS,
      fontSize: this.fontSize,
      mask: this.mask,
      maskAlpha: this.maskAlpha,
      maskDepth: this.maskDepth,
      maskBackground: {
        type: 'Vec3',
        _x: this.maskBackground._x,
        _y: this.maskBackground._y,
        _z: this.maskBackground._z,
      },
      lineHeight: this.lineHeight,
      letterSpacing: this.letterSpacing,
      fontStyle: this.fontStyle,
      fontWeight: this.fontWeight,
      opacity: this.opacity,
      textAlign: this.textAlign,
      textContent: this.textContent,
      trackMouse: this.trackMouse,
      mouseMomentum: this.mouseMomentum,
      width: this.width,
      height: this.height,
    };
  }

  unpackage() {
    this.fill = deserializeNestedArray(this.fill);
    this.coords = deserializeNestedArray(this.coords);
    this.effects = deserializeNestedArray(this.effects);
    this.maskBackground = new Vec3(this.maskBackground._x, this.maskBackground._y, this.maskBackground._z);

    return this;
  }

  render() {
    if (!this?.local?.ctx) {
      console.warn('Canvas context not available for TextBox render');
      return;
    }
    
    const position = this.getPositionOffset();

    let x = position.x;
    let y = position.y;

    let index = 0;

    let width = this.width;
    let height = this.height;
    let size = this.fontSize > 0 ? this.fontSize : 0;
    let lineHeight = this.lineHeight > 0 ? this.lineHeight : 0;
    let style = this.fontStyle.includes('italic') ? 'italic' : 'normal';
    let weight = '400';

    this.local.textBoxPos = { x: x, y: y };

    this.local.ctx.clearRect(0, 0, StudioStore.state.canvasWidth, StudioStore.state.canvasHeight);

    this.local.ctx.font = `${style} ${weight} ${size}px/${lineHeight}px ${this.fontFamily}, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial`;

    if (!this.isSafari) {
      this.local.ctx.textAlign = this.textAlign;
      this.local.ctx.letterSpacing = this.letterSpacing + 'px';
    }

    const minWidth = this.local.ctx.measureText('m').width; // Using 'm' as it's one of the widest characters
    width = Math.max(width, minWidth);

    this.local.ctx.save();
    this.local.ctx.translate(x + width / 2, y + height / 2);
    this.local.ctx.rotate((this.rotation * 360 * Math.PI) / 180);
    this.local.ctx.translate(-(x + width / 2), -(y + height / 2));

    if (this.textAlign === 'center') {
      x += width / 2;
    }
    if (this.textAlign === 'right') {
      x += width;
    }

    this.local.ctx.fillStyle = getShapeFill(this.local.ctx, this, this.coords);

    const drawTextWithSpacing = (ctx, text, startX, startY, spacing, align, containerWidth) => {
      // Calculate total width of the text with spacing
      let textTotalWidth = text
        .split('')
        .reduce((acc, char, i) => acc + ctx.measureText(char).width + (i < text.length - 1 ? spacing : 0), 0);

      // Determine starting X position based on text alignment
      let currentX;
      if (align === 'center') {
        currentX = startX + (containerWidth - textTotalWidth) / 2 - containerWidth / 2;
      } else if (align === 'right') {
        currentX = startX;
      } else {
        // align === 'left'
        currentX = startX;
      }

      // Draw each character
      if (align === 'right') {
        for (let i = text.length - 1; i >= 0; i--) {
          const char = text[i];
          currentX -= ctx.measureText(char).width;
          ctx.fillText(char, currentX, startY);
          if (i > 0) currentX -= spacing; // Add spacing if it's not the last character
        }
      } else {
        // For 'left' and 'center' alignment
        for (let i = 0; i < text.length; i++) {
          ctx.fillText(text[i], currentX, startY);
          currentX += ctx.measureText(text[i]).width + spacing;
        }
      }
    };

    const render = (content, index) => {
      let textY = y + lineHeight * index + lineHeight / 2 + size / 3;

      if (this.isSafari) {
        drawTextWithSpacing(this.local.ctx, content, x, textY, this.letterSpacing, this.textAlign, width);
      } else {
        this.local.ctx.fillText(content, x, textY);
      }
    };

    const lines = this.textContent ? this.textContent.split('\n') : [''];
    let line_count = lines.length;

    const measureTextWithSpacing = (ctx, text, spacing) => {
      let totalWidth = text.split('').reduce((acc, char, index) => {
        acc += ctx.measureText(char).width;
        if (index < text.length - 1) acc += spacing; // Add spacing between characters, not after the last one
        return acc;
      }, 0);
      return totalWidth;
    };

    for (let i = 0; i < line_count; i++) {
      let line = '';
      let words = lines[i].split(/(\s|\n)/);

      for (let wordidx = 0; wordidx < words.length; wordidx++) {
        const word = words[wordidx];
        const potentialLine = line + word;

        // Adjust the line width measurement to include letter spacing
        let potentialLineWidth =
          this.isSafari && this.letterSpacing
            ? measureTextWithSpacing(this.local.ctx, potentialLine, this.letterSpacing)
            : this.local.ctx.measureText(potentialLine).width;

        if (potentialLineWidth > width || word === '\n') {
          if (line !== '') {
            lines[i] = line.trim();

            if (wordidx !== words.length - 1) {
              lines.splice(i + 1, 0, words.slice(wordidx).join(''));
              line_count++;
            } else if (word !== '\n') {
              lines.push(word);
            }
          } else {
            // Line is empty, which means word is too long and needs to be split
            let remainingWord = word;
            let currentLine = i;

            while (remainingWord.length > 0) {
              let wordFragment = '';
              for (let c = 0; c < remainingWord.length; c++) {
                if (this.local.ctx.measureText(wordFragment + remainingWord[c]).width <= width || c == 0) {
                  wordFragment += remainingWord[c];
                } else {
                  break;
                }
              }

              remainingWord = remainingWord.slice(wordFragment.length);
              lines[currentLine] = wordFragment.trim();

              if (remainingWord.length > 0) {
                lines.splice(currentLine + 1, 0, remainingWord);
                currentLine++;
                line_count++;
              }
            }

            if (words.slice(wordidx + 1).length > 0) {
              lines[currentLine] += words.slice(wordidx + 1).join('');
            }
          }
          break;
        } else {
          line = potentialLine;
        }

        if (wordidx === words.length - 1) {
          lines[i] = line.trim();
        }
      }
    }

    lines.forEach((ln, i) => {
      render(ln, index);
      if (i < lines.length - 1) {
        index++;
      }
    });

    this.local.ctx.translate(-(x + width / 2), -(y + height / 2));
    this.local.ctx.restore();

    this.height = this.lineHeight * index + this.lineHeight;

    if (this.justCreated) {
      this.width = this.local.ctx.measureText(this.textContent).width + 20;
      this.height = this.lineHeight;
      this.coords = [
        [-2, 0],
        [-2 + this.width, 0],
        [-2 + this.width, 0 + this.height],
        [-2, 0 + this.height],
      ];
    } else {
      this.coords = [
        [0, 0],
        [0 + this.width, 0],
        [0 + this.width, 0 + this.height],
        [0, 0 + this.height],
      ];
    }
  }
}
