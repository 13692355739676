import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  SIMPLEX_2S_NOISE,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const noiseBlurShader = `#version 300 es
precision highp float;

in vec2 vTextureCoord;

uniform sampler2D uTexture;

uniform float uAmount;
uniform float uTime;
uniform float uScale;
uniform float uDirection;
uniform float uAngle;
uniform float uPhase;
uniform vec2 uPos;
${UNIVERSAL_UNIFORMS}

${SIMPLEX_2S_NOISE}

const int MAX_ITERATIONS = 72;
const float MAX_ITERATIONS_F = 72.0;
const float HALF_ITERATIONS = 36.0;

out vec4 fragColor;

const float PI = 3.14159265;

mat2 rot(float a) {
    return mat2(cos(a), -sin(a), sin(a), cos(a));
}

void main() {
    vec2 uv = vTextureCoord;
    float aspectRatio = uResolution.x/uResolution.y;
    vec2 noiseUv = rot(uAngle * -1. * 2.0 * PI) * 
                   (uv * vec2(aspectRatio, 1.) - uPos * vec2(aspectRatio, 1.)) * 
                   vec2(uDirection, 1.-uDirection) * 5. * uScale;
    
    vec4 noise = bccNoiseDerivatives_XYBeforeZ(vec3(noiseUv, uTime * 0.025 + uPhase * 2.));
    vec2 noiseOffset = (noise.xy - 0.5) * uAmount * 0.5;
    
    vec4 color = vec4(0.0);
    
    for (int i = 0; i < MAX_ITERATIONS; i++) {
        float offset = float(i) - HALF_ITERATIONS;
        vec2 st = uv + noiseOffset * (offset / MAX_ITERATIONS_F);
        color += texture(uTexture, st);
    }
    
    color /= MAX_ITERATIONS_F;
    
    ${computeFragColor('color')}
}`;

const diffuseParams = {
  fragmentShader: noiseBlurShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.25,
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.25,
    },
    direction: {
      name: 'uDirection',
      type: '1f',
      value: 0.5,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    phase: {
      name: 'uPhase',
      type: '1f',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const NOISE_BLUR = {
  id: 'noiseBlur',
  label: 'Noise Blur',
  params: diffuseParams,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  downSample: true,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Amount',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    scale: {
      label: 'Scale',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    direction: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    phase: {
      label: 'Phase',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
