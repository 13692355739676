<script>
import ColorInput from './ColorInput.vue';
import Button from './Button.vue';
import SliderParam from './SliderParam.vue';
import InputField from './InputField.vue';
import RadioToggle from './RadioToggle.vue';
import ParamLabel from './ParamLabel.vue';
import DropdownMenu from './DropdownMenu.vue';
import ClassicToggle from './ClassicToggle.vue';
import MaskControls from './MaskControls.vue';
import StateEffects from "./StateEffects.vue";
import DisplacementControls from './DisplacementControls.vue';
import AlignmentControls from './AlignmentControls.vue';
import CoordInput from './CoordInput.vue';
import { BLEND_MODES } from '../scripts/Constants.js';
import { StudioStore } from '../stores/StudioStore.js';

export default {
  components: {
    ColorInput,
    InputField,
    SliderParam,
    Button,
    DisplacementControls,
    AlignmentControls,
    CoordInput,
    ParamLabel,
    MaskControls,
    StateEffects,
    ClassicToggle,
    DropdownMenu,
    RadioToggle
  },
  props: ['randomizer'],
  data() {
    return {
      state: StudioStore.state,
      blendModes: BLEND_MODES,
    }
  },
  computed: {
    image() { return StudioStore.getSelectedItem() || StudioStore.state.image },
    width() { return Math.round(this.image.width * this.image.size)},
    height() { return Math.round(this.image.height * this.image.size)}
  },
  watch: {
    randomizer() {
      this.randomize();
    },
  },
  methods: {
    updateValue(value, name) {
      this.effect[name] = value;
      this.$emit('update-value', this.effect.local.id, name, value);
    },
    handleBlendModeChange() {
      this.$emit('change');
    },
    handleCoordChange(val, type) {
      if(!val.target && this.image.setTranslateX && this.image.setTranslateY) {
        switch(type) {
          case 'X':
            this.image.setTranslateX(val);
            break;
          case 'Y':
            this.image.setTranslateY(val);
            break;
          case 'W':
            this.image.coords[1][0] = val;
            this.image.coords[2][0] = val;
            break;
          case 'H':
            this.image.coords[2][1] = val;
            this.image.coords[3][1] = val;
            break;
          case 'Z':
            this.image.rotation += (val - this.image.rotation);
            break;
        }
        this.$emit('change');
      }
    },
    handleBlendModePreview() {
      StudioStore.renderFrame();
    },
    handleMaskChange() {
      StudioStore.refreshPlanes(() => {
        StudioStore.renderFrame();
      }, this.image);
      this.$emit('change');
    },
  }
}
</script>

<template>
  <div
    class="effect-properties control-section-wrapper"
    ref="effectWindow">
    <div class="parameter parameter__block">
      <label class="parameter-label">Image</label>
    </div>
    
    <!-- <StateEffects :effect="image" /> -->

    <AlignmentControls class="mt-3" :item="image" @change="$emit('change')" />
    <div class="image-effect-preview mt-1" @click="$emit('replace-image', image.local.id)">
      <Button class="replace-image secondary-inverse replace-image-button">Replace image</Button>
      <img :src="image.thumb">
    </div>
    <MaskControls
      :item="image"
      @change="$emit('change')"
      @edit-fill="$emit('edit-fill', {item: image, prop: 'maskBackground'})"
    />
    <ClassicToggle 
      label="Fit to artboard"
      v-model="image.fitToCanvas"
      tooltip="Fits the image to best fill the entire artboard for any given aspect ratio."
      @update:modelValue="$emit('input')"
    ></ClassicToggle>
    <template v-if="!image.fitToCanvas">
      <div class="parameter parameter__block">
        <ParamLabel 
            prop="pos"
            :props="['translateX', 'translateY']"
            label="Position"
            @reset-breakpoint-prop="$emit('update')"
        >Position</ParamLabel>
        <div class="coords-input-group">
          <InputField
            :label="'X'"
            :value="image.getTranslateX()"
            output="px"
            @change="handleCoordChange($event, 'X')"
          />
          <InputField
            :label="'Y'"
            :value="image.getTranslateY()"
            output="px"
            @change="handleCoordChange($event, 'Y')"
          />
        </div>
      </div>
      <!-- <div class="parameter parameter__block">
        <label class="icon-label slider-label">Size</label>
        <div class="coords-input-group">
          <InputField
            :label="'X'"
            :value="image.width"
            output="px"
            @change="handleCoordChange($event, 'W')"
          />
          <InputField
            :label="'Y'"
            :value="image.height"
            output="px"
            @change="handleCoordChange($event, 'H')"
          />
        </div>
      </div> -->
      <SliderParam label="Rotation"
        v-model="image.rotation"
        :min="0"
        :max="1"
        :step="0.0027"
        prop="rotation"
        output="degrees"
        @update="$emit('input')"
      ></SliderParam>
      <SliderParam 
        label="Size"
        v-model.number="image.size"
        :min="0"
        :max="1"
        :step="0.01"
        prop="size"
        output="percent"
        @update="$emit('input')"
      ></SliderParam>
    </template>
    
    <SliderParam 
      label="Opacity"
      v-model.number="image.opacity"
      :min="0"
      :max="1"
      :step="0.01"
      output="percent"
      prop="opacity"
      @update="$emit('input')"
    ></SliderParam>
    <div class="parameter parameter__block">
      <label class="icon-label slider-label">Blend mode</label>
      <DropdownMenu
        v-model="image.blendMode"
        :options="blendModes"
        :rolloverPreview="true"
        @input="handleBlendModePreview"
        @update="handleBlendModePreview"
        @change="handleBlendModeChange"
      ></DropdownMenu>
    </div>
    <DisplacementControls
      :item="image"
      @input="$emit('input')"
      @change="$emit('change')"
    ></DisplacementControls>
    <SliderParam 
      label="Mouse tracking"
      header="Interactivity"
      v-model.number="image.trackMouse"
      :min="0"
      :max="1"
      :step="0.01"
      output="percent"
      prop="trackMouse"
      @update="$emit('input')"
    ></SliderParam>
    <SliderParam 
      label="Momentum"
      v-model.number="image.mouseMomentum"
      tooltip="The amount of drag or delay of the track mouse effect"
      :min="0"
      :max="1"
      :step="0.01"
      output="percent"
      prop="mouseMomentum"
      @update="$emit('input')"
    ></SliderParam>
    <SliderParam 
        label="3D axis tilt"
        v-model.number="image.axisTilt"
        :min="0"
        :max="1"
        :step="0.01"
        output="percent"
        prop="axisTilt"
        @update="$emit('input')"
      ></SliderParam>
  </div>
</template>