<script>
import { UserStore } from '../stores/UserStore'

function checkForClassClick(event, className, element = event.target) {
  if (!element || !element.classList) {
    return false; // end recursion if target or classList is not defined
  }

  if (element.classList.contains(className)) {
    return true; // class found
  }

  // Recursively check parent elements
  return checkForClassClick(event, className, element.parentNode);
}

export default {
  data() {
    return {
      user: UserStore,
      open: false,
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClick);
  },
  unmounted() {
    window.removeEventListener('click', this.handleClick);
  },
  methods: {
    handleClick(e) {
      if(!checkForClassClick(e, 'admin-flyout')) {
        this.open = false;
      }
    },
    selectOption(val) {
      this.open = false;
    },
  }
}
</script>

<template>
<div class="relative admin-flyout">  
  <a @click="open = true" href="javascript:void(0)" class="user-profile">
    <template v-if="user.avatar">
      <img :src="user.avatar" class="image-avatar">
    </template>
    <template v-else>{{user.email[0]}}</template>
  </a>
  <div v-if="open" class="design-listing-item-context-menu" ref="dropdown_options">
    <slot />
  </div>
</div>
</template>

<style lang="scss">

  .user-profile {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    min-width: 2.4rem;
    min-height: 2.4rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    font-size: 1.2rem;
    border-radius: 50%;
    background-color: var(--font-color);
    color: var(--bg-color);
  }

  .image-avatar {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
  }

</style>