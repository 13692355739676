import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision highp float;

in vec2 vTextureCoord;
uniform sampler2D uTexture;
uniform sampler2D uCustomTexture;

uniform float uMouseClick;
uniform vec3 uColor1;
uniform vec3 uColor2;
uniform vec2 uPos;
uniform vec3 uAxis;
uniform vec3 uAnimationDirection;
uniform vec2 uLightPosition;
uniform float uAmount;
uniform float uScale;
uniform float uRefraction;
uniform float uShapeControl;
uniform vec2 uTwist;
uniform float uBend;
uniform float uReflect;
uniform float uSpecular;
uniform float uFresnel;
uniform float uDispersion;
uniform int uMaterial;
uniform float uRoughness;
uniform float uRounding;
uniform float uSpacing;
uniform float uOpacity;
uniform float uFrost;
uniform float uExtrude;
uniform float uTrackMouseMove;
uniform float uTextureAmount;
uniform int uShape;
uniform int uRepeat;
uniform int uShowBg;
uniform vec3 uTint;
uniform float uTime;
${UNIVERSAL_UNIFORMS}

const float PI = 3.141592653;
const float PI2 = 6.283185306;
const float DISP_STEPS = 12.;
const vec3 viewDir = vec3(0,0, -4.25);

const mat3 ROT_Y_90 = mat3(
  0.0, 0.0, 1.0,
  0.0, 1.0, 0.0,
  -1.0, 0.0, 0.0
);

const mat3 ROT_Z_90 = mat3(
  0.0, -1.0, 0.0,
  1.0,  0.0, 0.0,
  0.0,  0.0, 1.0 
);

const mat3 ROT_X_90 = mat3(
  1.0,  0.0,  0.0,
  0.0,  0.0, -1.0,
  0.0,  1.0,  0.0 
);

float rand(vec2 co) {
  return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

vec3 rgb(float r, float g, float b) {
    return 1.0 - vec3(r / 255.0, g / 255.0, b / 255.0);
}

mat3 rotY(float ang) {
    float c = cos(ang), s = sin(ang);
    return mat3(c, 0.0, s, 0.0, 1.0, 0.0, -s, 0.0, c);
}

mat3 rotX(float ang) {
    float c = cos(ang), s = sin(ang);
    return mat3(1.0, 0.0, 0.0, 0.0, c, -s, 0.0, s, c);
}

mat3 rotZ(float ang) {
  float c = cos(ang), s = sin(ang);
  return mat3(c, -s, 0.0, s, c, 0.0, 0.0, 0.0, 1.0);
}


mat2 rot(float a) {
  float s = sin(a);
  float c = cos(a);
  return mat2(c, -s, s, c);
}

vec3 twistY(vec3 p, float amount) {
    float c = cos(amount * p.y);
    float s = sin(amount * p.y);
    mat2 m = mat2(c, -s, s, c);
    return vec3(m * p.xz, p.y);
}

vec3 twistX(vec3 p, float amount) {
  float c = cos(amount * p.x);
  float s = sin(amount * p.x);
  mat2 m = mat2(c, -s, s, c);
  return vec3(p.x, m * p.yz);
}

float unionSdf(float a, float b) {
    return min(a, b);
}

float subtractSdf( float d1, float d2 ) {
    return max(-d1,d2);
}

float sdf_blend(float d1, float d2, float a) {
    return a * d1 + (1. - a) * d2;
}

float opSmoothSubtraction( float d1, float d2, float k ) {
    float h = clamp( 0.5 - 0.5*(d2+d1)/k, 0.0, 1.0 );
    return mix( d2, -d1, h ) + k*h*(1.0-h);
}


float opSmoothUnion( float d1, float d2, float k ) {
    float h = clamp( 0.5 + 0.5*(d2-d1)/k, 0.0, 1.0 );
    return mix( d2, d1, h ) - k*h*(1.0-h);
}

float smin(float a, float b, float k) {
  float h = max(k - abs(a - b), 0.0);
  return min(a, b) - h * h * 0.25 / k;
}

float opExtrusion(vec3 p, float d, float h) {
  vec2 w = vec2( d, abs(p.z) - h );
  return min(max(w.x,w.y),0.0) + length(max(w,0.0));
}

// SDF FUNCTIONS

float sphere(vec3 p, float r) {
    return length(p) - r;
}

float honeycomb(vec3 p, float r) {
  vec3 np = normalize(p);
  float latitude = acos(np.x); // Angle from the X-axis
  
  float freq = 10.0;
  float phase = 1. + uTime * 0.2;
  
  float wave = sin(latitude * freq + phase) * cos(latitude * freq + phase);
  
  float waveAmplitude = 0.01 + uShapeControl * 0.1; // Reduced amplitude
  float modifiedRadius = r + wave * waveAmplitude;
  
  float baseSphere = length(p) - r * 0.8;
  float modifiedSphere = length(p) - modifiedRadius;
  
  return smin(baseSphere, modifiedSphere, 0.01);
}


float torus(vec3 p, vec2 t) {
    vec2 q = vec2(length(p.xz)-t.x,p.y);
    return length(q)-t.y;
}

float sdfCapsule(vec3 p, vec3 a, vec3 b, float r) {
    vec3 pa = p - a, ba = b - a;
    float h = clamp( dot(pa,ba)/dot(ba,ba), 0.0, 1.0 );
    return length( pa - ba*h ) - r;
}

float sdfBox(vec3 p, vec3 b) {
  vec3 d = abs(p) - b;
  return length(max(d,0.0)) + min(max(d.x,max(d.y,d.z)),0.0);
}

float sdfPlus(vec3 p, vec3 b) {
  float ctrl = uShapeControl * 0.5 + 0.25;
  float a = 1. - ctrl;
  float c = 1. + ctrl;
  p *= ROT_Y_90;
  p *= ROT_Z_90;
  float v = sdfBox(p, vec3(b.x * 0.5 * uExtrude, b.y * a, b.z * c));
  float h = sdfBox(p, vec3(b.x * 0.5 * uExtrude, b.y * c, b.z * a));
  return unionSdf(v, h);
}

float sdfCylinder(vec3 p, float radius, float height) {
  vec2 d = abs(vec2(length(p.xz) - radius, p.y)) - vec2(radius, height * 0.5);
  return min(max(d.x, d.y), 0.0) + length(max(d, 0.0));
}

float sdfCylinderX(vec3 p, float radius, float height) {
  vec2 d = abs(vec2(length(p.yz) - radius, p.x)) - vec2(radius, height * 0.5);
  return min(max(d.x, d.y), 0.0) + length(max(d, 0.0));
}

float sdfCylinderY(vec3 p, float radius, float height) {
  vec2 d = abs(vec2(length(p.xz) - radius, p.y)) - vec2(radius, height * 0.5);
  return min(max(d.x, d.y), 0.0) + length(max(d, 0.0));
}

float sdfCylinderZ(vec3 p, float radius, float height) {
  vec2 d = abs(vec2(length(p.xy) - radius, p.z)) - vec2(radius, height * 0.5);
  return min(max(d.x, d.y), 0.0) + length(max(d, 0.0));
}

float sdfMergedCylinders(vec3 p, float radius, float height) {
  float dX = sdfCylinderX(p, radius, height);
  float dY = sdfCylinderY(p, radius, height);
  float dZ = sdfCylinderZ(p, radius, height);
  return opSmoothUnion(opSmoothUnion(dX, dY, 0.5), dZ, 0.5);
}

float sdOctahedron( vec3 p, float s ) {
  float shape = 0.5 + uShapeControl;
  p.x *= shape;
  p.y *= (shape);
  p.z *= (1.5-uShapeControl);
  p = abs(p);
  float m = p.x+p.y+p.z-s;
  vec3 q;
       if( 3.0*p.x < m ) q = p.xyz;
  else if( 3.0*p.y < m ) q = p.yzx;
  else if( 3.0*p.z < m ) q = p.zxy;
  else return m*0.57735027;
    
  float k = clamp(0.5*(q.z-q.y+s),0.0,s); 
  return length(vec3(q.x,q.y-s+k,q.z-k)); 
}


float sdHexPrism( vec3 p, vec2 h ) {
  float ctrl = uShapeControl;
  h.x = 0.5 + ctrl;
  h.y = 1.5 - ctrl;
  const vec3 k = vec3(-0.8660254, 0.5, 0.57735);
  p = abs(p);
  p.xy -= 2.0*min(dot(k.xy, p.xy), 0.0)*k.xy;
  vec2 d = vec2(
       length(p.xy-vec2(clamp(p.x,-k.z*h.x,k.z*h.x), h.x))*sign(p.y-h.x),
       p.z-h.y );
  return min(max(d.x,d.y),0.0) + length(max(d,0.0));
}

float sdfSpiral(vec3 p, float tubeRadius, float spiralRadius, float pitch, int turns) {
  float cylRadius = length(p.xy);
  float angle = atan(p.y, p.x);

  float totalLength = 2.0 * 3.14159265 * float(turns) * spiralRadius;

  angle = mod(angle, 2.0 * 3.14159265);

  float spiralZ = mod(p.z + (pitch / (2.0 * 3.14159265)) * angle, pitch) - pitch / 2.0;

  float distToSpiral = sqrt(pow(cylRadius - spiralRadius, 2.0) + pow(spiralZ, 2.0)) - tubeRadius;

  float spiralStartZ = -totalLength / 2.0;
  float spiralEndZ = totalLength / 2.0;
  if (p.z < spiralStartZ || p.z > spiralEndZ) {
    float verticalDist = abs(p.z - clamp(p.z, spiralStartZ, spiralEndZ));
    float horizontalDist = abs(cylRadius - spiralRadius);
    distToSpiral += (verticalDist);
  }

  return distToSpiral;
}

float sdEquilateralTriangle(vec2 p, float r ) {
    const float k = sqrt(3.0);
    p.x = abs(p.x) - r;
    p.y = p.y + r/k;
    if( p.x+k*p.y>0.0 ) p = vec2(p.x-k*p.y,-k*p.x-p.y)/2.0;
    p.x -= clamp( p.x, -2.0*r, 0.0 );
    return -length(p)*sign(p.y);
}

float sdBlobbyCross(vec2 pos, float he) {
  pos = abs(pos);
  pos = vec2(abs(pos.x - pos.y), 1.0 - pos.x - pos.y) / sqrt(2.0);

  float p = (he - pos.y - 0.25 / he) / (6.0 * he);
  float q = pos.x / (he * he * 16.0);
  float h = q * q - p * p * p;

  float x;

  if (h > 0.0) { 
    float r = sqrt(h);
    x = pow(q + r, 1.0 / 3.0) - pow(abs(q - r), 1.0 / 3.0) * sign(r - q);
  } else {
    float r = sqrt(p);
    x = 2.0 * r * cos(acos(q / (p * r)) / 3.0);
  }
  
  x = min(x, sqrt(2.0) / 2.0);

  vec2 z = vec2(x, he * (1.0 - 2.0 * x * x)) - pos;
  return length(z) * sign(z.y);
}

float triangle(vec3 p, float r) {
  p *= ROT_Y_90;
  p *= ROT_X_90;
  float d = sdEquilateralTriangle(p.xy, r * 1.5);
  return opExtrusion(p, d, uExtrude);
}

float roundedCross(vec3 p, float r) {
  p *= ROT_Y_90;
  p *= ROT_X_90;
  float d = sdBlobbyCross(p.xy * 0.6, r * (0.1 + 0.4 * uShapeControl));
  return opExtrusion(p, d, uExtrude);
}

float sdStar5(vec2 p, float r, float rf)
{
    const vec2 k1 = vec2(0.809016994375, -0.587785252292);
    const vec2 k2 = vec2(-k1.x,k1.y);
    p.x = abs(p.x);
    p -= 2.0*max(dot(k1,p),0.0)*k1;
    p -= 2.0*max(dot(k2,p),0.0)*k2;
    p.x = abs(p.x);
    p.y -= r;
    vec2 ba = rf*vec2(-k1.y,k1.x) - vec2(0,1);
    float h = clamp( dot(p,ba)/dot(ba,ba), 0.0, r );
    return length(p-ba*h) * sign(p.y*ba.x-p.x*ba.y);
}

float star(vec3 p) {
  p *= ROT_Y_90;
  p *= ROT_X_90;
  //p *= ROT_Z_90;
  float d = sdStar5(p.xy, 1.5,  0.75 * uShapeControl);
  return opExtrusion(p, d, uExtrude);
}

float sdTwistedColumn(vec3 p, float height, float twist) {
  float c = cos(twist * p.y);
  float s = sin(twist * p.y);
  mat2  m = mat2(c, -s, s, c);
  vec2  q = m * p.xz;
  return max(length(q) - 1.0, abs(p.y) - height);
}

float sdRoundedBox( vec2 p, vec2 b, vec4 r ) {
    r.xy = (p.x>0.0)?r.xy : r.zw;
    r.x  = (p.y>0.0)?r.x  : r.y;
    vec2 q = abs(p)-b+r.x;
    return min(max(q.x,q.y),0.0) + length(max(q,0.0)) - r.x;
}

float squircle(vec3 p) {
  p *= ROT_Y_90;
  p *= ROT_X_90;
  float d = sdRoundedBox(p.xy, vec2(0.75 + uShapeControl, 0.75 + (1.-uShapeControl)), vec4(0.33));
  return opExtrusion(p, d, uExtrude);
}

float mergedDiscs(vec3 p) {
  float result = sdfCylinder(p, 0.7, 0.01 + uShapeControl * 0.5);

  for(float i = 1.; i < 4.; i++) {
    float angle = (i * 45.)/360. * 2.0 * PI;
    mat2 rotZMat = rot(angle);
    vec3 rotatedP = vec3(rotZMat * p.xy, p.z);

    result = smin(
      result,
      sdfCylinder(rotatedP, 0.7, 0.01 + uShapeControl * 0.5),
      0.2
    );
  }

  return max(result, sphere(p, 1.4));
}

float sdfTop(vec3 p, float radius, float height) {
  float dX = sdfCylinderX(p, radius * 0.1, height * 0.8);
  float dY = sdfCylinderX(p, radius * 3.4, height * 0.005);
  dY = smin(dY, sphere(p, radius * 2.2), 0.5);
  return smin(dX, dY, 0.5);
}

float sdTriCube(vec3 p, float cubeSize) {
  float extension = cubeSize*2.;
  vec3 b = vec3(cubeSize) / 2.0;

  float dX = sdfBox(p * vec3(1.0, extension, extension), b * vec3(1.0, extension, extension));
  float dY = sdfBox(p * vec3(extension, 1.0, extension), b * vec3(extension, 1.0, extension));
  float dZ = sdfBox(p * vec3(extension, extension, 1.0), b * vec3(extension, extension, 1.0));

  float d = min(dX, min(dY, dZ)) - cubeSize;
  
  return d;
}

float smoothMax(float a, float b, float k) {
  float h = clamp(0.5 + 0.5 * (b - a) / k, 0.0, 1.0);
  return mix(a, b, h) + k * h * (1.0 - h);
}

float sdDodecahedron(vec3 p, float r) {
  const float phi = (1.0 + sqrt(5.0)) / 2.0;
  const float a = 1.0;
  const float b = 1.0 / phi;
  const float c = 2.0 - phi;

  vec3 n = abs(p);
  float d1 = dot(vec3(a, c, 0), n);
  float d2 = dot(vec3(-a, c, 0), n);
  float d3 = dot(vec3(b, b, b), n);
  float d4 = dot(vec3(0, a, c), n);
  float d5 = dot(vec3(c, 0, a), n);

  // Use smoothMax for blending instead of max
  float d = smoothMax(d1, d2, uRounding * 0.5);
  d = smoothMax(d, d3, uRounding * 0.5);
  d = smoothMax(d, d4, uRounding * 0.5);
  d = smoothMax(d, d5, uRounding * 0.5);

  return (d - r) / sqrt(3.0);
}

float sdfPyramid(vec3 p) {
  p *= ROT_Y_90;
  p *= ROT_X_90;
  p *= ROT_Z_90;
  p /= (2. - uRounding);
  p.y += 0.25;
  if (p.y <= 0.0)
    return length(max(abs(p)-vec3(0.5,0.0,0.5),0.0));
  float h = sqrt(2./3.); // Height from the center to a vertex, assuming the tetrahedron is centered at the origin
  float m2 = h * h + 0.25; // Precomputed value used for distance calculations
  
  // Apply symmetry to simplify the problem
  p.xz = abs(p.xz); // Reflect to first quadrant
  p.xz = (p.z > p.x) ? p.zx : p.xz; // Ensure p.x is the largest of x and z for uniformity
  p.xz -= 0.5; // Adjust position
  
  // Project into the plane of one of the tetrahedron's faces
  vec3 q = vec3(p.z, h * p.y - 0.5 * p.x, h * p.x + 0.5 * p.y);
  
  // Calculate distances to the sides of the tetrahedron
  float s = max(-q.x, 0.0);
  float t = clamp((q.y - 0.5 * q.x) / (m2 + 0.25), 0.0, 1.0);
  
  // Calculate squared distances in the projected plane
  float a = m2 * (q.x + s) * (q.x + s) + q.y * q.y;
  float b = m2 * (q.x + 0.5 * t) * (q.x + 0.5 * t) + (q.y - m2 * t) * (q.y - m2 * t);
  
  // Choose the appropriate distance
  float d2 = (max(-q.y, q.x * m2 + q.y * 0.5) < 0.0) ? 0.0 : min(a, b);
  
  // Convert back to 3D distance, adjust for scale, and determine sign
  return sqrt((d2 + q.z * q.z) / m2) * sign(max(q.z, -p.y));
}

float mergedRects(vec3 p) {
  p *= ROT_Y_90;
  //p *= ROT_Z_90;
  //p *= ROT_X_90;
  vec3 b = vec3(0.5);
  float ctrl = uShapeControl * 2. + 1.;
  float extrude = 2. * uExtrude + 0.1;
  vec3 box = vec3(b.x * 0.25, b.y * ctrl, b.z * 0.25 + extrude);
  float result = sdfBox(p, box);

  for(float i = 1.; i < 4.; i++) {
    float angle = (i * 45.)/360. * 2.0 * PI;
    vec3 rotatedP = p * rotZ(angle);

    result = min(
      result,
      sdfBox(rotatedP, box)
    );
  }

  return result;
}


float median(float r, float g, float b) {
  return max(min(r, g), min(max(r, g), b));
}

float sdCustom(vec3 p) {
  p *= rotX(radians(90.0));
  p *= rotY(radians(180.0));
  p *= rotZ(radians(180.0));
  p.xy = p.xy * 0.25 + 0.5;

  if (p.x < 0.0 || p.x > 1.0 || p.y < 0.0 || p.y > 1.0) {
      return 1.0; 
  }

  vec4 sdColor = texture(uCustomTexture, p.xy);
  float sd = median(sdColor.r, sdColor.g, sdColor.b);

  float screenPxDistance = (sd - 0.5);
  float d = -screenPxDistance;

  return opExtrusion(p, d, uExtrude * 0.5);
}

vec3 stripePattern(vec3 x) {
  x *= 5.;
  x *= rotY(radians(45.));
  x *= rotX(radians(45.));
  float dot = sin(x.x);
  return vec3(dot * normalize(dot));
}

vec3 checkerboardPattern(vec3 x) {
  float checker = floor(mod(x.x, 2.0)) * floor(mod(x.y, 2.0)) * floor(mod(x.z, 2.0));
  checker = 1. - step(0.5, sin(x.x * 3.141592) * sin(x.y * 3.141592) * sin(x.z * 3.141592));
  vec3 center = vec3(uPos, 0.);
  vec3 dir = x - center;
  return vec3(
    checker * sign(dir.x),
    checker * sign(dir.y),
    checker * sign(dir.z)
  );
}

vec3 diamondPattern(vec3 x) {
  // Calculate the checker value without the sharp transition
  float checker = mod(floor(x.x) + floor(x.y) + floor(x.z), 2.0);
  
  // Calculate fractional parts of the coordinates for smoothing
  vec3 fracParts = abs(fract(x) - 0.5);

  // Find the minimum distance to the edge of the current "cube"
  float minDistance = min(min(fracParts.x, fracParts.y), fracParts.z);

  // Use smoothstep to interpolate between 0 and 1 around the boundary
  float smoothed = smoothstep(0.0, 1., minDistance);

  // Apply smoothing based on the checker value
  return mix(vec3(smoothed), vec3(1.-smoothed), minDistance);
}


vec3 marbleTexture(vec3 x) {
  float marble = sin(x.x * 3.5 + sin(x.y * 7.0 + sin(x.z * 12.))) * 0.5 + 0.5;
  return vec3(marble * normalize(marble));
}

vec3 quiltPattern(vec3 x) {
  x *= 5.;
  float grainA = sin(x.x) + sin(x.y);
  float grainB = sin(x.x) + sin(x.z);
  float grainC = sin(x.y) + sin(x.z);

  x *= rotY(radians(45.));
  x *= rotX(radians(45.));

  float grainD = sin(x.x * 2.) * sin(x.y * 2.);
  float grainE = sin(x.x * 2.) * sin(x.z * 2.);
  float grainF = sin(x.y * 2.) * sin(x.z * 2.);

  x *= rotZ(radians(45.));
  x *= rotY(radians(45.));

  float grainG = sin(x.x * 0.5) * sin(x.y * 0.5);
  float grainH = sin(x.x * 0.5) * sin(x.z * 0.5);
  float grainI = sin(x.y * 0.5) * sin(x.z * 0.5);

  float grain = (grainA + grainB + grainC + grainD + grainE + grainF + grainG + grainH + grainI)/9.;
  
  return vec3(grain );
}

vec3 stripedPattern(vec3 x) {
  float stripe = sin(x.x);
  return vec3(stripe * 0.5 + 0.5);
}

vec3 polkaDotsPattern(vec3 x) {
  x *= 5.;
  float dot = sin(x.x) + sin(x.y) + sin(x.z);
  return vec3(dot * normalize(dot));
}

vec3 metalPattern(vec3 x) {
  float len = abs(length(x - vec3(uPos, 0)));
  x *= 5.;
  vec3 result = vec3(
      sin(x.x + x.z) * sin(x.y + x.z),
      sin(x.y + x.z) * sin(x.x + x.y),
      sin(x.x + x.y) * sin(x.x + x.z)
  ) * 0.5 + 0.5;
  return (normalize(result));
}

vec3 getSurfaceTexture(vec3 coord, float frequency) {
  switch(uMaterial) {
    case 0: return stripePattern(coord * frequency); break;
    case 1: return marbleTexture(coord * frequency); break;
    case 2: return polkaDotsPattern(coord * frequency); break;
    case 3: return diamondPattern(coord * frequency); break;
    case 4: return metalPattern(coord * frequency); break;
    default: return coord; break;
  }
}

vec3 xyRepeat(vec3 p, float spacing) {
  p.xy = vec2(
    mod(p.x + 0.5 * spacing, spacing) - 0.5 * spacing,
    mod(p.y + 0.5 * spacing, spacing) - 0.5 * spacing
  );
  return p;
}

vec3 xRepeat(vec3 p, float spacing) {
  p.x = mod(p.x + 0.5 * spacing, spacing) - 0.5 * spacing;
  return p;
}

vec3 yRepeat(vec3 p, float spacing) {
  p.y = mod(p.y + 0.5 * spacing, spacing) - 0.5 * spacing;
  return p;
}

vec3 threeDRepeat(vec3 p, float spacing) {
  vec3 l = vec3(1.0);
  return p - spacing * clamp(round(p / spacing), -l, l);
}

vec3 getRepeat(vec3 p) {
  float spacing = (uSpacing + uRounding * 0.38) * 8.;
  switch(uRepeat) {
    case 0: return p; break;
    case 1: return xyRepeat(p, spacing); break;
    case 2: return xRepeat(p, spacing); break;
    case 3: return yRepeat(p, spacing); break;
    default: return p; break;
  }
}

vec3 getThreeDRepeat(vec3 p) {
  float spacing = (uSpacing + uRounding * 0.38) * 8.;
  switch(uRepeat) {
    case 0: return p; break;
    case 1: return p; break;
    case 2: return p; break;
    case 3: return p; break;
    case 4: return threeDRepeat(p, spacing); break;
    default: return p; break;
  }
}

vec3 getAdjustedP(vec3 p) {
  float scaleFactor = uScale < 1e-7 ? 1e7 : 1.0/uScale;
  vec3 adjustedP = p * scaleFactor;

  vec2 twist = uTwist;

  adjustedP.xy *= vec2(uResolution.x / uResolution.y, 1);

  adjustedP *= (1. + (uRounding + 0.01));

  vec2 mousePos = mix(vec2(0), uMousePos - 0.5, uTrackMouse);
  vec2 axis = vec2(-1. * uAxis.y - 1. + mousePos.y/PI, uAxis.x + mousePos.x/PI) * 2.;

  adjustedP = getRepeat(adjustedP);

  float baseTime = uTime * 0.02;
  float timeX = uAnimationDirection.x * baseTime;
  float timeY = uAnimationDirection.y * baseTime;
  float timeZ = uAnimationDirection.z * baseTime;

  mat3 rotYMat = rotY(axis.y * PI);
  mat3 rotXMat = rotX(axis.x * PI);
  mat3 rotZMat = rotZ(uAxis.z * 2.0 * PI);

  mat3 combinedRotation = rotZMat * rotYMat * rotXMat;
  mat3 combinedAnimation = rotZ(timeZ) * rotX(timeX) * rotY(timeY);

  adjustedP = combinedRotation * adjustedP;
  adjustedP = combinedAnimation * adjustedP;
  
  adjustedP = getThreeDRepeat(adjustedP);
  
  adjustedP = mix(adjustedP, twistY(adjustedP, -1.0 * twist.y), step(0.0, abs(twist.y)));
  adjustedP = mix(adjustedP, twistX(adjustedP, -1.0 * twist.x), step(0.0, abs(twist.x)));
  
  // #ifelseopen
  if (uTextureAmount > 0.0) {
    adjustedP += getSurfaceTexture(adjustedP, 20. * uRoughness) * 0.025 * uTextureAmount;
  }
  // #ifelseclose

  return adjustedP;
}

float getMergedSDF(vec3 p) {
  p = getAdjustedP(p);
  switch (uShape) {
      case 0: return torus(p, vec2(1.25, 0.5 * uShapeControl)); break;
      case 1: return sdfBox(p, vec3(0.8, 0.8, 1.6 * uShapeControl)); break;
      case 2: return sphere(p, 1.0); break;
      case 3: return sdfCapsule(p, vec3(-1.5 * uShapeControl - 0.5, 0.0, 0.), vec3(1.5 * uShapeControl + 0.5, 0.0, 0.), 0.5); break;
      case 4: return sdfCylinder(p, 0.7, 0.05 + 0.25 * uShapeControl); break;
      case 5: return sdfCylinder(p, 0.2, 1. + 3. * uShapeControl); break;
      case 6: return sdOctahedron(p, 1.5); break;
      case 7: return sdHexPrism(p, vec2(1.)); break;
      case 8: return sdfPlus(p, vec3(1)); break;
      case 9: return sdfSpiral(p, 0.1, 0.6, uShapeControl + 0.5, 1); break;
      case 10: return sdfMergedCylinders(p, 0.2, 1. + 3. * uShapeControl); break;
      case 11: return triangle(p, 1.); break;
      case 12: return roundedCross(p, 1.); break;
      case 13: return squircle(p); break;
      case 14: return mergedDiscs(p); break;
      case 15: return honeycomb(p, 1.); break;
      case 16: return sdfTop(p, 0.2, 1. + 3. * uShapeControl); break;
      case 17: return star(p); break;
      case 18: return sdfPyramid(p); break;
      case 19: return mergedRects(p); break;
      case 21: return sdDodecahedron(p, 1.); break;
      case 20: return sdCustom(p); break;
      default: return torus(p, vec2(1.5, 0.5)); break;
  }
}

float fresnel(vec3 eyeVector, vec3 worldNormal, float power) {
  float fresnelFactor = 1.0 - abs(dot(eyeVector, worldNormal));
  return pow(fresnelFactor, power);
}

float specular(vec3 viewDir, vec3 lightDir, vec3 normal, float shininess, float diffuseness) {
    vec3 halfVector = normalize(viewDir + lightDir);
    return pow(max(0.0, dot(normal, halfVector)), shininess) * diffuseness;
}

vec3 noFrostOrDispersion(vec3 rd, vec3 normal) {
  float ior = 1.0 / (1.0 + uRefraction * 0.25);
  vec3 refractedRay = refract(rd, normal, ior);
  return texture(uTexture, vTextureCoord - refractedRay.xy).rgb;
}

vec3 frostOrDispersion(vec3 rd, vec3 normal) {
  vec3 refractionColor = vec3(0);
  float iorBase = 1. + uRefraction * 0.25;

  vec3 dispCoefficients = vec3(0.03, 0.06, 0.1) * uDispersion * 1.2;

  for(float i = 0.; i < DISP_STEPS; i++) {
    float step = i / DISP_STEPS;
    
    vec3 disp = step * dispCoefficients;
    vec3 ior = 1.0 / (iorBase + disp);

    vec3 refractedRayR = refract(rd, normal, ior.r);
    vec3 refractedRayG = refract(rd, normal, ior.g);
    vec3 refractedRayB = refract(rd, normal, ior.b);

    // #ifelseopen
    if (uFrost > 0.0) {
      vec2 rayDirOffset = vec2(
        rand(rd.xy + step) * 2. - 1.,
        rand(rd.xy + step * 2.) * 2. - 1.
      ) * mix(1., rand(rd.xy + step * 3.), 0.8);
      
      refractedRayR.xy += rayDirOffset * (0.1 + disp.r) * uFrost;
      refractedRayG.xy += rayDirOffset * (0.1 + disp.g) * uFrost;
      refractedRayB.xy += rayDirOffset * (0.1 + disp.b) * uFrost;
    }
    // #ifelseclose

    refractionColor.r += texture(uTexture, vTextureCoord - refractedRayR.xy).r;
    refractionColor.g += texture(uTexture, vTextureCoord - refractedRayG.xy).g;
    refractionColor.b += texture(uTexture, vTextureCoord - refractedRayB.xy).b;
  }

  return clamp(refractionColor / DISP_STEPS, 0.0, 1.0);
}

vec3 calculateNormal(vec3 p, float eps) {
    float dx = getMergedSDF(p + vec3(eps, 0.0, 0.0)) - getMergedSDF(p - vec3(eps, 0.0, 0.0));
    float dy = getMergedSDF(p + vec3(0.0, eps, 0.0)) - getMergedSDF(p - vec3(0.0, eps, 0.0));
    float dz = getMergedSDF(p + vec3(0.0, 0.0, eps)) - getMergedSDF(p - vec3(0.0, 0.0, eps));
    return normalize(vec3(dx, dy, dz));
}

vec3 sampleTexture(vec3 rd, vec3 normal) {
  // #ifelseopen
  if(uOpacity == 1.0) {
    return vec3(0);
  }
  // #ifelseclose

  // #ifelseopen
  if(uDispersion > 0.0) {
    return frostOrDispersion(rd, normal);
  }
  // #ifelseclose

  // #ifelseopen
  if(uFrost > 0.0) {
    return frostOrDispersion(rd, normal);
  }
  // #ifelseclose
  
  return noFrostOrDispersion(rd, normal);
}

float scene(vec3 p) {
  return max(0., getMergedSDF(p) - uRounding) * max(uScale, 0.0000000001);
}

float getPixelSize() {
  // Calculate pixel size based on screen resolution
  return 1.0 / min(uResolution.x, uResolution.y);
}

const int STEPS = 128;
const float MAX_DISTANCE = 40.0;

vec4 rayMarch(vec3 ro, vec3 rd, float min_dist) {
  float traveled = 0.;
  vec3 entryPoint = vec3(0.0);
  vec3 entryNormal = vec3(0.0);
  float partialAlpha = 0.0;

  for (int i = 0; i < STEPS; ++i) {
      vec3 currentPos = ro + rd * traveled;
      float distance = scene(currentPos);
      
      float pixelSize = getPixelSize();

      if (distance < pixelSize * 2.) {
        partialAlpha = 0.5;
        entryPoint = currentPos;
        entryNormal = calculateNormal(entryPoint, pixelSize);
      }

      if (distance < pixelSize) {
          partialAlpha = 1.;
          entryPoint = currentPos;
          entryNormal = calculateNormal(entryPoint, pixelSize);
          break;
      }

      if(traveled < 0.05) {
        traveled += distance * 0.9;
      } else {
        traveled += distance;
      }
      if (traveled > MAX_DISTANCE) break;
  }

  if (partialAlpha == 0.0) {
      return uShowBg == 1 ? texture(uTexture, vTextureCoord) : vec4(0);
  }

  vec4 bg = texture(uTexture, vTextureCoord);

  vec3 samplePosition = mix(rd, entryPoint, uReflect);
  vec3 refractionColor = sampleTexture(samplePosition, entryNormal);

  vec3 lightDir = vec3(((vec2(uLightPosition.x, 1.-uLightPosition.y) - 0.333) * 3.) - uPos, -3.0);
  vec3 normLightDir = normalize(lightDir);

  float lightAndShadow = dot(entryNormal, normLightDir);
  vec3 lightColor = mix(vec3(1), uTint, 1. - uOpacity);

  // Fresnel effect calculation
  vec3 fresnelEffect = fresnel(rd, entryNormal, 8.0) * uFresnel * uTint;

  // Specular effect calculation
  vec3 halfwayDir = normalize(lightDir + rd);
  float specFactor = pow(max(dot(entryNormal, halfwayDir), 0.0), 64.0 * uSpecular + 0.01);
  vec3 specularEffect = specFactor * uSpecular * lightColor;

  // Blend refraction color with Fresnel and Specular
  vec3 combinedEffects = fresnelEffect + specularEffect;
  vec3 finalColor = mix(refractionColor, uTint * lightAndShadow, uOpacity);
  finalColor += combinedEffects;

  // Combine with background texture
  vec4 outputColor = mix(bg, vec4(finalColor, 1.), partialAlpha);

  return outputColor;
}

out vec4 fragColor;

void main() {
  vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouseMove);
  vec2 uv = vTextureCoord - pos;
  float fovFactor = tan(radians(20.) * 0.5);
  vec3 rd = vec3(uv * fovFactor, 0.5);

  float mdist = 0.004;
  
  vec4 col = rayMarch(viewDir, rd, mdist);
  float dither = (rand(vTextureCoord.xy) - 0.5) / 255.0;
  col += dither;
  ${computeFragColor('col')}
}
`;

const params = {
  fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  texturesOptions: {
    floatingPoint: 'float',
    premultiplyAlpha: true,
  },
  uniforms: {
    refraction: {
      name: 'uRefraction',
      type: '1f',
      value: 0.5,
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.5,
    },
    shape: {
      name: 'uShape',
      type: '1i',
      value: 0,
    },
    shapeControl: {
      name: 'uShapeControl',
      type: '1f',
      value: 0.5,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    twist: {
      name: 'uTwist',
      type: '2f',
      value: new Vec2(0),
    },
    lightPosition: {
      name: 'uLightPosition',
      type: '2f',
      value: new Vec2(0.25),
    },
    dispersion: {
      name: 'uDispersion',
      type: '1f',
      value: 0.25,
    },
    specular: {
      name: 'uSpecular',
      type: '1f',
      value: 0.5,
    },
    fresnel: {
      name: 'uFresnel',
      type: '1f',
      value: 0.5,
    },
    frost: {
      name: 'uFrost',
      type: '1f',
      value: 0,
    },
    material: {
      name: 'uMaterial',
      type: '1i',
      value: 0,
    },
    reflect: {
      name: 'uReflect',
      type: '1f',
      value: 0,
    },
    patternScale: {
      name: 'uRoughness',
      type: '1f',
      value: 0.4,
    },
    fillOpacity: {
      name: 'uOpacity',
      type: '1f',
      value: 0,
    },
    rounding: {
      name: 'uRounding',
      type: '1f',
      value: 0.05,
    },
    extrude: {
      name: 'uExtrude',
      type: '1f',
      value: 0.25,
    },
    distort: {
      name: 'uDistort',
      type: '1f',
      value: 0,
    },
    textureAmount: {
      name: 'uTextureAmount',
      type: '1f',
      value: 0,
    },
    showBg: {
      name: 'uShowBg',
      type: '1i',
      value: 1,
    },
    repeat: {
      name: 'uRepeat',
      type: '1i',
      value: 0,
    },
    spacing: {
      name: 'uSpacing',
      type: '1f',
      value: 0.5,
    },
    trackMouseMove: {
      name: 'uTrackMouseMove',
      type: '1f',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    twist: {
      name: 'uTwist',
      type: '2f',
      value: new Vec2(0),
    },
    axis: {
      name: 'uAxis',
      type: '3f',
      control: 'rotation',
      value: new Vec3(0.5),
    },
    animationDirection: {
      name: 'uAnimationDirection',
      type: '3f',
      control: 'rotation',
      value: new Vec3(0, 1, 0),
    },
    tint: {
      name: 'uTint',
      type: '3f',
      value: new Vec3(1),
    },
    ...universalUniformParams,
  },
};

export const SDF_SHAPE = {
  id: 'sdf_shape',
  label: '3D Shape',
  params: params,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    axis: {
      label: 'Axis',
      value: new Vec3(0.5),
      min: 0,
      max: 1,
      step: 0.0027,
      control: 'rotation',
      output: 'degrees',
    },
    shape: {
      label: 'Shape',
      value: 0,
      options: {
        0: 'Torus',
        1: 'Box',
        2: 'Sphere',
        3: 'Capsule',
        4: 'Disc',
        5: 'Cylinder',
        6: 'Octahedron',
        7: 'Hex Prism',
        8: 'Plus',
        9: 'Spring',
        10: 'Tricylinder',
        11: 'Triangle',
        12: 'Rounded cross',
        13: 'Rounded rect',
        14: 'Merged discs',
        15: 'Rippled sphere',
        16: 'Top',
        17: 'Star',
        18: 'Pyramid',
        19: 'Asterisk',
        21: 'Dodecahedron',
        20: 'Custom',
      },
      responsiveDisabled: true,
    },
    texture: {
      label: 'SVG',
      value: {
        src: 'https://assets.unicorn.studio/images/Zz28X5RDkvcGGVYLr9X6QdTIhxy1/us_logo_white_msdf_1721436846470.png',
        name: 'unicornstudio.svg',
        svgSrc: '',
        thumb:
          'https://firebasestorage.googleapis.com/v0/b/unicorn-studio.appspot.com/o/Zz28X5RDkvcGGVYLr9X6QdTIhxy1%2Fus_logo_white_%40thumbnail.svg?alt=media&token=7915c370-eb2e-4c09-8f67-4e0079808c51',
      },
      output: 'texture',
      conditional: {
        prop: 'shape',
        value: '20',
        notEqual: false,
      },
      tooltip: 'Converts an SVG into a 3D object. Must be a flattened shape.',
      responsiveDisabled: true,
    },
    precision: {
      label: 'Precision',
      value: 1,
      options: {
        0: 'Low',
        1: 'Medium',
        2: 'High',
      },
      conditional: {
        prop: 'shape',
        value: '20',
        notEqual: false,
      },
      responsiveDisabled: true,
      tooltip:
        'Used for optimizing quality. Simple shapes should have low precision while complex shapes (like text) should use high precision.',
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    twist: {
      label: 'Twist',
      value: new Vec2(0),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    rounding: {
      label: 'Rounding',
      value: 0.05,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Subtracts distance from the sdf to give a rounder and puffier appearance.',
    },
    shapeControl: {
      label: 'Variation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: "Modifies some aspect of the shape's dimensions depending on which one is selected.",
    },
    extrude: {
      label: 'Extrude',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the depth of 2D shapes',
    },
    showBg: {
      label: 'Show background',
      value: 1,
      classic: true,
      options: {
        1: 'On',
        0: 'Off',
      },
      tooltip: 'Include or hide the underlying layers',
    },
    repeat: {
      label: 'Type',
      header: 'Repeat',
      value: 0,
      options: {
        0: 'None',
        1: '2D XY',
        2: '2D X',
        3: '2D Y',
        4: '3D',
      },
      tooltip: 'Repeat pattern',
      responsiveDisabled: true,
    },
    spacing: {
      label: 'Spacing',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    refraction: {
      label: 'Amount',
      specificLabel: 'Refract. amount',
      header: 'Refraction / Reflection',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    reflect: {
      label: 'Behavior',
      value: 0,
      radio: true,
      options: [
        { value: 0, label: 'Refract' },
        { value: 1, label: 'Reflect' },
      ],
      tooltip: 'Refract for a glass material, reflect for a mirror material.',
      responsiveDisabled: true,
    },
    dispersion: {
      label: 'Dispersion',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount of chromatic dispersion. Does not scale with amount of refraction.',
    },
    frost: {
      label: 'Roughness',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the graininess of light passing through the object.',
    },
    material: {
      label: 'Type',
      header: 'Surface texture',
      value: 0,
      options: {
        0: 'Striped',
        1: 'Wavy',
        2: 'Beaded',
        3: 'Diamond',
        4: 'Linoleum',
      },
      tooltip: 'Adds a uniform patterned surface texture to the SDF',
      responsiveDisabled: true,
    },
    textureAmount: {
      label: 'Amount',
      specificLabel: 'Texture amount',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    patternScale: {
      label: 'Scale',
      specificLabel: 'Texture scale',
      value: 0.4,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    specular: {
      label: 'Specular',
      header: 'Light',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount and diffusion of specular light, or the "shiniess" of the object.',
    },
    fresnel: {
      label: 'Fresnel',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the ambient reflectivity of the object',
    },
    lightPosition: {
      label: 'Position',
      value: new Vec2(0.25),
      specificLabel: 'Light position',
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    tint: {
      label: 'Color',
      value: new Vec3(1),
      output: 'color',
      tooltip:
        'Controls the tint of the lighting effects, as well as the color of the shape if opaqueness is greater than 0.',
      responsiveDisabled: true,
    },
    fillOpacity: {
      label: 'Opaqueness',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Fills the object with a solid color',
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    animationDirection: {
      label: 'Direction',
      specificLabel: 'Animation direction',
      value: new Vec3(0, 1, 0),
      min: 0,
      max: 1,
      step: 0.01,
      control: 'rotation',
      output: 'percent',
    },
    trackMouseMove: {
      label: 'Track mouse',
      header: 'Interactivity',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'The amount to which the mouse cursor controls the position of the object',
    },
    mouseMomentum: {
      label: 'Momentum',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'The amount of drag or delay of the track mouse effect',
    },
    trackMouse: {
      label: 'Axis tilt',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'The amount to which the mouse cursor controls the position of the axis',
    },
  },
};
