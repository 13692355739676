<script>
import { basicSetup } from "codemirror";
import { EditorState, StateField, StateEffect } from "@codemirror/state";
import { EditorView, Decoration } from '@codemirror/view';
import { oneDark } from "@codemirror/theme-one-dark";
import { cpp } from "@codemirror/lang-cpp";
import { EFFECTS } from '../scripts/Shaders.js'
import { Debouncer } from '../scripts/Helpers.js'
import { CUSTOM_SHADERS } from '../scripts/CustomShaders.js';
import { PERLIN_NOISE, SIMPLEX_NOISE, SIMPLEX_2S_NOISE } from '../scripts/ShaderHelpers.js';
import { StudioStore } from '../stores/StudioStore.js'
import Button from '../components/Button.vue';
import Icon from '../components/Icon.vue';
import RadioToggle from '../components/RadioToggle.vue'
import DropdownMenu from '../components/DropdownMenu.vue'

let errorOffsetHeight; 

// Define the StateEffects
const addLineDecorationEffect = StateEffect.define();
const removeLineDecorationEffect = StateEffect.define();

function minifyGLSL(code) {
    // remove comments
    let noComments = code.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, '');
    // replace multiple spaces with a single space
    let singleSpace = noComments.replace(/\s+/g, ' ');
    // remove spaces before and after {} and ()
    let compactSpaces = singleSpace.replace(/ ?([{}();]) ?/g, '$1');
    // replace the newline after preprocessor directives with a space
    let finalCode = compactSpaces.replace(/#\w+\n/g, function (match) {
        return match.replace('\n', ' ');
    });
    return finalCode;
}

const lineDecorationField = StateField.define({
  create() {
    return Decoration.none;
  },
  update(decorations, tr) {
    decorations = decorations.map(tr.changes);
    for (let e of tr.effects) {
      if (e.is(addLineDecorationEffect)) {
        const deco = Decoration.line({ attributes: { style: `margin-bottom: ${errorOffsetHeight}px;` }, });
        decorations = decorations.update({ add: [deco.range(e.value.docPosition)] });
      }
      // Check for a removeLineDecorationEffect effect
      else if (e.is(removeLineDecorationEffect)) {
        decorations = Decoration.none;
      }
    }
    return decorations;
  },
  provide: (f) => EditorView.decorations.from(f),
});

// Remove all line decorations
function removeAllLineDecorations(editor) {
  editor.dispatch({ effects: removeLineDecorationEffect.of({ removeAll: true }) });
}



export default {
  components: {
    RadioToggle,
    DropdownMenu,
    Button,
    Icon
  },
  data() {
    return {
      editor: {},
      editorState: {},
      effects: EFFECTS,
      errorMessage: '',
      errorWidget: null,
      fragmentValue: '',
      vertexValue: '',
      shaderView: 'fragment',
      shaderTemplate: 'Try a starter template',
      shaderViewOptions: [
        {
          label: 'Fragment',
          value: 'fragment'
        },{
          label: 'Vertex',
          value: 'vertex'
        }
      ],
      shaderTemplateOptions: {
        'blankWithComments': 'Shader tutorial',
        'blank': 'Basic',
        'superShader': 'Multi-function',
        'ripple': 'Ripple',
        'liquify': 'Liquify',
        'simplex': 'Simplex Noise',
        'simplex2s': 'Simplex 2S Noise',
        'fbm': 'FBM',
        'grain': 'Grain',
        'pixelate': 'Pixelate',
        'voronoi': 'Voronoi pattern',
        'lens': 'Lens distort',
      },
      value: '',
      debounced: new Debouncer({
        fn: this.onChange,
        interval: 50,
      }),
      saveDebounced: new Debouncer({
        fn: StudioStore.save,
        interval: 1000,
      }),
    };
  },
  computed: {
    selected() {
      return StudioStore.getCustomCodeItem()
    },
  },
  mounted() {
    this.initalizeEditor();

    this.$nextTick(() => {
      this.$emit('fit');
      StudioStore.state.curtain.resize();
      StudioStore.renderNFrames(3);
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    initalizeEditor() {
      const params = EFFECTS[this.selected.type].params;
      this.fragmentValue = this.selected.customFragmentShaders[0] || params.fragmentShader;
      this.vertexValue = this.selected.customVertexShaders[0] || params.vertexShader;

      const state = EditorState.create({
        doc: this.selected.customFragmentShaders[0] || params.fragmentShader,
        extensions: [
          cpp(),
          basicSetup,
          lineDecorationField,
          oneDark,
          EditorView.updateListener.of(update => {
            if(update.docChanged) {
              this.debounced.fire([update.state.doc.toString()]);
            }
          })
        ]
      });

      this.editor = new EditorView({
        state,
        parent: this.$refs.editor,
      });
    },
    refreshLatest() {
      StudioStore.refreshDesign(history => {
        const params = EFFECTS[this.selected.type].params;
        this.fragmentValue = this.selected.customFragmentShaders[0] || params.fragmentShader;
        this.vertexValue = this.selected.customVertexShaders[0] || params.vertexShader;
        const tr = { 
            changes: { 
                from: 0, 
                to: this.editor.state.doc.length, 
                insert: this.shaderView === 'fragment' ? this.fragmentValue : this.vertexValue
            }
        };
        this.editor.dispatch(tr);
        this.$nextTick(() => {
          this.debounced.fire([this.editor.state.doc.toString()]);
        });
      });
    },
    changeTemplate() {
      const tr = { 
          changes: { 
              from: 0, 
              to: this.editor.state.doc.length, 
              insert: CUSTOM_SHADERS[this.shaderTemplate]
          }
      };
      this.editor.dispatch(tr);
      this.$nextTick(() => {
        this.debounced.fire([this.editor.state.doc.toString()]);
      });
    },
    changeView() {
      const tr = { 
          changes: { 
              from: 0, 
              to: this.editor.state.doc.length, 
              insert: this.shaderView === 'fragment' ? this.fragmentValue : this.vertexValue
          }
      };
      this.editor.dispatch(tr);
      this.$nextTick(() => {
        this.debounced.fire([this.editor.state.doc.toString()]);
      });
    },
    changeState() {
      this.changeView();
    },
    handleImports(str) {
      if(str.includes('@import perlin_noise;')) {
        return str.replace('@import perlin_noise;', minifyGLSL(PERLIN_NOISE));
      } else if(str.includes('@import simplex_noise;')) {
        return str.replace('@import simplex_noise;', minifyGLSL(SIMPLEX_NOISE));
      } else if(str.includes('@import simplex_2s_noise;')) {
        return str.replace('@import simplex_2s_noise;', minifyGLSL(SIMPLEX_2S_NOISE));
      } else {
        return str;
      }
    },
    onChange(val) {
      this.errorMessage = '';

      let plane = this.selected.getPlane();
      let shader = this.shaderView === 'fragment' ? 'fragmentShader' : 'vertexShader';
      let value = this.shaderView === 'fragment' ? 'fragmentValue' : 'vertexValue';

      this[value] = val;
      
      plane.gl.shaderSource(plane._program[shader], val);
      plane.gl.compileShader(plane._program[shader]);
      let compileStatus = plane.gl.getShaderParameter(plane._program[shader], plane.gl.COMPILE_STATUS);
      
      if(this.errorLine) {
        removeAllLineDecorations(this.editor);
      }

      if(!compileStatus) {
        this.errorMessage = plane.gl.getShaderInfoLog(plane._program[shader]);
        this.handleError();
      } else {
        this.handleChange(val);
      }
    },
    handleChange(val) {
      this.selected[this.shaderView === 'fragment' ? 'customFragmentShaders' : 'customVertexShaders'][0] = val;

      this.saveDebounced.fire();

      this.selected.getPlanes().forEach(plane => {
        plane._restoreContext();
      });
      StudioStore.renderNFrames(3);
    },
    handleError() {
      const match = this.errorMessage.match(/ERROR: 0:(\d+):/);
      if (match) {
        const number = parseInt(match[1], 10);
        this.errorLine = number;
        
        this.$nextTick(() => {
          if(this.$refs.error) {
            this.$refs.error.style.top = 18.2 * number + 60 + 'px';
            errorOffsetHeight = this.$refs.error.offsetHeight;
          }

          const docPosition = this.editor.state.doc.line(number).from;
          this.editor.dispatch({ effects: addLineDecorationEffect.of({ docPosition }) });
        })
      }
    },
    closeEditor() {
      StudioStore.state.customCodeItemId = '';
    }
  },
};
</script>

<template>
  <div class="editor-container">
    <div v-if="errorMessage" class="error-container" ref="error">
      {{ errorMessage }}
    </div>
    <div class="editor-header flex justify-between">
        <DropdownMenu 
          v-model="shaderTemplate"
          :fullWidth="true"
          :noClip="true"
          :options="shaderTemplateOptions"
          @change="changeTemplate"
        />
       <div class="flex align-center">
          <RadioToggle
            v-model="shaderView"
            :options="shaderViewOptions"
            @change="changeState"
          ></RadioToggle>
          <Button 
            @click="closeEditor"
            class="button button__icon ml-3 font-secondary-color">
            <Icon icon="x" size="15" />
          </Button>
       </div>
    </div>
    <div ref="editor" class="editor"></div>
  </div>
</template>

<style lang="scss" scoped>

.editor-container {
  position: fixed;
  width: 70rem;
  height: 100vh;
  top: 0rem;
  left: 0rem;
  background-color: #141419;
  border-right: 1px solid var(--accent-color);
  z-index: 9999;
  overflow: auto;
}

.editor {
  height: calc(100% - 5.8rem);
}

.editor-header {
  padding: 1.4rem 1.5rem;
  width: 100%;
  background-color: var(--bg-color);
}

.error-container {
  position: absolute;
  top: 0;
  width: 70rem;
  color: white;
  padding: 1rem 0.5rem;
  background-color: #ad0134;
  font-size: 1.3rem;
  font-family: "JetBrains Mono", monospace;
  z-index: 99999;
}

</style>
