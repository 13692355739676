<script>
export default {
    props: ['image', 'index', 'disabled'],
    data() {
        return {
            contextMenuOpen: false
        }
    },
}
</script>

<template>
    <div class="image-library-image" :class="{'disabled': disabled}">
      <img @click="$emit('choose', image)" :src="image.thumb">
      <a @click="contextMenuOpen = true" href="javascript:void(0)" class="edit-design-button button button__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><circle cx="128" cy="128" r="16"></circle><circle cx="64" cy="128" r="16"></circle><circle cx="192" cy="128" r="16"></circle></svg>
      </a>
      <div aria-modal v-if="contextMenuOpen" @mouseleave="contextMenuOpen = false" class="design-listing-item-context-menu">
        <a @click="$emit('choose', image)" class="context-menu-link" href="javascript:void(0)" title="Select">Select</a>
        <a @click="image.delete" class="context-menu-link" href="javascript:void(0)" title="Delete">Delete</a>
      </div>
    </div>
</template>

<style lang="scss" scoped>

.image-library-image {
  position: relative;
  width: 13rem;
  height: 13rem;
  box-shadow: inset 0 0 0 0.1rem var(--accent-color);
  border-radius: 0.4rem;
  background-image: linear-gradient(45deg, var(--artboard-color) 25%, transparent 25%), linear-gradient(135deg, var(--artboard-color) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--artboard-color) 75%), linear-gradient(135deg, transparent 75%, var(--artboard-color) 75%);
  background-size: 25px 25px;
  /* Must be a square */
  background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px;
  cursor: pointer;

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    box-shadow: inset 0 0 0 0.1rem var(--font-color);

    .edit-design-button {
      display: block;
    }
  }
}

.image-library-image:hover {
  .delete-image {
    display: initial;
  }
}

.edit-design-button.button.button__icon {
  position: absolute;
  background-color: var(--bg-color);
  top: 1rem;
  right: 1rem;
  width: 3.2rem;
  height: 3.2rem;
  z-index: 0;
  color: var(--font-secondary-color);
  box-shadow: 0 0.1rem 0.3rem rgba(#000, 0.2);
}

</style>