<script>
import Icon from './Icon.vue';

export default {
  props: ['animation', 'visible', 'animating'],
  components: {Icon}
}
</script>

<template>
  <span>
  <div
    v-if="animation"
    class="
      button button__icon button__icon-auto-width
      effect-animate-button
    "
    :class="{
      'effect-animate-button__animating':
        animating && visible,
    }"
    @click="$emit('toggle-animation')"
  >
    <svg
      v-if="animating"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="none"></rect>
      <path
        d="M216,48V208a16.01833,16.01833,0,0,1-16,16H164a16.01833,16.01833,0,0,1-16-16V48a16.01833,16.01833,0,0,1,16-16h36A16.01833,16.01833,0,0,1,216,48ZM92,32H56A16.01833,16.01833,0,0,0,40,48V208a16.01833,16.01833,0,0,0,16,16H92a16.01833,16.01833,0,0,0,16-16V48A16.01833,16.01833,0,0,0,92,32Z"
      ></path>
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="currentColor"
      viewBox="0 0 256 256"
    >
      <rect width="256" height="256" fill="none"></rect>
      <path
        d="M232.3125,114.34375,88.34375,26.35937A15.99781,15.99781,0,0,0,64,40.00781V215.99219a16.00521,16.00521,0,0,0,24.34375,13.64843L232.3125,141.65625a16.00727,16.00727,0,0,0,0-27.3125Z"
      ></path>
    </svg>
  </div>
  <Icon v-else icon="effect" size="24" />
  </span>
</template>

<style lang="scss" scoped>


.button.effect-animate-button {
  position: relative;
  padding: 0.6rem;
  border-radius: 50%;
  border: 1px solid var(--font-tertiary-color);
}

.history-item-editing .effect-animate-button {
  border: 1px solid #ffffff50;
}

.history-item-editing .effect-animate-button:hover {
  background-color: #ffffff25;
}

.effect-animate-button__animating {
  border: 1px solid transparent;
  &:before {
    content: "";
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    border: 2px dotted var(--primary-color);
    animation: spin 8s linear infinite;
  }
  &.effect-animate-button__animating-hidden {
    &:before {
      border: 2px dotted var(--font-secondary-color);
    }
  }
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

</style>