<script>
import * as UnicornStudio from '../lib/unicornStudio';

export default {
  mounted() {
    //document.body.style.overflow = 'hidden';
    document.body.style.background = 'transparent';
    const urlParams = new URLSearchParams(window.location.search);

    let projectId = this.$route.params.designId;
    let update = urlParams.get('update');
    let production = urlParams.get('production');

    if (update) {
      projectId += `?update=${update}`
    }

    let start = performance.now();
    UnicornStudio.addScene({
      elementId: 'unicorn',
      projectId: projectId,
      production: production,
      dpi: urlParams.get('dpi'),
      fps: urlParams.get('fps'),
      scale: urlParams.get('scale'),
      iframe: true,
    }).then(scene => {
      window.scrollTo(0,0);
      console.log('Scene rendered in: ' + (performance.now() - start).toFixed(0) + 'ms');
    });
  }
}
</script>

<template>
    <div class="embed-wrapper">
      <div class="main-container" id="unicorn"></div>
    </div>
</template>

<style lang="scss">

.embed-wrapper {
  height: 100vh;
}

.main-container {
  width: 100vw;
  height: 100vh;
}

.unicorn-1 {
  height: 300px;
  width: 200px;
}

.unicorn-2 {
  height: 400px;
  width: 200px;
  margin-top: 20vh;
  margin-left: 20vh;
}

</style>