import { reactive, computed } from 'vue';
import { FirebaseStore } from './FirebaseStore';

const curated = [
  {
    family: 'Fivo Sans Modern',
    category: 'sans-serif',
    featured: true,
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/FivoSansModern%2FFivoSansModern-Regular.otf',
      '400italic':
        'https://assets.unicorn.studio/fonts/studio_picks/FivoSansModern%2FFivoSansModern-Regular-Oblique.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/FivoSansModern%2FFivoSansModern-Medium.otf',
      '500italic':
        'https://assets.unicorn.studio/fonts/studio_picks/FivoSansModern%2FFivoSansModern-Medium-Oblique.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/FivoSansModern%2FFivoSansModern-Bold.otf',
      '600italic': 'https://assets.unicorn.studio/fonts/studio_picks/FivoSansModern%2FFivoSansModern-Bold-Oblique.otf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/FivoSansModern%2FFivoSansModern-Heavy.otf',
      '700italic': 'https://assets.unicorn.studio/fonts/studio_picks/FivoSansModern%2FFivoSansModern-Heavy-Oblique.otf',
      800: 'https://assets.unicorn.studio/fonts/studio_picks/FivoSansModern%2FFivoSansModern-ExtBlack.otf',
      '800italic':
        'https://assets.unicorn.studio/fonts/studio_picks/FivoSansModern%2FFivoSansModern-ExtBlack-Oblique.otf',
    },
    variants: ['regular', '500', '600', '700', '800', '400italic', '500italic', '600italic', '700italic', '800italic'],
  },
  {
    family: 'Adelphe Floreal',
    category: 'serif',
    featured: true,
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Floreal%2FAdelphe-FlorealRegular.otf',
      italic: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Floreal%2FAdelphe-FlorealItalic.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Floreal%2FAdelphe-FlorealSemiBold.otf',
      '500italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Floreal%2FAdelphe-FlorealSemiBoldItalic.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Floreal%2FAdelphe-FlorealBold.otf',
      '600italic': 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Floreal%2FAdelphe-FlorealBoldItalic.otf',
    },
    variants: ['regular', 'italic', '500', '500italic', '600', '600italic'],
  },
  {
    family: 'Adelphe Fructidor',
    category: 'serif',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Fructidor%2FAdelphe-FructidorRegular.otf',
      italic: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Fructidor%2FAdelphe-FructidorItalic.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Fructidor%2FAdelphe-FructidorSemiBold.otf',
      '500italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Fructidor%2FAdelphe-FructidorSemiBoldItalic.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Fructidor%2FAdelphe-FructidorBold.otf',
      '600italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Fructidor%2FAdelphe-FructidorBoldItalic.otf',
    },
    variants: ['regular', 'italic', '500', '500italic', '600', '600italic'],
  },
  {
    family: 'Adelphe Germinal',
    category: 'serif',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Germinal%2FAdelphe-GerminalRegular.otf',
      italic: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Germinal%2FAdelphe-GerminalItalic.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Germinal%2FAdelphe-GerminalSemiBold.otf',
      '500italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Germinal%2FAdelphe-GerminalSemiBoldItalic.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Germinal%2FAdelphe-GerminalBold.otf',
      '600italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Adelphe%20Germinal%2FAdelphe-GerminalBoldItalic.otf',
    },
    variants: ['regular', 'italic', '500', '500italic', '600', '600italic'],
  },
  {
    family: 'Azadliq',
    category: 'display',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Azadliq%2FFFF_AZADLIQ-Black.otf',
    },
    variants: ['regular'],
  },
  {
    family: 'Pressuru',
    category: 'sans-serif',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Pressuru%2Fpressuru.otf',
    },
    variants: ['regular'],
  },
  {
    family: 'Unbounded',
    featured: true,
    category: 'display',
    files: {
      200: 'https://assets.unicorn.studio/fonts/studio_picks/Unbounded%2FUnbounded-ExtraLight.ttf',
      300: 'https://assets.unicorn.studio/fonts/studio_picks/Unbounded%2FUnbounded-Light.ttf',
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Unbounded%2FUnbounded-Regular.ttf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Unbounded%2FUnbounded-Medium.ttf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Unbounded%2FUnbounded-Bold.ttf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/Unbounded%2FUnbounded-Black.ttf',
    },
    variants: ['200', '300', 'regular', '500', '600', '700'],
  },
  {
    family: 'Overused Grotesk',
    featured: true,
    category: 'sans-serif',
    files: {
      200: 'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-Light.otf',
      300: 'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-Book.otf',
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-Roman.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-Medium.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-SemiBold.otf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-Bold.otf',
      800: 'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-Black.otf',
      '200italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-LightItalic.otf',
      '300italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-BookItalic.otf',
      italic: 'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-Italic.otf',
      '500italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-MediumItalic.otf',
      '600italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-SemiBoldItalic.otf',
      '700italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-BoldItalic.otf',
      '800italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Overused%20Grotesk%2FOverusedGrotesk-BlackItalic.otf',
    },
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
  },
  {
    family: 'Libre Caslon Condensed',
    featured: true,
    category: 'serif',
    files: {
      regular:
        'https://assets.unicorn.studio/fonts/studio_picks/Libre%20Caslon%20Condensed%2FLibreCaslonCondensed-Regular.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Libre%20Caslon%20Condensed%2FLibreCaslonCondensed-Medium.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Libre%20Caslon%20Condensed%2FLibreCaslonCondensed-SemiBold.otf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/Libre%20Caslon%20Condensed%2FLibreCaslonCondensed-Bold.otf',
      italic:
        'https://assets.unicorn.studio/fonts/studio_picks/Libre%20Caslon%20Condensed%2FLibreCaslonCondensed-Italic.otf',
      '500italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Libre%20Caslon%20Condensed%2FLibreCaslonCondensed-MediumItalic.otf',
      '600italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Libre%20Caslon%20Condensed%2FLibreCaslonCondensed-SemiBoldItalic.otf',
      '700italic':
        'https://assets.unicorn.studio/fonts/studio_picks/Libre%20Caslon%20Condensed%2FLibreCaslonCondensed-BoldItalic.otf',
    },
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
  },
  {
    family: 'Aspekta',
    category: 'sans-serif',
    featured: true,
    files: {
      100: 'https://assets.unicorn.studio/fonts/studio_picks/Aspekta%2FAspekta-100.otf',
      200: 'https://assets.unicorn.studio/fonts/studio_picks/Aspekta%2FAspekta-200.otf',
      300: 'https://assets.unicorn.studio/fonts/studio_picks/Aspekta%2FAspekta-300.otf',
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Aspekta%2FAspekta-400.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Aspekta%2FAspekta-500.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Aspekta%2FAspekta-600.otf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/Aspekta%2FAspekta-700.otf',
      800: 'https://assets.unicorn.studio/fonts/studio_picks/Aspekta%2FAspekta-800.otf',
      900: 'https://assets.unicorn.studio/fonts/studio_picks/Aspekta%2FAspekta-900.otf',
      1000: 'https://assets.unicorn.studio/fonts/studio_picks/Aspekta%2FAspekta-1000.otf',
    },
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900', '1000'],
  },
  {
    family: 'TASA Orbiter Display',
    featured: true,
    category: 'display',
    files: {
      regular:
        'https://assets.unicorn.studio/fonts/studio_picks/TASA%20Orbiter%20Display%2FTASAOrbiterDisplay-Regular.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/TASA%20Orbiter%20Display%2FTASAOrbiterDisplay-Medium.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/TASA%20Orbiter%20Display%2FTASAOrbiterDisplay-SemiBold.otf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/TASA%20Orbiter%20Display%2FTASAOrbiterDisplay-Bold.otf',
      800: 'https://assets.unicorn.studio/fonts/studio_picks/TASA%20Orbiter%20Display%2FTASAOrbiterDisplay-Black.otf',
    },
    variants: ['regular', '500', '600', '700', '800'],
  },
  {
    family: 'TASA Explorer',
    category: 'sans-serif',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/TASA%20Explorer%2FTASAExplorer-Regular.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/TASA%20Explorer%2FTASAExplorer-Medium.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/TASA%20Explorer%2FTASAExplorer-SemiBold.otf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/TASA%20Explorer%2FTASAExplorer-Bold.otf',
      800: 'https://assets.unicorn.studio/fonts/studio_picks/TASA%20Explorer%2FTASAExplorer-Black.otf',
    },
    variants: ['regular', '500', '600', '700', '800'],
  },
  {
    family: 'Fixel Display',
    featured: true,
    category: 'display',
    files: {
      100: 'https://assets.unicorn.studio/fonts/studio_picks/Fixel%20Display%2FFixelDisplay-Thin.ttf',
      200: 'https://assets.unicorn.studio/fonts/studio_picks/Fixel%20Display%2FFixelDisplay-ExtraLight.ttf',
      300: 'https://assets.unicorn.studio/fonts/studio_picks/Fixel%20Display%2FFixelDisplay-Light.ttf',
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Fixel%20Display%2FFixelDisplay-Regular.ttf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Fixel%20Display%2FFixelDisplay-Medium.ttf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Fixel%20Display%2FFixelDisplay-SemiBold.ttf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/Fixel%20Display%2FFixelDisplay-Bold.ttf',
      800: 'https://assets.unicorn.studio/fonts/studio_picks/Fixel%20Display%2FFixelDisplay-ExtraBold.ttf',
      900: 'https://assets.unicorn.studio/fonts/studio_picks/Fixel%20Display%2FFixelDisplay-Black.ttf',
    },
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
  },
  {
    family: 'Connection III',
    featured: true,
    category: 'display',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Connection%20II%2FConnectionIii-Rj3W.otf',
    },
    variants: ['regular'],
  },
  {
    family: 'Meyrin',
    category: 'display',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Meyrin%2FMeyrin.ttf',
    },
    variants: ['regular'],
  },
  {
    family: 'Generale Station',
    category: 'display',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Generale%20Station%2FGeneraleStation-Regular.otf',
      'semi-extended':
        'https://assets.unicorn.studio/fonts/studio_picks/Generale%20Station%2FGeneraleStation-SemiExtended.otf',
      extended: 'https://assets.unicorn.studio/fonts/studio_picks/Generale%20Station%2FGeneraleStation-Extended.otf',
      'ultra-extended':
        ' https://assets.unicorn.studio/fonts/studio_picks/Generale%20Station%2FGeneraleStation-UltraExtended.otf',
    },
    variants: ['regular', 'semi-extended', 'extended', 'ultra-extended'],
  },
  {
    family: 'Murmure',
    featured: true,
    category: 'display',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Murmure%2Fle-murmure.otf',
    },
    variants: ['regular'],
  },
  {
    family: 'Excessive Extra Condensed',
    featured: true,
    category: 'display',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Excessive%2FExcessive-ExtraCondensed.otf',
    },
    variants: ['regular'],
  },
  {
    family: 'Americaine Condensed',
    featured: true,
    category: 'display',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Americaine%2FAmericaine-Condensed.otf',
    },
    variants: ['regular'],
  },
  {
    family: 'Lucette',
    category: 'serif',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Lucette%2FLucette-Regular.otf',
      italic: 'https://assets.unicorn.studio/fonts/studio_picks/Lucette%2FLucette-Regularitalic.otf',
    },
    variants: ['regular', 'italic'],
  },
  {
    family: 'Savate',
    featured: true,
    category: 'display',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Savate%2Fsavate-regular.otf',
      italic: 'https://assets.unicorn.studio/fonts/studio_picks/Savate%2Fsavate-italique.otf',
    },
    variants: ['regular', 'italic'],
  },
  {
    family: 'Alinsa',
    category: 'display',
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Alinsa%2FAlinsa.otf',
    },
    variants: ['regular', 'italic'],
  },
  {
    family: 'Departure Mono',
    category: 'mono',
    featured: true,
    files: {
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/DepartureMono/DepartureMono-Regular.woff',
    },
    variants: ['regular'],
  },
  {
    family: 'Humane',
    featured: true,
    category: 'sans-serif',
    files: {
      100: 'https://assets.unicorn.studio/fonts/studio_picks/Humane%202.0%2FHumane-Thin.ttf',
      200: 'https://assets.unicorn.studio/fonts/studio_picks/Humane%202.0%2FHumane-ExtraLight.ttf',
      300: 'https://assets.unicorn.studio/fonts/studio_picks/Humane%202.0%2FHumane-Light.ttf',
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Humane%202.0%2FHumane-Regular.ttf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Humane%202.0%2FHumane-Medium.ttf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Humane%202.0%2FHumane-SemiBold.ttf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/Humane%202.0%2FHumane-Bold.ttf',
      800: 'https://assets.unicorn.studio/fonts/studio_picks/Humane%202.0%2FHumane-ExtraBold.ttf',
      900: 'https://assets.unicorn.studio/fonts/studio_picks/Humane%202.0%2FHumane-Black.ttf',
    },
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
  },
  {
    family: 'Nohemi',
    featured: true,
    category: 'sans-serif',
    files: {
      100: 'https://assets.unicorn.studio/fonts/studio_picks/Nohemi/Nohemi-Thin.otf',
      200: 'https://assets.unicorn.studio/fonts/studio_picks/Nohemi/Nohemi-ExtraLight.otf',
      300: 'https://assets.unicorn.studio/fonts/studio_picks/Nohemi/Nohemi-Light.otf',
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Nohemi/Nohemi-Regular.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Nohemi/Nohemi-Medium.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Nohemi/Nohemi-SemiBold.otf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/Nohemi/Nohemi-Bold.otf',
      800: 'https://assets.unicorn.studio/fonts/studio_picks/Nohemi/Nohemi-ExtraBold.otf',
      900: 'https://assets.unicorn.studio/fonts/studio_picks/Nohemi/Nohemi-Black.otf',
    },
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
  },
  {
    family: 'Thunder',
    featured: true,
    category: 'sans-serif',
    files: {
      100: 'https://assets.unicorn.studio/fonts/studio_picks/Thunder%2FThunder-ThinLC.otf',
      200: 'https://assets.unicorn.studio/fonts/studio_picks/Thunder%2FThunder-ExtraLightLC.otf',
      300: 'https://assets.unicorn.studio/fonts/studio_picks/Thunder%2FThunder-LightLC.otf',
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Thunder%2FThunder-LC.otf',
      500: 'https://assets.unicorn.studio/fonts/studio_picks/Thunder%2FThunder-MediumLC.otf',
      600: 'https://assets.unicorn.studio/fonts/studio_picks/Thunder%2FThunder-SemiBoldLC.otf',
      700: 'https://assets.unicorn.studio/fonts/studio_picks/Thunder%2FThunder-BoldLC.otf',
      800: 'https://assets.unicorn.studio/fonts/studio_picks/Thunder%2FThunder-ExtraBoldLC.otf',
      900: 'https://assets.unicorn.studio/fonts/studio_picks/Thunder%2FThunder-BlackLC.otf',
    },
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
  },
  {
    family: 'Sprat',
    featured: true,
    category: 'sans-serif',
    files: {
      'condensed-thin': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-CondensedThin.otf',
      'regular-thin': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-RegularThin.otf',
      'extended-thin': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-ExtendedThin.otf',
      'condensed-light': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-CondensedLight.otf',
      'regular-light': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-Regularlight.otf',
      'extended-light': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-ExtendedLight.otf',
      'condensed-regular': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-CondesedRegular.otf',
      regular: 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-Regular.otf',
      'extended-regular': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-Extendedregular.otf',
      'condensed-medium': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-CondensedMedium.otf',
      'regular-medium': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-RegularMedium.otf',
      'extended-medium': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-ExtendedMedium.otf',
      'extended-regular': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-Extendedregular.otf',
      'condensed-bold': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-CondensedBold.otf',
      'regular-bold': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-RegularBold.otf',
      'extended-bold': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-ExtendedBold.otf',
      'condensed-black': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-CondensedBlack.otf',
      'regular-black': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-RegularBlack.otf',
      'extended-black': 'https://assets.unicorn.studio/fonts/studio_picks/Sprat%2FSprat-ExtendedBlack.otf',
    },
    // variants: ['thin', 'extra-light', 'light', 'regular', 'medium', 'semi-bold', 'bold', 'extra-bold', 'black']
    variants: [
      'condensed-thin',
      'regular-thin',
      'extended-thin',
      'condensed-light',
      'regular-light',
      'extended-light',
      'condensed-regular',
      'regular-regular',
      'extended-regular',
      'condensed-medium',
      'regular-medium',
      'extended-medium',
      'condensed-bold',
      'regular-bold',
      'extended-bold',
      'condensed-black',
      'regular-black',
      'extended-black',
    ],
  },
];

let curatedFonts = {};
curated.forEach(font => {
  curatedFonts[font.family] = font;
});

const featuredGoogleFonts = [
  'Instrument Serif',
  'Gloock',
  'Inter Tight',
  'Koulen',
  'Montagu Slab',
  'Outfit',
  'Syne',
  'Rammetto One',
  'Spline Sans Mono',
  'Xanh Mono',
];

export const FontsStore = {
  state: reactive({
    googleFonts: {},
    curatedFonts,
    customFonts: {},
    fonts: computed(() => {
      return { ...FontsStore.state.googleFonts, ...FontsStore.state.curatedFonts, ...FontsStore.state.customFonts };
    }),
    loadingFonts: false,
  }),
  async loadGoogleFonts() {
    this.state.loadingFonts = true;
    await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDjQtf5-bJTvLT1CLOGEeeGDtuT1KlV48E`)
      .then(resp => resp.json())
      .then(resp => {
        for (let i = 0; i < resp?.items.length; i++) {
          if (resp.items[i].family) {
            if (featuredGoogleFonts.includes(resp.items[i].family)) {
              resp.items[i].featured = true;
            }
            this.state.googleFonts[resp.items[i].family] = resp.items[i];
          }
        }
        this.state.loadingFonts = false;
      })
      .catch(err => {
        this.state.loadingFonts = false;
        console.log(err);
      });
  },
  loadFonts() {
    FirebaseStore.getCustomFonts().then(items => {
      items.docs.forEach(doc => {
        let data = doc.data();
        data.category = 'custom';
        data.id = doc.id;
        this.state.customFonts[data.family] = data;
      });
    });
  },
};
