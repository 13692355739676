import {
  computeFragColor,
  vertexShader,
  EASE,
  mixRadiusProperties,
  EASING_FUNCTIONS,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  GAUSSIAN_WEIGHTS_36,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const blurShader = `#version 300 es
  precision highp float;

  in vec3 vVertexPosition;
  in vec2 vTextureCoord;

  uniform sampler2D uTexture;
  uniform float uAmount;
  uniform float uWarp;
  uniform float uSkew;
  uniform int uDirection;
  uniform int uType;
  uniform int uEasing;
  uniform vec2 uPos;
  ${UNIVERSAL_UNIFORMS}
  ${EASE}

  out vec4 fragColor;

  float random(vec2 seed) {
    return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
  }

  const int kernelSize = 36;
  ${GAUSSIAN_WEIGHTS_36}

  vec4 GaussianBlur(sampler2D tex, vec2 uv, vec2 direction) {
    vec4 color = vec4(0.0);
    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    float inner = distance(uv, pos);
    float outer = max(0., 1.-distance(uv, pos));

    float amt = uDirection <= 1 ? 6. : 11.;
    float amount = (uAmount * amt) * ease(uEasing, mix(inner, outer, uWarp));
 
    color += texture(tex, uv) * getGaussianWeight(0);
    
    for (int i = 0; i < kernelSize; i++) {
      float x = float(i - kernelSize / 2) * amount;
      color += texture(tex, uv + vec2(x * 0.001) * direction * vec2(uSkew, 1. - uSkew)) * getGaussianWeight(i);
    }

    float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;
    color.rgb += dither;
    
    return color;
  }

  vec4 BoxBlur(sampler2D tex, vec2 uv, vec2 direction) {
    vec4 color = vec4(0.0);

    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
    float inner = distance(uv, pos);
    float outer = max(0., 1.-distance(uv, pos));
    float amount = uAmount * ease(uEasing, mix(inner, outer, uWarp));
    
    for (int i = 0; i < kernelSize; i++) {
      float x = float(i - kernelSize / 2) * amount/144.;
      color += texture(tex, uv + vec2(x) * direction * vec2(uSkew, 1. - uSkew));
    }
    
    return color/float(kernelSize);
  }

  vec4 blur(vec2 uv, vec2 direction) {
    switch(uType) {
      case 0: return GaussianBlur(uTexture, uv, direction); break;
      case 1: return BoxBlur(uTexture, uv, direction); break;
      default: return GaussianBlur(uTexture, uv, direction); break;
    }
  }

  void main() {	
    vec2 uv = vTextureCoord;
    vec4 color = vec4(0);
    int dir = uDirection % 2;
    vec2 direction = dir == 1 ? vec2(0, uResolution.x/uResolution.y) : vec2(1, 0);

    color = blur(uv, direction);

    ${computeFragColor('color')}
  }
`;

const blurParams = {
  fragmentShader: blurShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    blurType: {
      name: 'uType',
      type: '1i',
      value: 0,
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.2,
    },
    vertical: {
      name: 'uDirection',
      type: '1i',
      value: 0,
    },
    warp: {
      name: 'uWarp',
      type: '1f',
      value: 0.5,
    },
    skew: {
      name: 'uSkew',
      type: '1f',
      value: 0.5,
    },
    easing: {
      name: 'uEasing',
      type: '1i',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const BLUR = {
  id: 'blur',
  label: 'Blur',
  params: blurParams,
  aspectRatio: 1,
  downSample: true,
  passes: [
    {
      prop: 'vertical',
      value: 1,
      downSample: true,
    },
    {
      prop: 'vertical',
      value: 2,
      downSample: true,
    },
    {
      prop: 'vertical',
      value: 3,
      downSample: true,
    },
  ],
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    blurType: {
      label: 'Type',
      value: 0,
      options: {
        0: 'Gaussian',
        1: 'Box',
      },
      responsiveDisabled: true,
    },
    amount: {
      label: 'Radius',
      value: 0.2,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount of blur',
    },
    skew: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Skews the blur along the X or Y axis',
    },
    warp: {
      label: 'Tilt shift',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls tilt shift, adding a falloff towards or away from the effect position',
    },
    easing: {
      label: 'Easing func',
      value: 0,
      options: Object.keys(EASING_FUNCTIONS).map((n, index) => n.split('_').join(' ').toLowerCase()),
    },
    ...interactiveProperties,
  },
};
