import { computeFragColor, vertexShader, FLOATING_POINT, UNIVERSAL_UNIFORMS, universalUniformParams } from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision mediump float;

// Want a quick intro?
// Select "Shader tutorial" from the dropdown above

in vec2 vTextureCoord;

uniform sampler2D uTexture; // Underlying scene
uniform sampler2D uBgTexture;
${UNIVERSAL_UNIFORMS}

uniform vec3 uTint1;
uniform vec3 uTint2;
uniform vec2 uPos;
uniform float uScale;
uniform float uAngle;
uniform float uPass;
uniform float uIntensity1;
uniform float uIntensity2;
uniform float uAmbience;
uniform float uDistance;
uniform float uDiffusion;
uniform float uTime;

out vec4 fragColor;

// Kernel size can be a uniform if it needs to be dynamic, or calculated based on sigma.
const int kernelSize = 41;

vec4 GaussianBlur(sampler2D tex, vec2 uv, vec2 direction) {
  float sigma = 10.0 * (uDiffusion + 0.01);
  vec4 color = vec4(0.0);
  float weightSum = 0.0;

  float weights[kernelSize];

  // Compute weights dynamically
  for (int i = 0; i < kernelSize; i++) {
    float x = float(i - kernelSize / 2);
    weights[i] = exp(-x * x / (2.0 * sigma * sigma)) / (sqrt(2.0 * 3.14159265) * sigma);
    weightSum += weights[i];
  }

  // Apply the blur
  for (int i = 0; i < kernelSize; i++) {
    float x = float(i - kernelSize / 2);
    color += texture(tex, uv + vec2(x/max(uResolution.x, uResolution.y)) * direction) * (weights[i] / weightSum);
  }

  return color;
}

float angleBetweenVec2(vec2 A, vec2 B) {
    float dotProduct = dot(A, B);
    float magnitudeA = length(A);
    float magnitudeB = length(B);
    float cosTheta = dotProduct / (magnitudeA * magnitudeB);
    
    // Clamp the value to the domain of arccos, which is [-1, 1]
    cosTheta = clamp(cosTheta, -1.0, 1.0);
    
    float angle = acos(cosTheta);
    
    // Normalize to [0, 1] by dividing by pi
    float normalizedAngle = angle / 3.14159265359;
    
    return normalizedAngle;
}

  float getHeight(vec2 uv) {
    vec4 col = texture(uTexture, uv);
    return col.r;
  }

vec4 computeNoise(vec2 uv) {
    vec2 ste = (1. / uResolution);
    float height = getHeight(uv);
    
    vec2 dxy = height - vec2(getHeight(uv + vec2(ste.x, 0.)),
                             getHeight(uv + vec2(0., ste.y)));

    return vec4(normalize(vec3(dxy * (0.02 + uDiffusion * 0.06) / ste,  2.)), height);
}

float circularOut(float t) {
    return sqrt((2.0 - t) * t);
  }

  void main() {
    vec2 uv = vTextureCoord;
    vec4 color;

    if(uPass == 0.) {
        color = GaussianBlur(uTexture, uv, vec2(uResolution.y / uResolution.x, 0));
    } else if(uPass == 1.) {
        color = GaussianBlur(uTexture, uv, vec2(0, 1));
    } else {
        vec2 aspectRatio = vec2(uResolution.x / uResolution.y, 1);
        color = texture(uBgTexture, uv);
        
        // Rotated positions
        vec2 center = uPos;
        float radian = (uAngle + fract(uTime*0.01)) * 2.0 * 3.14159;
        mat2 rotation = mat2(cos(radian), -sin(radian), sin(radian), cos(radian));
        
        vec2 pos1 = center + rotation * vec2(-0.5 - uDistance*0.5, 0);
        vec2 pos2 = center + rotation * vec2(0.5 + uDistance*0.5, 0);
        
        vec3 finalColor = vec3(0);
        
        // Iterate over each light source
        for(int i = 0; i < 2; ++i) {
            vec2 pos = i == 0 ? pos1 : pos2;
            float intensity = i == 0 ? uIntensity1 : uIntensity2;
            intensity *= 4.;
            vec3 tint = i == 0 ? uTint1 : uTint2;
            
            float dist = distance(uv, pos) / (uScale + 0.5);
            float highlightDist = dist / 2.0;
            
            float spot = max(0., max(0., (1. - dist)) * max(0., (1. - dist)));
            float highlightSpot = max(0., max(0., (1. - highlightDist)) * max(0., (1. - highlightDist)));
            
            vec3 normal = computeNoise(uv).rgb;
            vec2 lightDir = pos - uv;
            float lightDistance = 1. - length(lightDir);
            
            lightDir = normalize(lightDir);
            
            vec3 diff = vec3(max(dot(normal, vec3(lightDir, lightDistance - 0.5)), 0.0));
            vec3 shadows = vec3(1. - max(dot(normal, vec3(lightDir, 1.)), 0.0)) * 0.25;
            vec3 highlights = vec3(max(dot(normal, vec3(lightDir, 0.)), 0.0));
            
            vec3 base = color.rgb * tint * spot * intensity;
            
            finalColor += mix(
                base + base * tint * diff * highlightSpot * 2.0 - shadows * highlightSpot,
                color.rgb + base * diff,
                uAmbience + 0.01
            ) * 0.5;
        }
        
        color.rgb = finalColor;
    }

    ${computeFragColor('color')}
}
`

const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    pos: {
      name: "uPos",
      type: "2f",
      value: new Vec2(0.5),
    },
    scale: {
      name: "uScale",
      type: "1f",
      value: 0.5,
    },
    distance: {
      name: "uDistance",
      type: "1f",
      value: 0.5,
    },
    light1Intensity: {
      name: "uIntensity1",
      type: "1f",
      value: 0.5,
    },
    light2Intensity: {
      name: "uIntensity2",
      type: "1f",
      value: 0.5,
    },
    ambience: {
      name: "uAmbience",
      type: "1f",
      value: 0.5,
    },
    angle: {
      name: "uAngle",
      type: "1f",
      value: 0,
    },
    diffusion: {
      name: "uDiffusion",
      type: "1f",
      value: 0.5,
    },
    tint1: {
      name: "uTint1",
      type: "3f",
      value: new Vec3(0.98, 0.12, 0.89),
    },
    tint2: {
      name: "uTint2",
      type: "3f",
      value: new Vec3(0.12, 0.77, 0.89),
    },
    time: {
      name: "uTime",
      type: "1f",
      value: 0,
    },
    pass: {
      name: "uPass",
      type: "1f",
      value: 0,
    },
    ...universalUniformParams
  }
}

export const DOUBLEPOINTLIGHT = {
  id: 'doublepointlight',
  label: 'Double point light',
  params: params,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1
  },
  downSample: true,
  passes: [{
    prop: 'pass',
    value: 1,
    downSample: true
  }, {
    prop: 'pass',
    value: 2,
  }],
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    light1: {
      label: 'Light 1 Tint',
      value: new Vec3(0.98, 0.12, 0.89),
      output: 'color'
    },
    light1Intensity: {
      label: 'Light 1 Mix',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    light2: {
      label: 'Light 2 Tint',
      value: new Vec3(0.12, 0.77, 0.89),
      output: 'color'
    },
    light2Intensity: {
      label: 'Light 2 Mix',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    diffusion: {
      label: 'Diffusion',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    distance: {
      label: 'Distance',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    ambience: {
      label: 'Ambience',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    speed: {
      label: 'Speed',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
  }
}