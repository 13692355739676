<script>
    export default {
        props: ['modelValue', 'small']
    }
</script>

<template>
    <input class="text-input"
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="$emit('update:modelValue', $event.target.value)"
    />
</template>

<style lang="scss">

    .text-input {
        font: inherit;
        width: 100%;
        background-color: var(--field-bg);
        padding: 1.2rem 1.2rem;
        transition: outline 0.25s ease;
        border: none;
        border-radius: 0.3rem;

        &:focus {
            outline: 0.2rem solid var(--primary-color);
            outline-offset: 0.2rem;
        }

        .small {
            padding: 1rem 1.2rem;
        }

        &:disabled {
            opacity: 0.6;
        }
        
    }

</style>