<script>
export default {
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  data() {
    return {
      tooltipPosition: {},
      isHovering: false,
    };
  },
  watch: {
    isHovering() {
      if(this.isHovering) {
        this.$nextTick(() => {
          let rect = this.$refs.ttwrapper.getBoundingClientRect();
          let ttRect = this.$refs.tt.getBoundingClientRect();
          let top = (rect.top + window.scrollY - ttRect.height) - 9 + 'px';
          if(this.position === 'bottom') {
            top = (rect.top + rect.height + window.scrollY) + 'px';
          }
          this.tooltipPosition = {
            top: top,
            left: (rect.left + window.scrollX - (ttRect.width / 2) + rect.width/2) + 'px'
          };
        })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.$refs.ttwrapper) {
        let rect = this.$refs.ttwrapper.getBoundingClientRect();
        this.tooltipPosition = {
          top: (rect.top + window.scrollY) + 'px',
          left: (rect.left + window.scrollX) + 'px'
        };
      }
    });
  },
  computed: {
    computedPosition() {
      return this.tooltipPosition;
    }
  },
};
</script>

<template>
  <div class="tooltip-wrapper  flex align-center inline-flex" ref="ttwrapper" @mouseover="isHovering = true" @mouseleave="isHovering = false">
    <div class="tooltip-content">
      <slot></slot>
    </div>
    <Teleport to="body">
      <div v-if="tooltip && isHovering" ref="tt" class="tooltip" :style="computedPosition">
        {{ tooltip }}
        <div class="tooltip-caret" :class="{'tooltip-caret__bottom': position === 'bottom'}"></div>
      </div>
    </Teleport>
  </div>
</template>


<style scoped lang="scss">
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  cursor: pointer;
}

.tooltip {
  position: absolute;
  display: inline-table;
  bottom: 100%;
  left: 50%;
  max-width: 26rem;
  padding: 1rem;
  color: var(--body-text-color);
  background-color: var(--menu-bg-color);
  border-radius: 0.4rem;
  font-size: 1.2rem;
  line-height: 1.4;
  text-align: center;
  pointer-events: none;
  transition: visibility 0s linear 0.1s, opacity 0.1s linear;
  z-index: 999999;
}

.tooltip-caret {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: var(--menu-bg-color) transparent transparent transparent;

  &.tooltip-caret__bottom {
    top: -0.5rem;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--menu-bg-color) transparent;
  }
}
</style>
