<script>
  import ParamLabel from './ParamLabel.vue';
    export default {
        props: ['label', 'modelValue', 'options', 'locked', 'nav', 'tooltip'],
        emits: ['change', 'update:modelValue'],
        components: {ParamLabel}
    }
</script>

<template>
  <div class="parameter parameter__block">
    <ParamLabel :label="label" :tooltip="tooltip">{{ label }}</ParamLabel>
    <div class="radio-group" :class="{'radio-group__nav' : nav}">
      <a
        class="radio"
        v-for="option in options"
        :key="option.value"
        :class="{'radio__active': modelValue === option.value}"
        @click="$emit('update:modelValue', option.value); $emit('change')"
        v-html="option.label"
      ></a>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.radio {
  color: var(--font-secondary-color);
  padding: 0.4rem 0.4rem;
  margin: 0.3rem;
  line-height: 1;
  width: 100%;
  text-align: center;
  border-radius: 0.2rem;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  &:hover {
    color: var(--font-color);
  }
  &.radio__active {
    background-color: var(--selected-radio-color);
    color: var(--font-color);
    box-shadow: inset 0 1px 0 var(--font-tertiary-color);
  }
}

.radio-group {
  width: var(--param-width);
  align-items: center;
}

.radio-group,
.toggle-group {
  display: flex;
  border-radius: 0.4rem;
  background-color: var(--accent-color);
}

.radio-group.radio-group__nav {
  background-color: transparent;
  margin: 0rem 0.5rem 0.1rem 0.5rem;
  font-weight: 600;
  border-radius: 0;
  //margin-bottom: 0.5rem;

  .radio {
    margin: 0.5rem 0;
    padding: 0.8rem 0.5rem;
    border-radius: 0.4rem;
  }
  .radio__active {
    background-color: var(--accent-color);
    box-shadow: none;
  }
}
</style>