const lerp = (x, y, a) => x * (1 - a) + y * a;
const distance = (x1, y1, x2, y2) =>
  Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
const slope = (x1, x2, y1, y2) => (y2 - y1) / (x2 - x1);

function getPathDistance(arr) {
  return arr
    .map((coord, index) => {
      if (index > 0) {
        return Math.sqrt(
          Math.pow(coord[0] - arr[index - 1][0], 2) +
          Math.pow(coord[1] - arr[index - 1][1], 2)
        );
      } else {
        return 0;
      }
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue);
};

const trimExtraPoints = (arr, min) => {
  const result = [arr[0]];
  const length = arr.length - 1;
  let segmentDist = 0;

  for (let i = 1; i < length; i++) {
    const dist = distance(
      arr[i][0],
      arr[i][1],
      arr[i - 1][0],
      arr[i - 1][1]
    );
    if (dist <= min / 2) {
      if (segmentDist < min / 2) {
        segmentDist += dist;
      } else {
        result.push([
          lerp(arr[i - 1][0], arr[i][0], 0.5),
          lerp(arr[i - 1][1], arr[i][1], 0.5)
        ]);
        segmentDist = 0;
      }
    } else {
      result.push([
        lerp(arr[i - 1][0], arr[i][0], 0.5),
        lerp(arr[i - 1][1], arr[i][1], 0.5)
      ]);
    }
  }

  result.push(arr[length]);
  return result;
};

export const evenlyDistrubutePoints = (arr, min) => {
  const pathDist = getPathDistance(arr);
  const length = Math.floor(pathDist / min);
  const points = [arr[0]];

  function getNearestPoint(index) {
    let i = 1;
    let totalDist = 0;

    while (arr[i + 1] && totalDist < index * min) {
      totalDist += distance(arr[i][0], arr[i][1], arr[i - 1][0], arr[i - 1][1]);
      i++;
    }

    return arr[i];
  }

  for (let i = 0; i < length; i++) {
    const nearest = getNearestPoint(i);
    const slp = slope(
      points[i][0],
      nearest[0],
      points[i][1],
      nearest[1]
    ) || 0;
    const theta = Math.atan(slp);
    const vector = {
      x: points[i][0] <= nearest[0] ? 1 : -1,
      y: points[i][1] <= nearest[1] ? 1 : -1
    };
    points.push([+(vector.x * Math.abs(Math.cos(theta)) * min + points[i][0]).toFixed(1), +(vector.y * Math.abs(Math.sin(theta)) * min + points[i][1]).toFixed(1)]);
  }

  return points;
};

export const interpolatePath = (coords, size) => {
  const min = Math.max(1.5, size / 500 * 4);
  return evenlyDistrubutePoints(
    trimExtraPoints(coords, min),
    min
  );
}