<script>
export default {
  props: ["type", 'disabled', 'loading', 'href'],
  data() {
    return {};
  },
};
</script>

<template>
  <a v-if="href" :href="href" class="button" :disabled="disabled || loading">
    <slot />
  </a>
  <button v-else :type="type" class="button" :disabled="disabled || loading">
    <svg v-if="loading" class="mr-1" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="16" height="16" viewBox="0 0 24 24"><path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"><animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg>
    <slot />
  </button>
</template>

<style lang="scss">

button {
  background-color: unset;
  appearance: none;
  border: 0;
  color: inherit;
  font: inherit;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 1.5rem;
  text-align: center;
  border-radius: 0.3rem;
  cursor: pointer;
  letter-spacing: -0.01em;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  user-select: none;

  &.large {
    padding: 1.2rem 1.5rem;
  }

  &.small {
    font-size: 1.4rem;
  }

  &.disabled,
  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.margin-top {
    margin-top: 1rem;
  }

  &.full-width {
    width: 100%;
  }

  &.param {
    padding: 0.5rem 2rem;
    margin: 1rem 0 0 2.5rem;
    width: calc(100% - 2.5rem);
    font-weight: 400;
    background-color: var(--accent-color);
    border: 0;

    &:hover {
      background-color: var(--border-color);
    }
  }

  &.primary {
    background-color: var(--primary-color);
    color: var(--font-color);
    border-top: 1px solid var(--primary-color-light);
    border-bottom: 1px solid var(--primary-hover-color);

    &:hover {
      background-color: var(--primary-hover-color);
    }

    // & + .secondary {
    //   margin-left: 1rem;
    // }
  }

  &.secondary {
    border: 1px solid var(--border-color);
    border-top: 1px solid var(--selected-radio-color);
    color: var(--font-color);

    &:hover {
      background-color: var(--border-color);
    }
  }

  &.secondary-inverse {
    background-color: var(--selected-fill-color);
    color: var(--bg-color);

    &:hover {
      background-color: var(--font-color);
    }
  }

  &.full-width {
    width: 100%;
  }

  &.icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    border-radius: 0.3rem;

    &:hover {
      background-color: var(--accent-color);
    }

    &.icon-auto-width {
      width: auto;
    }

    &.icon-large {
      padding: 0.6rem;
    }

    &.icon-seconary {
      color: var(--font-secondary-color);
    }
  }
}
</style>