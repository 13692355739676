import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  BLEND,
  UNIVERSAL_UNIFORMS,
  UNIVERSAL_HEADER,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { BLEND_MODES } from '../../scripts/Constants.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
  precision mediump float;
  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uRadius;
  uniform float uFalloff;
  uniform float uMix;
  uniform float uDisplace;
  uniform float uColorAlpha;
  uniform float uSkew;
  uniform int uBlendMode;
  uniform vec3 uColor;
  uniform vec2 uPos;
  ${UNIVERSAL_UNIFORMS}
  ${BLEND}

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;

    vec4 color = texture(uTexture, uv);
    float colorAlpha = color.a;
    float luma = dot(color.rgb, vec3(0.299, 0.587, 0.114));
    float displacement = (luma - 0.5) * uDisplace * 0.5;
    vec2 aspectRatio = vec2(uResolution.x/uResolution.y, 1.) * vec2(uSkew, 1.-uSkew);

    // Adjust radius based on luma and displacement
    float scaledRadius = uRadius * 0.5;

    float adjustedRadius = scaledRadius + uFalloff * scaledRadius; // Increase the radius based on falloff
    float innerEdge = scaledRadius - uFalloff * scaledRadius * 0.5; // Move inward for inner edge
    float outerEdge = scaledRadius + uFalloff * scaledRadius * 0.5; // Move outward for outer edge

    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);

    float radius = length(uv * aspectRatio - pos * aspectRatio);
    float falloff = smoothstep(innerEdge + displacement, outerEdge + displacement, radius);


    if(uBlendMode > 0) {
      vec3 blended = blend(uBlendMode, uColor, color.rgb);
      color.rgb = mix(color.rgb, blended, falloff * uMix);
    } else {
      color.rgb = mix(color.rgb, mix(color.rgb, uColor, uMix), falloff);
    }

    color.a = mix((1. - falloff), 1., uColorAlpha);
              
    ${computeFragColor('color')}
  }
`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    radius: {
      name: 'uRadius',
      type: '1f',
      value: 0.5,
    },
    falloff: {
      name: 'uFalloff',
      type: '1f',
      value: 1,
    },
    mix: {
      name: 'uMix',
      type: '1f',
      value: 1,
    },
    displace: {
      name: 'uDisplace',
      type: '1f',
      value: 0,
    },
    backgroundAlpha: {
      name: 'uColorAlpha',
      type: '1f',
      value: 1,
    },
    skew: {
      name: 'uSkew',
      type: '1f',
      value: 0.5,
    },
    color: {
      name: 'uColor',
      type: '3f',
      value: new Vec3(0),
    },
    blendMode: {
      name: 'uBlendMode',
      type: '1i',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const VIGNETTE = {
  id: 'vignette',
  label: 'Vignette',
  params: params,
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    color: {
      label: 'Color',
      value: new Vec3(0),
      output: 'color',
      tooltip: 'The color of the vignette',
      alpha: 'backgroundAlpha',
      responsiveDisabled: true,
    },
    backgroundAlpha: {
      label: 'Color Alpha',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      hidden: true,
    },
    radius: {
      label: 'Radius',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    falloff: {
      label: 'Falloff',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    skew: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    mix: {
      label: 'Mix',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    displace: {
      label: 'Displace',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    blendMode: {
      label: 'Blend mode',
      value: 'NORMAL',
      options: BLEND_MODES,
      responsiveDisabled: true,
    },
    ...interactiveProperties,
  },
};
