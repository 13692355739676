import { SIMPLEX_2S_NOISE, GAUSSIAN_WEIGHTS_24, vertexShader } from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const mouseFragmentShader = `#version 300 es
precision highp float;

in vec2 vTextureCoord;
in vec3 vVertexPosition;

uniform sampler2D uTexture;
uniform sampler2D uPingPongTexture;
uniform vec2 uResolution;

uniform float uAmount;
uniform float uRadius;
uniform float uScale;
uniform float uTime;
uniform float uChromAbAmount;
uniform float uBackgroundAlpha;
uniform vec3 uBackground;
uniform int uEffectType;

const float PI = 3.1415926;
const float ITERATIONS = 24.0;
${GAUSSIAN_WEIGHTS_24}

out vec4 fragColor;

${SIMPLEX_2S_NOISE}

mat2 rot(float a) {
    float c = cos(a);
    float s = sin(a);
    return mat2(c, -s, s, c);
}

float luma(vec3 color) {
    return dot(color, vec3(0.299, 0.587, 0.114));
}

vec2 random2(vec2 p) {
    return fract(sin(vec2(dot(p, vec2(127.1, 311.7)), dot(p, vec2(269.5, 183.3)))) * 43758.5453);
}

float random(vec2 seed) {
    return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

vec3 rgb2hsv(vec3 c) {
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

vec3 chromatic_aberration(vec3 color, vec2 uv, vec2 offset) {
    vec4 left = texture(uTexture, uv - offset);
    vec4 right = texture(uTexture, uv + offset);

    color.r = left.r;
    color.b = right.b;

    return color;
}

vec3 pal(float t, vec3 a, vec3 b, vec3 c, vec3 d) {
    return a + b * cos(6.28318 * (c * t + d));
}

vec2 pixelate(vec2 uv) {
    float aspectRatio = uResolution.x / uResolution.y;
    float scale = uScale / 2.0;
    vec2 modulate = mod(vec2(uv.x * aspectRatio, uv.y) - 0.5, (scale + 0.01) / 12.0);
    return vec2(
        uv.x - modulate.x / aspectRatio + (0.08333 * scale) / 2.0,
        uv.y - modulate.y + (0.08333 * scale) / 2.0
    );
}

vec2 angleToDir(float angle) {
    float rad = angle * 2.0 * PI;
    return vec2(cos(rad), sin(rad));
}

vec4 blurTrail(vec2 uv, vec2 mouseDir) {
    vec2 distorted = mouseDir * 0.4;
    float total_weight = 0.0;
    vec4 color = vec4(0);

    for (int i = 0; i <= 24; i++) {
        float scale = 0.0001 + uScale * 0.5;
        float weight = getGaussianWeight(i);
        vec2 offset = distorted * (1.5 + scale) * mix(1.0, float(i) / ITERATIONS, scale);
        vec4 samp = texture(uTexture, uv - offset);
        samp.rgb = chromatic_aberration(samp.rgb, uv - offset, offset * uChromAbAmount * 0.12);
        color += weight * samp;
        total_weight += weight;
    }
    return color / total_weight;
}

vec4 noiseTrail(vec2 uv, vec2 mouseDir, float strength, float aspectRatio) {
    vec4 color = vec4(0);
    vec2 noise = bccNoiseDerivatives_XYBeforeZ(vec3((uv * vec2(aspectRatio, 1) - mouseDir / 8.0 - 0.5) * 250.0 * uScale, strength)).xy * strength * 0.25;
    vec2 distorted = (mouseDir + noise) * 0.4;
    color = texture(uTexture, uv - distorted);
    color.rgb = chromatic_aberration(color.rgb, uv - distorted, distorted * uChromAbAmount * 0.12);
    return color;
}

vec4 pixelTrail(vec2 uv, vec2 mouseDir, float strength) {
    vec4 color = vec4(0);
    vec2 distorted = mouseDir * 0.4;
    vec2 pixelated = uv - distorted;
    color = texture(uTexture, pixelated);
    color.rgb = chromatic_aberration(color.rgb, pixelated, distorted * uChromAbAmount * 0.12);
    return color;
}

vec4 revealTrail(vec2 uv, vec2 mouseDir, float strength) {
    vec4 color = vec4(0);
    float zMix = strength;
    vec2 distorted = mouseDir * 0.4 * uScale;
    color = texture(uTexture, uv - distorted);
    color.rgb = chromatic_aberration(color.rgb, uv - distorted, distorted * uChromAbAmount * 0.12);
    color = mix(vec4(uBackground, uBackgroundAlpha), color, zMix);
    return color;
}

vec4 inverTrail(vec2 uv, vec2 mouseDir, float strength) {
    vec4 color = vec4(0);
    vec2 distorted = mouseDir * 0.4 * uScale;
    float sMix = step(0.3, strength);
    color = texture(uTexture, uv - distorted);
    color.rgb = chromatic_aberration(color.rgb, uv - distorted, distorted * uChromAbAmount * 0.12);
    color.rgb = mix(color.rgb, 1.0 - color.rgb, sMix);
    return color;
}

vec4 defaultTrail(vec2 uv, vec2 mouseDir) {
    vec4 color = vec4(0);
    vec2 distorted = mouseDir * 0.4;
    color = texture(uTexture, uv - distorted);
    color.rgb = chromatic_aberration(color.rgb, uv - distorted, distorted * uChromAbAmount * 0.12);
    return color;
}

vec4 shatterTrail(vec2 uv, vec2 mouseDir, float strength, float aspectRatio) {
    vec2 st = uv * vec2(aspectRatio, 1) * 80.0 * uScale;
    vec2 i_st = floor(st);
    vec2 f_st = fract(st);

    float m_dist = 15.0;
    vec2 m_point;
    vec2 d;

    for (int j = -1; j <= 1; j++) {
        for (int i = -1; i <= 1; i++) {
            vec2 neighbor = vec2(float(i), float(j));
            vec2 point = random2(i_st + neighbor);

            vec2 diff = neighbor + point - f_st;
            float dist = length(diff);

            if (dist < m_dist) {
                m_dist = dist;
                m_point = point;
                d = diff;
            }
        }
    }

    vec2 distorted = mouseDir * 0.4;
    vec2 offset = (m_point * 0.2 * 1.0 * 2.0) - (1.0 * 0.2) - distorted;
    return texture(uTexture, uv + offset * strength);
}

vec4 getTrailColor(vec2 uv, vec2 mouseDir, float strength) {
    vec4 color = vec4(0);
    float aspectRatio = uResolution.x / uResolution.y;

    switch (uEffectType) {
        case 0: return defaultTrail(uv, mouseDir); break;
        case 1: return blurTrail(uv, mouseDir); break;
        case 2: return noiseTrail(uv, mouseDir, strength, aspectRatio); break;
        case 3: return pixelTrail(uv, mouseDir, strength); break;
        case 4: return revealTrail(uv, mouseDir, strength); break;
        case 5: return inverTrail(uv, mouseDir, strength); break;
        case 6: return shatterTrail(uv, mouseDir, strength, aspectRatio); break;
        default: return defaultTrail(uv, mouseDir); break;
    }
}

void main() {
    vec2 uv = vTextureCoord;
    vec2 pingpongUv = uv;

    // #ifelseopen
    if (uEffectType == 3) {
        pingpongUv = pixelate(pingpongUv);
    }
    // #ifelseclose

    vec3 mouseRgb = texture(uPingPongTexture, pingpongUv).rgb;
    vec3 mouseTrail = rgb2hsv(mouseRgb);

    float angle = mouseTrail.x;
    float strength = mouseTrail.z * (uAmount * 2.0);
    vec2 direction = angleToDir(angle);
    vec2 mouseDir = direction * strength;

    vec4 color = getTrailColor(uv, mouseDir, strength);

    fragColor = color;
}

`;

const mouseParams = {
  fragmentShader: mouseFragmentShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: 'half-float',
    premultiplyAlpha: true,
  },
  uniforms: {
    resolution: {
      name: 'uResolution',
      type: '2f',
      value: new Vec2(1080),
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.5,
    },
    radius: {
      name: 'uRadius',
      type: '1f',
      value: 0.5,
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.5,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    chromAbAmout: {
      name: 'uChromAbAmount',
      type: '1f',
      value: 0.25,
    },
    hardness: {
      name: 'uHardness',
      type: '1f',
      value: 0,
    },
    effectType: {
      name: 'uEffectType',
      type: '1i',
      value: 0,
    },
    background: {
      name: 'uBackground',
      type: '3f',
      value: new Vec3(0),
      alpha: 'backgroundAlpha',
    },
    backgroundAlpha: {
      name: 'uBackgroundAlpha',
      type: '1f',
      value: 1,
    },
  },
};

export const MOUSE = {
  id: 'mouse',
  label: 'Mouse',
  params: mouseParams,
  aspectRatio: 1,
  animation: {
    active: true,
    speed: 1,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    radius: {
      label: 'Radius',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amount: {
      label: 'Strength',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    hardness: {
      label: 'Hardness',
      value: 0,
      min: 0,
      max: 0.8,
      step: 0.01,
      output: 'percent',
      tooltip: "Controls the softness of the path's edges",
    },
    decay: {
      label: 'Tail',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'This controls the length of the tail, or the speed of the decay',
    },
    liquidity: {
      label: 'Fluidity',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Adds fluid motion to the trail',
    },
    dissipate: {
      label: 'Dissipation',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: "The trail's path expands outwards as it decays",
    },
    chromAbAmout: {
      label: 'Chromatic abb.',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    // mouseMomentum: {
    //   label: 'Momentum',
    //   value: 0,
    //   min: 0,
    //   max: 1,
    //   step: 0.01,
    //   output: 'percent',
    //   tooltip: 'The amount of drag or delay of the track mouse effect'
    // },
    effectType: {
      label: 'Type',
      header: 'Effect',
      value: 0,
      options: {
        0: 'None',
        1: 'Motion blur',
        2: 'Noise displace',
        3: 'Pixelate',
        4: 'Reveal background',
        5: 'Invert color',
        6: 'Shatter',
      },
      responsiveDisabled: true,
    },
    scale: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls a different aspect of the effect depending on which one is selected.',
    },
    background: {
      label: 'Background',
      value: new Vec3(0),
      output: 'color',
      tooltip: 'Applies only to the reveal effect',
      alpha: 'backgroundAlpha',
      responsiveDisabled: true,
    },
    backgroundAlpha: {
      label: 'Background Alpha',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      hidden: true,
    },
  },
};
