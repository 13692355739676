import { computeFragColor, vertexShader, FLOATING_POINT, UNIVERSAL_UNIFORMS, universalUniformParams } from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const stretchShader = `#version 300 es
  precision mediump float;
  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uAmount;
  uniform vec2 uPos;
  uniform float uAngle;
  ${UNIVERSAL_UNIFORMS}

  out vec4 fragColor;

  float pointLineSide(vec2 A, vec2 B, vec2 P) {
    vec2 d = B - A;
    vec2 AP = P - A;
    return d.y * AP.x - d.x * AP.y;
  }

  void main() {
    vec2 uv = vTextureCoord;
    float degrees = uAngle * 360.;
    float rot1 = degrees * 3.1415926 / 180.;
    float rot2 = ((uAngle - 0.25) * 360.) * 3.1415926 / 180.;
    float amt = smoothstep(0.15, 1., uAmount) * 10.;
    
    vec2 diff = vec2(
      (uv.x - uPos.x),
      (uv.y - uPos.y)
    );

    vec2 scaled = vec2(1) + diff;
    
    vec2 pointA = uPos + vec2(0.1 * sin(rot1), 0.1 * cos(rot1));
    vec2 pointB = uPos - vec2(0.1 * sin(rot1), 0.1 * cos(rot1));

    vec2 pointC = uv + vec2(0.1 * sin(rot2), 0.1 * cos(rot2));
    vec2 pointD = uv - vec2(0.1 * sin(rot2), 0.1 * cos(rot2));

    float m1 = (pointB.y - pointA.y)/(pointB.x - pointA.x);
    float c1 = pointA.y - m1*pointA.x;
    float m2 = (pointD.y - pointC.y)/(pointD.x - pointC.x);
    float c2 = pointC.y - m2*pointC.x;

    vec2 line1 = vec2((uv.y-c1)/m1, uv.x*m1+c1);
    vec2 line2 = vec2((uPos.y-c2)/m2, uPos.x*m2+c2);
    
    float intersectX = (c1 - c2) / (m2 - m1);
    float intersectY = intersectX * m1 + c1;

    
    vec4 color = vec4(0);
    
    float diffX = abs(uv.x - intersectX);
    float diffY = abs(uv.y - intersectY);

    vec2 st = uv;
    
    float degreesModulo = mod(degrees, 360.0);

    if(degreesModulo >= 0.0 && degreesModulo < 90.0) {
      if(uv.x < intersectX || uv.y > intersectY) {
        st.x = intersectX - diffX/((diffX + 1.0) + diffX * amt * 4.0);
        st.y = intersectY + diffY/((diffY + 1.0) + diffY * amt * 4.0);
      }
    }
    else if(degreesModulo >= 90.0 && degreesModulo < 180.0) {
      if(uv.x > intersectX || uv.y > intersectY) {
        st.x = intersectX + diffX/((diffX + 1.0) + diffX * amt * 4.0);
        st.y = intersectY + diffY/((diffY + 1.0) + diffY * amt * 4.0);
      }
    }
    else if(degreesModulo >= 180.0 && degreesModulo < 270.0) {
      if(uv.x > intersectX || uv.y < intersectY) {
        st.x = intersectX + diffX/((diffX + 1.0) + diffX * amt * 4.0);
        st.y = intersectY - diffY/((diffY + 1.0) + diffY * amt * 4.0);
      }
    }
    else if(degreesModulo >= 270.0 && degreesModulo < 360.0) {
      if(uv.x < intersectX || uv.y < intersectY) {
        st.x = intersectX - diffX/((diffX + 1.0) + diffX * amt * 4.0);
        st.y = intersectY - diffY/((diffY + 1.0) + diffY * amt * 4.0);
      }
    }

    color = texture(uTexture, st);


    ${computeFragColor('color')}
  }
`;

export const stretchParams = {
  fragmentShader: stretchShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: "uAmount",
      type: "1f",
      value: 0.5,
    },
    angle: {
      name: "uAngle",
      type: "1f",
      value: 0,
    },
    pos: {
      name: "uPos",
      type: "2f",
      value: new Vec2(0.5)
    },
    ...universalUniformParams
  }
}

export const STRETCH = {
  id: 'scan',
  label: 'Scan',
  params: stretchParams,
  aspectRatio: 1,
  properties: {
    amount: {
      label: 'Amount',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    angle: {
      label: 'Angle',
      value: 0.0027,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees'
    }
  }
}