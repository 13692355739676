import { Vec3 } from 'curtainsjs';
import {
  computeFragColor,
  GAUSSIAN_WEIGHTS_24,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';

const bloomShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform sampler2D uTexture;
uniform sampler2D uBgTexture;
uniform float uAmount;
uniform float uIntensity;
uniform float uExposure;
uniform float uDirection;
uniform int uVertical;
uniform int uFinal;
uniform vec3 uTint;
uniform float uTime;
${UNIVERSAL_UNIFORMS}

out vec4 fragColor;

vec3 screen(vec3 src, vec3 dst) {
  return (src + dst) - (src * dst);
}

float random(vec2 seed) {
  return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

float luma(vec4 color) {
  return dot(color.rgb, vec3(0.299, 0.587, 0.114));
}

${GAUSSIAN_WEIGHTS_24}

vec4 blur(vec2 uv) {
  vec4 color = vec4(0.0);
  float total_weight = 0.0;
  float aspectRatio = uResolution.x/uResolution.y;

  vec2 dir = (uVertical % 2 == 1) ? vec2(0, 1) : vec2(1, 0);
  dir *= vec2(uDirection, 1. - uDirection);
  dir.x /= aspectRatio;
  
  vec4 center = texture(uTexture, uv);
  float center_weight = getGaussianWeight(0);
  color += center * center_weight;
  total_weight += center_weight;

  float radius = uVertical > 2 ? 10. : 5.;
  radius *= (uAmount + 0.1);
  
  for (int i = 1; i <= 11; i++) {
    float weight = getGaussianWeight(i);
    float offset = mix(0.005, 0.015, radius) * float(i)/11.;
      
      vec4 sample1 = texture(uTexture, uv + offset * dir);
      vec4 sample2 = texture(uTexture, uv - offset * dir);
      
      color += (sample1 + sample2) * weight;
      total_weight += 2.0 * weight;
  }

  return color / total_weight;
}

vec4 thresholdPass(vec4 color) {
  float gamma = 2.2;
  color.rgb = pow(color.rgb, vec3(1.0/gamma));
  color.rgb = 1.2 * (color.rgb - 0.5) + 0.5;
  vec3 bloom = color.rgb * smoothstep(uExposure - 0.1, uExposure, luma(color));
  return vec4(bloom, color.a);
}

vec4 finalPass(vec4 color) {
  vec3 bloom = (color.rgb * uTint * uIntensity*2.);
  color.rgb = screen(texture(uBgTexture, vTextureCoord).rgb, bloom) + bloom;
  return color;
}

vec4 getColor(vec4 color) {
  switch(uVertical) {
    case 0: return thresholdPass(color); break;
    case 1: return blur(vTextureCoord); break;
    case 2: return blur(vTextureCoord); break;
    case 3: return blur(vTextureCoord); break;
    case 4: return blur(vTextureCoord); break;
    case 5: return finalPass(color); break;
  }
}

void main() {	
  vec2 uv = vTextureCoord;
  vec4 color = texture(uTexture, uv);

  // #ifelseopen
  if(uIntensity == 0.00) {
    fragColor = color;
    return;
  }
  // #ifelseclose
  
  color = getColor(color);

  // #ifelseopen
  if(uVertical == 3) {
    float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;
    color.rgb += dither;
  }
  // #ifelseclose
  
  fragColor = color;
}
`;

const bloomParams = {
  fragmentShader: bloomShader,
  vertexShader: vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 0.25,
    },
    vertical: {
      name: 'uVertical',
      type: '1i',
      value: 0,
    },
    direction: {
      name: 'uDirection',
      type: '1f',
      value: 0.5,
    },
    intensity: {
      name: 'uIntensity',
      type: '1f',
      value: 0.5,
    },
    exposure: {
      name: 'uExposure',
      type: '1f',
      value: 0.5,
    },
    final: {
      name: 'uFinal',
      type: '1i',
      value: 0,
    },
    tint: {
      name: 'uTint',
      type: '3f',
      value: new Vec3(1),
    },
    ...universalUniformParams,
  },
};

export const BLOOM = {
  id: 'bloom',
  label: 'Bloom',
  params: bloomParams,
  downSample: true,
  passes: [
    {
      prop: 'vertical',
      value: 1,
      downSample: true,
    },
    {
      prop: 'vertical',
      value: 2,
      downSample: true,
    },
    {
      prop: 'vertical',
      value: 3,
      downSample: true,
    },
    {
      prop: 'vertical',
      value: 4,
      downSample: true,
    },
    {
      prop: 'vertical',
      value: 5,
      includeBg: true,
    },
  ],
  aspectRatio: 1,
  properties: {
    amount: {
      label: 'Radius',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the size of the bloom',
    },
    intensity: {
      label: 'Intensity',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Controls the amount of bloom',
    },
    exposure: {
      label: 'Threshold',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Sets the brightness threshold at which to bloom',
    },
    tint: {
      label: 'Tint',
      value: new Vec3(1),
      output: 'color',
    },
    direction: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Skews the bloom on the X or Y axis',
    },
  },
};
