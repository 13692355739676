import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
} from '../ShaderHelpers.js';

const mirrorShader = `#version 300 es
  precision mediump float;
  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uDirection;
  ${UNIVERSAL_UNIFORMS}

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
    vec2 vertCoords = uv;
    vec2 horizCoords = uv;
    vec2 bothCoords = uv;

    vertCoords.x = vertCoords.x > 0.5 ? 0.5 - (vertCoords.x - 0.5) : vertCoords.x;
    vertCoords.y = vertCoords.x > 0.5 ? 0.5 - (vertCoords.y - 0.5) : vertCoords.y;

    horizCoords.y = horizCoords.y > 0.5 ? 0.5 - (horizCoords.y - 0.5) : horizCoords.y;
    horizCoords.x = horizCoords.y > 0.5 ? 0.5 - (horizCoords.x - 0.5) : horizCoords.x;

    bothCoords.y = horizCoords.y > 0.5 ? (1. - horizCoords.y) : 0.5 - (horizCoords.y - 0.5);
    bothCoords.x = vertCoords.y > 0.5 ? (1. - vertCoords.x) : 0.5 - (vertCoords.x - 0.5);

    uv = mix(vertCoords, bothCoords, uDirection);
    vec4 color = texture(uTexture, uv);
    ${computeFragColor('color')}
  }
`;

const mirrorParams = {
  fragmentShader: mirrorShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    direction: {
      name: 'uDirection',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const MIRROR = {
  id: 'mirror',
  label: 'Mirror',
  params: mirrorParams,
  aspectRatio: 1,
  properties: {
    direction: {
      label: 'Direction',
      value: 1,
      options: {
        0: 'Half',
        1: 'Quad',
      },
    },
  },
};
