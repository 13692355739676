import { UserStore } from '../stores/UserStore';
import { setUserId, track, identify } from '@logsnag/vue';

export function logsnagTrack(options) {
  if (UserStore.email !== 'g.hastings3@gmail.com') {
    track(options);
  }
}

export function logsnagIdentify(options) {
  if (UserStore.email !== 'g.hastings3@gmail.com') {
    identify(options);
  }
}

export function logsnagSetUserId(id) {
  setUserId(id);
}
