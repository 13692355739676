import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const liquifyShader = `#version 300 es
precision mediump float;

in vec3 vVertexPosition;
in vec2 vTextureCoord;

uniform float uTime;
uniform float uFrequency;
uniform float uAmplitude;
uniform float uMix;
uniform vec2 uPos;
uniform float uAngle;
uniform float uChromatic;
uniform sampler2D uTexture;
${UNIVERSAL_UNIFORMS}

const float PI = 3.14159265;

mat2 rot(float a) {
  return mat2(cos(a), -sin(a), sin(a), cos(a));
}

vec2 liquify(vec2 st) {
  float aspectRatio = uResolution.x / uResolution.y;
  vec2 pos = uPos + mix(vec2(0), (uMousePos - 0.5), uTrackMouse);
  
  st -= pos;
  st.x *= aspectRatio;
  st = st * rot(uAngle * 2. * PI);

  float amplitude = uAmplitude * mix(0.2, 0.2/(uFrequency + 0.05), 0.25);
  for (float i = 1.0; i <= 5.0; i++) {
    st = st * rot(i / 5. * PI * 2.);
    float cosFactor = cos(i * (5.0 * (uFrequency + 0.1)) * st.y + uTime * 0.025); 
    st.x += amplitude * cosFactor;
    
    float sinFactor = sin(i * (5.0 * (uFrequency + 0.1)) * st.x + uTime * 0.025);
    st.y += amplitude * sinFactor;
  }

  st = st * rot(uAngle * -1. * 2. * PI);
  st.x /= aspectRatio; // Revert the aspect ratio correction
  st += pos;

  return st;
}

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;
  vec2 liquifiedUV = liquify(uv);
  vec2 normalizedUv = normalize(liquifiedUV - uv);
  float distanceUv = length(liquifiedUV - uv);
  float chromAbb = uChromatic * 0.5;

  vec2 offsetR = liquifiedUV + chromAbb * normalizedUv * distanceUv;
  vec2 offsetG = liquifiedUV;
  vec2 offsetB = liquifiedUV - chromAbb * normalizedUv * distanceUv;

  vec4 colorR = texture(uTexture, mix(uv, offsetR, uMix));
  vec4 colorG = texture(uTexture, mix(uv, offsetG, uMix));
  vec4 colorB = texture(uTexture, mix(uv, offsetB, uMix));

  vec4 color = vec4(colorR.r, colorG.g, colorB.b, colorR.a * colorG.a * colorB.a);

  ${computeFragColor('color')}
}
`;

const liquifyParams = {
  fragmentShader: liquifyShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    frequency: {
      name: 'uFrequency',
      type: '1f',
      value: 0.5,
    },
    variation: {
      name: 'uAmplitude',
      type: '1f',
      value: 0.5,
    },
    blend: {
      name: 'uMix',
      type: '1f',
      value: 0.5,
    },
    chromatic: {
      name: 'uChromatic',
      type: '1f',
      value: 0.25,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    ...universalUniformParams,
  },
};

export const LIQUIFY = {
  id: 'liquify',
  label: 'Liquify',
  params: liquifyParams,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    frequency: {
      label: 'Frequency',
      value: 0.5,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    variation: {
      label: 'Amplitude',
      value: 0.5,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    chromatic: {
      label: 'Chrom. Abb.',
      value: 0.25,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
      tooltip: 'Chromatic abberation',
    },
    blend: {
      label: 'Mix',
      value: 0.5,
      min: 0.01,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
