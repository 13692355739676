<script>
import {
  getAuth,
  sendPasswordResetEmail,
  GoogleAuthProvider
} from "firebase/auth";
import Button from "../components/Button.vue";
import Input from "../components/Input.vue";
import { app, db } from "../firestore/init.js";
import * as UnicornStudio from '../lib/unicornStudio';

const auth = getAuth(app);

export default {
  components: {
    Button,
    Input,
  },
  data() {
    return {
      email: '',
      showGoogleSignIn: false,
      resettingPassword: false,
      passwordReset: false,
      validation: {
        invalidEmail: false,
        error: "",
      },
    };
  },
  mounted() {
    this.showGoogleSignIn = localStorage.getItem('signedInWithGoogle');
    
    UnicornStudio.init();
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      this.resetPassword();
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    resetPassword() {
      this.resettingPassword = true;
      sendPasswordResetEmail(auth, this.email).then(() => {
        console.log("Password reset email sent.");
        this.resettingPassword = false;
        this.passwordReset = true;
      }).catch((error) => {
        this.resettingPassword = false;
        this.validation.error = "Error sending password reset email: ", error;
        console.error("Error sending password reset email: ", error);
      });
    },

  },
};
</script>

<template>
  <div class="login-container">
    <div v-if="resettingPassword" class="loading-bar"></div>
    <div class="form-container">
      <div class="login-form">
        <a href="" class="logo">
          <svg width="50px" height="50px" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M298.219 257.12 300 43.718C300 14.46 278.01.34 258.018.34c-23.59-1.243-35.984 15.615-41.982 30.971l-84.592 242.82L176.5 48C182.5 19 164 .05 134.79.05c-32.61-.206-71.413.29-93.808.29 0 0-15.493.66-27.988 12.16C-.162 24.61.106 40.387.106 40.387-.357 63.15.847 156.9.847 156.9.847 257.12 58 289.298 99.94 295.571c24.007 3.59 41.128-6.88 51.237-21.44l137.828-202.99c-24.157 58.337-69.97 165.148-72.251 170.58C203.542 275.727 231.748 300 256.237 300c30.769 0 42.149-28.587 41.982-42.88Z" fill="#fff"/></svg>
        </a>
        <h1 class="h2">Reset password</h1>
          <form class="form" @submit="submitForm">
            <div class="field">
              <Input 
                class="field-input"
                autocomplete="username"
                type="email"
                placeholder="Email"
                v-model="email"
              />
            </div>
            <p v-if="passwordReset" class="success-text">Password reset email sent. Please check your email. <a href="/login">Back to login.</a></p>
            <p v-else-if="validation.error" class="validation-error">
              {{ validation.error }}
            </p>
            <Button v-if="!passwordReset" :loading="resettingPassword" class="primary large mt-4 w-100" type="submit">
              Reset password
            </Button>
          </form>
        
      </div>
    </div>
    <div class="video-container" id="unicorn" data-us-project="kRb0Sk7NF1hul6B24ukK?update=45"></div>
  </div>
</template>

<style scoped lang="scss">


.login-form {
  border-radius: 0.5rem;
  width: 26rem;

  .h2 {
    text-align: center;
    margin-bottom: 1em;
  }
}

.login-container {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  color: var(--font-color);
  background-color: var(--bg-color);

  @media only screen and (max-width: 58rem) {
    flex-direction: column;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
}

.form-container {
  width: 50%;
  border-right: 1px solid var(--border-color);

  @media only screen and (max-width: 58rem) {
    width: 100%;
    margin-top: 5rem;
  }
}

.video-container {
  width: 50%;

  @media only screen and (max-width: 58rem) {
    width: 100%;
    height: 50vh;
    margin-top: 5rem;
  }
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--accent-color);
}

.logo {
  display: block;
  width: 5rem;
  margin: 0 auto 3rem;
  margin-bottom: 3rem;
  color: inherit;

  img {
    width: 100%;
  }
}

video,
iframe {
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
}

iframe {
  outline: none;
  border: 0;
}

.signup-link {
  display: block;
  text-align: center;
  margin-top: 1.5rem;
}

</style>