import { computeFragColor, vertexShader, FLOATING_POINT, UNIVERSAL_UNIFORMS, universalUniformParams } from '../ShaderHelpers.js';

const colorShader = `#version 300 es
  precision mediump float;
  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uTime;
  uniform float uAmount;
  uniform float uFrequency;
  uniform float uAngle;
  ${UNIVERSAL_UNIFORMS}

  float luma(vec3 color) {
    return dot(color, vec3(0.299, 0.587, 0.114));
  }

  vec3 hue(float h, float angle) {
      const float PI = 3.14159265358979323846;
      return vec3(0.5) + 0.5 * cos(h + 2.0 * PI * angle + vec3(0, 2, 4));
  }

  vec3 getNormal(vec2 uv, float scale) {
      const vec2 offset1 = vec2(0.0, 0.001);
      const vec2 offset2 = vec2(0.001, 0.0);

      float luma1 = luma(texture(uTexture, uv + offset1.xy).rgb);
      float luma2 = luma(texture(uTexture, uv + offset2.yx).rgb);
      float luma3 = luma(texture(uTexture, uv - offset1.xy).rgb);
      float luma4 = luma(texture(uTexture, uv - offset2.yx).rgb);
      vec3 normal = vec3(luma1 - luma3, luma2 - luma4, scale);
      
      return normalize(normal);
  }

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
  
    vec4 color = texture(uTexture, uv);
    vec3 normal = getNormal(uv, uAmount + 0.0001);
    
    // Create light direction 
    vec3 lightDir = normalize(vec3(cos(uTime * 0.1), sin(uTime * 0.05), 0.8));

    // Dot product gives us cosine of angle between two vectors
    float ndl = dot(normal, lightDir);
    
    // Convert the original color to hue and mix with the original color
    vec3 holoColor = hue(color.r * uFrequency * 10., uAngle + uTime * 0.05);  // adjust time multiplication factor for desired speed
    color.rgb = mix(color.rgb, holoColor, ndl * uAmount);
    ${computeFragColor('color')}
  }
`;

const colorParams = {
  fragmentShader: colorShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    amount: {
      name: "uAmount",
      type: "1f",
      value: 0.5,
    },
    frequency: {
      name: "uFrequency",
      type: "1f",
      value: 0.5,
    },
    angle: {
      name: "uAngle",
      type: "1f",
      value: 0,
    },
    time: {
      name: "uTime",
      type: "1f",
      value: 0,
    },
    ...universalUniformParams
  }
}

export const HOLOGRAM = {
  id: 'hologram',
  label: 'Hologram',
  params: colorParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1
  },
  properties: {
    amount: {
      label: 'Mix',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    frequency: {
      label: 'Frequency',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees'
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
  }
}