import { BLEND_MODES } from './Constants';
import { BLEND } from '../scripts/ShaderHelpers';
import { hexToRgb } from '../scripts/ColorHelpers';

// Pre-compiled regular expressions
const singleLineCommentRegex = /\/\/.*$/gm;
const multiLineCommentRegex = /\/\*[\s\S]*?\*\//g;
const leadingTrailingSpaceRegex = /^\s+|\s+$/gm;
const multipleSpaceRegex = /[ \t]{2,}/g;
const emptyLineRegex = /^\s*[\r\n]/gm;
const newLineRegex = /\n/g;
const versionRegex = /(#version 300 es)\s/;

function minifyShader(shader) {
  return shader
    .replace(singleLineCommentRegex, '')
    .replace(multiLineCommentRegex, '')
    .replace(leadingTrailingSpaceRegex, '')
    .replace(multipleSpaceRegex, ' ')
    .replace(emptyLineRegex, '')
    .replace(newLineRegex, ' ')
    .replace(versionRegex, '$1\n');
}

function removeUnusedUniforms(shaderSource) {
  // Regex to find all uniform declarations
  const uniformRegex = /uniform\s+(int|float|bool|vec[2-4]|mat[2-4]|sampler2D)\s+(\w+);/g;
  let match;
  const uniforms = [];

  // Collect all uniform declarations
  while ((match = uniformRegex.exec(shaderSource)) !== null) {
    uniforms.push({ type: match[1], name: match[2] });
  }

  // Check usage of each uniform and remove unused ones
  uniforms.forEach(uniform => {
    const uniformUsageRegex = new RegExp(`\\b${uniform.name}\\b`, 'g');
    if (!uniformUsageRegex.test(shaderSource.replace(uniformRegex, ''))) {
      // Remove the uniform declaration if not used
      const declarationRegex = new RegExp(`uniform\\s+${uniform.type}\\s+${uniform.name};\\n?`, 'g');
      shaderSource = shaderSource.replace(declarationRegex, '');
    }
  });

  return shaderSource;
}

function evaluateIfElse(shaderCode) {
  const ifElseRegex =
    /\/\/ #ifelseopen\s*if\s*\((.*?)\)\s*{([\s\S]*?)}\s*(?:else\s*{([\s\S]*?)})?\s*\/\/ #ifelseclose/g;

  return shaderCode.replace(ifElseRegex, (match, condition, ifCode, elseCode) => {
    // Remove whitespace and evaluate the condition
    const cleanCondition = condition.replace(/\s+/g, '');
    let result;

    try {
      // Use Function to safely evaluate the condition
      result = Function(`return ${cleanCondition}`)();
    } catch (error) {
      console.warn(`Error evaluating condition: ${cleanCondition}`);
      return match; // Return original code if evaluation fails
    }

    if (result) {
      // Condition is true, keep the if code
      return ifCode.trim();
    } else if (elseCode) {
      // Condition is false and else exists, keep the else code
      return elseCode.trim();
    } else {
      // Condition is false and no else, remove the entire block
      return '';
    }
  });
}

function extractFunctionBodies(shaderSource) {
  const functionStartRegex =
    /(?:float|int|void|vec[234]|mat[234]|in|out|StructFunc|sampler[2D3DCube])\s+([a-zA-Z_]\w*)\s*\(([^)]*)\)\s*\{/;
  let lines = shaderSource.split('\n');
  let functions = {};
  let braceDepth = 0;
  let currentFunctionName = null;
  let currentFunctionStart = null;
  let currentFunctionParams = null;
  let functionBodyLines = [];

  lines.forEach((line, index) => {
    const functionStartMatch = line.match(functionStartRegex);
    const isOpenBrace = line.includes('{');
    const isCloseBrace = line.includes('}');

    if (functionStartMatch && braceDepth === 0) {
      // Start of a new function
      currentFunctionName = functionStartMatch[1];
      currentFunctionParams = functionStartMatch[2];
      currentFunctionStart = index;
      functionBodyLines = []; // Reset for new function
    }

    if (isOpenBrace) braceDepth++;
    if (isCloseBrace) braceDepth--;

    if (currentFunctionName !== null) {
      // Accumulate lines for current function's body
      functionBodyLines.push(line);
    }

    if (braceDepth === 0 && currentFunctionName !== null) {
      // End of the current function
      functions[currentFunctionName] = {
        params: currentFunctionParams,
        body: functionBodyLines.join('\n'),
        used: false,
      };
      currentFunctionName = null; // Reset for next function
    }
  });

  return functions;
}

function markFunctionAsUsed(functionName, functions) {
  if (functions.hasOwnProperty(functionName) && !functions[functionName].used) {
    functions[functionName].used = true;

    const body = functions[functionName].body;
    const calledFunctions = findFunctionCalls(body);
    calledFunctions.forEach(calledFunctionName => {
      if (functions.hasOwnProperty(calledFunctionName)) {
        markFunctionAsUsed(calledFunctionName, functions);
      }
    });
  }
}

function findFunctionCalls(functionBody) {
  const functionCallRegex = /\b([a-zA-Z_]\w*)\s*\(/g;
  let match;
  const functionCalls = new Set();

  while ((match = functionCallRegex.exec(functionBody)) !== null) {
    functionCalls.add(match[1]);
  }

  return functionCalls;
}

function removeUnusedFunctions(shaderSource) {
  const functions = extractFunctionBodies(shaderSource);
  markFunctionAsUsed('main', functions);

  // Convert the shader source into an array of lines for processing.
  const lines = shaderSource.split('\n');
  let resultShaderLines = [];
  let braceDepth = 0;
  let skipFunction = false;

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const functionStartMatch = line.match(
      /(?:float|int|void|vec[234]|mat[234]|in|out|StructFunc|sampler[2D3DCube])\s+([a-zA-Z_]\w*)\s*\(([^)]*)\)\s*\{/
    );

    // Check if the line is the start of any function.
    if (functionStartMatch && !skipFunction) {
      const functionName = functionStartMatch[1];
      // Determine if the function is marked as used.
      if (!functions[functionName].used) {
        skipFunction = true; // Mark to skip lines belonging to this unused function.
      }
    }

    // Track the depth of braces to handle nested structures correctly.
    if (line.includes('{')) braceDepth++;
    if (line.includes('}')) braceDepth--;

    // If not skipping or at global scope, include the line in the result.
    if (!skipFunction) {
      resultShaderLines.push(line);
    }

    // If the end of a function (braceDepth returns to 0) is reached, stop skipping (if we were).
    if (braceDepth === 0 && skipFunction) {
      skipFunction = false;
    }
  }

  // Rejoin the retained lines back into a single shader source string.
  return resultShaderLines.join('\n');
}

function optimizeSwitchStatementsInShader(shaderSource) {
  // Regex to find switch statements, capturing the condition value and the switch body
  const switchRegex = /switch\s*\(\s*(\d+)\s*\)\s*\{([\s\S]*?)\}/g;

  // Function to replace switch statements with the optimized content
  const replaceSwitchWithCaseBlock = (match, conditionValue, switchBody) => {
    // Parse the condition value as a number
    const parsedConditionValue = parseInt(conditionValue, 10);

    // Find the case block matching the condition value or the default case block
    const caseRegex = new RegExp(`\\bcase\\s+${parsedConditionValue}:([\\s\\S]*?)\\bbreak;`, 'gs');
    const defaultCaseRegex = /default:\s*([\s\S]+?)(break;)?\s*}/gs;

    // Try to match the specific case block
    let specificCaseMatch = switchBody.match(caseRegex);
    let caseContent = specificCaseMatch && specificCaseMatch[0];

    // If no specific case is matched, try to match the default case
    if (!caseContent) {
      let defaultCaseMatch = switchBody.match(defaultCaseRegex);
      caseContent = defaultCaseMatch && defaultCaseMatch[0];
    }

    // Extract and return the actionable part of the matched case or default block
    if (caseContent) {
      // Extract the return statement or other action from the case content
      const actionRegex = /return\s+([^;]+);/;
      const actionMatch = caseContent.match(actionRegex);
      if (actionMatch) {
        return `return ${actionMatch[1]};`;
      } else {
        // If no return statement is found, return the caseContent
        return caseContent
          .replace(/case\s+\d+:/, '')
          .replace(/default:/, '')
          .trim()
          .replace(/break;/, '')
          .trim();
      }
    } else {
      console.warn(`No matching case for value ${parsedConditionValue} and no default case found.`);
      return match; // Return original switch statement if no match or default is found
    }
  };

  // Replace switch statements in the shader source
  shaderSource = shaderSource.replace(switchRegex, replaceSwitchWithCaseBlock);

  return shaderSource;
}

function replaceBlendFunctionInShader(shaderSource, blendMode) {
  // Extract the return statement for the specific blend mode
  const blendModeRegex = new RegExp(`if\\(blendMode == ${blendMode}\\) \\{\\s*(return[^;]+);`, 'm');
  const blendModeMatch = BLEND.match(blendModeRegex);

  if (!blendModeMatch) {
    console.warn(`Blend mode ${blendMode} not found in the given blend function.`);
    return shaderSource; // If the specific blend mode is not found, return the original shader source.
  }

  // Construct the replacement string
  const replacementStr = `vec3 blend (int blendMode, vec3 src, vec3 dst) {\n  ${blendModeMatch[1]};\n}`;

  // Replace the old blend function with the new one
  return shaderSource.replace(BLEND, replacementStr);
}

function replaceUniformsInShader(shaderSource, uniforms) {
  // Iterate over the uniforms to find and replace vec3 array uniforms
  Object.keys(uniforms).forEach(key => {
    if (uniforms[key] instanceof Float32Array && uniforms[key].length % 3 === 0) {
      // Construct the GLSL array literal from the Float32Array
      const arraySize = uniforms[key].length / 3;
      let glslArrayLiteral = `const vec3 ${key}[${arraySize}] = vec3[](\n`;

      for (let i = 0; i < uniforms[key].length; i += 3) {
        // Ensure values are defined and are numbers
        const x = typeof uniforms[key][i] === 'number' ? uniforms[key][i].toFixed(6) : '0.000000';
        const y = typeof uniforms[key][i + 1] === 'number' ? uniforms[key][i + 1].toFixed(6) : '0.000000';
        const z = typeof uniforms[key][i + 2] === 'number' ? uniforms[key][i + 2].toFixed(6) : '0.000000';
        glslArrayLiteral += `    vec3(${x}, ${y}, ${z}),\n`;
      }

      // Remove the last comma and newline, then close the array literal
      glslArrayLiteral = glslArrayLiteral.replace(/,\n$/, '\n);\n');

      // Find and replace the uniform declaration in the shader source
      const uniformArrayRegex = new RegExp(`uniform vec3 ${key}\\s*\\[\\d+\\];`, 'g');
      shaderSource = shaderSource.replace(uniformArrayRegex, glslArrayLiteral);
    } else if (uniforms[key] !== undefined) {
      // Handle other uniform types as before
      const uniformTypeRegex = new RegExp(`uniform (?:int|float|bool|vec[2-4]|mat[2-4]|sampler2D) ${key};\\n?`, 'g');
      const uniformValueRegex = new RegExp('\\b' + key + '\\b', 'g');

      shaderSource = shaderSource.replace(uniformTypeRegex, '').replace(uniformValueRegex, uniforms[key].toString());
    }
  });

  // Resolve ternary expressions of the form `1.0 == 1.0 ? A : B` or `0.0 == 1.0 ? A : B`,
  // and also handle cases like `1. == 0.0 ? A : B` or `0.0 == 1. ? A : B`
  let ternaryRegex = /(\()?(\d\.?\d*) == (\d\.?\d*) \? ([^:]+) : ([^;]+)(\))?;/g;
  shaderSource = shaderSource.replace(
    ternaryRegex,
    function (match, openParen, condition, comparison, trueExpr, falseExpr, closeParen) {
      let replacement = condition === comparison ? `${trueExpr}` : `${falseExpr}`;
      if (openParen && closeParen) {
        replacement = `(${replacement})`;
      }
      return `${replacement};`;
    }
  );

  if ('uBlendMode' in uniforms) {
    shaderSource = replaceBlendFunctionInShader(shaderSource, uniforms.uBlendMode);
  }

  return shaderSource;
}

function normalizeValue(val, prop, type) {
  //console.log(val)
  if (prop === 'blendMode') {
    val = Object.keys(BLEND_MODES).indexOf(val);
  }
  if (prop === 'gradientType') {
    if (!isNaN(val)) {
    } else {
      val = ['linear', 'radial', 'conic'].indexOf(val);
    }
  }
  if (prop === 'fill') {
    val = new Float32Array(val.map(n => hexToRgb(n).map(o => o / 255)).flat());
  }
  if (typeof val === 'boolean') {
    val = val ? 0 : 1;
  } else if (type === '3f') {
    val = `vec3(${val._x}, ${val._y}, ${val._z})`;
  } else if (type === '2f') {
    if (prop === 'pos') {
      val = `vec2(${val._x}, ${1 - val._y})`;
    } else {
      val = `vec2(${val._x}, ${val._y})`;
    }
  } else if (val !== undefined && type === '1f') {
    val = +val;
    val = val.toFixed(2);
  }
  return val;
}

function compileUniformValues(item, uniforms) {
  let values = {};
  const skipList = ['time', 'mousePos', 'resolution', 'previousMousePos', 'dpi', 'sampleBg'];
  if (item.states) {
    skipList.push(...item.states.appear.map(n => n.prop));
    skipList.push(...item.states.scroll.map(n => n.prop));
    skipList.push(...item.states.hover.map(n => n.prop));
  }
  if (item.breakpoints.some(n => n.name !== 'Desktop')) {
    item.breakpoints
      .filter(n => n.name !== 'Desktop')
      .forEach(bp => {
        let props = Object.keys(bp.props);
        props.forEach(prop => {
          if (!skipList.includes(prop)) {
            skipList.push(prop);
          }
        });
      });
  }
  for (let prop in uniforms) {
    if (!skipList.includes(prop)) {
      if (prop in item) {
        values[uniforms[prop].name] = normalizeValue(item[prop], prop, uniforms[prop].type);
      } else {
        values[uniforms[prop].name] = normalizeValue(uniforms[prop].value, prop, uniforms[prop].type);
      }
    }
  }
  return values;
}

function runComplexReplacements(fs, values) {
  let replacedUniforms = replaceUniformsInShader(fs, values);
  let replacedSwitch = optimizeSwitchStatementsInShader(replacedUniforms);
  let replacedIfElse = evaluateIfElse(replacedSwitch);
  let replacedDeadFunctions = removeUnusedFunctions(replacedIfElse);
  let replacedUnusedUniforms = removeUnusedUniforms(replacedDeadFunctions);
  return replacedUnusedUniforms;
}

function handleCustomShaders(item, data) {
  const fragmentShaders = [];
  const vertexShaders = [];
  item.customFragmentShaders.forEach((fs, index) => {
    let values = compileUniformValues(item, data.params.uniforms);
    let vs =
      index < item.customVertexShaders.length
        ? item.customVertexShaders[index]
        : item.customVertexShaders[0] || data.params.vertexShader;
    fragmentShaders.push(replaceUniformsInShader(fs, values));
    vertexShaders.push(replaceUniformsInShader(vs, values));
  });
  return {
    fragmentShaders,
    vertexShaders,
  };
}

export function optimizeShaders(item, data, pingpongParams) {
  let fragmentShaders = [];
  let vertexShaders = [];
  if (item.type === 'custom') {
    const shaders = handleCustomShaders(item, data);
    fragmentShaders = shaders.fragmentShaders;
    vertexShaders = shaders.vertexShaders;
  } else {
    const fs = data.params.fragmentShader;
    const vs = data.params.vertexShader;

    let values = compileUniformValues(item, data.params.uniforms);
    fragmentShaders = [runComplexReplacements(fs, values)];
    vertexShaders = [runComplexReplacements(vs, values)];

    if (data.passes && data.passes.length) {
      data.passes.forEach(pass => {
        item[pass.prop] = pass.value;
        values = compileUniformValues(item, data.params.uniforms);

        fragmentShaders.push(runComplexReplacements(fs, values));
        let compiledVs = runComplexReplacements(vs, values);
        if (compiledVs.trim() !== vertexShaders[0].trim()) {
          console.log('pass has unique');
          vertexShaders.push(compiledVs);
        }
      });
    }

    if (pingpongParams) {
      values = compileUniformValues(item, pingpongParams.uniforms);
      fragmentShaders.push(runComplexReplacements(pingpongParams.fragmentShader, values));
      vertexShaders.push(runComplexReplacements(pingpongParams.vertexShader, values));
    }
  }
  item.compiledFragmentShaders = fragmentShaders.map(n => minifyShader(n));
  item.compiledVertexShaders = vertexShaders.map(n => minifyShader(n));
  return item;
}
