<script>
import InputField from "./InputField.vue";
import { Vec2 } from "curtainsjs";

export default {
  components: {
    InputField,
  },
  props: ["label", "dims", "x", "y", "z", "locked", "min", "max", "output"],
  data() {
    return {
      xPos: this.x,
      yPos: this.y,
    };
  },
  created() {
    this.xPos = this.x;
    this.yPos = this.y;
  },
  watch: {
    x() {
      this.xPos = this.x;
    },
    y() {
      this.yPos = this.y;
    },
  },
  computed: {
    displayValue() {
      return Math.round(this.valuePercent);
    },
    multiplier() {
      return this.output === "percent" ? 100 : 1;
    },
  },
  methods: {
    updateValue() {
      this.$emit(
        "change",
        new Vec2(this.xPos, this.yPos)
      );
    },
    updateValueExact(val) {
      this.$emit(
        'change',
        val,
        this.dims ? 'W' : 'X',
        this.dims ? 'W' : 'X'
      )
    },
    handleTypeInput(e) {
      e = e ? e : window.event;
      var charCode = e.which ? e.which : e.keyCode;
      console.log(e);
      if (charCode === 13) {
        e.target.blur();
      }
      if (
        (charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46) ||
        e.target.value > 999
      ) {
        e.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<template>
  <div class="">
    <span
      v-if="locked !== undefined"
      class="lock-history-item button button__icon button__icon-auto-width"
      :class="{ item__locked: locked }"
      @click="$emit('toggle-lock', !locked)"
    >
      <svg
        v-if="locked"
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none"></rect>
        <rect
          x="39.99414"
          y="88"
          width="176"
          height="128"
          rx="8"
          stroke-width="16"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
        ></rect>
        <path
          d="M91.99414,88V52a36,36,0,1,1,72,0V88"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="16"
        ></path>
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none"></rect>
        <rect
          x="39.99414"
          y="88"
          width="176"
          height="128"
          rx="8"
          stroke-width="16"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
        ></rect>
        <path
          d="M91.99414,88V52a36,36,0,1,1,72,0"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="16"
        ></path>
      </svg>
    </span>
    <div class="coords-input-group">
      <InputField
        :label="dims ? 'W' : 'X'"
        :value="xPos"
        :output="output || 'percent'"
        @change="updateValueExact"
      >
      </InputField>
      <InputField
        :label="dims ? 'H' : 'Y'"
        :value="yPos"
        :output="output || 'percent'"
        @change="
          $emit(
            'change',
            $event * multiplier,
            dims ? 'H' : 'Y',
            dims ? 'W' : 'X'
          )
        "
      >
      </InputField>
      <InputField
        v-if="z !== undefined"
        label='<svg width="14" height="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.8914 2.1937C9.1158 2.35464 9.16725 2.66701 9.00631 2.89141L2.47388 12H13.5C13.7761 12 14 12.2239 14 12.5C14 12.7762 13.7761 13 13.5 13H1.5C1.31254 13 1.14082 12.8952 1.0552 12.7284C0.969578 12.5616 0.984438 12.361 1.09369 12.2086L8.19369 2.30862C8.35462 2.08422 8.667 2.03277 8.8914 2.1937ZM11.1 6.50001C11.1 6.22387 11.3238 6.00001 11.6 6.00001C11.8761 6.00001 12.1 6.22387 12.1 6.50001C12.1 6.77615 11.8761 7.00001 11.6 7.00001C11.3238 7.00001 11.1 6.77615 11.1 6.50001ZM10.4 4.00001C10.1239 4.00001 9.90003 4.22387 9.90003 4.50001C9.90003 4.77615 10.1239 5.00001 10.4 5.00001C10.6762 5.00001 10.9 4.77615 10.9 4.50001C10.9 4.22387 10.6762 4.00001 10.4 4.00001ZM12.1 8.50001C12.1 8.22387 12.3238 8.00001 12.6 8.00001C12.8761 8.00001 13.1 8.22387 13.1 8.50001C13.1 8.77615 12.8761 9.00001 12.6 9.00001C12.3238 9.00001 12.1 8.77615 12.1 8.50001ZM13.4 10C13.1239 10 12.9 10.2239 12.9 10.5C12.9 10.7761 13.1239 11 13.4 11C13.6762 11 13.9 10.7761 13.9 10.5C13.9 10.2239 13.6762 10 13.4 10Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>'
        :value="z"
        output="degrees"
        @change="$emit('change', $event, 'Z')"
      >
      </InputField>
    </div>
  </div>
</template>

<style lang="scss">
</style>