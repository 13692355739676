<script>
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider
} from "firebase/auth";
import Button from "../components/Button.vue";
import Input from "../components/Input.vue";
import router from '../router';
import { app, db } from "../firestore/init.js";
import { UserStore } from '../stores/UserStore';
import { logsnagIdentify } from '../scripts/LogSnag.js';
import * as UnicornStudio from '../lib/unicornStudio';

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export default {
  components: {
    Button,
    Input,
  },
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
      signupEnabled: this.$route.query.signup,
      signup: {
        name: "",
        email: "",
        password: "",
      },
      showGoogleSignIn: false,
      resettingPassword: false,
      signingup: this.$route.query.signup,
      creatingAccount: false,
      validation: {
        invalidEmail: false,
        error: "",
      },
    };
  },
  mounted() {
    this.showGoogleSignIn = localStorage.getItem('signedInWithGoogle');
    
    this.$nextTick(() => {
      try {
        const container = document.getElementById('unicorn');
        if (!container) {
          throw new Error('Unicorn container not found');
        }

        this.unicornStudio = UnicornStudio;
        if(window.location.hostname.includes('unicorn.studio') && window.UnicornStudio) {
          this.unicornStudio = window.UnicornStudio;
        }
        if(this.unicornStudio && this.unicornStudio.init) {
          this.unicornStudio.init();
        }
        
      } catch (error) {
        console.error('Failed to initialize UnicornStudio:', error);
      }
    });
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      if(this.signingup) {
        this.attemptSignup();
      } else {
        this.attemptLogin();
      }
    },
    attemptLogin(e) {
      if (this.login.email.length > 0) {
        if (this.login.password.length > 8) {
          signInWithEmailAndPassword(
            auth,
            this.login.email,
            this.login.password
          )
            .then((userCredential) => {
              // Signed in
              router.push(`/dashboard`);
              // ...
            })
            .catch((error) => {
              if(error.message === 'Firebase: Error (auth/wrong-password).') {
                this.validation.error = 'Incorrect password, please try again.';
              } else {
                this.validation.error = error.message;
              }
            });
        } else {
          this.validation.error =
            "Please choose a password long than 8 characters";
        }
      } else {
        this.validation.error = "Please enter a valid email";
      }
    },

    signUpWithGoogle() {
      signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        if(result._tokenResponse.isNewUser) {
          localStorage.setItem('signedInWithGoogle', true);
          this.creatingAccount = true;
          this.handleSuccessfulSignUp(user);
        } else {
          localStorage.setItem('signedInWithGoogle', true);
          UserStore.setUser(user);
          router.push(`/dashboard`);
        }

      }).catch((error) => {
        console.log(error)

        this.creatingAccount = false;
        // Handle Errors here.
        this.validation.error = error.code + ': ' + error.message;
        // The email of the user's account used.
        //const email = error.customData?.email;
        // The AuthCredential type that was used.
        //const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
    },
    attemptSignup() {
      if (this.login.email.length > 0) {
        if (this.login.password.length > 8) {
          this.creatingAccount = true;
          createUserWithEmailAndPassword(auth, this.login.email, this.login.password)
            .then((userCredential) => {
              // Signed in 
              const user = userCredential.user;
              this.handleSuccessfulSignUp(user);
            })
            .catch((error) => {
              console.log(this.validation.error)
              this.validation.error = error.code + ': ' + error.message;
              this.creatingAccount = false;
            });
        } else {
          this.validation.error =
            "Please choose a password long than 8 characters";
        }
      } else {
        this.validation.error = "Please enter a valid email";
      }
    },
    handleSuccessfulSignUp(user) {
      UserStore.setUser(user);
      logsnagIdentify({
        user_id: user.uid,
        properties: {
          email: user.email,
        }
      });
      this.creatingAccount = false;
      router.push(`/dashboard`);
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
  },
};
</script>

<template>
  <div class="login-container">
    <div v-if="creatingAccount" class="loading-bar"></div>
    <div class="form-container">
      <div class="login-form">
        <a href="" class="logo">
          <svg width="50px" height="50px" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M298.219 257.12 300 43.718C300 14.46 278.01.34 258.018.34c-23.59-1.243-35.984 15.615-41.982 30.971l-84.592 242.82L176.5 48C182.5 19 164 .05 134.79.05c-32.61-.206-71.413.29-93.808.29 0 0-15.493.66-27.988 12.16C-.162 24.61.106 40.387.106 40.387-.357 63.15.847 156.9.847 156.9.847 257.12 58 289.298 99.94 295.571c24.007 3.59 41.128-6.88 51.237-21.44l137.828-202.99c-24.157 58.337-69.97 165.148-72.251 170.58C203.542 275.727 231.748 300 256.237 300c30.769 0 42.149-28.587 41.982-42.88Z" fill="#fff"/></svg>
        </a>
        <h1 class="h2">Welcome</h1>

        <template v-if="signupEnabled || showGoogleSignIn">
          <Button v-if="signingup" class="secondary large mt-2 w-100" @click="signUpWithGoogle">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M128,128h88a88.1,88.1,0,1,1-25.8-62.2" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
            &nbsp Sign up with Google
          </Button>
          <Button v-else class="secondary large mt-2 w-100" @click="signUpWithGoogle">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M128,128h88a88.1,88.1,0,1,1-25.8-62.2" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
            &nbsp Sign in with Google
          </Button>
          <div class="divider my-3"></div>
          <form class="form" @submit="submitForm">
            <div class="field">
              <Input 
                class="field-input"
                autocomplete="username"
                type="email"
                placeholder="Email"
                v-model="login.email"
              />
            </div>
            <div class="field mt-2">
              <Input
                class="field-input"
                autocomplete="current-password"
                type="password"
                v-model="login.password"
                placeholder="Password"
              />
            </div>
            <div v-if="signingup" class="field mt-2">
              <Input
                class="field-input"
                autocomplete="current-password"
                type="password"
                v-model="login.repeatPassword"
                placeholder="Retype password"
              />
            </div>
            <p v-if="validation.error" class="validation-error">
              {{ validation.error }}
            </p>
            <Button class="primary large mt-4 w-100" type="submit">{{signingup ? 'Create account' : 'Login'}}</Button>
          </form>
          <a v-if="signupEnabled" class="a signup-link link" href="#" @click="signingup = !signingup">{{signingup ? 'Login to existing account' : 'Sign up'}}</a>
          <p v-if="signupEnabled" class="small-text font-tertiary-color mt-6" style="text-align: center;">By signing up for unicorn.studio up you agree to the <a href="https://www.unicorn.studio/terms" target="_blank">terms and conditions</a></p>
        </template>
        <template v-else>
          <form class="form" @submit="submitForm">
            <div class="field">
              <Input 
                class="field-input"
                autocomplete="username"
                type="email"
                placeholder="Email"
                v-model="login.email"
              />
            </div>
            <div class="field mt-2">
              <Input
                class="field-input"
                autocomplete="current-password"
                type="password"
                v-model="login.password"
                placeholder="Password"
              />
            </div>
            <p v-if="validation.error" class="validation-error">
              {{ validation.error }}
            </p>
            <Button class="primary large mt-4 w-100" type="submit">{{signingup ? 'Create account' : 'Login'}}</Button>
          </form>
        </template>
        
      </div>
    </div>
    <div class="video-container" id="unicorn" data-us-project="kRb0Sk7NF1hul6B24ukK?update=45"></div>
  </div>
</template>

<style scoped lang="scss">


.login-form {
  border-radius: 0.5rem;
  width: 26rem;

  .h2 {
    text-align: center;
    margin-bottom: 1em;
  }
}

.login-container {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  color: var(--font-color);
  background-color: var(--bg-color);

  @media only screen and (max-width: 58rem) {
    flex-direction: column;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
}

.form-container {
  width: 50%;
  border-right: 1px solid var(--border-color);

  @media only screen and (max-width: 58rem) {
    width: 100%;
    margin-top: 5rem;
  }
}

.video-container {
  width: 50%;

  @media only screen and (max-width: 58rem) {
    width: 100%;
    height: 50vh;
    margin-top: 5rem;
  }
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--accent-color);
}

.logo {
  display: block;
  width: 5rem;
  margin: 0 auto 3rem;
  margin-bottom: 3rem;
  color: inherit;

  img {
    width: 100%;
  }
}

video,
iframe {
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
}

iframe {
  outline: none;
  border: 0;
}

.signup-link {
  display: block;
  text-align: center;
  margin-top: 1.5rem;
}

</style>