<script>
import { computed } from 'vue';
import { UserStore } from '../stores/UserStore';
import { StudioStore } from '../stores/StudioStore';
import Button from '../components/Button.vue'
import Icon from '../components/Icon.vue'

export default {
  name: 'TrialInfo',
  components: {
    Button,
    Icon
  },
  props: ['showButton'],
  setup() {
    const daysRemaining = computed(() => {
      if (!UserStore.trialEndDate) return 0;

      const now = new Date();
      const trialEndDate = new Date(UserStore.trialEndDate);
      const timeDiff = trialEndDate - now;
      const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      return days > 0 ? days : 0;
    });


    return { daysRemaining };
  },
  methods: {
    signUpForPro() {
      this.$emit('sign-up');
    }
  }
};
</script>

<template>
  <div v-if="daysRemaining > 0" class="trial-info">
    <p class="my-0">You have <span style="color: var(--gold)">{{ daysRemaining }} day<span v-if="daysRemaining > 1">s</span></span> left in your free trial</p>
  </div>
  <div v-else class="trial-info flex align-center justify-center">
    <p class="my-0">Your free trial has ended</p>
    <Button
        v-if="showButton"
        href="#" 
        @click="signUpForPro" 
        class="secondary ml-4 flex align-center" style="font-size: 1.4rem">
        Upgrade
        <div class="tag flex ml-2" style="border: 1px solid var(--gold); background-color: transparent; color: var(--gold); font-weight: 1000">
          <Icon icon="legend" :size="8" class="mr-1" />Legend
        </div>
      </Button>
  </div>
</template>

<style scoped>

  .trial-info {
    color: var(--font-secondary-color);
  }

</style>
