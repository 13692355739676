import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  BLEND,
  UNIVERSAL_UNIFORMS,
  UNIVERSAL_HEADER,
  PERLIN_NOISE,
  universalUniformParams,
  interactiveProperties,
} from '../ShaderHelpers.js';
import { BLEND_MODES } from '../../scripts/Constants.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision highp float;

in vec2 vTextureCoord;

uniform sampler2D uTexture;

uniform vec3 uColor;
uniform vec2 uPos;
uniform float uAmount;
uniform float uRadius;
uniform float uThickness;
uniform float uAngle;
uniform float uAmplitude;
uniform float uTime;
uniform float uPhase;
uniform float uSkew;
uniform float uMix;
uniform int uBlendMode;
uniform int uType;
${UNIVERSAL_UNIFORMS}
${BLEND}

vec3 Tonemap_tanh(vec3 x) {
  x = clamp(x, -40.0, 40.0);
  return (exp(x) - exp(-x)) / (exp(x) + exp(-x));
}

float random(vec2 seed) {
  return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

out vec4 fragColor;

const float PI = 3.14159265359;
const float TWO_PI = 2.0 * PI;

float calculateAngle(vec2 point, vec2 center) {
  vec2 direction = point - center;
  float angle = atan(direction.y, direction.x);
  if (angle < 0.0) {
      angle += TWO_PI; // Normalize angle to [0, 2π]
  }
  return angle;
}

float angularDifference(float angle1, float angle2) {
  float diff = abs(angle1 - angle2);
  if (diff > PI) {
      diff = TWO_PI - diff; // Correct for angles passing through 0/2π
  }
  return diff;
}

float angularFading(float pointAngle, float peakAngle, float fadeAmount) {
  float diff = angularDifference(pointAngle, peakAngle);
  return 1.04 - smoothstep(0.0, fadeAmount, diff);
}

mat2 rot(float a) {
  return mat2(cos(a),-sin(a),sin(a),cos(a));
}

vec3 drawRing(vec2 uv, vec2 center, float scale, float angle) {
  uv.x *= uResolution.x/uResolution.y;
  center.x *= uResolution.x/uResolution.y;
  vec2 skew = vec2(uSkew, 1.-uSkew) * 2.;
  uv = uv * rot(uAngle * TWO_PI) * skew;
  center = center * rot(uAngle * TWO_PI) * skew;
  float ringRadius = scale * 0.5;
  float distFromCenter = length(uv - center);
  float ringDist = abs(distFromCenter - ringRadius);
  float lineRadius = uThickness * 0.25;
  float brightness = lineRadius / (1.0 - smoothstep(0.2, 0.002, ringDist + 0.02));

  angle = fract(uAngle + uTime * 0.01 + uPhase) * TWO_PI;

  float pointAngle = calculateAngle(uv, center);
  float peakAngle = angle; // Now angle is a parameter
  float angleFactor = angularFading(pointAngle, peakAngle, PI * 0.5);

  brightness *= angleFactor;

  vec3 ringColor = brightness * pow(1.-ringDist, 3.) * uColor;
  return ringColor;
}

vec3 drawLine(vec2 uv, vec2 center, float scale, float angle) {
  float radAngle = -angle * TWO_PI;
  float phase = fract(uTime * 0.01 + uPhase) * (3. * max(1., scale)) - (1.5 * max(1., scale));

  vec2 direction = vec2(cos(radAngle), sin(radAngle));

  vec2 centerToPoint = uv - center;

  float projection = dot(centerToPoint, direction);

  float distToLine = length(centerToPoint - projection * direction);

  float lineRadius = uThickness * 0.25;
  float brightness = lineRadius / (1. - smoothstep(0.4, 0., distToLine + 0.02));

  float glowRadius = scale; // Controls the spread of the glow
  float glow = smoothstep(glowRadius, 0.0, abs(projection - phase));

  return brightness * (1.-distToLine)*(1.-distToLine) * uColor * glow;
}

vec3 drawPoint(vec2 uv, vec2 center, float scale) {
  uv.x *= uResolution.x/uResolution.y;
  center.x *= uResolution.x/uResolution.y;
  vec2 skew = vec2(uSkew, 1.-uSkew) * 2.;
  uv = uv * rot(uAngle * TWO_PI) * skew;
  center = center * rot(uAngle * TWO_PI) * skew;
  float dist = distance(uv, center);
  float radius = scale * 0.25;

  float brightness = radius / dist;
  return brightness * uColor;
}

vec3 drawStar(vec2 uv, vec2 center, float scale) {
  uv.x *= uResolution.x/uResolution.y;
  center.x *= uResolution.x/uResolution.y;

  vec3 total = vec3(0);

  for(float i = 0.; i < 3.; i++) {
    float angle = uAngle + i/3. + uTime * 0.01;
    float thickness = uThickness * 0.5;
    vec2 skew = vec2(0.5 + thickness, 0.5 - thickness) * 2.;
    vec2 st =  uv * rot(angle * TWO_PI) * skew;
    vec2 c = center * rot(angle * TWO_PI) * skew;
    float dist = distance(st, c);
    float radius = scale * 0.25;

    vec3 brightness = (radius / dist) * uColor;

    total += brightness * 0.3333;
  }

  return total;
}

vec3 drawRadialLines(vec2 uv, vec2 center, float scale) {
  float brightness;
  float distToLine;

  for(int i = 0; i < 8; i++) {
    float radAngle = -1. * float(i)/8. * TWO_PI;
    float phase = fract(uTime * 0.01 + uPhase) * (3. * max(1., scale)) - (1.5 * max(1., scale));

    vec2 direction = vec2(cos(radAngle), sin(radAngle));

    vec2 centerToPoint = uv - center;

    float projection = dot(centerToPoint, direction);

    distToLine = length(centerToPoint - projection * direction);
    
    float lineRadius = uThickness * 0.25;
    brightness += lineRadius / (1. - smoothstep(0.4, 0., distToLine + 0.02));
  }

  float glowRadius = scale; // Controls the spread of the glow

  float glowDist = max(0., 1.-distToLine) * (1.-distance(uv, center))*scale;
  return brightness * glowDist*glowDist * uColor * glowRadius;
}

vec3 drawExpandingRings(vec2 uv, vec2 center, float scale, float angle) {
  uv.x *= uResolution.x/uResolution.y;
  center.x *= uResolution.x/uResolution.y;
  vec2 skew = vec2(uSkew, 1.-uSkew) * 2.;
  uv = uv * rot(uAngle * TWO_PI) * skew;
  center = center * rot(uAngle * TWO_PI) * skew;
  float modulo = fract(uTime * 0.02 + uPhase);
  float ringRadius = scale * 0.5 * modulo;
  float distFromCenter = length(uv - center);
  float ringDist = abs(distFromCenter - ringRadius);
  float lineRadius = uThickness * modulo;
  float brightness = lineRadius / (1.0 - smoothstep(0.2, 0.002, ringDist + 0.02));

  brightness = brightness * max(0., 1.-modulo);

  vec3 ringColor = brightness * pow(1.-ringDist, 3.) * uColor;
  return ringColor;
}

float sdSegment(vec2 p, vec2 a, vec2 b) {
  vec2 pa = p - a;
  vec2 ba = b - a;
  float h = clamp(dot(pa, ba) / dot(ba, ba), 0.0, 1.0);
  return length(pa - ba * h);
}

vec3 drawRectangularBeam(vec2 uv, vec2 center, float scale, float skew) {
  uv.x *= uResolution.x / uResolution.y;
  center.x *= uResolution.x / uResolution.y;
  vec2 skewFactor = vec2(uSkew, 1.-uSkew) * 2.;

  
  uv = uv * rot(uAngle * TWO_PI) * skewFactor;
  center = center * rot(uAngle * TWO_PI) * skewFactor;

  float halfWidth = scale * 0.5;
  float halfHeight = scale * 0.5;

  // Define the four corners of the rectangle
  vec2 topLeft = center + vec2(-halfWidth, halfHeight);
  vec2 topRight = center + vec2(halfWidth, halfHeight);
  vec2 bottomLeft = center + vec2(-halfWidth, -halfHeight);
  vec2 bottomRight = center + vec2(halfWidth, -halfHeight);

  // Calculate distance to each line segment of the rectangle
  float distToLeft = sdSegment(uv, bottomLeft, topLeft);
  float distToRight = sdSegment(uv, bottomRight, topRight);
  float distToTop = sdSegment(uv, topLeft, topRight);
  float distToBottom = sdSegment(uv, bottomLeft, bottomRight);

  // Find minimum distance to any of the rectangle's sides
  float sdf = min(min(distToLeft, distToRight), min(distToTop, distToBottom));

  float brightness = 1./sdf * uThickness * 0.1;


  return brightness * uColor;
}

vec3 getBeam(vec2 uv) {
  vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);
  switch(uType) {
    case 0: return drawLine(uv, pos, uRadius, uAngle); break;
    case 1: return drawPoint(uv, pos, uRadius); break;
    case 2: return drawRing(uv, pos, uRadius, uAngle); break;
    case 3: return drawExpandingRings(uv, pos, uRadius, uAngle); break;
    case 4: return drawStar(uv, pos, uRadius); break;
    case 5: return drawRectangularBeam(uv, pos, uRadius, uSkew); break;
    default: return drawPoint(uv, pos, uRadius); break;
  }
}


void main() {
  vec2 uv = vTextureCoord;
  vec4 bg = texture(uTexture, uv);

  vec3 beam = getBeam(uv);
  float dither = (random(gl_FragCoord.xy) - 0.5) / 255.0;

  vec3 blended = blend(uBlendMode, Tonemap_tanh(beam), bg.rgb);
  vec3 result = mix(bg.rgb, blended, uMix);
  result += dither;

  fragColor = vec4(result, bg.a);
}`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
  },
  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    radius: {
      name: 'uRadius',
      type: '1f',
      value: 0.5,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    skew: {
      name: 'uSkew',
      type: '1f',
      value: 0.5,
    },
    displace: {
      name: 'uDisplace',
      type: '1f',
      value: 0,
    },
    thickness: {
      name: 'uThickness',
      type: '1f',
      value: 0.5,
    },
    phase: {
      name: 'uPhase',
      type: '1f',
      value: 0.5,
    },
    mix: {
      name: 'uMix',
      type: '1f',
      value: 1,
    },
    beamType: {
      name: 'uType',
      type: '1i',
      value: 0,
    },
    color: {
      name: 'uColor',
      type: '3f',
      value: new Vec3(0.4, 0.1, 1),
    },
    blendMode: {
      name: 'uBlendMode',
      type: '1i',
      value: 1,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 1,
    },
    ...universalUniformParams,
  },
};

export const BEAM = {
  id: 'beam',
  label: 'Beam',
  params: params,
  animation: {
    active: false,
    speed: 1,
  },
  aspectRatio: 1,
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    beamType: {
      label: 'Type',
      value: 0,
      options: {
        0: 'Line',
        1: 'Point',
        2: 'Ring',
        3: 'Nova',
        4: 'Star',
        5: 'Rect',
      },
      responsiveDisabled: true,
    },
    color: {
      label: 'Color',
      value: new Vec3(0.4, 0.1, 1),
      output: 'color',
      responsiveDisabled: true,
    },
    radius: {
      label: 'Scale',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees',
    },
    thickness: {
      label: 'Thickness',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    phase: {
      label: 'Phase',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    skew: {
      label: 'Skew',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    mix: {
      label: 'Opacity',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    blendMode: {
      label: 'Blend mode',
      value: 'ADD',
      options: BLEND_MODES,
      responsiveDisabled: true,
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    ...interactiveProperties,
  },
};
