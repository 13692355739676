<script>
import ColorInput from "./ColorInput.vue";
import SliderParam from "./SliderParam.vue";
import InputField from "./InputField.vue";
import RadioToggle from "./RadioToggle.vue";
import ClassicToggle from "./ClassicToggle.vue";
import PlayButton from "./PlayButton.vue";
import Button from "./Button.vue";
import CoordInput from "./CoordInput.vue";
import ParamLabel from "./ParamLabel.vue";
import Icon from "./Icon.vue";
import DropdownMenu from './DropdownMenu.vue';
import AlignmentControls from './AlignmentControls.vue';
import { StudioStore } from "../stores/StudioStore.js";
import { rotate, getShapeBoundingBox } from "../scripts/Draw.js";

function filterByOccurrence(originalArray, n) {
  // Count the occurrences of each item
  const countMap = new Map();
  originalArray.forEach(item => {
    countMap.set(item, (countMap.get(item) || 0) + 1);
  });

  // Filter items based on the occurrence count
  const filteredArray = originalArray.filter(item => countMap.get(item) === n);

  // Remove duplicates from the filtered array
  return Array.from(new Set(filteredArray));
}

function getAlignmentCoords(item) {
  const box = getShapeBoundingBox(item.coords);
  const coords = (
    item.layerType === 'draw' ? box.corners : item.coords
  ).map(([x, y]) =>
    rotate(box.center.x, box.center.y, x, y, -item.rotation * 360)
  );
  const position = item.getPositionOffset();

  return coords.map(([x, y]) => {
    return [
      Math.round((x + position.x)),
      Math.round((y + position.y)),
    ];
  });
}

const AlignmentType = {
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
  CENTER_VERTICAL: 'centerVertical',
  CENTER_HORIZONTAL: 'centerHorizontal'
};

export default {
  components: {
    ColorInput,
    InputField,
    SliderParam,
    CoordInput,
    Button,
    Icon,
    ParamLabel,
    ClassicToggle,
    RadioToggle,
    DropdownMenu,
    AlignmentControls,
    PlayButton
  },
  props: ['background', 'childEffect', 'label', 'randomizer'],
  data() {
    return {
      pickColor: null,
      propsKey: 1,
      ignoreList: ['effects', 'coords', 'local', 'visible', 'locked', 'aspectRatio', 'isElement', 'layerType', 'mask'],
      AlignmentType: {
        LEFT: 'left',
        RIGHT: 'right',
        TOP: 'top',
        BOTTOM: 'bottom',
        CENTER_VERTICAL: 'centerVertical',
        CENTER_HORIZONTAL: 'centerHorizontal'
      }
    };
  },
  // created() {
  //   console.log(this.getMatchingProperties())
  // },
  computed: {
    items() {
      return StudioStore.state.selectedItems;
    },
    matchingProps() {
      return this.getMatchingProperties();
    },
    pixlePosition() {
      return this.matchingProps.includes('translateX') && this.matchingProps.includes('translateY');
    },
    pixelPositionValue() {
      return this.getPixelPositionValue();
    }
  },
  // watch: {
  //   "items.length"() {
  //     console.log(this.getMatchingProperties())
  //   }
  // },
  methods: {
    getMatchingProperties() {
      let props = []
      this.items.forEach(item => {
        for(let prop in item) {
          if(!this.ignoreList.includes(prop)) {
            props.push(prop);
          }
        }
      });
      return filterByOccurrence(props, this.items.length).reverse();
    },
    getPixelPositionValue() {
      let x = undefined;
      let y = undefined;

      this.items.forEach((item, index) => {
        // Determine current item's translateX and translateY values
        const currentX = typeof item.getTranslateX === 'function' ? item.getTranslateX() : item.translateX;
        const currentY = typeof item.getTranslateY === 'function' ? item.getTranslateY() : item.translateY;

        // For the first item, just set x and y
        if (index === 0) {
          x = currentX;
          y = currentY;
        } else {
          // If current item's x is not equal to the previously stored x, mark as Mixed
          if (currentX !== x) {
            x = 'Mixed';
          }
          // If current item's y is not equal to the previously stored y, mark as Mixed
          if (currentY !== y) {
            y = 'Mixed';
          }
        }
      });
      
      return { x, y };
    },

    handleCoordChange(val, prop) {
      if(val.type) {
        return;
      }
      if(prop === 'x') {
        this.items.forEach(item => {
          if(item.getTranslateX) {
            item.setTranslateX(val);
          } else {
            item.translateX = val;
          }
        });
      } 
      if(prop === 'y') {
        this.items.forEach(item => {
          if(item.getTranslateY) {
            item.setTranslateY(val);
          } else {
            item.translateY = val;
          }
        });
      }
      this.$emit('change');
    },
  },
};
</script>

<template :key="propsKey">
  <div class="effect-properties control-section-wrapper" ref="effectWindow">
    <div class="effect-header">
      <div class="parameter-label">Multiple layers</div>
    </div>
    <AlignmentControls @change="$emit('change')" />

    <div v-if="pixlePosition" class="parameter parameter__block">
        <ParamLabel
            label="Position"
          >Position</ParamLabel>
        <div class="coords-input-group">
          <InputField
            :label="'X'"
            :value="pixelPositionValue.x"
            output="px"
            @change="handleCoordChange($event, 'x')"
            :slider="true"
          />
          <InputField
            :label="'Y'"
            :value="pixelPositionValue.y"
            output="px"
            @change="handleCoordChange($event, 'y')"
            :slider="true"
          />
        </div>
      </div>
  </div>
</template>

<style lang="scss">
.effect-label {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
}

.effect-properties {
  padding: 1.5rem;
  width: 100%;
  background-color: var(--bg-color);
  color: var(--font-color);

  .button-group {
    margin-top: 1.5rem;
  }

  .parameter {
    padding-left: 0;
  }

  &.effect-properties__video-export {
    left: 0;
    top: calc(100% + 1rem);
  }
}

.effect-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 1rem;
}

.effect-title {
  display: flex;
  align-items: center;
  user-select: none;
}

.effect-actions {
  display: flex;
  align-items: center;
  color: var(--font-secondary-color);

  .button + .button {
    margin-left: 0.5rem;
  }

  .button:hover {
    color: var(--font-color);
  }
}

.button.effect-animate-button {
  position: relative;
  padding: 0.4rem;
  border-radius: 50%;
  border: 1px solid var(--border-color);
}

.effect-animate-button__animating {
  border: 1px solid transparent;

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 50%;
    border: 2px dotted var(--primary-color);
    animation: spin 8s linear infinite;
  }

  &.effect-animate-button__animating-hidden {
    &:before {
      border: 2px dotted var(--font-secondary-color);
    }
  }
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
</style>