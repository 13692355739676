<script>
import Button from "./Button.vue";
import Icon from "./Icon.vue";
import { StudioStore } from "../stores/StudioStore.js";
import { rotate, getShapeBoundingBox } from "../scripts/Draw.js";

function getAlignmentCoords(item) {
  const box = getShapeBoundingBox(item.coords);
  const coords = (
    item.layerType === 'draw' ? box.corners : item.coords
  ).map(([x, y]) =>
    rotate(box.center.x, box.center.y, x, y, -item.rotation * 360)
  );
  const position = item.getPositionOffset();

  return coords.map(([x, y]) => {
    return [
      Math.round((x + position.x)),
      Math.round((y + position.y)),
    ];
  });
}

export default {
  components: {
    Button,
    Icon,
  },
  props: ['item'],
  data() {
    return {
      pickColor: null,
      propsKey: 1,
      AlignmentType: {
        LEFT: 'left',
        RIGHT: 'right',
        TOP: 'top',
        BOTTOM: 'bottom',
        CENTER_VERTICAL: 'centerVertical',
        CENTER_HORIZONTAL: 'centerHorizontal'
      }
    };
  },
  computed: {
    items() {
      return StudioStore.state.selectedItems;
    },
  },
  methods: {
    alignItem(item, alignmentType) {
      let AlignmentType = this.AlignmentType;
      const isHorizontal = [AlignmentType.LEFT, AlignmentType.RIGHT, AlignmentType.CENTER_HORIZONTAL].includes(alignmentType);

      let artboardWidth = StudioStore.state.canvasWidth;
      let artboardHeight = StudioStore.state.canvasHeight;
      let artboardCenterX = artboardWidth / 2;
      let artboardCenterY = artboardHeight / 2;

      if (item.layerType === 'effect' && item.pos) {
        if (isHorizontal) {
          let xPos = item.pos.x;
          if (alignmentType === AlignmentType.LEFT) {
            xPos = 0;
          } else if (alignmentType === AlignmentType.RIGHT) {
            xPos = 1;
          } else if (alignmentType === AlignmentType.CENTER_HORIZONTAL) {
            xPos = 0.5;
          }
          item.pos.x = xPos;
        } else {
          let yPos = item.pos.y;
          if (alignmentType === AlignmentType.TOP) {
            yPos = 0;
          } else if (alignmentType === AlignmentType.BOTTOM) {
            yPos = 1;
          } else if (alignmentType === AlignmentType.CENTER_VERTICAL) {
            yPos = 0.5;
          }
          item.pos.y = yPos;
        }
      } else {
        const coords = getAlignmentCoords(item);
        let itemMinX = Math.min(...coords.map(coord => coord[0]));
        let itemMaxX = Math.max(...coords.map(coord => coord[0]));
        let itemMinY = Math.min(...coords.map(coord => coord[1]));
        let itemMaxY = Math.max(...coords.map(coord => coord[1]));
  
        let offsetX = 0;
        let offsetY = 0;
  
        if (alignmentType === AlignmentType.LEFT) {
          offsetX = -itemMinX;
        } else if (alignmentType === AlignmentType.RIGHT) {
          offsetX = artboardWidth - itemMaxX;
        } else if (alignmentType === AlignmentType.CENTER_HORIZONTAL) {
          offsetX = artboardCenterX - ((itemMinX + itemMaxX) / 2);
        }
  
        if (alignmentType === AlignmentType.TOP) {
          offsetY = -itemMinY;
        } else if (alignmentType === AlignmentType.BOTTOM) {
          offsetY = artboardHeight - itemMaxY;
        } else if (alignmentType === AlignmentType.CENTER_VERTICAL) {
          offsetY = artboardCenterY - ((itemMinY + itemMaxY) / 2);
        }
  
        if (isHorizontal) {
          if (item.setTranslateX) {
            item.setTranslateX(item.getTranslateX() + offsetX);
          } else {
            item.translateX += offsetX;
          }
        } else {
          if (item.setTranslateY) {
            item.setTranslateY(item.getTranslateY() + offsetY);
          } else {
            item.translateY += offsetY;
          }
        }
      }

    },
    alignItems(alignmentType) {
      if(this.item) {
        this.alignItem(this.item, alignmentType);
        this.$emit('change');
        return;
      }

      this.items.forEach(item => {
        this.alignItem(item, alignmentType);
      });

      this.$emit('change');
    }
  },
};
</script>

<template :key="propsKey">
  <div class="flex font-secondary-color mb-3">
    <Button class="icon" @click="alignItems(AlignmentType.LEFT)"><Icon icon="alignLeft" /></Button>
    <Button class="icon" @click="alignItems(AlignmentType.CENTER_HORIZONTAL)"><Icon icon="alignCenterHorizontal" /></Button>
    <Button class="icon" @click="alignItems(AlignmentType.RIGHT)"><Icon icon="alignRight" /></Button>
    <Button class="icon" @click="alignItems(AlignmentType.TOP)"><Icon icon="alignTop" /></Button>
    <Button class="icon" @click="alignItems(AlignmentType.CENTER_VERTICAL)"><Icon icon="alignCenterVertical" /></Button>
    <Button class="icon" @click="alignItems(AlignmentType.BOTTOM)"><Icon icon="alignBottom" /></Button>
  </div>
</template>