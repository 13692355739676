<script>
  import Icon from './Icon.vue';
  import AssetItem from './AssetItem.vue';
  import { StudioStore } from '../stores/StudioStore';
  import { CreateCommand } from '../scripts/Commands.js';
  import { Effect } from '../scripts/LayerTypes.js';

  const SHAPE_KEYS = {
      'torus': {
        label: 'Torus',
        value: 0
      },
      'box': {
        label: 'Box',
        value: 1
      },
      'sphere': {
        label: 'Sphere',
        value: 2
      },
      'capsule': {
        label: 'Capsule',
        value: 3
      },
      'disc': {
        label: 'Disc',
        value: 4
      },
      'cylinder': {
        label: 'Cylinder',
        value: 5
      },
      'octahedron': {
        label: 'Octahedron',
        value: 6
      },
      'hex': {
        label: 'Hex Prism',
        value: 7
      },
      'plus': {
        label: 'Plus',
        value: 8
      },
      'spring': {
        label: 'Spring',
        value: 9
      },
      'tricylinder': {
        label: 'Tricylinder',
        value: 10
      },
      'triangle': {
        label: 'Triangle',
        value: 11
      },
      'rounded_cross': {
        label: 'Rounded cross',
        value: 12
      },
      'rounded_rect': {
        label: 'Rounded Rectangle',
        value: 13
      },
      'discs': {
        label: 'Merged Discs',
        value: 14
      },
      'rippled_sphere': {
        label: 'Rippled sphere',
        value: 15
      },
      'top': {
        label: 'Top',
        value: 16
      },
      'star': {
        label: 'Star',
        value: 17
      },
      'pyramid': {
        label: 'Pyramid',
        value: 18
      },
      'asterisk': {
        label: 'Asterisk',
        value: 19
      },
      'dodecahedron': {
        label: 'Dodecahedron',
        value: 21
      },
    }

  export default {
  components: {
    Icon,
    AssetItem
  },
  props: ['currentSize'],
  data() {
    return {
      state: StudioStore.state,
      shapes: SHAPE_KEYS
    }
  },
  mounted() {
    document.addEventListener("mousedown", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  },
  methods: {
    selectShape(shape) {
      const command = new CreateCommand(new Effect({
        type: 'sdf_shape',
        shape: shape.value
      }));
      StudioStore.performAction(command);
      StudioStore.state.sdfShapeBrowser = false;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        StudioStore.state.sdfShapeBrowser = false;
      }
    },
    createCustomSvgSDF() {
      StudioStore.state.imagesBrowser.open = true;
      StudioStore.state.imagesBrowser.createMsdf = true;
      StudioStore.state.sdfShapeBrowser = false;
    }
  },
  computed: {
    selectedItem() { return StudioStore.getSelectedItem()},
    settingEffectTexture() { return this.selectedItem && this.selectedItem.layerType === 'effect' && (this.selectedItem.type === 'custom' || this.selectedItem.type === 'sdf_shape')}
  },
}
</script>

<template>
  <div class="modal modal__image-library modal__pop-in" :class="{'modal__right': settingEffectTexture}">
    <div class="images-container">
      <div v-if="state.imageUploading" class="image-library-new">
        <svg class="saving-spinner" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
          <path d="M16 8.5A7.5 7.5 0 1 1 8.5 1" stroke="currentColor"/>
        </svg>
        <span style="margin-top: 0.5rem">{{ state.imageUploadProgress }}%</span>
      </div>
      <div class="image-library-new">
        <button @click="createCustomSvgSDF" class="button button__icon flex direction-column" style="width: 100%; height: 100%">
          <Icon size="16" icon="upload" />
          SVG
        </button>
      </div>
      <div class="image-library-image" v-for="(shape, key) in shapes" :key="key">
        <img @click="selectShape(shape)" :src="'/images/sdf_shape_icons/' + key + '.png'">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.modal__image-library {
  width: 58rem;
  height: 65rem;
  top: 5.9rem;
  right: unset;
  left: 28.5rem;
  border-radius: 0.8rem;
  z-index: 99999;
  overflow: hidden;
  
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2rem;
    width: 100%;
    background: linear-gradient(to top, var(--bg-color), var(--bg-color-no-alpha))
  }

  &.modal__right {
    left: unset;
    right: 29rem;
  }
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding-bottom: 3rem;
  margin-left: -0.5rem;
  margin-bottom: -0.5rem;
  width: calc(100% + 1rem);
  overflow-y: auto;
  &>* {
    margin: 0.5rem;
  }
}

.image-library-new {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 13rem;
  height: 13rem;
  border: 1px dashed var(--accent-color);
  color: var(--font-secondary-color);
  border-radius: 0.4rem;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    background-color: var(--accent-color);
    color: var(--font-color);
  }
  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.image-library-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 12rem;
  height: 12rem;
  box-shadow: inset 0 0 0 0.1rem var(--accent-color);
  color: var(--font-secondary-color);
  border-radius: 0.4rem;
}

.image-library-image {
  position: relative;
  width: 13rem;
  height: 13rem;
  box-shadow: inset 0 0 0 0.1rem var(--accent-color);
  border-radius: 0.4rem;
  background-image: linear-gradient(45deg, var(--artboard-color) 25%, transparent 25%), linear-gradient(135deg, var(--artboard-color) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--artboard-color) 75%), linear-gradient(135deg, transparent 75%, var(--artboard-color) 75%);
  background-size: 25px 25px;
  /* Must be a square */
  background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    box-shadow: inset 0 0 0 0.1rem var(--font-color);

    .edit-design-button {
      display: block;
    }
  }
}

</style>