<script>
  import ColorInput from './ColorInput.vue';
  import SliderParam from './SliderParam.vue';
  import RadioToggle from './RadioToggle.vue';
  import { ColorService, getFillStyle , hexToRgb} from '../scripts/ColorHelpers.js';
  import  { Vec3 } from 'curtainsjs'

  const COLOR_TYPES = [
    { value: 'colors', label: 'Colors' },
    { value: 'gradients', label: 'Gradients' },
  ];
  const GRAD_TYPES = [
    { value: 'linear', label: 'Linear' },
    { value: 'conic', label: 'Conic' },
    { value: 'radial', label: 'Radial' },
  ]

  export default {
    components: {
      ColorInput,
      SliderParam,
      RadioToggle
    },
    props: ['color', 'viewingSwatchesProp', 'historyColors'],
    data() {
      return {
        grads: ColorService.grads,
        swatches: [...ColorService.swatches],
        editGradColorIndex: undefined,
        colorTypes: COLOR_TYPES,
        gradientTypes: GRAD_TYPES,
        gradientTypesBg: [
          {value: '0', label: 'Linear'},
          {value: '1', label: 'Radial'},
          {value: '2', label: 'Conic'},
        ],
        colorType: 'colors',
        originalFill: this.color.item[this.color.prop || 'fill'],
        viewingSwatches: this.viewingSwatchesProp || this.fill && this.fill.length === 1 || false,
      }
    },
    mounted() {
      document.addEventListener("mousedown", this.handleClickOutside);
    },
    beforeUnmount() { // Vue 3 uses beforeUnmount instead of beforeDestroy
      document.removeEventListener("mousedown", this.handleClickOutside);
    },
    created() {
      this.$nextTick(() => {
        this.colorType = this.fill.length > 1 ? 'gradients' : 'colors';
      });
    },
    computed: {
      fill() {
        return this.color.item[this.color.prop || 'fill'];
      },
      gradInsertIndices() {
        return Array.from({ length: this.fill.length + 1 }, (_, index) => index);
      },
      noGradients() {
        return this.originalFill.type || this.color.prop === 'stroke';
      }
    },
    methods: {
      getFillStyle(fill, deg) {
        return getFillStyle(fill, deg);
      },
      selectFill(fill) {
        if(this.color.colorsInUse) {
          this.$emit('update', fill);
        } else {

          if(this.color.item[this.color.prop || 'fill'].type) {
            let rgb = hexToRgb(fill[0]);
            fill = new Vec3(...rgb.map(n => n/255));
          }

          this.color.item[this.color.prop || 'fill'] = fill;

          this.$emit('update');
        }
      },
      selectGradFill(color, index) {
        if((color instanceof Event)) {
          return
        }
        // Create a deep copy of the gradient array
        let gradient = JSON.parse(JSON.stringify(this.color.item[this.color.prop || 'fill']));
        
        let idx = this.editGradColorIndex !== undefined ? this.editGradColorIndex : index || 0;
        gradient[idx] = typeof color === 'string' ? color : color[0];
        
        this.color.item[this.color.prop || 'fill'] = gradient;
        this.$emit('update');
      },

      handleClickOutside(event) {
        // Check if the click was outside the component's element
        if (!this.$el.contains(event.target)) {
          this.$emit('close');
        }

        if(this.$refs.gradColorPicker && !this.$refs.gradColorPicker.contains(event.target)) {
          this.editGradColorIndex = undefined;
        }
      },
      removeGradColor(i) {
        let gradient = JSON.parse(JSON.stringify(this.color.item[this.color.prop || 'fill']));
        gradient.splice(i, 1);
        this.color.item[this.color.prop || 'fill'] = gradient;
        this.$emit('update');
      },
      addGradColor(i) {
        let gradient = JSON.parse(JSON.stringify(this.color.item[this.color.prop || 'fill']));
        gradient.splice(i, 0, '#AAAAAA');
        this.color.item[this.color.prop || 'fill'] = gradient;
        this.$emit('update');
      },
      handleColorChange(e) {
        if (this.editGradColorIndex !== undefined) {
          const colorValue = Array.isArray(e) ? e[0] : e;
          this.selectGradFill(colorValue);
        } else {
          if (!(e instanceof Event)) {
            this.selectFill(e);
          }
        }
      }
    },
  }
</script>

<template>
  <div class="color-container" ref="colorContainer" aria-modal="true" role="dialog" :style="{right: color.right || '' }">
    <div v-if="!noGradients" class="color-picker-header">
      <RadioToggle
        v-model="colorType"
        :options="colorTypes"
      >
      </RadioToggle>
    </div>
    <div class="color-swatches">
      <div class="flex">
        <template v-if="fill && fill.length > 1 && colorType === 'gradients'">
          <div class="grad-add-buttons" v-if="fill.length < 16">
            <div
              v-for="i in gradInsertIndices"
              :key="'grad-add-btn' + i"
              class="button button__icon button__icon-auto-width button__icon-circle gray"
              @click="addGradColor(i)"
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 256 256"><path d="M222,128a6,6,0,0,1-6,6H134v82a6,6,0,0,1-12,0V134H40a6,6,0,0,1,0-12h82V40a6,6,0,0,1,12,0v82h82A6,6,0,0,1,222,128Z"></path></svg>
            </div>
          </div>
          <div class="grad-color-container w-100 flex direction-column">
            <div class="flex align-center w-100 mt-1"
              v-for="(color, i) in fill"
              :key="color.toString() + i"
            >
              <ColorInput
                :fill="[color]"
                @change="(color) => selectGradFill(color, i)"
                @click-swatch="editGradColorIndex = i"
              ></ColorInput>
              <div class="button button__icon button__icon-auto-width gray ml-auto"
                @click="removeGradColor(i)"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 256 256"><path d="M216,50H174V40a22,22,0,0,0-22-22H104A22,22,0,0,0,82,40V50H40a6,6,0,0,0,0,12H50V208a14,14,0,0,0,14,14H192a14,14,0,0,0,14-14V62h10a6,6,0,0,0,0-12ZM94,40a10,10,0,0,1,10-10h48a10,10,0,0,1,10,10V50H94ZM194,208a2,2,0,0,1-2,2H64a2,2,0,0,1-2-2V62H194ZM110,104v64a6,6,0,0,1-12,0V104a6,6,0,0,1,12,0Zm48,0v64a6,6,0,0,1-12,0V104a6,6,0,0,1,12,0Z"></path></svg>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="fill && colorType === 'colors'" class="parameter parameter__block">
        <ColorInput
          :fill="fill"
          :picker="true"
          @change="handleColorChange($event)"
        ></ColorInput>
      </div>

      <div v-if="colorType === 'gradients' && 'gradientType' in color.item" class="grad-controls">
        <RadioToggle
          v-if="color.item.isBackground"
          v-model="color.item.gradientType"
          :options="gradientTypesBg"
          @change="$emit('update')"
        >
        </RadioToggle>
        <RadioToggle
          v-else
          v-model="color.item.gradientType"
          :options="gradientTypes"
          @change="$emit('update')"
        >
        </RadioToggle>
        <SliderParam
          v-model="color.item.gradientAngle"
          :min="0"
          :max="1"
          :step=".0027"
          output="degrees"
          @update="$emit('update')"
        ></SliderParam>
      </div>
      <div class="brushes" v-if="colorType === 'colors'">
        <template v-for="swatch in swatches">
          <a href="javascript:void(0)" v-for="(color, i) in swatch" class="brush"
            :key="i"
            :class="{'brush__selected': fill && fill.toString() === [color].toString()}" @click="selectFill([color])"
            :style="getFillStyle([color])"></a>
        </template>
      </div>
      <div class="brushes" v-else>
        <a v-for="(grad, i) in grads" href="javascript:void(0)" class="brush"
          :key="i"
          :class="{'brush__selected': fill && fill.toString() === grad.toString()}" @click="selectFill(grad)"
          :style="getFillStyle(grad)"></a>
      </div>
      <div class="label" style="margin-top: 1.5rem;">Document colors</div>
      <div class="brushes">
        <a v-for="(col, i) in historyColors" href="javascript:void(0)" class="brush"
          :key="i"
          :class="{'brush__selected': fill && fill.toString() === col.toString()}" @click="selectFill(col)"
          :style="getFillStyle(col)"></a>
      </div>
    </div>

    <div v-if="editGradColorIndex !== undefined" ref="gradColorPicker"  class="gradient-color-picker">
      <ColorInput
        :fill="fill"
        :picker="true"
        :index="editGradColorIndex"
        @change="handleColorChange($event)"
      ></ColorInput>
      <div class="gradient-color-picker-wrapper mt-3">
        <template v-for="swatch in swatches">
          <a href="javascript:void(0)" v-for="(color, i) in swatch" class="brush"
            :key="'swatch' + i"
            :class="{'brush__selected': fill && fill.toString() === [color].toString()}" @click="selectGradFill(color)"
            :style="getFillStyle([color])"></a>
        </template>
      </div>
    </div>
  </div>

</template>

<style lang="scss">

.color-container {
  display: flex;
  flex-direction: column;
  z-index: 9999999999;
  width: 35rem;
  right: 29rem;
  position: absolute;
  top: 5.9rem;
  padding: 1.5rem;
  border-radius: 0.8rem;
  transform-origin: 0 0;
  box-shadow: 0 0.5rem 2rem rgba(#000, 0.15);
  background-color: var(--bg-color);

  .button .selected-color {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: text-top;
  }

  &.color-container__history {
    right: 0;
    left: unset;
  }

  .color-picker-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5rem;

    .parameter {
      width: auto;
      justify-content: normal;
    }
  }
  
  .parameter {
    padding-left: 0;
  }

  .selected-gradient {
    display: flex;
    justify-content: space-between;

  }

  .color-stop {
    width: 1rem;
    height: 100%;
    background-color: white;
    border: 2px solid white;
  }

  .gradient-color-picker {
    position: absolute;
    width: 35rem;
    right: 35.5rem;
    top: 0;
    padding: 1.5rem;
    border-radius: 0.8rem;
    z-index: 9999999999;
    box-shadow: 0 0.5rem 2rem rgba(#000, 0.15);
    background-color: var(--bg-color);
  }

  .gradient-color-picker-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .grad-add-buttons {
    margin-top: -0.7rem;

    .button__icon-circle {
      margin-bottom: 0.9rem;
    }
  }
}


</style>