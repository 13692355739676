<script>
import ClassicToggle from './ClassicToggle.vue';
import SliderParam from './SliderParam.vue';
import { StudioStore } from '../stores/StudioStore.js';

export default {
  components: {
    ClassicToggle,
    SliderParam
  },
  props: ['item'],
  emits: ['input', 'change'],
  data() {
    return {
      userOpen: this.item.displace > 0 || false
    };
  },
  watch: {
    "item.displace"() {
      if(!StudioStore.state.mouse.dragging) {
        this.userOpen = this.item.displace > 0;
      }
    },
    userOpen() {
      if(!this.userOpen) {
        this.item.displace = 0;
        this.item.bgDisplace = 0;
      } else {
        this.item.displace = 0.5;
      }
      this.$emit('change');
    }
  }
}
</script>

<template>
  <ClassicToggle label="Displace"
      v-model="userOpen"
  ></ClassicToggle>
  <div class="child-block mt-2" v-if="userOpen">
    <SliderParam label="Amount"
      v-model="item.displace"
      :min="0"
      :max="1"
      :step="0.01"
      output="percent"
      @update="$emit('input')"
    ></SliderParam>
    <ClassicToggle v-if="userOpen" label="Use background"
        v-model="item.bgDisplace"
        @change="$emit('change')"
    ></ClassicToggle>
    <ClassicToggle v-if="userOpen && item.bgDisplace" label="Dispersion"
        v-model="item.dispersion"
        @change="$emit('change')"
    ></ClassicToggle>
  </div>
</template>

<style lang="scss">

.child-block {
  width: calc(100% - 1rem);
  margin-left: var(--unit2);
}

</style>