import { computeFragColor, vertexShader, FLOATING_POINT, UNIVERSAL_UNIFORMS, universalUniformParams, interactiveProperties } from '../ShaderHelpers.js';
import { Vec2 } from 'curtainsjs';

const swirlShader = `#version 300 es
  precision mediump float;
  in vec3 vVertexPosition;
  in vec2 vTextureCoord;
  uniform sampler2D uTexture;
  uniform float uRadius;
  uniform float uAngle;
  uniform float uPhase;
  uniform float uTime;
  uniform float uPinch;
  uniform float uMix;
  uniform vec2 uPos;
  ${UNIVERSAL_UNIFORMS}

  out vec4 fragColor;
  
  void main() {
    vec2 uv = vTextureCoord;
    float angle = uAngle * 10.;
    vec2 originalUV = uv; // store the original UV coordinates
    vec2 pos = uPos + mix(vec2(0), (uMousePos-0.5), uTrackMouse);

    uv -= pos;
    vec2 R = vec2(uv.x * uResolution.x / uResolution.y, uv.y);
    float distanceToCenter = length(R);
    distanceToCenter += uPinch*0.5;
    if (distanceToCenter <= uRadius) {
        // Apply the swirl only when the distance to the center point is within uRadius
        float rot = atan(R.y, R.x) + angle * smoothstep(uRadius, 0., distanceToCenter);
        uv = vec2(cos(rot + uTime / 20. + uPhase * 6.28), sin(rot + uTime / 20. + uPhase * 6.28));
        uv = distanceToCenter * uv + pos;
    }
    float t = smoothstep(0., uRadius, distanceToCenter); // linear interpolation factor
    vec2 mixedUV = mix(uv, originalUV, t); // mix distorted uv with original uv based on distance from the center

    vec4 color = texture(uTexture, mix(vTextureCoord, mixedUV, uMix));
    ${computeFragColor('color')}
  }
`;

const swirlParams = {
  fragmentShader: swirlShader,
  crossorigin: 'Anonymous',
  vertexShader,
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    radius: {
      name: "uRadius",
      type: "1f",
      value: 0.5,
    },
    angle: {
      name: "uAngle",
      type: "1f",
      value: 0.5,
    },
    pos: {
      name: "uPos",
      type: "2f",
      value: new Vec2(0.5),
    },
    time: {
      name: "uTime",
      type: "1f",
      value: 0,
    },
    pinch: {
      name: "uPinch",
      type: "1f",
      value: 0,
    },
    mix: {
      name: "uMix",
      type: "1f",
      value: 1,
    },
    phase: {
      name: "uPhase",
      type: "1f",
      value: 0,
    },
    ...universalUniformParams
  }
}

export const SWIRL = {
  id: 'swirl',
  label: 'Swirl',
  params: swirlParams,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 1
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    angle: {
      label: 'Amount',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    radius: {
      label: 'Radius',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    pinch: {
      label: 'Pinch',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    mix: {
      label: 'Mix',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    phase: {
      label: 'Rotation',
      value: 0,
      min: 0,
      max: 1,
      step: 0.0027,
      output: 'degrees'
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent'
    },
    ...interactiveProperties,
  }
}