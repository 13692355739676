import {
  computeFragColor,
  vertexShader,
  FLOATING_POINT,
  UNIVERSAL_UNIFORMS,
  PERLIN_NOISE,
  universalUniformParams,
} from '../ShaderHelpers.js';
import { Vec2, Vec3 } from 'curtainsjs';

const fragmentShader = `#version 300 es
precision mediump float;

// Want a quick intro?
// Select "Shader tutorial" from the dropdown above

in vec2 vTextureCoord;

uniform sampler2D uTexture; // Underlying scene

uniform vec2 uPos;
uniform float uAmount;
uniform float uScale;
uniform float uFrequency;
uniform float uAngle;
uniform float uAmplitude;
uniform float uThreshold;
uniform int uStyle;
uniform float uTime;
${UNIVERSAL_UNIFORMS}

const float PI = 3.14159265359;
mat2 rot(float a) {
  return mat2(cos(a),-sin(a),sin(a),cos(a));
}

float cubicInOut(float t) {
  return t < 0.5
    ? 4.0 * t * t * t
    : 0.5 * pow(2.0 * t - 2.0, 3.0) + 1.0;
}

float getWaveLine(vec2 uv, float intensity) {
  float lineFreq = 150.0 * uScale;
  float waveAmplitude = 0.04 * uAmplitude;
  float waveFreq = 8.0 * uFrequency;
  
  // Calculate the sine wave offset for the line's path
  float sineOffset = waveAmplitude * sin((uv.x + uTime*0.005) * waveFreq * 2.0 * 3.14159);
  
  // Apply the offset to uv.y
  uv.y += sineOffset;
  
  // Variable line width based on intensity
  float lineWidth = mix(0., 1., intensity);

  float linePosition = fract(uv.y * lineFreq);
  float gradient = smoothstep(0., lineWidth, linePosition) - smoothstep(1.0 - lineWidth, 1.0, linePosition);

  return smoothstep(0., lineWidth*1.5, gradient*gradient);
}

float getZigZagLine(vec2 uv, float lineWidth) {
    float lineFreq = 150.0 * uScale;
    float waveAmplitude = 0.8 * uAmplitude;  // Adjust to control wave height
    
    float flipInterval = 0.2 * (1. - uFrequency + 0.001);   // Adjust this value to change how often the mirroring happens.
    float flip = mod(floor(uv.x / flipInterval), 2.0); // Produces 0 or 1 based on interval.
    
    // Translate UVs to rotate around the center of the flip interval
    uv.x -= flipInterval * 0.5 + floor(uv.x / flipInterval) * flipInterval + uTime*0.005;
    
    // Flip the rotation angle if flip == 1
    uv = (uv * rot(waveAmplitude * (flip * 2.0 - 1.0))); 
    
    // Translate UVs back after rotation
    uv.x += flipInterval * 0.5;

    float linePosition = fract(uv.y * lineFreq);

    float gradient = smoothstep(0.0, lineWidth, linePosition) - smoothstep(1.0 - lineWidth, 1.0, linePosition);
    
    return smoothstep(0., lineWidth*1.5, gradient*gradient);
}

float getGuillocheLine(vec2 uv, float lineWidth) {
    float lineFreq = 150.0 * uScale;
    float waveAmplitude = 0.04 * uAmplitude;  // Adjust to control wave height
    float waveFreq = 8.0 * uFrequency;       // Adjust to control number of waves across width

    // Apply sine wave to uv.y based on uv.x
    uv.x += waveAmplitude * sin((uv.y + uTime*0.005) * waveFreq * 2.0 * 3.14159);

    float linePosition = fract(uv.x * lineFreq);

    float gradient = smoothstep(0.0, lineWidth, linePosition) - smoothstep(1.0 - lineWidth, 1.0, linePosition);
    
    return smoothstep(0., 1., gradient*gradient);
}

float getGuillocheFull(vec2 uv, float lineWidth) {
  float mult = getGuillocheLine(uv * rot(0.25 * 2. * PI), lineWidth) * 0.75;
  float add = getGuillocheLine(uv * rot(0.25 * 2. * PI), lineWidth) + getGuillocheLine(uv, lineWidth);
  return mix(add, mult, smoothstep(0., 1., lineWidth));
}

vec3 photoshop_desaturate(vec3 color)
  {
    float bw = (min(color.r, min(color.g, color.b)) + max(color.r, max(color.g, color.b))) * 0.5;
    return vec3(bw);
  }

out vec4 fragColor;

void main() {
  vec2 uv = vTextureCoord;

  vec4 color = texture(uTexture, uv);
  vec3 linographed = vec3(0);

  float red = 1.-color.r;
  float green = 1.-color.g;
  float blue = 1.-color.b;

  vec2 st = rot(uAngle * -1. * 2. * PI) * ((uv * vec2(uResolution.x/uResolution.y, 1.)) - uPos);
  
  if(uStyle == 0) {
    linographed = vec3(
      getGuillocheFull(st, red),
      getGuillocheFull(st, green),
      getGuillocheFull(st, blue)
    );
  }
  if(uStyle == 1) {
    linographed = vec3(
      getWaveLine(st, red),
      getWaveLine(st, green),
      getWaveLine(st, blue)
    );
  }
  if(uStyle == 2) {
    linographed = vec3(
      getZigZagLine(st, red),
      getZigZagLine(st, green),
      getZigZagLine(st, blue)
    );
  }

  color.rgb = mix(color.rgb, linographed, uAmount);

  ${computeFragColor('color')}
}`;

const params = {
  fragmentShader: fragmentShader,
  vertexShader,
  crossorigin: 'Anonymous',
  depthTest: false,
  texturesOptions: {
    floatingPoint: FLOATING_POINT,
    premultiplyAlpha: true,
  },
  uniforms: {
    pos: {
      name: 'uPos',
      type: '2f',
      value: new Vec2(0.5),
    },
    scale: {
      name: 'uScale',
      type: '1f',
      value: 0.75,
    },
    style: {
      name: 'uStyle',
      type: '1i',
      value: 0,
    },
    amplitude: {
      name: 'uAmplitude',
      type: '1f',
      value: 0.5,
    },
    amount: {
      name: 'uAmount',
      type: '1f',
      value: 1,
    },
    threshold: {
      name: 'uThreshold',
      type: '1f',
      value: 0.5,
    },
    frequency: {
      name: 'uFrequency',
      type: '1f',
      value: 0.5,
    },
    angle: {
      name: 'uAngle',
      type: '1f',
      value: 0,
    },
    time: {
      name: 'uTime',
      type: '1f',
      value: 0,
    },
    ...universalUniformParams,
  },
};

export const GUILLOCHE = {
  id: 'guilloche',
  label: 'Guilloche',
  params: params,
  aspectRatio: 1,
  animation: {
    active: false,
    speed: 0.25,
  },
  properties: {
    pos: {
      label: 'Position',
      value: new Vec2(0.5),
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    style: {
      label: 'Style',
      value: 0,
      options: {
        0: 'Mesh',
        1: 'Waves',
        2: 'Zigzag',
      },
    },
    scale: {
      label: 'Scale',
      value: 0.75,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    // threshold: {
    //   label: 'Threshold',
    //   value: 0.5,
    //   min: 0,
    //   max: 1,
    //   step: 0.01,
    //   output: 'percent'
    // },
    amount: {
      label: 'Mix',
      value: 1,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    frequency: {
      label: 'Frequency',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    amplitude: {
      label: 'Amplitude',
      value: 0.5,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    angle: {
      label: 'Angle',
      value: 0,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
    speed: {
      label: 'Speed',
      header: 'Animation',
      value: 0.25,
      min: 0,
      max: 1,
      step: 0.01,
      output: 'percent',
    },
  },
};
